<template>
  <div class="pdftoolbox d-block w-100 h-auto">
    <a
      href="javascript:void(0)"
      class="arrow next"
      :class="{
        'd-none': this.$parent.displayInCanvas == this.$parent.requiredPage,
      }"
      @click="this.$parent.nextCanvas()"
    >
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
    </a>
    <a
      href="javascript:void(0)"
      class="arrow prev"
      :class="{
        'd-none': this.$parent.displayInCanvas == 1,
      }"
      @click="this.$parent.prevCanvas()"
    >
      <i class="fa fa-chevron-left" aria-hidden="true"></i>
    </a>
    <div
      v-for="index in this.$parent.requiredPage"
      :id="'dyncamiccanvasgenerate' + index"
      :key="index"
      class="d-flex w-100 flex-column"
      :class="{
        'd-none': this.$parent.displayInCanvas != index,
      }"
    >
      <div
        class="color-select-box position-fixed"
        :class="{
          'd-none': this.$parent.canvasText == true,
        }"
      >
        <div class="d-flex align-items-center">
          <div
            id="bleedArea"
            class="color-wrapper brown checkbox"
            :class="{
              boxSelected:
                this.$parent.bleedAreaVisiable[this.$parent.displayInCanvas] == true,
              'd-none':
                this.$parent.isbleedAreaPresent[this.$parent.displayInCanvas] == false,
            }"
            @click="this.$parent.bleedAreaShow()"
          >
            <div class="brown-box"><i class="far fa-check"></i></div>
            <span>Bleed Area</span>
          </div>
          <div
            id="mediaEnable"
            class="color-wrapper blue checkbox"
            :class="{
              boxSelected:
                this.$parent.MediaVisiable[this.$parent.displayInCanvas] == true,
              'd-none':
                this.$parent.isMediaboxPresent[this.$parent.displayInCanvas] == false,
            }"
            @click="this.$parent.mediaShow()"
          >
            <div class="blue-box"><i class="far fa-check"></i></div>
            <span>Media Box</span>
          </div>
          <div
            id="bleedEnable"
            class="color-wrapper red checkbox"
            :class="{
              boxSelected:
                this.$parent.BleedVisiable[this.$parent.displayInCanvas] === true,
              'd-none':
                this.$parent.isBleedboxPresent[this.$parent.displayInCanvas] === false,
            }"
            @click="this.$parent.bleedShow()"
          >
            <div class="red-box"><i class="far fa-check"></i></div>
            <span>Bleed Box</span>
          </div>
          <div
            id="trimEnable"
            class="color-wrapper green checkbox"
            :class="{
              boxSelected:
                this.$parent.TrimVisiable[this.$parent.displayInCanvas] == true,
              'd-none':
                this.$parent.isTrimboxPresent[this.$parent.displayInCanvas] == false,
            }"
            @click="this.$parent.trimShow()"
          >
            <div class="green-box"><i class="far fa-check"></i></div>
            <span>Trim Box</span>
          </div>

          <div
            id="cropEnable"
            class="color-wrapper purple checkbox"
            :class="{
              boxSelected:
                this.$parent.CropVisiable[this.$parent.displayInCanvas] == true,
              'd-none':
                this.$parent.isCropboxPresent[this.$parent.displayInCanvas] == false,
            }"
            @click="this.$parent.cropShow()"
          >
            <div class="purple-box"><i class="far fa-check"></i></div>
            <span>Crop Box</span>
          </div>
          <div
            id="artEnable"
            class="color-wrapper brown checkbox"
            :class="{
              boxSelected: this.$parent.ArtVisiable[this.$parent.displayInCanvas] == true,
              'd-none':
                this.$parent.isArtboxPresent[this.$parent.displayInCanvas] == false,
            }"
            @click="this.$parent.artShow()"
          >
            <div class="brown-box"><i class="far fa-check"></i></div>
            <span>Art Box </span>
          </div>
          <div
            id="panBtn"
            class="color-wrapper"
            :class="{
              'link-active bg-white':
                this.$parent.panning[this.$parent.displayInCanvas] == true,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Pan"
            @click="this.$parent.updatePannning()"
          >
            <i class="far fa-hand-paper"></i>
          </div>
          <div
            id="zoomInBtn"
            class="color-wrapper"
            :class="{
              'link-active bg-white':
                this.$parent.zoomFlag[this.$parent.displayInCanvas] == 1,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Zoom In"
            @click="this.$parent.ZoomIn(0.1, '1')"
          >
            <i class="far fa-search-plus"></i>
          </div>
          <div
            id="zoomOutBtn"
            class="color-wrapper"
            :class="{
              'link-active bg-white':
                this.$parent.zoomFlag[this.$parent.displayInCanvas] == 2,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Zoom Out"
            @click="this.$parent.ZoomIn(-0.1, '2')"
          >
            <i class="far fa-search-minus"></i>
          </div>
          <div
            id="resetZoom"
            class="color-wrapper resetBtn"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Fit Page"
            @click="this.$parent.resetCanvas()"
          >
            <i class="fal fa-arrows"></i>
          </div>
          <div class="zoomDropDown">
            <div class="input-group input-group-outline">
              <!-- <input id="showZoom" type="hidden" name="showZoom" value="100%" /> -->
              <input
                id="showZoomNew"
                v-model="this.$parent.dynamicZoom[this.$parent.displayInCanvas]"
                type="number"
                name="showZoomNew"
                class="form-control form-control-default"
                @keyup.enter="this.$parent.setZoom()"
              />
              <span class="symbol">%</span>
              <span class="dropdown dropstart">
                <a
                  id="dropZoom"
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="far fa-angle-down"></i
                ></a>
                <!-- <div v-for="(item, key, index) in this.$parent.zoomArray" :key="key">
                  <p>{{item}}  %</p>
                </div> -->
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                  data-bs-popper="none"
                >
                  <li
                    v-for="(item, key) in this.$parent.zoomArray"
                    :key="key"
                    class="mb-2"
                  >
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="this.$parent.setZoom(item)"
                      >{{ item }}%</a
                    >
                  </li>
                </ul>
              </span>
            </div>
          </div>
          <div
            id="goBack"
            class="color-wrapper gobackBtn"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-original-title="Go back"
            @click="this.$parent.goBack()"
          >
            <i class="far fa-times"></i>
          </div>
        </div>
      </div>
      <div
        class="card d-block"
        :class="{
          verticalMainDiv: this.$parent.isHeight != false,
          multiDiv: this.$parent.isWidth != false,
          canvasBlank: this.$parent.canvasText == true,
        }"
        :style="[
          this.$parent.MediaBoxHeight[this.$parent.displayInCanvas]
            ? '--minHeight:' + this.$parent.MediaBoxHeight[this.$parent.displayInCanvas]
            : '',
          '',
          this.$parent.MediaBoxWidth[this.$parent.displayInCanvas]
            ? '--maxWidth:' + this.$parent.MediaBoxWidth[this.$parent.displayInCanvas]
            : '',
          '',
        ]"
      >
        <!-- :style="'--minHeight:'+ this.$parent.MediaBoxHeight[this.$parent.displayInCanvas]+';--maxWidth:'+ this.$parent.MediaBoxWidth[this.$parent.displayInCanvas]+';'" -->

        <div
          class="imagePreviewWrapper draggable"
          :class="{
            verticalImage: this.$parent.isHeight != false,
            cursorZoomIn: this.$parent.zoomFlag[this.$parent.displayInCanvas] == 1,
            cursorZoomOut: this.$parent.zoomFlag[this.$parent.displayInCanvas] == 2,
            cursorPanning: this.$parent.panning[this.$parent.displayInCanvas] == true,
          }"
        >
          <canvas
            :id="`dynamicCanvas` + index"
            class="imagePreviewWrapper draggable canvasDrag"
          ></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setTooltip from "@/assets/js/tooltip.js";

export default {
  name: "PDFTool",

  mounted() {
    setTooltip(this.$store.state.bootstrap);

    document.getElementById("goBack").addEventListener("click", function () {
      const goBack = document.getElementById("goBack");
      const tooltip = this.$store.state.bootstrap.Tooltip.getOrCreateInstance(goBack);
      tooltip.hide();
    });
  },
};
</script>
