<template>
  <div class="py-4 px-0 container custom-container upload-page-container">
    <div class="position-fixed inner_toaster">
      <div v-for="(msg, index) in processingMsg" :key="index">
        <vmd-snackbar
          v-if="msg.status && msg.status === 'show'"
          :description="msg.translate == true ? $t(msg.message) : msg.message"
          :icon="{ color: 'white' }"
          :color="msg.icon"
          @click="closeSnackbarArray(index)"
        />
      </div>
      <ExtraNotification ref="extraNotificationRef"></ExtraNotification>
    </div>

    <div class="page-title-new">
      <span class="divider"></span>
      <h6 :class="color">
        {{ $t("upload-image.page-title") }}
      </h6>
    </div>
    <div class="row">
      <div class="col-lg-12 main-content">
        <h3 class="mb-0">{{ $t("upload-image.upload-image-title") }}</h3>

        <div class="dropzone-middle-box">
          <!-- <perfect-scrollbar> -->
          <div id="productImg" style="width: 100%" class="form-control border dropzone">
            <div id="template-container" style="display: none">
              <div class="main-upload" data-name="data-dz-name">
                <div class="image-upload">
                  <img data-dz-thumbnail />
                </div>
                <div class="upload-content">
                  <div class="dz-filename">
                    <h5 data-dz-name></h5>
                    <span class="text-white opacity-6" data-dz-size></span>
                  </div>
                  <div class="dz-progress">
                    <span class="dz-upload" data-dz-uploadprogress></span>
                  </div>
                  <div class="upload-status">
                    <div class="dz-success-mark d-none">
                      <!-- <i class="fa fa-spinner fa-spin"></i> -->
                      <!-- <i class="fas fa-check"></i> -->
                    </div>
                    <div class="dz-error-mark">
                      <i class="fas fa-times"></i>
                    </div>
                  </div>
                  <!-- Hide it bacause of Cannot POST error -->
                  <div class="dz-error-message">
                    <span data-dz-errormessage></span>
                  </div>

                  <div class="dz-success-message">
                    <!-- <span data-dz-successmessage></span> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="dz-message" data-dz-message>
              <span v-html-safe="$t('upload-image.dropzone-text')"></span>
            </div>
          </div>
          <!-- </perfect-scrollbar> -->
          <div class="multiupload-dropzone-btns d-none">
            <vmd-button
              class="shadow-none rounded-0 font-weight-normal submit-btn btn-sm"
              color="outline-grey"
              @click="openmodal()"
              >{{ $t("upload-image.dropbox-btn-text") }}
            </vmd-button>
            <vmd-button
              class="shadow-none rounded-0 font-weight-normal submit-btn btn-sm"
              color="outline-grey"
              @click="openmodal()"
              >{{ $t("upload-image.googledrive-btn-text") }}
            </vmd-button>
            <vmd-button
              class="shadow-none rounded-0 font-weight-normal submit-btn btn-sm"
              color="outline-grey"
              @click="openmodal()"
              >{{ $t("upload-image.onedrive-btn-text") }}
            </vmd-button>
            <vmd-button
              class="shadow-none rounded-0 font-weight-normal submit-btn btn-sm"
              color="outline-grey"
              @click="openmodal()"
              >{{ $t("upload-image.ftp-btn-text") }}
            </vmd-button>

            <div
              id="jkanban-info-modal"
              class="modal fade"
              style="display: none"
              tabindex="-1"
              role="dialog"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="h5 modal-title"></h5>
                    <button
                      type="button"
                      class="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="pt-4 modal-body text-center">
                    <img src="@/assets/img/coming_soon.jpg" />
                    <div class="text-end">
                      <button
                        class="m-1 btn btn-secondary"
                        data-bs-dismiss="modal"
                        data-target="#jkanban-info-modal"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="action-dropzone-btns mt-3">
            <vmd-button
              class="shadow-none rounded-pill font-weight-normal submit-btn btn-sm"
              color="primary"
              @click="nextClick"
            >
              {{ $t("upload-image.upload-continue-btn") }}
            </vmd-button>
            <vmd-button
              class="shadow-none rounded-pill font-weight-normal submit-btn btn-sm"
              color="outline-grey"
              :disabled="!isAbortActive"
              @click="abort"
            >
              {{ $t("upload-image.abort-btn") }}
            </vmd-button>
            <vmd-button
              v-if="quickCheck > 0"
              class="shadow-none rounded-pill font-weight-normal submit-btn btn-sm"
              color="primary"
              @click="preview"
              >{{ $t("upload-image.upload-next-btn") }}
            </vmd-button>
          </div>
        </div>
      </div>
      <div class="position-fixed floating-sidebar-right bg-dark">
        <div class="preview-box">
          <img src="@/assets/img/upload/sidebar_image.png" />
        </div>
        <div class="preview-details-box">
          <h6>{{ $t("upload-image.order-information") }}</h6>
          <h5>{{ $t("upload-image.business-card-title") }}</h5>
          <div class="basic-infos">
            <perfect-scrollbar>
              <div class="single-info upperView">
                <p>
                  <strong class="opacity-6 fw-bold">{{
                    $t("release-print-data.label-status")
                  }}</strong
                  >:
                  <i
                    class="fas fa-circle"
                    :title="orderStatus"
                    :class="{
                      'text-danger': ['error', 'internalError'].includes(orderStatus),
                      'text-success': orderStatus === 'done',
                      'text-warning': [
                        'waitForFeedback',
                        'warning',
                        'processing',
                        'created',
                      ].includes(orderStatus),
                    }"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    :data-bs-original-title="orderStatus"
                  ></i>
                </p>
              </div>

              <div class="single-info upperView">
                <p>
                  <strong class="opacity-6">{{
                    $t("release-print-data.label-milestone")
                  }}</strong
                  >: {{ orderMilestone }}
                </p>
              </div>

              <!--new div-->
              <div
                class="order-details d-flex align-items-center justify-content-between"
              >
                <div class="order-content">
                  <div
                    v-for="producttype in apiResponse"
                    :key="producttype.id"
                    class="orderPreviewDetails"
                  >
                    <div class="orderDetailTitle">
                      <p>
                        {{ $t("order-common.orderdetail-modal-productType") }}:
                        <strong class="text-white">{{ producttype.t0ype }}</strong>
                      </p>
                    </div>
                    <div
                      v-for="productparts in producttype.parts"
                      :key="productparts.id"
                      class="orderDetailContent"
                    >
                      <h5>{{ productparts.type }}</h5>
                      <div class="innerDetailContent">
                        <div class="single-info">
                          <h6>{{ $t("release-print-data.label-motifs") }}</h6>
                          <p>
                            {{ productparts.pageranges[0].variants.length }}
                            {{ $t("release-print-data.text-variant") }}
                          </p>
                        </div>
                        <div class="single-info">
                          <h6>{{ $t("release-print-data.label-format") }}</h6>
                          <p>
                            {{
                              updateOrderFormat(
                                productparts.intent.layoutIntent.dimensions.width
                              )
                            }}
                            *
                            {{
                              updateOrderFormat(
                                productparts.intent.layoutIntent.dimensions.height
                              )
                            }}

                            {{ dimensionFormat }}
                          </p>
                        </div>
                        <div class="single-info">
                          <h6>{{ $t("release-print-data.label-noofpages") }}</h6>
                          <p>
                            {{
                              updateOrderPages(
                                productparts.intent.layoutIntent.sides,
                                productparts.intent.layoutIntent.pages
                              )
                            }}
                            {{ $t("release-print-data.text-pages") }}
                          </p>
                        </div>
                        <div class="single-info">
                          <h6>{{ $t("release-print-data.label-colourfulness") }}</h6>
                          <p>
                            {{
                              productparts.intent.colorIntent.surfaceColor.front
                                .colorsUsed.length
                            }}
                            /

                            {{
                              productparts.intent.colorIntent.surfaceColor.back.colorsUsed
                                .length
                            }}
                          </p>
                        </div>
                        <div class="single-info">
                          <h6>{{ $t("release-print-data.label-material") }}</h6>
                          <p>{{ material }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import VmdButton from "@/components/VmdButton.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import store from "@/store";
import i18n from "@/i18n";
import axiosInterceptor from "@/shared/axiosInterceptor";
import VmdSnackbar from "@/components/VmdSnackbar.vue";
import { mapGetters } from "vuex";

import updateDimension from "../../Mixins/updateDimension";
import orderInfomration from "../../Mixins/orderInfomration";
import commonDropzone from "../../Mixins/commonDropzone";
import ExtraNotification from "./ExtraNotification.vue";
export default {
  name: "UploadImage",
  components: {
    VmdButton,
    PerfectScrollbar,
    VmdSnackbar,
    ExtraNotification,
  },
  mixins: [updateDimension, orderInfomration, commonDropzone],
  inject: ["eventBus"],
  data() {
    return {
      apiResponse: null,
      radixContainerId: 0,
      allFile: 0,
      uuid: null,
      fileuploadFlag: 0,
      // snackbar: null,
      allFileList: [],
      fileList: [],
      isActive: true,
      isAbortActive: true,
      validFile: 0,
      orderId: 0,
      socketOrderId: 0,
      socketStart: false,
      fileUploadComplete: 0,
      splitList: [],
      nextclick: false,
      loaderDisplayPosition: document.body,
      loader: null,
      loaderStart: false,
      k: 0,
      l: 0,
      isLoading: false,
      fullPage: true,
      errorFile: 0,
      resetFile: 0,
      socketClientId: null,
      abortList: [],
      //  fileAborted: false,
      predefineUploadText:
        "<i class='fas fa-upload'></i>" +
        i18n.global.t("upload-image.predefine-upload-text") +
        " : ",
      predefineSplitPDFText:
        "<i class='fas fa-file-pdf'></i>" +
        i18n.global.t("upload-image.predefine-splitPDF-text") +
        " : ",
      predefineSaveImageText:
        "<i class='fas fa-image'></i>" +
        i18n.global.t("upload-image.predefine-saveAsImage-text") +
        " : ",
      connectionLost: null,
      orderType: null,
      orderFormatheight: null,
      orderFormatwidth: null,
      orderPages: null,
      orderSides: null,
      orderColourfullnessFront: null,
      orderColourfullnessBack: null,
      default_mm_value: null,
      default_inch_value: null,
      myDropzone: "",
      DropzoneInit: false,
      setTimer: null,
      totalFile: 0,
      orderStatus: null,
      orderMilestone: null,
      spiltPDFCompleted: 0,
      checksocketconnect: null,
      resourceAvailable: false,
      mergePDF: 0,
      quickCheck: 0,
      allProcessDone: 0,
      processingMsg: [],
      mergeMessage: 0,
      quickcheckMessage: 0,
      nextClickCount: 0,
      assignedUniqueId: 0,
      addedFile: 0,
      totalCountSocket: 0,
    };
  },
  computed: {
    schema: function () {
      let temp = i18n.global.t("upload-image.error-message-duplicatefile");
      return temp;
    },
    ...mapGetters("auth", {
      getterLoginStatus1: "getRadixData",
    }),
    test: function () {
      return i18n.global.t("upload-image.predefine-upload-text");
    },
    updateOrderFormat: function () {
      store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },
    dimensionFormat() {
      let currentDimension = store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    successMessageForPDF() {
      return i18n.global.t("order-common.socket-PDF-upload");
    },
    /*quickCheckMessage() {
      return i18n.global.t("upload-image.toasterMessage-quickCheck");
    },

    UploadedFileFlag() {
      let checksameOrder = store.getters["auth/allorderInfo"];
      console.log(`checksameOrder`, chsecksameOrder);
      this.fileuploadFlag += parseInt(1);
      return this.fileuploadFlag;
    },*/
  },
  async mounted() {
    await this.getOrderDetail();
    this.default_mm_value = process.env.VUE_APP_MM_VALUE;
    this.default_inch_value = process.env.VUE_APP_INCH_VALUE;
    this.autoRefreshToken(3);

    this.socketClientId = this.$socket.id;
    //Active Abort Btn
    this.eventBus.on("activeAbortBtn", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        await this.activeAbortBtn(response.message);
      }
    });
    //MergePDF
    this.eventBus.on("increasemergePDF", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.updateMergePDF(response.message);
      }
    });
    //quickcheck
    this.eventBus.on("increasequickcheck", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.updatequickcheck(response.message);
      }
    });

    //saveasimage
    this.eventBus.on("increasesaveimage", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.updatesaveimage(response.message);
      }
    });

    //Extra Notification
    this.eventBus.on("generateExtraNotification", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.response.message.orderId
      );
      if (checkStatus == false && this.$route.name == "UploadImage") {
        if (Object.keys(response).length > 0) {
          await this.PassNotificationData(response);
        }
      }
    });

    this.$socket.on("connect_error", (error) => {
      /*this.connectionLost = true;*/
      console.log("connection_error", error);
      this.AddProcessingMsg(
        "connection_error",
        "Socket Connection Lost.Please refresh the page",
        "danger",
        false,
        false
      );

      //this.snackbarTimeOut(4);
      //this.socket.connect();
      //this.socket.emit("msgToServer", {});
    });
    /*this.socket.on('reconnect_attempt', () => {
        console.log("reconnect_attempt")
        this.socket.io.opts.query = {
          clientId: this.socketClientId || ''
        }
      });*/
  },

  beforeUnmount() {
    this.socketOrderId = 0;
    clearInterval(this.setTimer);
    this.checksocketconnect = null;
    //Need to off otherwise trigger multiple times
    this.eventBus.off("generateExtraNotification");
  },
  methods: {
    async PassNotificationData(response) {
      if (Object.keys(response).length > 0) {
        await this.$refs.extraNotificationRef.childNotification(response);
      }
    },
    async ValidateOpenURL(URLOrderId, responseOrderId) {
      var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      if (format.test(URLOrderId)) {
        URLOrderId = await this.decryptOrderId(URLOrderId);
      }
      if (this.socketOrderId == responseOrderId && this.$route.name == "UploadImage") {
        return true;
      } else {
        return false;
      }
    },
    closeSnackbarArray(index) {
      this.processingMsg[index].status = "hide";
    },
    AddProcessingMsg(messageType, message, type, timeoutFlag, translate = true) {
      this.mergeMessage = this.mergeMessage + 1;
      const ifExist = this.processingMsg.findIndex((object) => {
        return object.type == messageType;
      });
      const newMessage = {
        message,
        type: messageType,
        status: "show",
        id: this.mergeMessage,
        icon: type,
        translate,
      };

      if (ifExist >= 0) {
        //console.log("update new message", messageType);
        //update current message
        Object.assign(this.processingMsg[ifExist], newMessage);
      } else {
        this.processingMsg.push(newMessage);
      }

      if (timeoutFlag == true) {
        this.snackbarTimeOutArray(this.mergeMessage);
      }
    },
    async updatequickcheck(response) {
      if (
        this.socketOrderId > 0 &&
        parseInt(this.socketOrderId) == parseInt(response.orderId)
      ) {
        let message = "upload-image.toasterMessage-quickCheck";
        let icon = "success";
        if (response.status == "error") {
          message = "some error occur";
          icon = "danger";

          this.validFile -= parseInt(1);
          const ifExist = this.allFileList.findIndex((object) => {
            return parseInt(object.uplResourceId) === parseInt(response.resourceId);
          });

          if (ifExist >= 0) {
            this.allFileList[ifExist]["uniqueId"] = response.uniqueId;
          }

          let imageGenerate = null;
          await this.$store.dispatch("auth/imageGenerate", this.orderId).then((res) => {
            imageGenerate = res.status;
          });

          if (imageGenerate == false) {
            this.isAbortActive = true;
            const ifExist = this.processingMsg.findIndex((object) => {
              return object.type == "quickcheck";
            });

            this.processingMsg.splice(ifExist, 1);
          }
        }

        if (response.status == "completed") {
          this.quickCheck += parseInt(1);

          // Only once quickcheck
          //  if (this.quickCheck == 1) {
          this.AddProcessingMsg(
            "quickcheck",
            message,
            icon,
            icon == "danger" ? true : false,
            icon == "danger" ? false : true
          );
          this.quickcheckMessage += parseInt(1);
          //}
        }
      }
    },
    async updatesaveimage(response) {
      if (
        this.socketOrderId > 0 &&
        parseInt(this.socketOrderId) == parseInt(response.orderId)
      ) {
        if (response.status == "error") {
          this.validFile -= parseInt(1);
          /*const ifExist = this.allFileList.findIndex((object) => {
            return parseInt(object.uplResourceId) === parseInt(response.resourceId);
          });

          if (ifExist >= 0) {
            this.allFileList[ifExist]["uniqueId"] = response.uniqueId;
            this.abortList.push({
              id: this.allFileList[ifExist]["uplResourceId"],
            });
          }*/

          let imageGenerate = null;
          await this.$store.dispatch("auth/imageGenerate", this.orderId).then((res) => {
            imageGenerate = res.status;
          });
          if (imageGenerate == false) {
            this.isAbortActive = true;
            const ifExist = this.processingMsg.findIndex((object) => {
              return object.type == "quickcheck";
            });

            this.processingMsg.splice(ifExist, 1);
          }
        }
      }
    },
    async activeAbortBtn(response) {
      //console.log(`abortList`, this.abortList);
      this.totalCountSocket = this.totalCountSocket + 1;
      if (response.statusCode == 200) {
        this.abortList.push({
          id: response.resourceId,
          uniqueId: response.uniqueId,
        });
      }

      // If all files are valid
      console.log(`this.allFileList`, this.allFileList.length);
      console.log(`this.totalCountSocket`, this.totalCountSocket);
      if (this.allFileList.length == this.totalCountSocket) {
        this.isAbortActive = true;
      }

      /*//if any one file is not valid
      if (this.allFileList.length == this.abortList.length + this.errorFile) {
        this.isAbortActive = true;
      }*/
    },
    /*async activeAbortBtn(response) {
      let storeAllFile = await this.$store.getters["auth/getallOrderInfo"];

      let orderProcessStatus;
      await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
        orderProcessStatus = res.status;
      });

      let deleteFile = storeAllFile.filter(
        (element) => element.uniqueId == response.uniqueId
      );
      if (Object.keys(response).indexOf("resourceId") == -1) {
        for (let k = 0; k < deleteFile.length; k++) {
          this.abortList.push({
            id: deleteFile[k].resourceId,
          });
        }
      }

      //console.log(`abortList`, this.abortList);
      if (orderProcessStatus == false) {
        this.isAbortActive = true;
        if (response.statusCode == 200) {
          this.AddProcessingMsg(
            "quickcheck",
            "upload-image.image-complete",
            "success",
            true,
            true
          );
        }
      }
    },*/

    openmodal() {
      const bootstrap = this.$store.state.bootstrap;
      let jkanbanInfoModal = document.getElementById("jkanban-info-modal");
      var myModal = new bootstrap.Modal(jkanbanInfoModal, {
        show: true,
      });
      myModal.show();
    },
    //Order Detail API
    async getOrderDetail() {
      let getOrderId = await this.decryptOrderId(this.$route.params.OrderId);
      if (getOrderId != false) {
        this.orderId = await this.decryptOrderId(this.$route.params.OrderId);
        this.$route.params.OrderId = this.orderId;
        this.socketOrderId = this.orderId;
      } else {
        await this.InvalidOrderId();
        return;
      }

      await this.orderDetail(1);
      this.initDropzone();
    },
    //Dropzone
    async initDropzone() {
      this.commonDropzone(1);
    },
    async getContairInfo() {
      const containerInfo = await axiosInterceptor.get(
        `api/v1/containers/${this.radixContainerId}`
      );
      this.uuid = containerInfo.data.uuid;
    },
    async nextClick() {
      this.myDropzone.processQueue();
      let orderProcessStatus;
      await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
        orderProcessStatus = res.status;
      });

      // if orderProcessStatus is false and addedFile is less than or equal to 0
      if (orderProcessStatus == false && this.addedFile == 0) {
        this.AddProcessingMsg(
          "minimumfile",
          "upload-image.error-message-minimumfile",
          "danger",
          true,
          true
        );
        return;
      } else if (this.fileuploadFlag == 0 && this.validFile == 0) {
        this.AddProcessingMsg(
          "minimumfile",
          "upload-image.error-message-minimumfile",
          "danger",
          true,
          true
        );
        return;
      }

      // One file processing start then if user click on the next button then we can show message of valid file
      this.addedFile = 0;
      //Disabled the abort button becuase PDF upload process has been started
      this.isAbortActive = false;
      this.AddProcessingMsg(
        "quickcheck",
        "upload-image.PDF-processing-msg",
        "success",
        false,
        true
      );
    },
    preview() {
      let encryptedOrderId = this.OrderIdforURL(this.$route.params.OrderId);
      if (this.validFile > 0) {
        this.$router.replace(`/ReleasePrintdata/${encryptedOrderId}`);
      } else {
        this.AddProcessingMsg(
          "minimumfile",
          "upload-image.error-message-minimumfile",
          "danger",
          true,
          true
        );
      }
    },

    closeSnackbar(value) {
      if (value == 4) {
        this.connectionLost = null;
      }
    },
    snackbarTimeOut(value) {
      setTimeout(() => {
        if (value == 4) {
          this.connectionLost = null;
        }
      }, 5000);
    },

    snackbarTimeOutArray(value) {
      setTimeout(() => {
        const deleteMsg = this.processingMsg.findIndex((object) => {
          return parseInt(object.id) == parseInt(value);
        });
        this.processingMsg.splice(deleteMsg, 1);
      }, 5000);
    },
    async abort() {
      if (this.fileuploadFlag > 0 || this.errorFile > 0) {
        this.$swal({
          title: i18n.global.t("upload-image.abort-confirm-title"),
          text: i18n.global.t("upload-image.abort-confirm-text"),
          type: "warning",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: i18n.global.t("upload-image.abort-confirm-buttontext"),
        }).then((result) => {
          if (result.value) {
            this.abortAPI();
          }
        });
      } else {
        this.AddProcessingMsg(
          "minimumfile",
          "upload-image.error-message-minimumfile",
          "danger",
          true,
          true
        );
        this.$router.push("/orders/list");
      }
    },
    async abortAPI() {
      var abortResponse = await axiosInterceptor.post(
        "api/v1/resources/abort",
        this.abortList
      );
      if (abortResponse.data.status == "OK") {
        this.abortList = [];
        console.log(`this.processingMsg`, this.processingMsg);
        const ifExist = this.processingMsg.findIndex((object) => {
          return object.type == "quickcheck";
        });
        console.log(`ifExist`, ifExist);
        if (ifExist >= 0) {
          this.closeSnackbarArray(ifExist);
        }

        this.AddProcessingMsg(
          "fileAborted",
          "upload-image.abort-message",
          "success",
          true,
          true
        );

        var myDropzoneAbort = Dropzone.forElement("#productImg");
        myDropzoneAbort.removeAllFiles(true);
        this.allFileList = [];
        this.isActive = false;
        this.isAbortActive = false;
        this.totalCountSocket = 0;
        this.validFile = 0;
        this.fileUploadComplete = 0;
        this.fileuploadFlag = 0;
        this.errorFile = 0;
        this.totalFile = 0;
        this.quickCheck = 0;
      }
    },
  },
};
</script>
