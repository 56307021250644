<template>
<div>hii..</div>
  <div
    class="py-4 px-0 container custom-container upload-page-container print-page-container"
  >
    <div class="page-title-new">
      <span class="divider"></span>
      <h6 :class="color">{{ $t("release-print-data.page-title") }}</h6>
    </div>
    <div class="row">
      <div class="position-fixed floating-sidebar-left">
        <perfect-scrollbar>
          <div
            v-for="(image, index) in images"
            :key="image.id"
            class="selectable-image draggable"
            data-bs-placement="top"
            :draggable="false"
            data-bs-toggle="tooltip"
            :title="index + 1"
            data-animation="true"
            data-container=".ps"
            :class="{
              selected_image: isActivearray.includes(index),
              image_drag: isDrag == index,
            }"
            @click.stop
            @dragstart="drag($event, index)"
            @dragend="dragend($event)"
          >
            <a href="javascript:void(0)"
              ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
            ></a>

            <span v-if="tmp.includes(index) && showbothside1 == true" class="image-id">
              {{ tmp.indexOf(index) + 1 }}</span
            >
            <img :src="image.src" @click="getimage(index)" />
          </div>
        </perfect-scrollbar>
        <div class="add-new-image">
          <vmd-button
            class="shadow-none font-weight-normal submit-btn p-0 add-new-btn"
            color="primary"
          ></vmd-button>
        </div>
      </div>
      <div
        class="col-lg-8 main-content"
        :class="{
          show_both_side: showbothside1 == true,
        }"
      >
        <a
          v-if="nextimageicon"
          href="javascript:void(0)"
          class="arrow next"
          @click="nextimage()"
        >
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </a>
        <a
          v-if="previmageicon"
          href="javascript:void(0)"
          class="arrow prev"
          @click="previmage()"
        >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </a>
        <div
          v-if="!showbothside1"
          id="front"
          class="card"
          @dragover.prevent
          @drop="drop"
          @dragenter="dragenter(1)"
          @dragleave="dragend($event)"
        >
          <div
            class="imagePreviewWrapper draggable"
            :class="{
              image_drop: isDrop,
            }"
            :style="{ 'background-image': `url(${previewImage})` }"
          ></div>
          <p class="card-content">
            <strong>1</strong> {{ $t("release-print-data.image-single-title") }}
          </p>
        </div>

        <Frontback v-if="showbothside1" />

        <div class="card-preview-selector">
          <a
            href="javascript:void(0);"
            :class="{
              'link-active': clickedItem === $t('release-print-data.footerlink-front'),
            }"
            @click="
              showfront();
              clickedItem = $t('release-print-data.footerlink-front');
            "
            >{{ $t("release-print-data.footerlink-front") }}</a
          >
          <a href="#">{{ $t("release-print-data.footerlink-rear") }}</a>
          <span class="divider"></span>
          <a
            href="#"
            :class="{
              'link-active': clickedItem === $t('release-print-data.footerlink-pages'),
            }"
            >{{ $t("release-print-data.footerlink-pages") }}</a
          >
          <a
            href="javascript:void(0);"
            :class="{
              'link-active':
                clickedItem === $t('release-print-data.footerlink-miniature'),
            }"
            @click="
              showbothside();
              clickedItem = $t('release-print-data.footerlink-miniature');
            "
            @mouseleave="resetHover()"
            >{{ $t("release-print-data.footerlink-miniature") }}</a
          >
          <a href="#">{{ $t("release-print-data.footerlink-preview") }}</a>
        </div>
      </div>
      <div class="position-fixed floating-sidebar-right bg-dark">
        <div class="print-box p-3">
          <vmd-button
            class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
            color="primary"
            >{{ $t("release-print-data.release-print-data-btn") }}
          </vmd-button>
        </div>
        <perfect-scrollbar>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingOne" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#datacheck"
                  aria-expanded="false"
                  aria-controls="datacheck"
                >
                  {{ $t("release-print-data.data-check-title") }}
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="datacheck"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div class="accordion-body text-sm">
                  <div class="accordion-item">
                    <h5 id="headingOne1" class="accordion-header">
                      <button
                        class="accordion-button border-bottom collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        <i class="fas fa-circle mark-success-text"></i>
                        {{ $t("release-print-data.collapse-format") }}

                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne1"
                      style=""
                    >
                      <div class="accordion-body">
                        <p>Endformat ist 220 x 320 mm</p>
                        <p>
                          Benötigt wird
                          <span class="mark-danger-text">210 x 297 mm</span>
                        </p>
                        <p>Hinweis, was der User nun tun soll.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-body text-sm">
                  <div class="accordion-item">
                    <h5 id="headingOne2" class="accordion-header">
                      <button
                        class="accordion-button border-bottom collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSecond"
                        aria-expanded="false"
                        aria-controls="collapseSecond"
                      >
                        <i class="fas fa-circle mark-danger-text"></i>
                        {{ $t("release-print-data.collapse-page-number") }}
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      id="collapseSecond"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne1"
                      style=""
                    >
                      <div class="accordion-body">
                        <p>IST-Wert: xxx</p>
                        <p>SOLL-Wert: xxx</p>
                        <p>
                          Hinweis,, was der User nun tun soll. Später: Button
                          „Korrigieren“, „Ignore“
                        </p>
                        <vmd-button
                          class="shadow-none rounded-pill font-weight-normal btn-sm"
                          color="outline-white"
                          >Korrigieren
                        </vmd-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-body text-sm">
                  <div class="accordion-item">
                    <h5 id="headingOne2" class="accordion-header">
                      <button
                        class="accordion-button border-bottom collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThird"
                        aria-expanded="false"
                        aria-controls="collapseThird"
                      >
                        <i class="fas fa-circle mark-progress-text"></i>
                        {{ $t("release-print-data.collapse-colourfulness") }}
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      id="collapseThird"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne1"
                      style=""
                    >
                      <div class="accordion-body">
                        <p><img src="@/assets/img/cmyk.png" alt="" />CMYK</p>
                        <p><span class="color-box black-box"></span>Schwarz</p>
                        <p><span class="color-box grey-box"></span>Pantone XY</p>
                        <p><span class="color-box grey-box"></span>Pantone XY</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-body text-sm">
                  <div class="accordion-item">
                    <h5 id="headingOne1" class="accordion-header">
                      <button
                        class="accordion-button border-bottom collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <i class="fas fa-circle mark-success-text"></i>
                        {{ $t("release-print-data.collapse-resolution") }}
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne4"
                      style=""
                    >
                      <div class="accordion-body">
                        <p>IST-Wert: xxx</p>
                        <p>
                          SOLL-Wert:
                          <span class="mark-progress-text">xxx</span>
                        </p>
                        <p>Hinweis, was der User nun tun soll.</p>

                        <vmd-button
                          class="shadow-none rounded-pill font-weight-normal btn-sm"
                          color="outline-white"
                          >Ignorieren
                        </vmd-button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-body text-sm">
                  <div class="accordion-item">
                    <h5 id="headingOne1" class="accordion-header">
                      <button
                        class="accordion-button border-bottom collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <i class="fal fa-info-circle"></i>
                        {{ $t("release-print-data.collapse-information") }}
                        <i
                          class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                        <i
                          class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </h5>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingOne5"
                      style=""
                    >
                      <div class="accordion-body">
                        <p>
                          zum hochgeladenen Dokument Enthaltene Seiten, Endormat,
                          Enthaltene Schriftarten Ausgabebedingungen
                        </p>
                        <vmd-button
                          class="shadow-none rounded-pill font-weight-normal btn-sm w-100"
                          color="outline-white"
                          >Alle Fehler korrigieren
                        </vmd-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingSecond" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#order_information"
                  aria-expanded="false"
                  aria-controls="order_information"
                >
                  {{ $t("release-print-data.order-information") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="order_information"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <h5>{{ $t("upload-image.business-card-title") }}</h5>
                <div class="accordion-body text-sm basic-infos">
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-format") }}</h6>
                    <p>{{ format }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-material") }}</h6>
                    <p>{{ material }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-finishing") }}</h6>
                    <p>{{ finishing1 }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-finishing") }}</h6>
                    <p>{{ finishing2 }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-colourfulness") }}</h6>
                    <p>{{ colourfulness }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-motifs") }}</h6>
                    <p>{{ motifs }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-Crowd") }}</h6>
                    <p>{{ crowd }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingThird" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsThird"
                  aria-expanded="false"
                  aria-controls="collapsThird"
                >
                  {{ $t("release-print-data.label-remarks") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="collapsThird"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div class="accordion-body text-sm">Third</div>
              </div>
            </div>
          </div>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingFour" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  {{ $t("release-print-data.label-faq") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="collapsefour"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div class="accordion-body text-sm">Forth</div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import VmdButton from "@/components/VmdButton.vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import setTooltip from "@/assets/js/tooltip.js";
import Jquery from "jquery";
import i18n from "@/i18n";
import Frontback from "./Frontbackview.vue";
export default {
  name: "Print",
  components: {
    VmdButton,
    PerfectScrollbar,
    Frontback,
  },
  data: function () {
    return {
      images: [
        {
          id: "1",
          src: require("@/assets/img/products/product-7-min.jpg"),
          format: "8,5 x 5,7 cm QUERFORMAT",
          material: "200 g Bilderdruck matt",
          finishing1: "beidseitiger Dispersionslack",
          finishing2: "abrunden",
          colourfulness: "3/3-farbig",
          motifs: "2 Motive",
          crowd: "70 Stk. pro Motiv",
        },
        {
          id: "2",
          src: require("@/assets/img/car4.jpeg"),
          format: "8,5 x 5,7 cm QUERFORMAT",
          material: "200 g Bilderdruck matt",
          finishing1: "beidseitiger Dispersionslack",
          finishing2: "abrunden",
          colourfulness: "3/3-farbig",
          motifs: "2 Motive",
          crowd: "70 Stk. pro Motiv",
        },
      ],
      previewImage: "",
      backImage: "",
      frontImage: "",
      thirdimage:"",
      finalimage:"",
      format: "",
      material: "",
      finishing1: "",
      finishing2: "",
      colourfulness: "",
      motifs: "",
      crowd: "",
      currentindex: "",
      nextimageicon: true,
      previmageicon: false,
      isActive: "",
      isDrag: null,
      isDrop: null,
      isFrontDrop: null,
      isBackDrop: null,
      isThirdDrop: null,
      isFinalDrop:null,
      clickedItem: "",
      showbothside1: false,
      tmp: [],
      isActivearray: [],
      activeItem: {},
      singleimageindex: 0,
      frontimageindex: 0,
      backimageindex: 1,
      thirdimageindex: 2,
      finalimageindex: 3,
    };
  },
  created() {
    // document.title = "Release Print data";
  },
  mounted() {
    this.previewImage = this.images[0].src;
    this.format = this.images[0].format;
    this.material = this.images[0].material;
    this.finishing1 = this.images[0].finishing1;
    this.finishing2 = this.images[0].finishing2;
    this.finishing3 = this.images[0].finishing3;
    this.finishing4 = this.images[0].finishing4;
    this.colourfulness = this.images[0].colourfulness;
    this.motifs = this.images[0].motifs;
    this.crowd = this.images[0].crowd;
    this.currentindex = 0;
    this.isDrag = null;
    this.isDrop = null;
    this.clickedItem = i18n.global.t("release-print-data.footerlink-front");
    this.showbothside1 = false;
    this.isActivearray[2] = 0;
    this.frontimageindex = 0;
    this.backimageindex = 1;
    this.thirdimageindex = 2;
    this.finalimageindex = 3;

    setTooltip(this.$store.state.bootstrap);

    Jquery(".accordion-button").click(function () {
      if (Jquery(this).hasClass("collapsed")) {
        Jquery(this)
          .parents(".accordion-item")
          .find(".accordion-item .accordion-button:not(.collapsed)")
          .click();
      }
    });
  },

  methods: {
    getimage(index, image_type = "") {
      //image type = 1 for display front image
      //image type = 2 for display back image
      this.isDrag = null;
      if (image_type == 1) {
        this.frontImage = this.images[index].src;
      } else if (image_type == 2) {
        this.backImage = this.images[index].src;
      } 
      else if (image_type == 3) {
        this.thirdimage = this.images[index].src;
      } 
      else if (image_type == 4) {
        this.finalimage = this.images[index].src;
      } 

      this.singleimageindex = index;
      this.format = this.images[index].format;
      this.material = this.images[index].material;
      this.finishing1 = this.images[index].finishing1;
      this.finishing2 = this.images[index].finishing2;
      this.finishing3 = this.images[index].finishing3;
      this.finishing4 = this.images[index].finishing4;
      this.colourfulness = this.images[index].colourfulness;
      this.motifs = this.images[index].motifs;
      this.crowd = this.images[index].crowd;
      this.currentindex = index;
      // this.isActive = index == this.isActive ? "" : index;
      if (this.backImage == "") {
        this.nextimageicon = true;
        if (this.currentindex == this.images.length - 1) {
          this.nextimageicon = false;
        }
        if (this.currentindex == 0) {
          this.previmageicon = false;
        } else {
          this.previmageicon = true;
        }
      }
    },
    nextimage() {
      let last_index = this.images.length - 1;
      let nextindex = parseInt(this.currentindex) + 1;
      this.getimage(nextindex);
      this.nextimageicon = true;
      this.previmageicon = true;
      if (last_index == nextindex) {
        this.nextimageicon = false;
      }
    },
    previmage() {
      let first_index = 0;
      let previndex = parseInt(this.currentindex) - 1;
      this.getimage(previndex);
      this.previmageicon = true;
      this.nextimageicon = true;
      if (first_index == previndex) {
        this.previmageicon = false;
      }
    },
    drag(ev, imageindex) {
      ev.dataTransfer.setData("imageindex", imageindex);
      this.isDrag = parseInt(imageindex) == this.isDrag ? null : parseInt(imageindex);
      //this.isDrop = true;
    },
    dragend() {
      this.isDrop = null;
      this.isDrag = null;
      this.isFrontDrop = null;
      this.isBackDrop = null;
      this.isThirdDrop = null;
      this.isFinalDrop = null;
    },
    dragenter(value) {
      //1 for single image,2 for front image,2 for back image
      if (value == 1) {
        this.isDrop = true;
      } else if (value == 2) {
        this.isFrontDrop = true;
      } else if (value == 3) {
        this.isBackDrop = true;
      } else if (value == 4) {
        this.isThirdDrop = true;
      }else if (value == 5) {
        this.isFinalDrop = true;
      }
    },
    drop(ev) {
      var imageindex = ev.dataTransfer.getData("imageindex");
      this.getimage(imageindex);
      this.isDrag = null;
      this.isDrop = null;
    },
    displaybackimage(ev) {
      this.isFrontDrop = null;
      var imageindex = ev.dataTransfer.getData("imageindex");
      this.backimageindex = imageindex;
      //console.log(imageindex);
      let already_exist_id = this.tmp.includes(parseInt(imageindex));
      if (!already_exist_id) {
        this.tmp[1] = parseInt(imageindex) == this.tmp[1] ? "" : parseInt(imageindex);
        this.backImage = this.images[imageindex].src;
        this.getimage(imageindex, 2);
      }
      this.isActivearray[1] =
      parseInt(imageindex) == this.isActivearray[1] ? "" : parseInt(imageindex);
      console.log(this.isActivearray);
      this.previmageicon = false;
      this.nextimageicon = false;
    },
    displayfrontimage(ev) {
      this.isFrontDrop = true;
      var imageindex = ev.dataTransfer.getData("imageindex");
      this.frontimageindex = imageindex;
      let already_exist_id = this.tmp.includes(parseInt(imageindex));
      if (!already_exist_id) {
        this.tmp[0] = parseInt(imageindex) == this.tmp[0] ? "" : parseInt(imageindex);
        this.frontImage = this.images[imageindex].src;
        this.getimage(imageindex, 1);
      }
      console.log(this.tmp);
      this.isActivearray[0] =
        parseInt(imageindex) == this.isActivearray[0] ? "" : parseInt(imageindex);
      /*let test = this.isActivearray.includes(imageindex)
        ? ""
        : this.isActivearray.push(parseInt(imageindex));
      console.log(test);*/
      this.previmageicon = false;
      this.nextimageicon = false;
    },
    displaythirdimage(ev) {
      this.isThirdDrop = true;
      var imageindex = ev.dataTransfer.getData("imageindex");
      this.thirdimageindex = imageindex;
      let already_exist_id = this.tmp.includes(parseInt(imageindex));
      if (!already_exist_id) {
        this.tmp[2] = parseInt(imageindex) == this.tmp[2] ? "" : parseInt(imageindex);
        this.thirdimage = this.images[imageindex].src;
        this.getimage(imageindex, 3);
      }
      console.log(this.tmp);
      this.isActivearray[3] =
        parseInt(imageindex) == this.isActivearray[3] ? "" : parseInt(imageindex);
      this.previmageicon = false;
      this.nextimageicon = false;
    },
    displayfinalimage(ev) {
      this.isFinalDrop = true;
      var imageindex = ev.dataTransfer.getData("imageindex");
      this.finalimageindex = imageindex;
      let already_exist_id = this.tmp.includes(parseInt(imageindex));
      if (!already_exist_id) {
        this.tmp[3] = parseInt(imageindex) == this.tmp[3] ? "" : parseInt(imageindex);
        this.finalimage = this.images[imageindex].src;
        this.getimage(imageindex, 4);
      }
      console.log(this.tmp);
      this.isActivearray[4] =
        parseInt(imageindex) == this.isActivearray[4] ? "" : parseInt(imageindex);
      this.previmageicon = false;
      this.nextimageicon = false;
    },
    showfront() {
      //this.backImage = "";
      this.showbothside1 = false;
      this.isActivearray[0] = "";
      this.isActivearray[1] = "";
      this.isActivearray[2] = "";
      this.isActivearray[3] = "";
      this.isActivearray[4] = this.singleimageindex;
      /*this.tmp[0] = "";
      this.tmp[1] = "";*/
    },
    showbothside() {
      this.showbothside1 = true;
      if (this.frontImage == "") {
        this.frontImage = this.images[0].src;
        this.tmp[0] = this.frontimageindex;
      }
      if (this.backImage == "") {
        this.backImage = this.images[1].src;
        this.tmp[1] = this.backimageindex;
      }
      if (this.thirdimage == "") {
        this.thirdimage = this.images[2].src;
        this.tmp[2] = this.thirdimageindex;
      }
       if (this.finalimage == "") {
        this.finalimage = this.images[3].src;
        this.tmp[3] = this.finalimageindex;
      }
      this.isActivearray[0] = this.frontimageindex;
      this.isActivearray[1] = this.backimageindex;
      this.isActivearray[2] = this.thirdimageindex;
      this.isActivearray[3] = this.finalimageindex;
      this.isActivearray[4] = "";
      this.previmageicon = false;
      this.nextimageicon = false;
    },
    resetHover() {
      //this.hoveredItem = "";
    },
  },
};
</script>
