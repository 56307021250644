<template>
  <div class="form-check p-0 vmd-input">
    <input
      :id="id"
      :name="name"
      :checked="check"
      :value="value"
      type="checkbox"
      class="form-check-input"
      :disabled="disabled"
      @click="onChange(id)"
    />
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useField } from "vee-validate";
export default {
  name: "VmdCheckbox",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    //name: String,
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    check: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { name } = toRefs(props);
    const { checked, handleChange } = useField(name, undefined, {
      type: "checkbox",
      checkedValue: props.value,
      // uncheckedValue: false,
    });
    //console.log(`checkedValue11`, props.check, props.value);
    function onChange(getId) {
      var checkbox = document.getElementById(getId);
      var value = checkbox.checked;
      if (value == false) {
        value = "";
      }
      handleChange(value);
    }

    return {
      handleChange,
      onChange,
      checked,
    };
  },
  mounted() {
    //console.log(`checkedValue`, this.check);
    /*var text = document.getElementById(this.id);

    console.log("checkbox mounted", text.checked);*/
  },
};
</script>
