<template>
  <div class="d-flex w-100">
    <div
      id="frontimage"
      class="card"
      @dragover.prevent
      @drop="this.$parent.displayfrontimage"
      @dragenter="this.$parent.dragenter(2)"
      @dragleave="this.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.isFrontDrop,
        }"
        :style="{ 'background-image': `url(${this.$parent.frontImage})` }"
      ></div>
      <p class="card-content">
        <strong>1</strong>
      </p>
    </div>
    <div
      id="backimage"
      class="card ms-4"
      @dragover.prevent
      @drop="this.$parent.displaybackimage"
      @dragenter="this.$parent.dragenter(3)"
      @dragleave="this.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.isBackDrop,
        }"
        :style="{ 'background-image': `url(${this.$parent.backImage})` }"
      ></div>
      <p class="card-content">
        <strong>2</strong>
      </p>
    </div>
    <div
      id="thirdimage"
      class="card ms-4"
      @dragover.prevent
      @drop="this.$parent.displaythirdimage"
      @dragenter="this.$parent.dragenter(4)"
      @dragleave="this.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.isThirdDrop,
        }"
        :style="{ 'background-image': `url(${this.$parent.thirdimage})` }"
      ></div>
      <p class="card-content">
        <strong>3</strong>
      </p>
    </div>
    <div
      id="finalimage"
      class="card ms-4"
      @dragover.prevent
      @drop="this.$parent.displayfinalimage"
      @dragenter="this.$parent.dragenter(5)"
      @dragleave="this.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.isFinalDrop,
        }"
        :style="{ 'background-image': `url(${this.$parent.finalimage})` }"
      ></div>
      <p class="card-content">
        <strong>4</strong>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Frontback",
};
</script>
