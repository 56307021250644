import i18n from "@/i18n";
export default {
    computed: {
        statusList() {
            let statusArray = [
            {
            value: i18n.global.t("status-list.Created"),
            key: "created",
            },
            {
            value: i18n.global.t("status-list.Done"),
            key: "done",
            },
            {
            value: i18n.global.t("status-list.Warning"),
            key: "warning",
            },
            {
            value: i18n.global.t("status-list.Error"),
            key: "error",
            },
            {
            value: i18n.global.t("status-list.Internal-Error"),
            key: "internalerror",
            },
            {
            value: i18n.global.t("status-list.Processing"),
            key: "processing",
            },
            {
            value: i18n.global.t("status-list.Wait-for-feedback"),
            key: "waitforfeedback",
            },
            ];
            return statusArray;
        }
    },
};