<template>
  <div id="orderModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-570 modal-dialog-centered">
      <div class="modal-content main-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <span v-if="this.$parent.template == true">{{
              $t("template-grid.template-detail-label")
            }}</span>
            <span v-else>{{ $t("order-common.orderdetail-label") }}</span>
            -
            {{ this.$parent.OrderInfo["name"] }}
          </h5>
          <!-- <h5 v-else class="modal-title">
            {{ $t("order-common.orderdetail-label") }} -
            {{ this.$parent.OrderInfo["name"] }}
          </h5>-->
          <a
            href="javascript:void(0)"
            class="close p-0 m-0 text-white h4"
            data-dismiss="modal"
            data-target="#orderModal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </a>
        </div>
        <div class="modal-body">
          <perfect-scrollbar>
            <div class="order-details d-flex align-items-center justify-content-between">
              <div class="order-content">
                <div
                  v-for="producttype in this.$parent.OrderInfo['products']"
                  :key="producttype.id"
                  class="orderPreviewDetails"
                >
                  <div class="orderDetailTitle">
                    <p>
                      {{ $t("order-common.orderdetail-modal-productType") }}:
                      <strong class="text-white">{{ producttype.type }}</strong>
                    </p>
                  </div>
                  <div
                    v-for="productparts in producttype.parts"
                    :key="productparts.id"
                    class="orderDetailContent"
                  >
                    <h5>{{ productparts.type }}</h5>
                    <div class="innerDetailContent">
                      <div class="single-info">
                        <h6>
                          {{ $t("order-common.orderdetail-modal-partformat") }}
                          ({{ this.$parent.dimensionFormat }})
                        </h6>
                        <p>
                          {{
                            updateOrderFormat(
                              productparts.intent.layoutIntent.dimensions.width
                            )
                          }}
                          *
                          {{
                            updateOrderFormat(
                              productparts.intent.layoutIntent.dimensions.height
                            )
                          }}
                        </p>
                      </div>
                      <div class="single-info">
                        <h6>
                          {{ $t("order-common.orderdetail-modal-partpages") }}
                        </h6>
                        <p>
                          {{
                            this.$parent.updateOrderPages(
                              productparts.intent.layoutIntent.sides,
                              productparts.intent.layoutIntent.pages
                            )
                          }}
                        </p>
                      </div>

                      <div class="single-info">
                        <h6>
                          {{ $t("order-common.orderdetail-modal-partcolor") }}
                        </h6>
                        <p>
                          {{
                            productparts.intent.colorIntent.surfaceColor.front.colorsUsed
                              .length
                          }}
                          /
                          {{
                            productparts.intent.colorIntent.surfaceColor.back.colorsUsed
                              .length
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import updateDimension from "@/Mixins/updateDimension";
export default {
  components: {
    PerfectScrollbar,
  },
  mixins: [updateDimension],
  computed: {
    updateOrderFormat: function () {
      this.$store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },
  },
};
</script>
