import PDFIcon from "@/assets/img/upload/pdf.png";
import axios from "axios";
import Dropzone from "dropzone";
import i18n from "@/i18n";
import store from "@/store";
//import Cryptojs from 'crypto-js';
export default {
    methods: {
        async commonDropzone(type) {
            //type = 1 Uploader Page
            //type = 2 Preview Page
            function myParamName(){
              return "file";
              }
            const vm = this;
            Dropzone.autoDiscover = false;
            if (document.getElementById("productImg")) {
              if (vm.DropzoneInit == false) {
                vm.DropzoneInit = true;
               
                vm.myDropzone = new Dropzone("#productImg", {
                  addRemoveLinks: true,
                  autoProcessQueue:false,
                  uploadMultiple:true,
                  previewTemplate: document.querySelector("#template-container").innerHTML,
                  url: `${process.env.VUE_APP_API_URL}api/v1/containers/${this.radixContainerId}/webClientUpload?jobLock=true`,
                  paramName: myParamName,
                  parallelUploads:100,
                  maxFiles :100,
                  preventDuplicates: true,
                  dictDuplicateFile: "This file has already been uploaded.",
                  forcefallback:false,
                  headers: {
                    "Cache-Control": null,
                  },
                  options: {
                    //dictDefaultMessage: i18n.global.t("upload-image.error-message-duplicatefile"),
                  },
                  accept: function (file, done) {
                    if (file.type.toLowerCase() != "application/pdf") {
                      done(i18n.global.t("release-print-data.error-message-validfile"));
                    } else {
                      vm.errorFile = 0;
                      if (vm.socketStart == false) {
                        vm.socketStart = true;
                        vm.isLoading = true;
                      }
                      if(type == 2){
                        vm.uploadbtn=true;
                      }
                      vm.resetFile = vm.resetFile + 1;
                      done();
                    }
                  },
                });
              }
              vm.myDropzone.on("sendingmultiple", async (file, xhr, formData) => {
              console.log("processing")
                vm.nextClickCount=vm.nextClickCount+1;
                for(let f=0;f<file.length;f++){
                  vm.validFile+=parseInt(1);
                  vm.allFileList.push({
                    filename:file[f].name,
                    status:"progress",
                    uplResourceId:0,
                    UIId:vm.nextClickCount,
                    uniqueId:0,
                  });

                  let updateFileName=file[f].name;
                  updateFileName=updateFileName.replace(/[^a-zA-Z]/g, '');


                  let uploadProcess = document.getElementById(
                    "upload" + updateFileName
                  );
                   
                  if (uploadProcess != undefined) {
                    uploadProcess.innerHTML =
                      this.predefineUploadText + 'in progress';
                      //uploadProcess.className += "  customMessageDiv";
                  }

                }
                let auth = store.getters["auth/isTokenActive"];
                let authZaikio = store.getters["auth/isZaikioTokenActive"];
              
               
                if (!auth) {
                  const authData = store.getters["auth/getAuthData"];
                  if (authData.token) {
                    var payload = {
                      access_token: authData.token,
                      refresh_token: authData.refreshToken,
                    };
                    
                    let tokenApiName="api/auth/refresh";
                    if(authData.userType == 'Guest'){
                      let sessionId=authData.session;
                      tokenApiName=`api/v1/orders/verifySession?session=${sessionId}`;
                      payload={};
                    }
                    if(localStorage.getItem("loginType")== 2){
                      tokenApiName="api/auth/refresh?called_from=kc_token";
                    }
                    if(authData.token)
                    await axios
                      .post(tokenApiName, payload)
                      .then(function (response) {
                        response.data.userType="User";
                        response.data.loginType=authData.loginType;
                        if(authData.userType == 'Guest'){
                          response.data.userType="Guest";
                          response.data.session=localStorage.getItem("session");
                          response.data.access_token=response.data.data.clientAccount;
                        }
                        store.commit("auth/saveTokenData", response.data);
                        auth = true;
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }
                }

                if(!authZaikio && localStorage.getItem("loginType")== 2){
                  const ZaikioauthData = store.getters["auth/getZaikioAuthData"];
                  const zaikiopayload = {
                    access_token: ZaikioauthData.token,
                    refresh_token: ZaikioauthData.refreshToken,
                  };
                  await axios.post('api/auth/refresh?called_from=idp_token', zaikiopayload).then(function (response) {
                    response.data.userType="User";
                    response.data.loginType=2;
                    store.commit("auth/SaveZaikioTokenData", response.data);
                    auth = true;
                  }).catch(function (error) {
                    console.log(error);
                  });
                }
      
                let userInfo11 = this.$store.getters["auth/getAuthData"];
                this.filedata = file.name;
                //xhr.setRequestHeader("x-file-id", file.name);
               
                
                  xhr.setRequestHeader("Authorization", `bearer ${userInfo11.token}`);
                  if(localStorage.getItem('loginType') == 2){
                    xhr.setRequestHeader("idp_token", localStorage.getItem('zaikio_access_token'));
                    
                  }
                  if(userInfo11.userType == 'Guest'){
                    xhr.setRequestHeader("session", `${userInfo11.session}`);
                  }
                  xhr.setRequestHeader("orderId", vm.$route.params.OrderId);
                  formData.append("orderId", vm.$route.params.OrderId);
                  formData.append("clientId", vm.socketClientId);
             
              });
      
              vm.myDropzone.on("addedfile", function (file) {
               
                  vm.totalFile=vm.totalFile+1;
                  if(type==1){
                    vm.isAbortActive=false;
                   }
                if (file.type.match("application/pdf")) {
                  vm.isActive = false;
                  vm.myDropzone.emit("thumbnail", file, PDFIcon);
                  if(type==1){
                    vm.addedFile=vm.addedFile+1;
                    /*let mainDiv = file.previewElement;
                    mainDiv.id = "pending"+file; */
                  }
                  let fileName=file.name;
                    fileName=fileName.replace(/[^a-zA-Z]/g, '');
                    var splitPDF = document.createElement("span");
                    splitPDF.className = "customMessageDiv";
                    splitPDF.id = "upload" +fileName;
                    splitPDF.innerHTML =
                      vm.predefineUploadText +
                      i18n.global.t("release-print-data.pending-status");
                    let splitPreview = file.previewElement.querySelector(".dz-success-message");
                    splitPreview.insertBefore(splitPDF, splitPreview.firstChild);
                }



              });
      
              vm.myDropzone.on("successmultiple", async function (allfile, allresp) {
                let resp;
                let file;
                let fileIndex;
                let allFileIndex;
                let uploadFileName;
                for(let a=0;a<allresp.length;a++){
                  resp=allresp[a];
                  fileIndex = allfile.findIndex((object) => {
                    return object.name === resp.fileName && object.status!='done';
                  });
                  
                  
                  allFileIndex=vm.allFileList.findIndex((object) => {
                    return object.filename === resp.fileName && object.status!='done';
                  });
                  
             
                  file=allfile[fileIndex];
                  uploadFileName=file.upload.filename;
                  // File upload successfully
                  if (resp.statusCode == 200 && resp.fileName == uploadFileName) {
                    //03-11
                    /*let deleteFileName=uploadFileName;
                    deleteFileName=deleteFileName.replace(/[^a-zA-Z]/g, '');
                    file.previewElement.querySelector("#upload"+deleteFileName).style.display =
                      "none";*/


                    vm.fileuploadFlag += parseInt(1);
                    vm.allFileList[allFileIndex].status='done';
                    vm.allFileList[allFileIndex].uplResourceId=resp.resourceId;
                    //console.log(`file.previewElement`,file.previewElement)
                    //03-11
                    /*let mainDiv = file.previewElement;
                    mainDiv.id = "mainDivDrop" + resp.resourceId;
        
                    let successId = file.previewElement.querySelector(".dz-success-message");
                    successId.id = "customsuccessId" + resp.resourceId;
                    
        
                    let addId = file.previewElement.querySelector(".dz-success-mark");
                    addId.id = "imagedropzonediv" + resp.resourceId;*/
                    let errorAddId;
                    /*if(type == 1){
                      errorAddId = file.previewElement.querySelector(".dz-error-message");
                      errorAddId.id = "customErrorDiv" + resp.resourceId;
                    }*/
                    
                    if(type == 2){
                      vm.orderDetail(2);
                      errorAddId = document.createElement("span");
                      errorAddId.id = "customErrorDiv" + resp.resourceId;
                      errorAddId.className = "customErrorDiv error text-danger";
                      let customErrorPreview = file.previewElement.querySelector(
                          ".dz-success-message"
                        );
                        customErrorPreview.insertBefore(errorAddId, customErrorPreview.firstChild);
                    }
                     //03-11 
                    /*var uploadPDF = document.createElement("span");
                    uploadPDF.className = "customMessageDiv messageSuccess";
                    uploadPDF.id = "uploadPDFdiv" + resp.resourceId;
                    uploadPDF.innerHTML =
                      vm.predefineUploadText + i18n.global.t("release-print-data.done-status");
                    let uploadPDFPreview = file.previewElement.querySelector(
                      ".dz-success-message"
                    );
                    let t = document.getElementById(
                      "imagedropzonediv" + resp.resourceId
                    );
                    t.innerHTML = "<i class = 'fas fa-check'></i>";
                    t.classList.remove('d-none');
                    uploadPDFPreview.insertBefore(uploadPDF, uploadPDFPreview.firstChild);*/
                  } 
                  else {
                    if(type==1){
                      if(vm.addedFile>0){
                        vm.addedFile=vm.addedFile-1;
                      }
                      
                    }
                    let defaultError=i18n.global.t("release-print-data.dz-custom-error");
                    vm.allFileList[allFileIndex].status='error';
                    vm.errorFile+=parseInt(1);
                   if(parseInt(resp.statusCode) == parseInt(409)){ 
                      defaultError=i18n.global.t("release-print-data.error-message-duplicatefile");
                      
                   }

                   // if all files has error
                   if(vm.errorFile  == vm.validFile) {
                    if(type== 1){
                      const quickcheckIndex = vm.processingMsg.findIndex((object) => {
                        return object.type === "quickcheck";
                      });
                      vm.processingMsg.splice(quickcheckIndex, 1);
                      vm.isAbortActive=true;
                    }
                    if(type== 2){
                      const quickcheckIndex = vm.processingMsg.findIndex((object) => {
                        return object.type === "imageProcessingMsg";
                      });
                      vm.processingMsg.splice(quickcheckIndex, 1);
                    }
                    
                   }
                   else if(a+1 == allresp.length){
                    let messageType= type == 1 ? 'quickcheck': 'imageProcessingMsg';
                    if(type == 1){
                      //particular slot has an error
                    vm.isAbortActive=true;
                    }
                    
                    const deleteMsg = vm.processingMsg.findIndex((object) => {
                    return object.type === messageType;
                    });

                    vm.processingMsg.splice(deleteMsg, 1);
                    
                    
                   }

                    file.previewElement.querySelector(
                      ".dz-error-message"
                    ).textContent = defaultError;
                    file.previewElement.querySelector(".dz-success-mark").style.display = "none";
                    if(type == 2){
                      file.previewElement.querySelector(".messageBox").style.display = "none";
                    }
                    
        
                    file.previewElement.querySelector(".dz-success-message").style.display =
                      "none";
        
                    file.previewElement.className += " dz-error";
                    file.previewElement.classList.remove("dz-success");
                  }
                }
                
              });
      
              vm.myDropzone.on("completemultiple", function () {
                /*if (
                  this.getUploadingFiles().length === 0 &&
                  this.getQueuedFiles().length === 0 &&
                  parseInt(vm.validFile) > 0
                ) {
                }*/
                if (vm.resetFile == vm.errorFile) {
                  if (vm.socketStart == true) {
                    vm.socketStart = false;
                  }
                }
                if (
                  this.getUploadingFiles().length === 0 &&
                  this.getQueuedFiles().length === 0
                ) {
                  vm.resetFile = 0;
                }
              });
      
              vm.myDropzone.on("errormultiple", async function (allfile, allresp) {
                let resp;
                let file;
                let fileIndex;
                let allFileIndex;
                for(let a=0;a<allfile.length;a++){
                  resp=allresp;
                  file=allfile[a];
                  fileIndex = allfile.findIndex((object) => {
                    return object.name === resp.fileName;
                  });
                

                  allFileIndex=vm.allFileList.findIndex((object) => {
                    return object.filename === resp.fileName;
                  });
                  if(allFileIndex >=0){
                    vm.allFileList[allFileIndex].status='error';
                    file=allfile[fileIndex];
                  }
                 
             
                 
                //file.previewElement.classList.add("dz-error-mark");
                if(type == 2){
                    file.previewElement.querySelector(".messageBox").style.display = "none";
                }
                
                file.previewElement.querySelector(".dz-success-message").style.display = "none";
                vm.isActive = false;
                vm.errorFile = vm.errorFile + 1;
                if(type == 1){
                   if((vm.errorFile== vm.totalFile || vm.validFile == vm.fileUploadComplete) && this.getQueuedFiles().length === 0 && this.getUploadingFiles().length === 0) {
                      vm.isAbortActive=true;
                   }
                  
                }
               
                if (resp.statusCode == 401) {
                  file.previewElement.querySelector(
                  ".dz-error-message"
                  ).textContent = i18n.global.t("release-print-data.error-message-token");
                 
                  // Removed message of processing
                  if(type == 1){
                    const ifExist = vm.processingMsg.findIndex((object) => {
                      return object.type == "quickcheck";
                    });
                    vm.processingMsg.splice(ifExist, 1);
                  }

                } else if (parseInt(resp.statusCode) == parseInt(422)) {
                  file.previewElement.querySelector(
                  ".dz-error-message"
                  ).textContent = i18n.global.t(
                  "release-print-data.error-message-duplicatefile"
                  );
                } else {
                  let error_message = "";
                  if (resp != "") {
                    if (resp.message != "" && resp.message != undefined) {
                      error_message = resp.message;
                    } else {
                      error_message = resp;
                    }
                    file.previewElement.querySelector(
                    ".dz-error-message"
                    ).textContent = error_message;
                  }
                }
                }
              });


              vm.myDropzone.on('removedfile', function () {
               if(type == 2) {
                var myDropzoneFiles = Dropzone.forElement("#productImg");
                const box = document.getElementById('productImg');
                if(myDropzoneFiles.files.length == 0 || box.querySelector('.dz-image-preview') == null){
                  myDropzoneFiles.removeAllFiles(true);
                }
               }
            });
            
            }
          },
    }
}