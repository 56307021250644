var ordersPredefinedData=[
    {
      "PredefinedSides": ["OneSided", "TwoSidedHeadToHead"],
      "PedefinedGender":["male", "female","divers"],
      "PedefinedjobContactsRoles": ["approval", "receiver"],
      "PredefinedspreadType": ["Spread", "SinglePage"],
      "PredefinedOrienation": [
        "Rotate0",
        "Rotate90",
        "Rotate180",
        "Rotate270",
        "Flip0",
        "Flip90",
        "Flip180",
        "Flip270",
        ],
      "PredefinedPrintStandard":[
        "CGATS21-2-CRPC5",
        "CGATS21-2-CRPC6",
        "FOGRA39",
        "FOGRA47",
        "FOGRA51",
        "FOGRA52",
        "FOGRA53",
        "SRGB",
        ],
      "PredefinedColor": ["Cyan", "Magenta", "Yellow", "Black"],
      "predefineMainOrderdelete": [
        "createdAt",
        "updatedAt",
        "id",
        "deletedAt",
        "createdBy",
        "updatedBy",
        "archivedAt",
        "status",
        "displayStatus",
        "processesRef",
        "orderDate",
        "deliveryDate",
        "externalId",
        "sidecarResourceIds",
        "keycloakUserId",
        "radixContainerId",
        "webhook",
        "milestone",
        "isTemplate",
        ],
      "predefinedProductdelete": [
        "createdAt",
        "updatedAt",
        "id",
        "deletedAt",
        "createdBy",
        "updatedBy",
        "archivedAt",
        "displayStatus",
        "processesRef",
        "description",
        "customData",
        "orderDate",
        "deliveryDate",
        "intent",
        "sidecarResourceIds",
        "orderId",
        "keycloakUserId",
        "milestone",
        "isTemplate",
        "externalId",
        "status",
       ],
      "predefinedPartsDelete": [
        "archivedAt",
        "createdAt",
        "createdBy",
        "deletedAt",
        "description",
        "customData",
        "description",
        "displayStatus",
        "id",
        "isTemplate",
        "keycloakUserId",
        "milestone",
        "pageranges",
        "processesRef",
        "productId",
        "sidecarResourceIds",
        "status",
        "updatedAt",
        "updatedBy",
        ],
    }   
]
export default ordersPredefinedData[0];
