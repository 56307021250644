<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div class="px-0 pt-4 pb-4 container custom-container position-relative">
        <div class="title-box-new">
          <div class="text-h4 text-white">
            {{ $t("change-template-image.template-image") }}
          </div>
          <div class="button-box d-flex gap-1">
            <router-link
              :to="{
                path: '/template/templateGrid',
              }"
              class="box"
            >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="primary"
                >{{ $t("change-template-image.template-listing") }}</vmd-button
              >
            </router-link>
          </div>
        </div>
        <div class="position-absolute inner_toaster"></div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-4 pb-6 px-0 container custom-container">
        <div class="row grid-box-container">
          <div class="col-12 createTemplateForm">
            <Form
              id="createTemplateForm"
              ref="form"
              :validation-schema="validationSchema"
              @submit="submitTemplate"
              @invalid-submit="onInvalid"
            >
              <div class="fileSelector">
                <div class="formContent">
                  <div class="mb-3 form-group">
                    <vmd-input-file
                      id="imageFile"
                      v-model="imageFile"
                      :label="$t('change-template-image.label-upload-image-file')"
                      type="file"
                      placeholder="Select Json file for the Order"
                      :buttontext="$t('change-template-image.button-text-file')"
                      :nofileselected="$t('change-template-image.no-file-selected')"
                      name="imageFile"
                      accept="image/png, image/jpeg, image/gif, image/jpg"
                      @change="onFileChange($event)"
                    />

                    <vmd-button
                      v-if="fileDatasize != null"
                      id="resetbutton"
                      class="shadow-none rounded-pill font-weight-normal"
                      color="primary"
                      type="button"
                      @click="reset"
                      >{{ $t("change-template-image.label-reset-button") }}</vmd-button
                    >
                  </div>
                  <vmd-button
                    id="jsonsubmit"
                    class="shadow-none rounded-pill font-weight-normal mt-3"
                    color="primary"
                    type="submit"
                    >{{ $t("change-template-image.label-upload-button") }}</vmd-button
                  >
                </div>
                <div v-if="urlImg" id="preview" class="previewPart">
                  <img :src="urlImg" />
                </div>
                <div
                  v-if="uploadedImage && urlImg == null"
                  id="previewofuplaoded"
                  class="previewPart"
                >
                  <img :src="uploadedImage" />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import VmdInputFile from "@/components/VmdInputFile.vue";
import VmdButton from "@/components/VmdButton.vue";
import i18n from "@/i18n";
import axiosInterceptor from "@/shared/axiosInterceptor";
import Swal from "sweetalert2";
import updateDimension from "../../Mixins/updateDimension";
import orderInfomration from "../../Mixins/orderInfomration";
export default {
  name: "CreateTemplate",
  components: {
    Form,
    VmdInputFile,
    VmdButton,
  },
  mixins: [updateDimension, orderInfomration],
  data() {
    return {
      file: null,
      urlImg: null,
      fileData: {},
      fileDatasize: null,
      validFileExtensions: { image: ["jpg", "png", "jpeg"] },
      fileType: "image",
      loader: null,
      templateId: null,
      uploadFileType: null,
      templateImageAPIresponse: [],
      externalImage: null,
      uploadedImage: null,
    };
  },
  computed: {
    updateOrderFormat: function () {
      this.$store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },
    validationSchema() {
      let FileExtensionsList = this.validFileExtensions;
      function isValidFileType(fileName, fileType) {
        return (
          fileName && FileExtensionsList[fileType].indexOf(fileName.split(".").pop()) > -1
        );
      }
      const Schema = Yup.object().shape({
        imageFile: Yup.mixed()
          .test(
            "is-valid-type",
            i18n.global.t("change-template-image.error-valid-file"),
            (value) => isValidFileType(value && value.name.toLowerCase(), "image")
          )
          .required(i18n.global.t("change-template-image.error-file-required")),
      });
      return Schema;
    },
  },
  async mounted() {
    this.autoRefreshToken(7);
    this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
    this.templateId = await this.decryptOrderId(this.$route.params.templateId);
    this.templateImageAPIresponse = await axiosInterceptor.get(
      `api/v1/resources/${this.templateId}?join=previews&join=resourceData`
    );
    if (this.templateImageAPIresponse["data"]["name"] != null) {
      this.uploadedImage = `${this.externalImage}/${this.templateImageAPIresponse["data"]["name"]}`;
    }
  },
  beforeUnmount() {
    clearInterval(this.setTimer);
  },
  methods: {
    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      // File is not present
      if (!files.length) return;
      let fileName = files[0].name;
      // Invalid File
      let fileValid =
        fileName &&
        this.validFileExtensions[this.fileType].indexOf(fileName.split(".").pop()) > -1;
      if (!fileValid) {
        return;
      }
      var loaderDisplayPosition = document.body;
      this.loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      this.fileData = await this.readFile(files[0]);
      this.fileDatasize = this.fileData.byteLength;
      this.uploadFileType = files[0].type;
      this.urlImg = URL.createObjectURL(files[0]);
      this.loader.hide();
    },
    async readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.readAsArrayBuffer(file);
      });
    },
    async submitTemplate() {
      var loaderDisplayPosition = document.getElementById("new-board-modal");
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      const config = {
        headers: {
          "Content-Type": this.uploadFileType,
        },
      };

      let response = await axiosInterceptor.post(
        `api/v1/resources/${this.templateId}/upload?createSymLink=true`,
        this.fileData,
        config
      );

      if (response.status == 201) {
        Swal.fire({
          title: i18n.global.t("change-template-image.title-success"),
          text: i18n.global.t("change-template-image.message-success"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.global.t("change-template-image.confirm-button-text"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.replace("/template/templateGrid");
          }
        });
      }
      loader.hide();
    },
    async reset() {
      const file = document.querySelector("#imageFile");
      file.value = "";
      this.fileData = {};
      this.fileDatasize = null;
      this.$refs.form.resetForm();
      this.urlImg = null;
      let labelFilename = document.querySelector("#nofileselected");
      labelFilename.innerHTML = i18n.global.t("change-template-image.no-file-selected");
    },
  },
};
</script>
