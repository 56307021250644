<template>
  <div
    id="container1"
    class="card multipart multipartminiature display_image"
    :class="{
      coverVisible: this.$parent.coverPage > 0,
    }"
  >
    <!--Cover Part -->
    <div v-if="this.$parent.coverPage > 0" class="coverContent">
      <div class="titleBox">{{ $t("multipart-minature.Cover-Part") }}</div>
      <div class="innerPreviewboxes">
        <!--dynamic div-->
        <div
          v-for="(index, c) in this.$parent.coverPageArray"
          :key="c"
          class="previewBox"
        >
          <div
            v-for="(newindex, s) in this.$parent.coverPageArray[c]"
            :key="s"
            class="singleSide"
            :class="{
              middleSide: s == 'coverMiddle',
              'd-none': s == 'coverMiddle',
            }"
          >
            <div
              :id="'variantcover_' + [this.$parent.coverPageArray[c][s]]"
              class="imagePreviewWrapper draggable card drag display_image"
              :class="{
                highlightDiv: highlightArray.includes(this.$parent.coverPageArray[c][s]),
                mouseDrag:
                  mouseDragArray.includes(this.$parent.coverPageArray[c][s]) ||
                  rectangleArray.includes(this.$parent.coverPageArray[c][s]),
              }"
              :draggable="EnableDrag"
              :data-variant="this.$parent.coverVariant"
              @dragstart="
                dragStartMultipart(
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  $event,
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                    undefined
                )
              "
              @dragleave="RemovehighlightDropArea"
              @dragover="
                highlightDropArea(
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  $event,
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                    undefined
                )
              "
              @mouseover="
                highlightDropArea(
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  $event,
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                    undefined
                )
              "
              @mouseout="RemovehighlightDropArea"
              @dragover.prevent
              @drop="
                dragFinishMultipart(
                  -1,
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  $event
                )
              "
              @mousedown="
                this.$parent.selectMultiple(
                  $event,
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  1
                )
              "
              @click="mouseDroping(this.$parent.coverPageArray[c][s], 'Cover')"
            >
              <img
                v-if="
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                  undefined
                "
                :id="'c_img' + [this.$parent.coverPageArray[c][s]]"
                class="req"
                :data-test="this.$parent.coverPageArray[c][s]"
                :src="
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                    'dummyImage'
                  ]
                    ? this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                        'thumb'
                      ]
                    : this.$parent.externalImage +
                      this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                        'thumb'
                      ]
                "
              />

              <div
                v-if="
                  s != 'coverMiddle' &&
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                    undefined &&
                  !this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                    'dummyImage'
                  ]
                "
                class="drop_button dropdown dropstart"
              >
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
                ></a>
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                  data-bs-popper="none"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.ShowCanvas(
                          this.$parent.coverPageArray[c][s],
                          'miniature'
                        )
                      "
                      >PDFTool</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.confirmUnassignResource(
                          this.$parent.coverPageArray[c][s],
                          'Cover'
                        )
                      "
                      >{{ $t("release-print-data.unassign-link") }}</a
                    >
                  </li>
                </ul>
              </div>

              <p class="card-content">
                <strong v-if="s != 'coverMiddle'"
                  >U{{ this.$parent.coverPageArray[c][s] }}
                </strong>
                <strong v-else>{{ this.$parent.coverPageArray[c][s] }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--body part-->
    <div class="coverContent bookContent">
      <div class="titleBox">{{ $t("multipart-minature.Body-Part") }}</div>
      <div
        class="innerPreviewboxes"
        :class="{
          landscapeMode: this.$parent.bindingType == 'Top',
        }"
      >
        <div
          v-for="(index, l) in this.$parent.MainMinatureArray"
          :key="l"
          class="previewBox"
        >
          <div
            v-for="(newindex, k) in this.$parent.MainMinatureArray[l]"
            :key="k"
            class="singleSide"
            :class="{
              invisible:
                this.$parent.MainMinatureArray[l][k] == -1 ||
                this.$parent.MainMinatureArray[l][k] > this.$parent.bodyPage - 1,
            }"
          >
            <div
              :id="'variant_' + [this.$parent.MainMinatureArray[l][k] + 1]"
              class="imagePreviewWrapper draggable card drag display_image"
              :class="{
                highlightDiv: highlightArray.includes(
                  this.$parent.MainMinatureArray[l][k] + 1 + this.$parent.coverPage
                ),
                mouseDrag:
                  mouseDragArray.includes(
                    this.$parent.MainMinatureArray[l][k] + 1 + this.$parent.coverPage
                  ) ||
                  rectangleArray.includes(
                    this.$parent.MainMinatureArray[l][k] + 1 + this.$parent.coverPage
                  ),
              }"
              :draggable="EnableDrag"
              :data-variant="this.$parent.bodyVariant"
              @dragstart="
                dragStartMultipart(
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event,
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                    undefined
                )
              "
              @dragover="
                highlightDropArea(
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event,
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                    undefined
                )
              "
              @dragleave="RemovehighlightDropArea"
              @dragover.prevent
              @drop="
                dragFinishMultipart(
                  -1,
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event
                )
              "
              @mouseout="RemovehighlightDropArea"
              @mouseover="
                highlightDropArea(
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event,
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                    undefined
                )
              "
              @mousedown="
                this.$parent.selectMultiple(
                  $event,
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  1
                )
              "
              @click="mouseDroping(this.$parent.MainMinatureArray[l][k] + 1, 'Body')"
            >
              <img
                v-if="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1]
                    ?.thumb
                "
                :id="'b_img' + (this.$parent.MainMinatureArray[l][k] + 1)"
                class="req"
                :data-test="
                  this.$parent.MainMinatureArray[l][k] + 1 + this.$parent.coverPage
                "
                :src="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1][
                    'dummyImage'
                  ]
                    ? this.$parent.thumbnailArray[
                        this.$parent.MainMinatureArray[l][k] + 1
                      ]['thumb']
                    : this.$parent.externalImage +
                      this.$parent.thumbnailArray[
                        this.$parent.MainMinatureArray[l][k] + 1
                      ]['thumb']
                "
              />
              <div
                v-if="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                    undefined &&
                  !this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1][
                    'dummyImage'
                  ]
                "
                class="drop_button dropdown dropstart"
              >
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
                ></a>
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                  data-bs-popper="none"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.ShowCanvas(
                          this.$parent.MainMinatureArray[l][k] +
                            1 +
                            this.$parent.coverPage,
                          'miniature'
                        )
                      "
                      >PDFTool</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.confirmUnassignResource(
                          this.$parent.MainMinatureArray[l][k] + 1,
                          'Body'
                        )
                      "
                      >{{ $t("release-print-data.unassign-link") }}</a
                    >
                  </li>
                </ul>
              </div>
              <p class="card-content">
                <strong>{{ this.$parent.MainMinatureArray[l][k] + 1 }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="floatingBtn">
      <!-- Deselect Button -->
      <vmd-button
        v-show="mouseDragArray.length > 0"
        id="deselectBtn"
        class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
        color="primary"
        @click="RemoveMouseDragValue()"
      >
        {{ $t("multipart-minature.button-deselect") }}
      </vmd-button>

      <!-- Undo Button -->
      <vmd-button
        v-show="this.$parent.undoArray.length > 0"
        id="undoBtn"
        class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
        color="primary"
        @click="this.$parent.UndoProcess()"
        >{{ $t("multipart-minature.button-Undo") }}
      </vmd-button>

      <!-- Save Button -->
      <vmd-button
        v-show="this.$parent.showSaveBtnInMinature == true"
        id="saveDropData"
        class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
        color="primary"
        @click="saveDropData()"
        >{{ $t("multipart-minature.button-Save") }}
      </vmd-button>
    </div>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import axiosInterceptor from "@/shared/axiosInterceptor";

export default {
  name: "MultipartMiniature",
  components: {
    VmdButton,
  },
  data: function () {
    return {
      highlightArray: [],
      payload: [],
      minatureFlag: false,
      rectangle: 0,
      startDragging: false,
      mouseDragArray: [],
      mouseDragComplete: false,
      altKeyPressed: false,
      prevX: null,
      prevY: null,
      prevDirection: null,
      rectangleArray: [],
      rectDragging: false,
      EnableDrag: true,
    };
  },
  mounted() {
    //

    /**/
    /*Scroll*/
    /*0function smoothScrollTo(yPosition, duration) {
      const start = window.pageYOffset;
      const distance = yPosition - start;
      const startTime = performance.now();

      function animateScroll(timestamp) {
        const currentTime = timestamp || performance.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easeInOutQuad(progress);
        const scrollAmount = start + distance * easedProgress;
        window.scrollTo(0, scrollAmount);
        console.log(`elapsed`, elapsed);
        console.log(`duration`, duration);
        if (elapsed < duration) {
          console.log("inin");
          requestAnimationFrame(animateScroll);
        }
      }

      function easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      }

      requestAnimationFrame(animateScroll);
    }*/

    // Smooth scroll to top of the page
    /*function scrollToTop(duration) {
      smoothScrollTo(0, duration);
    }*/

    // Smooth scroll to bottom of the page
    /*function scrollToBottom(duration) {
      const windowHeight = window.innerHeight;
      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight
      );
      smoothScrollTo(documentHeight - windowHeight, duration);
    }*/
    /*Scroll*/

    /*LOGIC FOR CREATE RECTANGLE*/
    //const container = document.getElementById("container1");
    const container = document.getElementById("tmpdivid");
    var images = document.querySelectorAll(".req");
    var newdiv = document.getElementById("app");
    //var prevScrollPos = window.scrollY;
    let startPosition = { x: 0, y: 0 };
    let endPosition = { x: 0, y: 0 };
    let t = this;
    let lastY = 0;
    let lastX = 0;
    let prevX;
    let prevY;
    ////var ref = document.getElementById("tmpdivid");let lastMouseY = 0;
    //let currentMouseY = 0;

    const selectionBox = document.createElement("div");
    selectionBox.setAttribute("id", "selection-box");
    selectionBox.setAttribute(
      "style",
      "position: absolute; border: 2px dashed black; background-color: rgba(555,555,555,0.5); border: none; z-index: 1000; box-sizing: border-box; left: 0px; transform-origin: top left;"
    );
    //newdiv.appendChild(selectionBox);
    newdiv.append(selectionBox);

    container.addEventListener("mousedown", (e) => {
      const clickedElement = e.target;
      // CHECKING THE CLICK PLACE
      if (e.target.tagName.toLowerCase() === "img") {
        return;
      } else if (e.target.tagName.toLowerCase() === "div") {
        const divId = clickedElement.id;
        if (
          t.mouseDragArray.length > 0 ||
          t.$parent.selectMultipleImage.length > 0 ||
          divId == "left-sidebar"
        ) {
          return;
        }
        t.rectDragging = true;
      } else {
        return;
      }
      console.log("down");
      images = document.querySelectorAll(".req");
      startPosition.x = e.clientX + window.scrollX;
      startPosition.y = e.clientY + window.scrollY;
      endPosition.x = e.clientX + window.scrollX;
      endPosition.y = e.clientY + window.scrollY;
      selectionBox.style.left = startPosition.x + "px";
      selectionBox.style.top = startPosition.y + "px";
      //selectionBox.style.width = "0px";
      //selectionBox.style.height = "0px";
    });

    document.addEventListener("mousemove", (e) => {
      let currentY = e.clientY;
      let currentX = e.clientX;
      //lastMouseY = currentMouseY;
      //currentMouseY = e.clientY;

      if (t.rectDragging) {
        //console.log(`class`, e.target.className);
        //  e.target.classList.contains("navbar-main") ||
        if (
          e.target.classList.contains("commonClass") ||
          e.target.className == "ps__rail-y" ||
          e.target.className == "ps__thumb-y" ||
          e.target.tagName == "HTML" ||
          e.target.classList.contains("container") ||
          e.target.classList.contains("ps--active-y")
        ) {
          //console.log("ddd");
          return;
        }
      }

      if (e.target.tagName.toLowerCase() == "div" && t.rectDragging) {
        /*const clickedElement = e.target;
        const divId = clickedElement.id;
        const parentDiv = clickedElement.parentNode;*/
        /*console.log("Clicked element's parent div ID:", parentDiv.id);
        console.log("Clicked div ID:", divId);*/
        /*if (divId == "left-sidebar") {
          return;
        }
        if (parentDiv.id == "left-sidebar") {
          return;
        }*/
      }

      const threshold = 100; // Distance from the bottom to trigger the action

      const scrollHeight1 = document.documentElement.scrollHeight;
      const mouseY1 = e.clientY + window.scrollY;
      //console.log("new code", mouseY1, scrollHeight1, viewportHeight);
      if (mouseY1 >= scrollHeight1 - threshold) {
        console.log("Mouse is near the bottom of the page.");
      }

      if (t.rectDragging) {
        endPosition.x = e.clientX + window.scrollX;
        endPosition.y = e.clientY + window.scrollY;

        /*SCROLLING CODE*/

        /**/
        createRectangle(e);
      }
      if (t.rectDragging == false && t.rectangleArray.length > 0) {
        movePreview(e);
      }
      lastY = currentY;
      lastX = currentX;
    });

    function createRectangle(e, fromScrolling = false) {
      if (fromScrolling) {
        e.clientY = lastY;
        e.clientX = lastX;
      }

      endPosition.x = e.clientX + window.scrollX;
      endPosition.y = e.clientY + window.scrollY;

      const width = Math.abs(endPosition.x - startPosition.x);
      var height = Math.abs(endPosition.y - startPosition.y);
      let maxX = Math.max(startPosition.x, endPosition.x);
      let minX = Math.min(startPosition.x, endPosition.x);
      let maxY = Math.max(startPosition.y, endPosition.y);
      let minY = Math.min(startPosition.y, endPosition.y);

      selectionBox.style.width = width + "px";
      selectionBox.style.height = height + "px";
      selectionBox.style.left =
        Math.min(endPosition.x, startPosition.x) + parseInt(5) + "px";
      selectionBox.style.top = Math.min(endPosition.y, startPosition.y) + "px";
      images.forEach((image) => {
        const rect = image.getBoundingClientRect();
        //const containerRect = newdiv.getBoundingClientRect();
        const offsetX = rect.left - newdiv.getBoundingClientRect().left;
        const offsetY = rect.top - newdiv.getBoundingClientRect().top;
        const isSelected =
          offsetX < maxX &&
          offsetX + rect.width > minX &&
          offsetY < maxY &&
          offsetY + rect.height > minY;

        const dataTestValue = parseInt(image.getAttribute("data-test"));
        if (isSelected) {
          if (!t.rectangleArray.includes(dataTestValue)) {
            t.rectangleArray.push(dataTestValue);
            image.classList.add("selected");
          }
        } else {
          const deleteIndex = t.rectangleArray.indexOf(dataTestValue);
          if (deleteIndex >= 0) {
            t.rectangleArray.splice(deleteIndex, 1);
          }
          image.classList.remove("selected");
        }
      });
    }
    function movePreview(e, scrollX, scrollY, fromScroll = false) {
      var ifExistDiv = document.getElementById("rectPreviewContainer");
      let temFlag = false;
      let image;
      let getImageSrc;
      var getImage;
      if (!ifExistDiv) {
        temFlag = true;
        ifExistDiv = document.createElement("div");
        ifExistDiv.id = "rectPreviewContainer";
        if (t.rectangleArray.length > 4) {
          let imageSeq = t.rectangleArray[0];
          getImage = document.querySelector('img[data-test="' + imageSeq + '"]');
          getImageSrc = getImage.src;

          const newDiv1 = document.createElement("p");
          // Set the id attribute for the new div
          newDiv1.id = "textOverlay_rect";
          newDiv1.textContent = t.rectangleArray.length;
          newDiv1.setAttribute(
            "style",
            "position: absolute; top: 2px; right: 2px; width: 25px; height: 25px; display: flex; align-items: center; justify-content: center; text-align: center; color: white; font-size: 14px; background-color: #6731e2; border-radius: 20px; padding: 5px; margin: 0px;"
          );

          const newImage = document.createElement("img");
          newImage.src = getImageSrc;
          newImage.setAttribute("style", "objectfit:cover;width:100%;height:100%;");
          ifExistDiv.appendChild(newImage);
          ifExistDiv.appendChild(newDiv1);
          newdiv.appendChild(ifExistDiv);

          //tempDiv.appendChild(newImage);
          //newdiv.appendChild(newDiv1);
        } else {
          for (let k = 0; k < t.rectangleArray.length; k++) {
            let imageSeq = t.rectangleArray[k];
            getImage = document.querySelector('img[data-test="' + imageSeq + '"]');
            if (getImage) {
              getImageSrc = getImage.src;
              image = document.createElement("img");
              image.id = "img_" + imageSeq;
              image.src = getImageSrc;
              ifExistDiv.appendChild(image);
              newdiv.appendChild(ifExistDiv);
            }
          }
        }
      }

      // Calculate the new position for the image
      var newX;
      var newY;
      let lastImage = t.rectangleArray[t.rectangleArray.length - 1];

      if (lastImage == undefined) {
        return;
      }
      var divWithDataAttr = document.querySelector(`img[data-test="${lastImage}"]`);
      var rect1 = divWithDataAttr.getBoundingClientRect();
      if (temFlag == true) {
        newX = rect1.left;
        newY = rect1.top;
        if (newX > e.clientX) {
          newX = newX - e.clientX;
        } else {
          newX = e.clientX - newX;
        }
        if (newY > e.clientY) {
          newY = newY - e.clientY;
        } else {
          newY = e.clientY - newY;
        }
        newX = endPosition.x;
        newY = endPosition.y;
      } else {
        newX = e.clientX + window.scrollX;
        newY = e.clientY + window.pageYOffset;
      }
      if (fromScroll == true) {
        newX = prevX + scrollX;
        newY = prevY + scrollY;
      }
      if (e.clientX > 0) {
        prevX = e.clientX;
      }
      if (e.clientY > 0) {
        prevY = e.clientY;
      }

      newX = newX + parseInt(3);

      // Set the position of the image
      ifExistDiv.setAttribute(
        "style",
        `left:${newX}px;top:${newY}px;display:block;position:absolute;z-index:99999;`
      );
    }

    document.addEventListener("mouseup", (e) => {
      if (t.rectDragging) {
        t.rectDragging = false;
        movePreview(e);
        selectionBox.style.width = "0px";
        selectionBox.style.height = "0px";
        selectionBox.style.top = "0px";
        selectionBox.style.left = "0px";
      }
    });

    // Clear selection on click outside images
    document.addEventListener("mousedown", (e) => {
      if (!container.contains(e.target)) {
        images.forEach((image) => {
          image.classList.remove("selected");
        });
      }
      lastY = e.clientY;
    });
    //document.addEventListener("scroll", preventScrolling, { passive: false });

    document.addEventListener("scroll", async (e) => {
      //console.log("in scroll", e.target);
      e.preventDefault();
      if (t.rectDragging == false && t.rectangleArray.length > 0) {
        e.preventDefault();
        movePreview(e, window.scrollX, window.scrollY, true);
      } else {
        if (t.rectDragging == true) {
          /*if (
            e.target &&
            e.target.classList &&
            e.target.classList.contains("navbar-main")
          ) {
            scrollToTop(ref, 100);
          }*/
          await createRectangle(e, true);
        }
        //prevScrollPos = window.scrollY;
      }
    });

    document.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        t.RemovedRectagleValue();
      }
    });

    //
    /**/
    /**/
    var parentNode = document.querySelector(".col-lg-8.main-content");
    var superParentNode = document.querySelector(".print-page-container");
    setTimeout(() => {
      parentNode.classList.add("multiPartMainContent");
      superParentNode.classList.add("minimizeLeftSideBar");
      document.querySelectorAll(".multiPartMainContent").forEach(function (params) {
        params.addEventListener("click", function (e) {
          // var clickedElement = document.querySelector(".drop_button > a");
          var commonParent = document.querySelectorAll(".previewBox");
          var myElementToCheckIfClicksAreInsideOf = params.contains(e.target);
          if (params || myElementToCheckIfClicksAreInsideOf) {
            var parentSelector =
              e.target.parentElement.parentElement.parentElement.parentElement
                .parentElement;
            if (parentSelector) {
              commonParent.forEach((element) => {
                element.classList.remove("activePreviewBox");
              });

              parentSelector.classList.add("activePreviewBox");

              //console.log("parentPre", parentSelector.classList);
            } else {
              commonParent.forEach((element) => {
                element.classList.remove("activePreviewBox");
              });
            }
          } else {
            //console.log("dropClosed");
            commonParent.forEach((element) => {
              element.classList.remove("activePreviewBox");
            });
          }
        });
      });
    }, 0.2);
  },
  beforeUnmount() {
    var parentNode = document.querySelector(".col-lg-8.main-content");
    var superParentNode = document.querySelector(".print-page-container");
    if (parentNode) {
      setTimeout(() => {
        parentNode.classList.remove("multiPartMainContent");
        superParentNode.classList.remove("minimizeLeftSideBar");
      }, 0.2);
    }

    // Delete Preview Div
    this.RemovedRectagleValue();
    /*const deleteDiv = document.getElementById("rectPreviewContainer");
    if (deleteDiv) {
      this.rectangleArray = [];
      deleteDiv.parentNode.removeChild(deleteDiv);
    }*/
  },

  methods: {
    RemoveMouseDragValue() {
      this.mouseDragArray = [];
      const ifExist = this.$parent.processingMsg.findIndex(
        (msg) => msg.type === "dropmultipleImage"
      );
      if (ifExist >= 0) {
        this.$parent.processingMsg.splice(ifExist, 1);
      }
      this.prevDirection = null;
    },
    RemovedRectagleValue() {
      this.rectangleArray = [];
      const deleteDiv = document.getElementById("rectPreviewContainer");
      if (deleteDiv) {
        deleteDiv.parentNode.removeChild(deleteDiv);
      }
      this.RemovehighlightDropArea();
      const selectionBox = document.getElementById("selection-box");
      selectionBox.setAttribute(
        "style",
        "position: absolute; border: 2px dashed black; background-color: rgba(555,555,555,0.5); border: none; z-index: 1000; box-sizing: border-box; left: 0px; transform-origin: top left;"
      );
    },
    getImageId() {
      this.$parent.selectMultipleImage = [];
      for (let k = 0; k < this.mouseDragArray.length; k++) {
        let seqValue = this.mouseDragArray[k];
        let partType = "Cover";
        if (this.$parent.coverPage > 0) {
          if (seqValue > this.$parent.coverPage) {
            partType = "Body";
            seqValue = seqValue - this.$parent.coverPage;
          }
        } else {
          partType = "Body";
        }
        let storeDropIndex = this.$parent.images.findIndex(
          this.$parent.getOldSortSequence(this.$parent.images, seqValue, partType)
        );
        if (storeDropIndex >= 0) {
          this.$parent.selectMultipleImage.push(storeDropIndex);
        }
      }
    },
    async mouseDroping(dropIndex, dropPart) {
      let tmp = [];
      if (this.mouseDragArray.length == 0) {
        if (this.rectangleArray.length == 0) {
          return;
        }
      }

      let loaderDisplayPosition = document.body;
      let loader = await this.$loading.show({
        container: loaderDisplayPosition,
      });

      let temp = [];
      if (this.mouseDragArray.length > 0) {
        temp = this.mouseDragArray;
      }

      if (this.rectangleArray.length > 0) {
        temp = this.rectangleArray;
      }

      this.$parent.selectMultipleImage = [];
      for (let k = 0; k < temp.length; k++) {
        let seqValue = temp[k];
        let partType = "Cover";
        if (this.$parent.coverPage > 0) {
          if (seqValue > this.$parent.coverPage) {
            partType = "Body";
            seqValue = seqValue - this.$parent.coverPage;
          }
        } else {
          partType = "Body";
        }
        let storeDropIndex = this.$parent.images.findIndex(
          this.$parent.getOldSortSequence(this.$parent.images, seqValue, partType)
        );
        if (storeDropIndex >= 0) {
          tmp.push(storeDropIndex);
          this.$parent.selectMultipleImage.push(storeDropIndex);
        }
      }
      await this.dropMultipleImage(0, 0, dropIndex, dropPart);

      this.$parent.selectMultipleImage = [];
      this.mouseDragComplete = false;
      this.startDragging = false;
      this.altKeyPressed = false;
      this.RemoveMouseDragValue();
      this.RemovedRectagleValue();
      //console.log(loader);
      loader.hide();
      //console.log(`tmp`, tmp);
    },
    storedPayload(data) {
      //console.log("yes upload payload");
      this.payload = data;
    },
    async dragStartMultipart(dragimageindex, dragPartType, ev, imageExist) {
      if (
        imageExist == false ||
        this.rectangleArray.length > 0 ||
        this.rectDragging == true
      ) {
        ev.preventDefault();
        return;
      }
      if (this.mouseDragArray.length > 0) {
        return;
      }

      /*let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });*/

      this.minatureFlag = false;
      if (this.$parent.singleSelect == true) {
        //extucode code
      } else if (ev.altKey) {
        this.$parent.isDrag = null;
        return;
      } else {
        this.$parent.isDrag = null;
        this.minatureFlag = true;
        await this.$parent.dragMultiple(ev, 1, dragimageindex, dragPartType);
        return;
      }

      //ev.dataTransfer.setData("Text", dragimageindex);
      let imageArrayIndex = this.$parent.images.findIndex((object) => {
        return object.sortSequence == dragimageindex && object.partType == dragPartType;
      });

      ev.dataTransfer.dropEffect = "move";
      this.$parent.dragging = imageArrayIndex;
      this.$parent.isDrag = imageArrayIndex;
      //loader.hide();
    },
    dragginMultiple(ev, dragimageindex, dragPartType) {
      const currX = ev.clientX;
      //const currY = ev.clientY;
      let directionX;
      //let directionY;
      if (this.prevX !== null) {
        const deltaX = currX - this.prevX;
        //const deltaY = currY - this.prevY;

        directionX = deltaX > 0 ? "right" : "left";
        //directionY = deltaY > 0 ? "down" : "up";

        //console.log("Mouse direction: ", directionX, directionY);
      }

      this.prevX = currX;
      //this.prevY = currY;
      if (this.prevDirection == null) {
        this.prevDirection = directionX;
      }

      let index = dragimageindex;
      if (dragPartType == "Body") {
        index = parseInt(dragimageindex) + this.$parent.coverPage;
      }
      if (!this.mouseDragArray.includes(index)) {
        this.mouseDragArray.push(index);
      } else {
        //if (directionX == "left") {
        /*if (this.prevDirection != directionX) {
          let deleteIndex = this.mouseDragArray.indexOf(index);
          this.mouseDragArray.splice(deleteIndex, 1);
        }*/
      }
      console.log(`final`, this.mouseDragArray);
      this.$parent.AddProcessingMsg(
        "dropmultipleImage",
        "release-print-data.dropMultipleimage-msg",
        "warning",
        false,
        true,
        false
      );
    },
    highlightDropArea(seq, partType, event, imageExist) {
      let rectFlag = false;
      if (this.rectDragging == false && this.rectangleArray.length > 0) {
        // execute the main code
        rectFlag = true;
      } else {
        if (
          event.type == "mouseover" &&
          this.mouseDragArray.length == 0 &&
          !event.altKey
        ) {
          return;
        }
      }

      if (rectFlag == false) {
        if (event.altKey) {
          if (this.$parent.selectMultipleImage.length > 0) {
            return;
          }
          if (imageExist == false) {
            return;
          }
          //console.log("yeyeyeyeyeyeyeye");
          this.pushDragArray(seq, partType, event);
          return;
        }
        if (this.mouseDragArray.length == 0) {
          // if (this.$parent.selectMultipleImage.length == 0 && this.$parent.isdrag == null) {
          if (
            this.$parent.selectMultipleImage.length == 0 &&
            this.$parent.isDrag == null
          ) {
            return;
          }
          if (this.$parent.selectMultipleImage.length > 0 && event.shiftKey) {
            return;
          }
        }
      }

      this.highlightArray = [];
      if (partType == "Cover") {
        this.highlightArray.push(seq);
        if (seq == this.$parent.coverPage) {
          seq = 0;
          partType = "Cover";
        }
      } else {
        this.highlightArray.push(seq + this.$parent.coverPage);
      }
      let tmpSeq = seq;
      if (partType == "Cover") {
        ///
      } else {
        tmpSeq = tmpSeq + this.$parent.coverPage;
      }

      //increase the index based on selected images
      let numImages = this.$parent.selectMultipleImage.length;
      if (numImages == 0) {
        numImages = this.mouseDragArray.length;
      }
      if (rectFlag) {
        numImages = this.rectangleArray.length;
      }

      if (numImages > 1) {
        const increaseSeqArray = Array.from(
          { length: numImages - 1 },
          (_, index) => tmpSeq + index + 1
        );
        this.highlightArray.push(...increaseSeqArray);
      }
      //console.log(`this.highlightArray`, this.highlightArray);
      if (this.altKeyPressed == true) {
        this.altKeyPressed = false;
      }
    },
    RemovehighlightDropArea() {
      //this.$parent.isDrag = null;
      //console.log("remove");
      this.highlightArray = [];
    },
    pushDragArray(seq, partType, event) {
      if (event.altKey) {
        //console.log("dddd");
        if (this.altKeyPressed == false) {
          console.log("delete");
          //this.mouseDragArray = [];
          this.altKeyPressed = true;
        }
        console.log(seq, partType);
        this.dragginMultiple(event, seq, partType);
      }
    },
    async dragFinishMultipart(to, dropimageindex, dropPartType, ev) {
      //   this.$parent.isDrag = null;
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      var data = ev.dataTransfer.getData("Text");
      localStorage.setItem(`ShowSaveBtn_${btoa(this.$parent.OrderId)}`, true);

      if (this.$parent.selectMultipleImage.length > 0) {
        // console.log("asdjkahsdagdhjadsghadg");
        await this.dropMultipleImage(
          this.$parent.dragging,
          to,
          dropimageindex,
          dropPartType
        );
      } else if (ev.altKey || this.mouseDragArray.length > 0) {
        //this.mouseDroping(dropimageindex, dropPartType);
        this.startDragging = false;
        console.log("new logic");
        await this.RemoveMouseDragValue();
      } else {
        if (document.getElementById(data) != null || data != null) {
          console.log(`data`, document.getElementById(data));
          console.log(`data!!`, data);
          await this.$parent.moveCoverItem(
            this.$parent.dragging,
            to,
            dropimageindex,
            dropPartType
          );
          await this.RemovehighlightDropArea();
        }
      }
      loader.hide();
    },
    moveElementToPosition(array, elementIndex, newPosition) {
      const element = array[elementIndex];
      if (elementIndex > newPosition) {
        array.splice(elementIndex, 1);
        array.splice(newPosition, 0, element);
      } else if (elementIndex < newPosition) {
        array.splice(newPosition, 0, element);
        //array[elementIndex] = undefined;
      }
      return array;
    },
    async dropMultipleImage1(dragging, to, dropimageindex, dropPartType) {
      let endLoop = this.$parent.requiredPage;
      let seqMainArray = [];
      let seqPart = [];
      let oldSeq = [];
      let oldPart = [];
      let newIndex = [];
      console.log(dragging, to, dropimageindex, dropPartType, endLoop);

      let coverMainValues = this.$parent.CoverImageWithThumb.map((item) => item || null);
      console.log(`bef`, coverMainValues);
      //delete coverMainValues[0];
      //console.log(`coverMainValues--?`, coverMainValues);
      //let thumbnailMainValues = this.$parent.thumbnailArray.map((item) => item || {});
      let thumbnailMainValues = this.$parent.thumbnailArray.map((item) =>
        item !== null ? item : undefined
      );

      //console.log(`thumbnailMainValues====>>>`, thumbnailMainValues);

      // let Mergearray = [...coverMainValues, ...thumbnailMainValues];
      //let Mergearray = coverMainValues.concat(thumbnailMainValues);
      let Mergearray = coverMainValues;
      Mergearray.push(...thumbnailMainValues);
      console.log(`Mergearray---->>>>`, Mergearray);

      //Next logic
      let currentIndex = dropimageindex;
      console.log(`currentIndex---`, currentIndex);
      //let currentIndex = this.$parent.image[currentIndex].sortSequence
      //console.log(`this.$parent.coverPage`, this.$parent.coverPage);
      let nextIndex = this.$parent.selectMultipleImage.length;
      if (dropPartType == "Cover") {
        nextIndex = nextIndex + dropimageindex;
      }
      if (dropPartType == "Body" && this.$parent.coverPage > 0) {
        // first 2 for the 0 index delete in cover and body
        currentIndex = currentIndex + this.$parent.coverPage;
        nextIndex = nextIndex + this.$parent.coverPage + dropimageindex;
        if (currentIndex == this.$parent.coverPage + 1) {
          currentIndex = currentIndex + 1;
          nextIndex = nextIndex + 1;
        }
      }

      console.log(`nextStart----`, currentIndex, nextIndex);
      let temp = currentIndex;
      let arrayType;
      let arrayIndex;
      //temp code need to remove
      // endLoop = temp;
      for (let k = temp; k < endLoop; k++) {
        console.log(`currentIndex->`, currentIndex);
        // console.log(`valyueueu`, Mergearray[currentIndex]);
        /*let targetArray =
          currentIndex <= this.$parent.coverPage
            ? this.$parent.CoverImageWithThumb
            : this.$parent.thumbnailArray;*/

        //console.log(`dd`, targetArray[currentIndex]);

        /**/
        if (currentIndex === this.$parent.coverPage + 1) {
          currentIndex = currentIndex + 1;
        }

        if (nextIndex === this.$parent.coverPage + 1) {
          nextIndex = nextIndex + 1;
        }

        /*console.log(`currentIndex`, currentIndex);
        console.log(`nextIndex`, nextIndex);
        console.log(`targetArray[currentIndex]`, targetArray[currentIndex]);
        console.log(`Mergearray[currentIndex]`, Mergearray[nextIndex]);*/
        //console.log(`Mergearray[currentIndex]----`, Mergearray[currentIndex]);
        //if (
        //typeof Mergearray[currentIndex] === "undefined" ||
        //typeof Mergearray[nextIndex] === "undefined"
        //)
        if (typeof Mergearray[currentIndex] === "undefined") {
          break;
        }

        if (currentIndex > this.$parent.coverPage) {
          // one index is extra in array
          //if (currentIndex == this.$parent.coverPage + 1) {
          //currentIndex = currentIndex - 1;
          // }
          arrayType = "Body";
          arrayIndex = currentIndex - this.$parent.coverPage - 1;
          oldSeq.push(currentIndex - this.$parent.coverPage - 1);
          oldPart.push("Body");
        } else {
          arrayType = "Cover";
          arrayIndex = currentIndex;
          oldSeq.push(currentIndex);
          oldPart.push("Cover");
        }

        if (nextIndex > this.$parent.coverPage) {
          //if (nextIndex == this.$parent.coverPage + 1) {
          //nextIndex = nextIndex - 1;
          //}

          seqMainArray.push(nextIndex - this.$parent.coverPage - 1);
          seqPart.push("Body");
        } else {
          seqMainArray.push(nextIndex);
          seqPart.push("Cover");
        }
        console.log(`vvv`, currentIndex, nextIndex);
        //Mergearray[nextIndex] = Mergearray[currentIndex];
        if (arrayType == "Cover") {
          Mergearray[nextIndex] = this.$parent.CoverImageWithThumb[arrayIndex];
        } else {
          Mergearray[nextIndex] = this.$parent.thumbnailArray[arrayIndex];
        }
        newIndex.push(nextIndex);
        // delete only if new index has not same value as current index
        if (!newIndex.includes(currentIndex)) {
          console.log("if");
          delete Mergearray[currentIndex];
        } else {
          console.log("else");
        }
        currentIndex = currentIndex + 1;
        nextIndex = nextIndex + 1;
      }
      console.log(`oldseq`, oldSeq);
      console.log(`oldpart`, oldPart);

      console.log(`seqMainArray`, seqMainArray);
      console.log(`seqPart`, seqPart);
      //Mergearray = await this.moveElementToPosition(Mergearray, currentIndex, nextIndex);
      console.log(` after Mergearray---`, Mergearray);

      /* Drop logic*/
      let assignIndex = dropimageindex;
      let dragImagelength = this.$parent.selectMultipleImage.length;
      let dragIndex = this.$parent.selectMultipleImage[0];
      let dragSeq = this.$parent.images[dragIndex].sortSequence;
      let dragPartType = this.$parent.images[dragIndex].partType;
      if (dropPartType == "Body") {
        assignIndex = assignIndex + this.$parent.coverPage + 1;
      }
      for (let k = 0; k < dragImagelength; k++) {
        dragIndex = this.$parent.selectMultipleImage[k];
        dragSeq = this.$parent.images[dragIndex].sortSequence;
        dragPartType = this.$parent.images[dragIndex].partType;
        if (dropPartType == "Body") {
          //assignIndex = assignIndex + this.$parent.coverPage + 1;
          seqPart.push("Body");
          seqMainArray.push(assignIndex - this.$parent.coverPage - 1);
        } else {
          seqPart.push("Cover");
          seqMainArray.push(assignIndex);
        }

        oldPart.push(dragPartType);
        oldSeq.push(dragSeq);

        if (dragPartType == "Body") {
          dragSeq = dragSeq + this.$parent.coverPage + 1;
        }
        console.log(`dragSeq`, dragSeq, dragPartType, assignIndex, dragSeq);
        console.log(`assignIndex`, assignIndex);
        Mergearray[assignIndex] = Mergearray[dragSeq];
        delete Mergearray[dragSeq];
        assignIndex = assignIndex + 1;
      }

      /*drop*/

      let newData;
      let firstPart = Mergearray.slice(0, this.$parent.coverPage + 1);
      let secondPart = Mergearray.slice(this.$parent.coverPage + 2);

      // For start  index with 1
      newData = secondPart.reduce((acc, item, index) => {
        if (item === null) {
          acc[index + 1] = undefined;
        } else {
          acc[index + 1] = { ...item };
        }
        return acc;
      }, {});

      console.log(`firstPart`, firstPart);
      console.log(`secondPart`, secondPart);
      console.log(`newData-->`, newData);
      console.log(`Mergearray`, Mergearray);
      this.$parent.CoverImageWithThumb = firstPart;
      this.$parent.thumbnailArray = newData;
      console.log(seqMainArray, seqPart, oldPart, oldSeq);
      await this.updateMainImageArray(seqMainArray, seqPart, oldPart, oldSeq);
      await this.$parent.SortImageorder();
    },
    async dropMultipleImage(dragging, to, dropimageindex, dropPartType) {
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      await this.$parent.storedUndo();
      let dragImagelength = this.$parent.selectMultipleImage.length;
      const allSeq = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].sortSequence
      );

      const allDragPart = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].partType
      );

      const dragArray = this.$parent.selectMultipleImage.map((index) => {
        const image = this.$parent.images[index];
        return {
          sortSequence: image.sortSequence,
          partType: image.partType,
        };
      });

      //let temp = this.$parent.selectMultipleImage[0];

      let storeDropIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );

      let dropSeq;
      //image is not assigned
      if (storeDropIndex != -1) {
        dropSeq = this.$parent.images[storeDropIndex].sortSequence;
      } else {
        dropSeq = dropimageindex;
      }

      let dropPart = dropPartType;

      /*drop array list*/
      let dropArrayList = [];
      let dropArraySeq = dropSeq;
      let dropArrayPart = dropPart;
      let onlyOnceCover = 0;
      for (let t = 0; t < dragImagelength; t++) {
        dropArrayList.push({
          sortSequence: dropArraySeq,
          partType: dropArrayPart,
        });
        dropArraySeq = dropArraySeq + 1;
        // last condition set bcs if drop image has same seq as total cover page them next image has been first page of cover
        if (
          dropArraySeq > this.$parent.coverPage &&
          dropArrayPart == "Cover" &&
          dropArrayList[0].sortSequence != this.$parent.coverPage
        ) {
          dropArraySeq = 1;
          dropArrayPart = "Body";
        } else {
          if (
            onlyOnceCover == 0 &&
            dropArrayPart == "Cover" &&
            dropArraySeq > this.$parent.coverPage
          ) {
            dropArraySeq = 1;
            dropArrayPart = "Cover";
            onlyOnceCover = 1;
          } else if (
            dropArrayList[0].sortSequence == this.$parent.coverPage &&
            dropArraySeq >= this.$parent.coverPage
          ) {
            dropArraySeq = 1;
            dropArrayPart = "Body";
          }
        }
      }

      const commonByBoth = dragArray.filter((item1) =>
        dropArrayList.some(
          (item2) =>
            item1.sortSequence === item2.sortSequence && item1.partType === item2.partType
        )
      );

      /*Next logic Start*/
      let startLoop = dropimageindex;
      let endLoop;
      let startIndex = this.$parent.selectMultipleImage[0];
      let startDragPart = this.$parent.images[startIndex].partType;
      let startSeq = this.$parent.images[startIndex].sortSequence;

      // Only change Covers
      if (startDragPart == "Cover" && dropPartType == "Cover") {
        let maxSeq = Math.min(...allSeq);
        if (maxSeq >= dropimageindex) {
          endLoop = maxSeq;
        } else {
          endLoop = this.$parent.requiredPage;
        }
      } else if (startDragPart == "Cover" && dropPartType == "Body") {
        endLoop = this.$parent.bodyPage;
      } else if (startDragPart == "Body" && dropPartType == "Body") {
        if (startSeq > dropimageindex) {
          endLoop = startSeq;
        } else {
          endLoop = this.$parent.bodyPage;
        }
      } else if (startDragPart == "Body" && dropPartType == "Cover") {
        endLoop = this.$parent.requiredPage;
        // loop is not running if image drop into last page of cover
        if (dropArrayList[0].sortSequence == this.$parent.coverPage) {
          let temp = dragImagelength;
          if (dragImagelength < this.$parent.coverPage) {
            temp = this.$parent.coverPage - dragImagelength;
          }
          endLoop = endLoop + temp;
        }
      }

      // console.log(`startLoop`, startLoop);
      // console.log(`endLoop`, endLoop);
      let currSeq = null;
      let currSeqArray = [];
      let currSeqPart = [];
      let newSeq;
      let newSeqArray = [];
      let newSeqPart = [];
      let firstLoop = 0;
      let updatedK;
      let currPart = startDragPart;
      let updateDragLength = dragImagelength;
      updateDragLength = updateDragLength - commonByBoth.length;
      // SET NEW PART START SEQ IS GREATER THAN COVER PAGE
      let newPart = startDragPart;
      if (dropPartType == "Body") {
        newPart = "Body";
      }
      //console.log(`newPaet`, newPart);
      if (
        startLoop + updateDragLength > this.$parent.coverPage &&
        dropPartType == "Cover"
      ) {
        //updatedK = 1;
        // Set new part cover then it will go first condition of next seq
        newPart = "Cover";
      }
      // console.log(`newPaesssssst`, newPart);
      let oneTime = 0;
      let LastImageCover = 0;
      let dropArrayIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );

      let seqMainArray = [];
      let seqPart = [];
      let findImagePartType = [];
      let findoldSeq = [];
      if (dropArrayIndex != -1) {
        for (let k = startLoop; k < endLoop; k++) {
          if (currSeq >= this.$parent.coverPage && currPart === "Cover") {
            if (
              dropArrayList[0].sortSequence == this.$parent.coverPage &&
              currSeq == dropArrayList[0].sortSequence
            ) {
              currPart = "Cover";
            } else {
              currPart = "Body";
            }
            currSeq = 1;
          } else if (currSeq === null) {
            //console.log("iiiiiiiif 2");
            currSeq = k;
            currPart = dropPartType === "Cover" ? "Cover" : "Body";
          } else {
            //console.log("iiiiiiiif 3");
            currSeq += 1;
          }

          // For Curr seq
          let checkOldSeqAvailable = dropArrayList.filter(
            (check) => check.sortSequence == currSeq && check.partType == currPart
          );
          //console.log(`currSeq`, currSeq);
          //console.log(`checkOldSeqAvailable`, checkOldSeqAvailable);
          if (checkOldSeqAvailable.length >= 1 && currPart == "Cover") {
            if (
              checkOldSeqAvailable[0].sortSequence == this.$parent.coverPage &&
              currSeq == this.$parent.coverPage &&
              currSeqArray.includes(currSeq)
            ) {
              //console.log("demo1demoe");
              currSeq = 1;
              if (dragImagelength > this.$parent.coverPage) {
                // console.log("demo1demoefafsfsdfsdf");
                currSeq = dragImagelength - this.$parent.coverPage;
                currSeq = currSeq + 1;
              }
              //console.log(`dddddddddddddddddd`, currSeq);
              currPart = "Body";
            }
          }

          // if current seq and drag part is same as selcted seq then skip that
          if (allSeq.includes(currSeq)) {
            let getPart = allSeq.indexOf(currSeq);
            if (allDragPart[getPart] == currPart) {
              // It is required when drag image from B1,c2 and drop to C3
              //console.log(`continue`, currSeq, currPart);
              continue;
            }
          }
          if (
            currSeq + updateDragLength > this.$parent.coverPage &&
            oneTime == 0 &&
            newPart == "Cover"
          ) {
            // console.log(`tttif 1`);
            // This case generated when you drag last 2 image of body and drop into last cover page
            if (
              dropArrayList[0].sortSequence == this.$parent.coverPage &&
              LastImageCover == 0
            ) {
              //console.log("yes in");
              LastImageCover = 1;
              // Start Seq from the last seq of drop array
              let legofDropArray = dropArrayList.length;
              newSeq = dropArrayList[legofDropArray - 1].sortSequence + 1;
              updatedK = newSeq;
              newPart = dropArrayList[legofDropArray - 1].partType;
            } else {
              // console.log("proper");
              newSeq = 1;
              updatedK = 1;
              newPart = "Body";
              oneTime = 1;
            }
          } else if (firstLoop == 0 && LastImageCover == 0 && oneTime == 0) {
            //console.log(`ddddif 2`);
            firstLoop = 1;
            newSeq = k + updateDragLength;

            updatedK = newSeq;
            if (dropPartType == "Cover") {
              newPart = "Cover";
            } else {
              newPart = "Body";
            }
          } else {
            ///console.log(`if 3`);
            newSeq = updatedK + 1;
            updatedK = newSeq;
          }

          let checkNewSeqAvailable = dropArrayList.filter(
            (check) => check.sortSequence == newSeq && check.partType == newPart
          );
          //console.log(`checkNewSeqAvailable`, checkNewSeqAvailable);
          if (checkNewSeqAvailable.length >= 1 && newPart == "Cover") {
            if (checkNewSeqAvailable[0].sortSequence == this.$parent.coverPage) {
              //console.log("osdjfsjfksdjfksdjfkdfjjkshjdhfj");
              updatedK = 1;
              newSeq = 1;
              newPart = "Body";
            }
          }

          //console.log(`newSeq`, newSeq);
          if (newSeq > this.$parent.bodyPage && newPart == "Body") {
            //console.log("break");
            break;
          }

          currSeqArray.push(currSeq);
          currSeqPart.push(currPart);
          newSeqArray.push(newSeq);
          newSeqPart.push(newPart);
        }
        /*console.log(`currSeq--`, currSeqArray);
        console.log(`currPart--`, currSeqPart);

        console.log(`newSeq`, newSeqArray);
        console.log(`newPart`, newSeqPart);*/

        let p = 0;
        // create the curr seq loop and if any image came blank in beetween then break the loop otherwise assign the image for next logic
        for (let k = 0; k < newSeqArray.length; k++) {
          let oldSeq = currSeqArray[k];
          let oldPart = currSeqPart[k];

          let newSeq = newSeqArray[k];
          let newPart = newSeqPart[k];

          let getOldSortSequence = this.$parent.getOldSortSequence;

          let currentIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, oldSeq, oldPart)
          );

          let nextIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, newSeq, newPart)
          );

          //break the loop if any blank image came
          /*if (nextIndexImage == -1) {
            break;
          }*/

          if (currentIndexImage != -1) {
            seqMainArray.push(newSeq);
            seqPart.push(newPart);
            findoldSeq.push(oldSeq);
            findImagePartType.push(oldPart);
            const { thumbnail, mainImage } = this.$parent.images[currentIndexImage];
            const targetArray =
              newPart === "Cover"
                ? this.$parent.CoverImageWithThumb
                : this.$parent.thumbnailArray;
            /*console.log(`targetArray`, targetArray);
            console.log(`newSeq`, newSeq);*/
            targetArray[newSeq] = {
              thumb: thumbnail,
              main: mainImage,
              dummyImage: false,
            };

            this.$parent.previewImageArray[newSeq] = mainImage;
            // this condition required becuase it delete the newly assign value
            if (p < updateDragLength && !seqMainArray.includes(newSeq)) {
              if (oldPart == "Cover") {
                delete this.$parent.CoverImageWithThumb[oldSeq];
              } else {
                delete this.$parent.thumbnailArray[oldSeq];
              }

              p++;
            }
          }

          //break the loop after the assigning image if any blank image came
          if (nextIndexImage == -1) {
            break;
          }
        }
        await this.updateMainImageArray(
          seqMainArray,
          seqPart,
          findImagePartType,
          findoldSeq
        );
      }
      /*Next logic End*/
      // console.log(`image`, this.$parent.images);
      /******* Drop image logic start****************************/
      let onlyOnce = 0;
      let pushDropArray = [];
      //let updateDropIndex = dropArrayIndex;
      for (let d = 0; d < dragImagelength; d++) {
        let getIndex = this.$parent.selectMultipleImage[d];
        let dragSeq = this.$parent.images[getIndex].sortSequence;
        let dragPart = this.$parent.images[getIndex].partType;

        if (dropPart.trim() == null || dropPart.trim() == "") {
          dropPart = dropPartType;
        }

        if (dropSeq > this.$parent.coverPage && dropPart == "Cover") {
          // console.log("in 1 0000000000000");
          dropSeq = 1;
          dropPart = "Body";
          // image drop the last index then next value will be 1st page of cover
          if (dropArrayList[0].sortSequence == this.$parent.coverPage && onlyOnce == 0) {
            dropPart = "Cover";
            onlyOnce = 1;
          }
        }

        /** if drop ==4 & if already push then change to body*/
        if (
          dropSeq == this.$parent.coverPage &&
          dropArrayList[0].sortSequence == this.$parent.coverPage &&
          dropPart == "Cover"
        ) {
          let temp1 = dropSeq;
          let temp2 = dropPart;
          let checkAlreadyPush = pushDropArray.filter(
            (check) => check.sortSequence === temp1 && check.partType === temp2
          );

          if (checkAlreadyPush.length > 0) {
            dropSeq = 1;
            dropPart = "Body";
          }
        }

        //Drop Logic//
        let checkSeq = dropSeq;
        let imgStyle;

        if (this.minatureFlag == true) {
          let removeStyleId;
          if (dragPart == "Cover") {
            removeStyleId = "variantcover_" + allSeq[d];
          } else {
            removeStyleId = "variant_" + allSeq[d];
          }
          imgStyle = document.getElementById(removeStyleId).querySelector("img");
          // Chek that if imgStyle has style tag or not
          if (imgStyle.style.length == 0) {
            imgStyle = document
              .getElementById(this.$parent.selectMultipleImage[d])
              .querySelector("img");
          }
        } else {
          imgStyle = document
            .getElementById(this.$parent.selectMultipleImage[d])
            .querySelector("img");
        }

        if (dropPart == "Cover" && checkSeq > this.$parent.coverPage) {
          await this.$parent.RemovedDataFromMultipleImage();
          break;
        } else {
          if (checkSeq > this.$parent.bodyPage) {
            await this.$parent.RemovedDataFromMultipleImage();
            break;
          }
        }
        /*if (dropSeq > this.$parent.requiredPage) {
          break;
        }*/

        if (dragSeq == dropSeq && dragPart == dropPart) {
          dropSeq = dropSeq + 1;
          continue;
        }

        // OLD SEQ REMOVE
        let currentIndexImage = this.$parent.images.findIndex(
          this.$parent.getOldSortSequence(this.$parent.images, dropSeq, dropPart)
        );

        if (currentIndexImage != -1) {
          this.$parent.images[currentIndexImage]["sortSequence"] = null;
          this.$parent.images[currentIndexImage]["partType"] = "";
          this.$parent.images[currentIndexImage]["oldPart"] = dragPart;
          this.$parent.images[currentIndexImage]["oldSeq"] = dragSeq;
          this.payload.push(this.$parent.images[currentIndexImage]);
          pushDropArray.push({
            sortSequence: null,
            partType: "",
          });
        }

        //NEW SEQ ASSIGN

        this.$parent.images[getIndex]["sortSequence"] = dropSeq;
        this.$parent.images[getIndex]["partType"] = dropPart;
        this.$parent.images[getIndex]["variantId"] =
          dropPart == "Cover" ? this.$parent.coverVariant : this.$parent.bodyVariant;
        this.$parent.images[getIndex]["oldPart"] = dragPart;
        this.$parent.images[getIndex]["oldSeq"] = dragSeq;

        this.payload.push(this.$parent.images[getIndex]);
        pushDropArray.push({
          sortSequence: dropSeq,
          partType: dropPart,
        });
        // CHECK OLD IMAGE HAS ALREAY ASSIGNE tHEN DELETE FROM REQUIRED PLACES
        if (dragSeq != null) {
          const movingToCover = dropPart === "Cover";
          const movingFromCover = dragPart === "Cover";
          // Handle Cover-related operations
          if (movingToCover) {
            if (dragPart == "Cover") {
              this.$parent.CoverImageWithThumb[
                dropSeq
              ] = this.$parent.CoverImageWithThumb[dragSeq];
            } else {
              this.$parent.CoverImageWithThumb[dropSeq] = this.$parent.thumbnailArray[
                dragSeq
              ];

              let checkPartTypeChanged =
                seqMainArray.includes(dragSeq) &&
                seqPart[seqMainArray.indexOf(dragSeq)] !== dragPart;

              if (!seqMainArray.includes(dragSeq) || checkPartTypeChanged) {
                delete this.$parent.thumbnailArray[dragSeq];
              }
            }

            const isPartTypeChanged =
              seqMainArray.includes(dragSeq) &&
              seqPart[seqMainArray.indexOf(dragSeq)] !== dragPart;

            if (!seqMainArray.includes(dragSeq) || isPartTypeChanged) {
              if (dragPart == "Cover") {
                delete this.$parent.CoverImageWithThumb[dragSeq];
              }
            }
          }

          // Handle Body-related operations
          if (!movingToCover) {
            //console.log(`~---movingToCover`, movingToCover, dragSeq);
            this.$parent.thumbnailArray[dropSeq] = movingFromCover
              ? this.$parent.CoverImageWithThumb[dragSeq]
              : this.$parent.thumbnailArray[dragSeq];
            if (movingFromCover) {
              delete this.$parent.CoverImageWithThumb[dragSeq];
            } else {
              delete this.$parent.thumbnailArray[dragSeq];
            }

            const { main } = this.$parent.thumbnailArray[dropSeq]; // Destructuring
            this.$parent.previewImageArray[dropSeq] = main;
          }
        } else {
          const { mainImage, thumbnail } = this.$parent.images[getIndex];
          const targetArray =
            dropPart === "Cover"
              ? this.$parent.CoverImageWithThumb
              : this.$parent.thumbnailArray;
          targetArray[dropSeq] = {
            main: mainImage,
            thumb: thumbnail,
            dummyImage: false,
          };

          this.$parent.previewImageArray[dropSeq] = targetArray[dropSeq]["main"];
        }

        dropSeq = dropSeq + 1;

        //Removed the border
        imgStyle.removeAttribute("style");
      }

      //console.log(`image`, this.$parent.images);
      /************************* Drop image logic end*******************/

      this.highlightArray = [];
      await this.$parent.SortImageorder();
      //console.log(`passDataBetweenTab`);
      this.$parent.selectMultipleImage = [];
      this.payload = await this.removeDuplicates(this.payload);
      await this.$parent.passDataBetweenTab();
      //console.log(`this.payload`, this.payload);
      this.$parent.showSaveBtnInMinature = true;
      await this.$parent.dataCheckMessage();
      //await this.findDiffrence(this.$parent.images);
      /*let orderHeader = {
        orderId: this.$parent.OrderId,
      };

      const result1 = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.$parent.OrderId}&plausibilityCheck=true&jobLock=true`,
        this.payload,
        orderHeader
      );
      await this.$parent.CheckValidation(result1.data.plausibilityCheck, 2);*/
      //this.payload = [];
      loader.hide();
    },
    updateMainImageArray(seqMainArray, seqPart, oldPartList, oldSeqList) {
      console.log(`oldSeqList`, oldSeqList);
      for (let l = 0; l < seqMainArray.length; l++) {
        let p = seqMainArray[l];
        //console.log(`p`, p);
        //Found the image name
        let imageName;
        let findImagePartType = oldPartList[l];
        let assignPartType = seqPart[l];

        if (this.$parent.thumbnailArray[p] && assignPartType == "Body") {
          imageName = this.$parent.thumbnailArray[p]["thumb"];
        }

        if (this.$parent.CoverImageWithThumb[p] && assignPartType == "Cover") {
          imageName = this.$parent.CoverImageWithThumb[p]["thumb"];
        }

        // console.log(`imageName`, imageName);
        if (imageName != null) {
          //console.log(`this.$parent.images`, this.$parent.images);
          let getImageIndex = this.$parent.images.findIndex(
            this.$parent.getindexbasedonimage(
              this.$parent.images,
              imageName,
              findImagePartType
            )
          );
          //console.log(`getImageIndex`, getImageIndex, this.$parent.images[getImageIndex]);
          if (getImageIndex != -1) {
            let previousSortSequenceIndex = this.$parent.images.findIndex(
              this.$parent.getOldSortSequence(this.$parent.images, p, assignPartType)
            );
            // console.log(`previousSortSequence`, previousSortSequenceIndex);
            if (previousSortSequenceIndex != -1) {
              //   console.log(`previousSortSequenceIndex`, p);
              this.$parent.images[previousSortSequenceIndex]["sortSequence"] = null;
              this.$parent.images[previousSortSequenceIndex][
                "oldPart"
              ] = findImagePartType;
              this.$parent.images[previousSortSequenceIndex]["oldSeq"] = p;
              //this.$parent.images[previousSortSequenceIndex]["partType"] = "";
              this.payload.push(this.$parent.images[previousSortSequenceIndex]);
            }

            this.$parent.images[getImageIndex]["sortSequence"] = p;
            this.$parent.images[getImageIndex]["partType"] = assignPartType;
            this.$parent.images[getImageIndex]["variantId"] =
              assignPartType == "Cover"
                ? this.$parent.coverVariant
                : this.$parent.bodyVariant;
            //console.log(`after`, this.$parent.images[getImageIndex]["sortSequence"]);
            this.payload.push(this.$parent.images[getImageIndex]);
          }
        }
      }

      //console.log(`next pyload`, this.payload);
    },
    removeDuplicates(array) {
      const uniqueArray = array.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.singlePagesID === item.singlePagesID)
      );
      return uniqueArray;
    },
    async saveDropData() {
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      //Hide the save btn
      this.$parent.showSaveBtnInMinature = false;
      const propertyToCompare = "singlePagesID";
      const difference = await this.findArrayDifference(
        JSON.parse(localStorage.getItem(`loadImageArray_${btoa(this.$parent.OrderId)}`)),
        this.$parent.images,
        propertyToCompare
      );

      let orderHeader = {
        orderId: this.$parent.OrderId,
      };

      const result1 = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.$parent.OrderId}&plausibilityCheck=true&jobLock=true`,
        difference,
        orderHeader
      );

      await this.$parent.CheckValidation(result1.data.plausibilityCheck, 2);

      let saveFlag = 1;
      if (localStorage.getItem(`saveFlag_${btoa(this.$parent.OrderId)}`)) {
        saveFlag =
          parseInt(localStorage.getItem(`saveFlag_${btoa(this.$parent.OrderId)}`)) + 1;
      }
      localStorage.setItem(`saveFlag_${btoa(this.$parent.OrderId)}`, saveFlag);

      loader.hide();
    },
    findArrayDifference(array1, array2, property) {
      const map = new Map(array1.map((obj) => [obj[property], obj]));

      // Filter the second array to get only items not present in the first array
      const differenceArray = array2.filter((obj) => {
        const key = obj[property];
        const existingObj = map.get(key);

        return !existingObj || !(JSON.stringify(existingObj) === JSON.stringify(obj));
      });

      return differenceArray;
    },
  },
};
</script>
<style scoped>
.highlightDiv {
  border: 3px solid #6533dd !important;
}
.mouseDrag {
  border: 3px solid red;
}

.submit-btn {
  overflow: visible;
}

::-moz-selection {
  background: transparent;
}

::selection {
  background: transparent;
}
.draggable img,
* {
  user-select: none; /* Prevent image selection */
}

#container1 {
  position: relative;
}
#selection-box {
  position: absolute;
  border: 2px dashed white;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  z-index: 1000;
  box-sizing: border-box;
}
.imagePreviewWrapper {
  z-index: 9999 !important; /* Set a lower z-index than the rectPreviewContainer */
}
#container1 {
  scroll-behavior: smooth;
  scroll-behavior: 40s;
}
#tmpdivid {
  overflow-y: scroll !important;
}
</style>
