<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div class="px-0 pt-4 pb-4 container custom-container position-relative">
        <div class="text-h4 text-white">{{ $t("order-list.order-title") }}</div>
        <router-link
          :to="{
            path: '/template/templateGrid',
          }"
          class="box"
        >
          <vmd-button
            class="shadow-none rounded-pill font-weight-normal btn-sm"
            color="primary"
            >{{ $t("order-list.new-order-btn") }}</vmd-button
          >
        </router-link>

        <div class="position-absolute inner_toaster">
          <vmd-snackbar
            v-if="deleteOrder === 'success'"
            :description="deleteOrderMessage"
            :icon="{ color: 'white' }"
            :color="deleteOrder"
            @click="orderSnackbar(0)"
          />

          <vmd-snackbar
            v-if="mailMessage === 'successmail'"
            :description="sendMailMessage"
            :icon="{ color: 'white' }"
            color="success"
            @click="orderSnackbar(1)"
          />
        </div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-2 pb-6 px-0 container custom-container">
        <div class="row" style="margin: auto">
          <div class="col-12 px-0 d-flex top-page-section align-items-start">
            <!--<div class="d-sm-flex justify-between pt-1 btn-custom-group px-0 flex-wrap">
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal me-2 btn-sm btn-active"
                color="outline-grey"
                >{{ $t("order-list.open-minded-btn") }}</vmd-button
              ><vmd-button
                class="shadow-none rounded-pill font-weight-normal me-2 btn-sm"
                color="outline-grey"
                >{{ $t("order-list.in-progress-btn") }}</vmd-button
              >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="outline-grey"
                >{{ $t("order-list.closed-btn") }}</vmd-button
              >
            </div>-->

            <div class="order-search-box d-flex align-items-start mb-4">
              <!-- Filter box of status,milestone and search box -->
              <Searchbox></Searchbox>
            </div>
            <div
              class="right-page-section list-page-right-section ms-auto d-flex align-items-center"
            >
              <vmd-checkbox id="customRadio1" name="flexRadioDefault" class="d-none" />
              <i
                class="fas fa-th opacity-6"
                @click="gridData()"
                @testing-event="gridData()"
              ></i>
            </div>
          </div>

          <div class="row grid-box-container">
            <div class="table-responsive custom-list-card">
              <vue-good-table
                ref="mygoodtable"
                mode="remote"
                theme=""
                style-class="table table-flush order-listing-custom"
                :total-rows="totalRecords"
                :columns="columns"
                :rows="rows"
                :fixed-header="true"
                :search-options="{
                  externalQuery: searchTerm,
                }"
                :pagination-options="{
                  enabled: true,
                  position: 'top',
                  rowsPerPageLabel: '',
                  infoFn: (params) =>
                    ` ${$t('order-grid.datatable-info1')}  ${serverParams.page}  ${$t(
                      'order-grid.datatable-info2'
                    )} ${totalPages}`,
                  pageLabel: '', // for 'pages' mode
                  nextLabel: '',
                  prevLabel: '',
                }"
                :sort-options="{
                  enabled: true,
                  defaultSort: false,
                }"
                @page-change="onPageChange"
                @column-filter="onColumnFilter"
                @per-page-change="onPerPageChange"
                @sort-change="onSortChange"
                @search="searchTbl"
              >
                <template #table-column="props">
                  <span v-if="props.column.label == 'actionbtn'"> </span>
                  <span v-else-if="props.column.field === 'format'">
                    {{ props.column.label }} - ({{ dimensionFormat }})
                  </span>
                  <span v-else> {{ props.column.label }} </span>
                </template>

                <template #table-row="props" ref="currentRow">
                  <div
                    v-if="props.column.field == 'status'"
                    class="d-flex align-items-center justify-content-center order-status"
                  >
                    <i
                      class="fas fa-circle"
                      :title="props.row.status.toUpperCase()"
                      :class="{
                        'text-danger': ['error', 'internalError'].includes(
                          props.row.status
                        ),
                        'text-success': props.row.status === 'done',
                        'text-warning': [
                          'waitForFeedback',
                          'warning',
                          'processing',
                          'created',
                        ].includes(props.row.status),
                      }"
                    ></i>
                  </div>

                  <div
                    v-else-if="props.column.field == 'name'"
                    class="font-weight-bold avatar-box"
                    :title="props.row.name"
                  >
                    <router-link
                      :to="{
                        path: checkPath(
                          encryptOrderId(props.row.id),
                          props.row.thumbnail
                        ),
                      }"
                      class="nav-link d-inline p-0 link-white"
                    >
                      <div v-if="props.row.thumbnail != null" class="avatar">
                        <img :src="externalImage + props.row.thumbnail" alt="" />
                      </div>
                      <div v-else class="avatar">
                        <img :ref="noImage" :src="noImage" alt="" class="dummy-image" />
                      </div>
                      <div class="user-table-details">
                        <h5 data-bs-toggle="tooltip" data-bs-placement="top">
                          {{ props.row.name }}
                        </h5>
                        <span class="d-block"
                          >Created on
                          {{ updatedateformat(props.row.createdAt) }}
                        </span>
                      </div>
                    </router-link>
                  </div>
                  <div v-else-if="props.column.field == 'description'">
                    <p>{{ props.row.description }}</p>
                  </div>
                  <span v-else-if="props.column.label == 'actionbtn'" class="action-btns">
                    <!--<a href="javascript:void(0)" class="fav-icon link-white">
                      <i class="fas fa-star"></i>
                    </a>-->
                    <span class="innerButton">
                      <!--Order Detail-->
                      <a
                        href="javascript:void(0)"
                        class="link-white"
                        data-toggle="modal"
                        data-target="#orderModal"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-original-title="Order Info"
                        @click="showOrder(props.row.id, props.row)"
                        ><i class="fas fa-info-square"></i>
                      </a>
                    </span>
                    <span class="innerButton">
                      <!-- lock order -->
                      <a
                        href="javascript:void(0)"
                        :class="
                          props.row.jobStatus && props.row.jobStatus.isJobLock == 1
                            ? ''
                            : 'd-none'
                        "
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-original-title="Lock Order"
                        @click="unlock"
                      >
                        <i
                          class="fa fa-lock"
                          :data-id="props.row.id"
                          :title="
                            props.row.jobStatus != null
                              ? props.row.jobStatus.userName
                              : ''
                          "
                          aria-hidden="true"
                        ></i>
                      </a>
                    </span>

                    <span class="innerButton">
                      <!--Send link-->

                      <a
                        v-if="!['released', 'finished'].includes(props.row.milestone)"
                        href="javascript:void(0)"
                        class="link-white"
                        data-toggle="modal"
                        data-target="#new-board-modal"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-original-title="Send Link"
                        @click="
                          storeOrdeId(
                            props.row.id,
                            props.row.thumbnail,
                            props.row.name,
                            props.row.products[0].name,
                            props.row.products[0].type
                          )
                        "
                        ><i class="fas fa-envelope"></i>
                      </a>
                    </span>

                    <!--Download PDF-->

                    <span class="dropdown dropstart">
                      <a
                        v-if="['released', 'finished'].includes(props.row.milestone)"
                        id="dropdownMenuButton2"
                        href="javascript:void(0)"
                        class="table-menu-icon link-white"
                        :data-bs-toggle="
                          props.row.products[0].parts.length > 1 ? 'dropdown' : ''
                        "
                        aria-expanded="false"
                        @click="
                          props.row.products[0].parts.length > 1
                            ? ''
                            : downloadFile(
                                props.row.products[0].parts[0].pageranges[0].variants[0]
                                  .id,
                                props.row.name
                              )
                        "
                        ><i class="fa fa-download"></i
                      ></a>

                      <ul
                        v-if="props.row.products[0].parts.length > 0"
                        class="px-2 py-3 dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton2"
                        data-bs-popper="none"
                      >
                        <li
                          v-for="(partsloop, i) in props.row.products[0].parts"
                          :key="i"
                        >
                          <a
                            href="javascript:void(0)"
                            class="dropdown-item border-radius-md"
                            @click="
                              downloadFile(
                                partsloop.pageranges[0].variants[0].id,
                                props.row.name,
                                partsloop.type
                              )
                            "
                          >
                            {{ partsloop.type }}
                          </a>
                        </li>
                      </ul>
                    </span>

                    <span class="dropdown dropstart">
                      <a
                        id="dropdownMenuButton1"
                        href="javascript:void(0)"
                        class="table-menu-icon link-white"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="fas fa-ellipsis-h"></i
                      ></a>
                      <ul
                        class="px-2 py-3 dropdown-menu dropdown-menu-end"
                        aria-labelledby="dropdownMenuButton1"
                        data-bs-popper="none"
                      >
                        <li
                          v-if="!['released', 'finished'].includes(props.row.milestone)"
                          class="mb-2"
                        >
                          <router-link
                            :to="{ path: '/Uploader/' + encryptOrderId(props.row.id) }"
                            class="dropdown-item border-radius-md"
                          >
                            {{ $t("order-list.uploader-link") }}
                          </router-link>
                        </li>
                        <li v-if="props.row.thumbnail != null" class="mb-2">
                          <router-link
                            :to="{
                              path: '/releaseprintdata/' + encryptOrderId(props.row.id),
                            }"
                            class="dropdown-item border-radius-md"
                          >
                            {{ $t("order-list.preview-link") }}
                          </router-link>
                        </li>
                        <li @click="confirmDeleteOrder(props.row.id)">
                          <a
                            href="javascript:void(0)"
                            class="dropdown-item border-radius-md"
                            >{{ $t("order-list.delete-link") }}</a
                          >
                        </li>
                      </ul>
                    </span>
                  </span>

                  <span
                    v-else-if="props.column.field == 'id' && props.column.label == 'id'"
                  >
                    <a
                      href="javascript:void(0)"
                      class="link-white"
                      data-toggle="modal"
                      data-target="#orderModal"
                      @click="showOrder(props.row.id, props.row)"
                      >{{ props.row.id }}</a
                    >
                  </span>
                  <span v-else-if="props.column.field == 'updatedAt'">
                    {{ updatedateformat(props.row.updatedAt) }}
                  </span>
                  <!--<span v-else-if="props.column.field == 'keycloakUserId'">
                    <a
                      v-if="['released', 'finished'].includes(props.row.milestone)"
                      @click="
                        downloadFile(
                          props.row.products[0].parts[0].pageranges[0].variants[0]['id']
                        )
                      "
                    >
                      <i
                        class="fa fa-download"
                        href="javascript:void(0)"
                        download
                        aria-hidden="true"
                      ></i>
                      55555
                    </a>
                  </span>-->
                  <span
                    v-else-if="
                      props.column.field == 'id' && props.column.label == 'sendlink'
                    "
                  >
                    <a
                      v-if="!['released', 'finished'].includes(props.row.milestone)"
                      href="javascript:void(0)"
                      class="link-white"
                      data-toggle="modal"
                      data-target="#new-board-modal"
                      @click="
                        storeOrdeId(
                          props.row.id,
                          props.row.thumbnail,
                          props.row.name,
                          props.row.products[0].name,
                          props.row.products[0].type
                        )
                      "
                      ><i class="fas fa-envelope"></i>
                    </a>
                  </span>
                  <span v-else-if="props.column.field == 'orderDetail'">
                    <a
                      href="javascript:void(0)"
                      class="link-white"
                      data-toggle="modal"
                      data-target="#orderModal"
                      @click="showOrder(props.row.id, props.row)"
                      >{{ $t("order-common.table-orderdetail-row") }}
                    </a>
                  </span>

                  <span v-else-if="props.column.field == 'jobStatus'">
                    <a
                      href="javascript:void(0)"
                      :class="
                        props.row.jobStatus && props.row.jobStatus.isJobLock == 1
                          ? ''
                          : 'd-none'
                      "
                      @click="unlock"
                    >
                      <i
                        class="fa fa-lock"
                        :data-id="props.row.id"
                        :title="
                          props.row.jobStatus != null ? props.row.jobStatus.userName : ''
                        "
                        aria-hidden="true"
                      ></i>
                    </a>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Send link popup-->
    <SendLink></SendLink>

    <!-- Order Modal popup-->
    <orderDetail></orderDetail>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import VmdCheckbox from "@/components/VmdCheckbox.vue";
import VmdSnackbar from "@/components/VmdSnackbar.vue";
import Swal from "sweetalert2";
import "vue-good-table-next/dist/vue-good-table-next.css";
import axiosInterceptor from "@/shared/axiosInterceptor";
import i18n from "@/i18n";
import { markRaw } from "vue";
import * as Yup from "yup";
import moment from "moment";
import setTooltip from "@/assets/js/tooltip.js";
import store from "@/store";
import updateDimension from "../../Mixins/updateDimension";
import commonOrder from "../../Mixins/order";
import format from "../../Mixins/format";

import Choices from "choices.js";
import { faLastfmSquare } from "@fortawesome/free-brands-svg-icons";
import orderDetail from "../orderscommon/orderDetail.vue";
import SendLink from "../orderscommon/SendLink.vue";
import Searchbox from "../orderscommon/Searchbox.vue";

export default {
  name: "OrderList",
  components: {
    VmdButton,
    VmdCheckbox,
    VmdSnackbar,
    orderDetail,
    SendLink,
    Searchbox,
  },
  mixins: [updateDimension, commonOrder, format],
  inject: ["eventBus"],
  data() {
    return {
      showOrderId: 0,
      OrderInfo: [],
      checkInit: {
        isDataValid: false,
        sendme: false,
      },
      value: [],
      milestoneValue: [],
      taggingStatus: [],
      taggingMilestone: [],
      single: null,
      choicesd: null,
      choicesformat: null,
      choiceslang: null,
      modalInfo: [],
      modalOrderId: 0,
      modelOrderName: null,
      modelOrderImage: null,
      modelProductName: null,
      modelProductType: null,
      isLoading: false,
      totalPages: 0,
      rows: [],
      totalRecords: 0,
      setTimer: null,
      deleteOrder: null,
      mailMessage: null,
      search: null,
      initSearch: null,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        perPage: 10,
        firstRecordOnPage: 0,
        totalNoPages: 0,
        default_mm_value: null,
        default_inch_value: null,
        externalImage: null,
        noImage: null,
        snackbarColor: null,
      },
      locallang: "English",
      localFormat: "Point",
    };
  },
  computed: {
    getUploadProcess() {
      return this.$store.getters["notifications/getListstate"];
    },
    encryptOrderId: function () {
      return (id) => this.OrderIdforURL(id);
    },
    deleteOrderMessage() {
      return i18n.global.t("order-common.delete-order-success-message");
    },
    sendMailMessage() {
      return i18n.global.t("order-common.order-popup-success-message");
    },
    dimensionFormat() {
      let currentDimension = store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    searchschema() {
      return markRaw(
        Yup.object().shape({
          search: Yup.string(),
        })
      );
    },
    initialvaluesearch() {
      var checkInit = {
        search: "",
        //isDataValid: false,
      };
      // console.log("initial", checkInit);
      return checkInit;
    },
    columns() {
      return [
        {
          label: i18n.global.t("order-list.table-status-column"),
          field: "status",
          sortable: true,
          thClass: "status",
        },
        {
          label: i18n.global.t("order-list.table-milestone-column"),
          field: "milestone",
          sortable: true,
          thClass: "milestone",
        },
        {
          label: "id",
          field: "id",
          sortable: false,
          columnIndex: 0,
        },
        {
          label: i18n.global.t("order-list.table-name-column"),
          field: "name",
          sortable: true,
          tdClass: "font-weight-bold avatar-box",
          thClass: "name",
        },
        {
          label: i18n.global.t("order-list.table-description"),
          field: "description",
          sortable: false,
          tdClass: "descriptionTd",
        },
        {
          label: "actionbtn",
          field: "id",
          sortable: false,
          tdClass: "action-btns",
        },
      ];
    },
  },
  mounted() {
    setTooltip(this.$store.state.bootstrap);
    this.default_mm_value = process.env.VUE_APP_MM_VALUE;
    this.default_inch_value = process.env.VUE_APP_INCH_VALUE;
    this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
    this.noImage = this.$noImagePreview;
    this.choicesd = new Choices(document.getElementById("expired"));

    this.choicesformat = new Choices(document.getElementById("orderformat"));
    this.choiceslang = new Choices(document.getElementById("orderlang"));

    this.autoRefreshToken(1);
    this.loadItems();
    this.infoFn();
    this.listingNot;
    let tableHead = document.getElementsByTagName("thead")[0];
    tableHead.id = "theadId";
    setTimeout(() => {
      window.onscroll = function () {
        myFunction();
      };
      var sticky = "300";

      function myFunction() {
        if (window.pageYOffset > sticky) {
          tableHead.classList.add("sticky");
        } else {
          tableHead.classList.remove("sticky");
        }
      }
      window.addEventListener("click", () => {
        const allTooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        allTooltips.forEach((e) => {
          const tooltip = this.$store.state.bootstrap.Tooltip.getOrCreateInstance(e);
          tooltip.hide();
        });
      });
    }, 1000);

    this.eventBus.on("loadlisting", (a) => {
      console.log(a);
      this.loadItems();
    });
  },
  beforeUnmount() {
    this.eventBus.off("loadlisting");
    clearInterval(this.setTimer);
  },

  created() {
    document.addEventListener("click", function (e) {
      var orderModal = document.getElementById("orderModal");
      if (orderModal) {
        //console.log("orderModal Clicked", e.target.parentElement.parentElement.classList);
        if (
          orderModal.style.display == "block" &&
          e.target.parentElement.parentElement.classList[0] != "modal-dialog" &&
          e.target.parentElement.parentElement.classList[0] != "modal-body" &&
          e.target.parentElement.parentElement.classList[0] != "modal-content" &&
          e.target.parentElement.parentElement.classList[0] != "orderPreviewDetails" &&
          e.target.parentElement.parentElement.classList[0] != "innerDetailContent" &&
          e.target.parentElement.parentElement.classList[0] != "orderDetailTitle" &&
          e.target.parentElement.parentElement.classList[0] != "orderDetailContent" &&
          e.target.parentElement.parentElement.classList[0] != "ps" &&
          e.target.parentElement.parentElement.classList[0] != "order-details" &&
          e.target.parentElement.parentElement.classList[0] != "order-content"
        ) {
          const parentTR = document.querySelectorAll(".trSelected");
          parentTR.forEach((element) => {
            element.classList.remove("trSelected");
          });
        }
      }
    });
  },

  methods: {
    async StoreDownloadInfo(id, value) {
      this.showOrderId = id;
      this.OrderInfo = value;
    },
    async showOrder(id, value) {
      this.showOrderId = id;
      this.OrderInfo = value;
      var parentTR =
        event.target.parentElement.parentElement.parentElement.parentElement
          .parentElement;
      parentTR.classList.add("trSelected");
      setTimeout(() => {
        let checkLoaderExist = document.getElementsByClassName("modal-backdrop");
        if (checkLoaderExist.length > 0) {
          for (var i = 0; i < checkLoaderExist.length; i++) {
            //console.log(checkLoaderExist[i]);
            checkLoaderExist[i].classList.remove("show");
          }
        }
      }, 0.1);
    },
    updateBCC() {
      var ele = document.getElementById("sendme");
      this.showBCC = ele.checked;
    },
    unlock(element) {
      let orderId = element.target.dataset.id;
      Swal.fire({
        title: i18n.global.t("order-list.unlock-order-confirm-title"),
        text: i18n.global.t("order-list.unlock-order-confirm-text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.global.t("order-list.unlock-order-confirm-buttontext"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.unlockOrder(orderId, true);
          // Set Timeout because milenstone has been updated properly from the backend but status take some time
          let t = this;
          setTimeout(async function () {
            await t.loadItems();
          }, 2000);

          Swal.fire(
            "",
            i18n.global.t("order-list.unlock-order-success-message"),
            "success"
          );
        }
      });
    },
    getChoices(value) {
      var element = document.getElementById(value);
      var initElement = new Choices(element, {
        searchEnabled: faLastfmSquare,
        removeItemButton: true,
      });
      return initElement;
    },
    async storeOrdeId(value, image, orderName, productname, producttype) {
      document.getElementById("frm").reset();
      //this.getChoices("expired");

      this.locallang = localStorage.getItem("applang");
      this.localFormat = localStorage.getItem("format");

      document.getElementById("sendme").checked = false;
      this.showBCC = false;

      this.choicesd.setChoiceByValue("d");

      // For Format
      this.choicesformat.setChoiceByValue(this.localFormat);

      // For language
      this.choiceslang.setChoiceByValue(this.locallang);

      if (image != null) {
        image = this.externalImage + image;
      } else {
        image = this.noImage;
      }
      this.modelOrderName = orderName;
      this.modelOrderImage = image;
      this.modelProductName = productname;
      this.modelProductType = producttype;
      this.modalOrderId = value;
    },
    reset() {
      //this.choicesd.destroy();
      document.getElementById("frm").reset();
    },
    onInvalidSubmit() {},
    infoFn() {
      this.serverParams.firstRecordOnPage = this.serverParams.currentPage;
      this.serverParams.totalNoPages = this.totalPages;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      var sortvalue = "&sort=id,DESC";
      if (this.serverParams.sort.length > 0) {
        if (this.serverParams.sort[0].type != "none") {
          this.serverParams.sort[0].type = this.serverParams.sort[0].type.toUpperCase();
          sortvalue = `&sort=${this.serverParams.sort[0].field},${this.serverParams.sort[0].type}`;
        }
      }
      let searchvalue = "";
      if (this.search != "" && this.search != undefined) {
        searchvalue += `&filter=name||$contL||${this.search}`;

        if (localStorage.getItem("status")) {
          let status = JSON.parse(localStorage.getItem("status"));
          let statusSearchResult = status.filter(
            (item) => item.toLowerCase().indexOf(this.search.trim().toLowerCase()) > -1
          );
          if (statusSearchResult.length > 0) {
            searchvalue = `&filter=status||$eqL||${statusSearchResult.toString()}`;
          }
        }

        if (localStorage.getItem("milestones")) {
          let milestone = JSON.parse(localStorage.getItem("milestones"));
          let milestoneSearchResult = milestone.filter(
            (item) => item.toLowerCase().indexOf(this.search.trim().toLowerCase()) > -1
          );
          if (milestoneSearchResult.length > 0) {
            searchvalue = `&filter=milestone||$in||${milestoneSearchResult.toString()}`;
          }
        }
      }

      // this.value refers to status filter
      if (this.taggingStatus.length > 0) {
        searchvalue += `&filter=status||$in||${this.taggingStatus.toString()}`;
      }

      // this.value refers to milestone filter
      if (this.taggingMilestone.length > 0) {
        searchvalue += `&filter=milestone||$in||${this.taggingMilestone.toString()}`;
      }

      let joinForResources = ``;
      //console.log(`this.serverParams.page`, this.serverParams.page);
      const result = await axiosInterceptor.get(
        `api/v1/orders?page=${this.serverParams.page}&limit=${this.serverParams.perPage}${sortvalue}&join=products&join=products.parts&join=products.parts.pageranges&join=jobStatus&join=jobStatus.orderId&join=products.parts.pageranges.variants&${joinForResources}&${searchvalue}&getMilestones=true&getStatus=true&filter=isTemplate||$eq||false`
      );

      if (result.status == 200) {
        if (typeof result.data.data[0] !== "undefined") {
          localStorage.setItem(
            "milestones",
            JSON.stringify(Object.values(result.data.allMilestones))
          );
        }

        if (typeof result.data.data[0] !== "undefined") {
          localStorage.setItem(
            "status",
            JSON.stringify(Object.values(result.data.allStatus))
          );
        }

        this.rows = result.data.data;
        this.totalPages = result.data.pageCount;
        this.totalRecords = result.data.total;
      } else {
        console.log(result);
      }
      loader.hide();
      setTimeout(() => {
        setTooltip(this.$store.state.bootstrap);
      }, 1000);
    },
    gridData() {
      this.$router.push("/orders/grid");
    },
    listData() {
      this.$router.push("/orders/list");
    },
    updatedateformat(value) {
      return moment(value).format(process.env.VUE_APP_DATE_FORMAT);
    },
  },
};
</script>
