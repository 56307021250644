// console.log(document.querySelector("body").classList);
// alert(document.querySelector(".modal").classList);


// document.addEventListener("click", myFunction);

// function myFunction() {
// }

const interval = setInterval(function () {
    // method to be executed;
    var ModalClasses = document.querySelector(".modal").classList;
    // alert(ModalClasses.contains("show"));
    if (ModalClasses.contains("show")) {
        console.log("hi");
        // document.querySelector(".modal-backdrop").classList.add("show");
    } else {
        document.querySelector(".modal-backdrop").classList.remove("show");
    }
}, 500);

clearInterval(interval);