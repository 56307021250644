<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div
        class="px-0 pt-4 pb-4 container custom-container custom-page-title align-items-center position-relative"
      >
        <div class="title-box-new">
          <div class="text-h4 text-white">{{ $t("template-grid.my-template-list") }}</div>
          <div class="button-box d-flex gap-1">
            <router-link
              :to="{
                path: '/orders/list',
              }"
              class="box"
            >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="primary"
                >{{ $t("template-grid.order-listing") }}</vmd-button
              >
            </router-link>

            <router-link
              :to="{
                path: '/template/createTemplate',
              }"
              class="box"
            >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="primary"
                >{{ $t("template-grid.create-template") }}</vmd-button
              >
            </router-link>
          </div>
        </div>

        <div class="position-absolute inner_toaster"></div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-2 pb-6 px-0 container custom-container">
        <div class="row" style="margin: auto">
          <div class="col-12 px-0 d-flex top-page-section align-items-start">
            <div
              class="right-page-section grid-page-right-section ms-auto d-flex align-items-center"
            >
              <select v-model="recordsPerPage" @change="onChangeRecordsPerPage">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="40">40</option>
                <option :value="50">50</option>
              </select>
              <span class="page-info opacity-6">
                <span :current-page="page" :total-pages="totalPages"
                  >{{ $t("template-grid.datatable-info1") }} {{ page }}
                  {{ $t("template-grid.datatable-info2") }} {{ totalPages }}</span
                >
              </span>
              <Pagination
                v-if="listItems"
                :total-pages="totalPages"
                :per-page="recordsPerPage"
                :current-page="page"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
          <div class="order-search-box d-flex align-items-end mt-4 mb-2">
            <Form
              id="customSerachForm"
              :validation-schema="schema"
              @submit.prevent="getSearch($event)"
            >
              <vmd-input
                id="search"
                type="text"
                :placeholder="$t('template-grid.grid-serach-placeholder')"
                name="search"
                @keyup.enter="getSearch($event)"
                @keyup="keyupSearch($event)"
              />
              <span
                v-if="search != null && search != ''"
                class="clear-search"
                @click="clearSearch()"
              >
                <i class="fal fa-times"></i>
              </span>
            </Form>
          </div>

          <div class="row grid-box-container">
            <div v-if="totalRecords == 0" class="nodata text-center mt-4">
              <h3 style="font-family: 'Roboto'">
                {{ $t("template-grid.error-no-data") }}
              </h3>
            </div>
            <div v-for="item in listItems" :key="item.id" class="col-20per col">
              <div class="card-shadow custom-grid-card rounded-0 h-100">
                <div class="grid-box-image">
                  <div>
                    <img
                      :src="getTemplateImage(item.resources)"
                      alt=""
                      class="dummy-image"
                    />
                  </div>
                  <span class="dropdown dropstart">
                    <a
                      id="dropdownMenuButton1"
                      href="javascript:void(0)"
                      class="table-menu-icon link-white"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fas fa-ellipsis-h"></i
                    ></a>
                    <ul
                      class="px-2 py-3 dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                      data-bs-popper="none"
                    >
                      <li>
                        <router-link
                          :to="{
                            path:
                              '/template/templateImage/' + getResourceId(item.resources),
                          }"
                          class="dropdown-item border-radius-md"
                        >
                          {{ $t("template-grid.template-image") }}
                        </router-link>
                      </li>
                    </ul>
                  </span>
                </div>
                <div class="grid-box-title pb-0">
                  <router-link
                    :to="{ path: '/template/NewOrder/' + encryptOrderId(item.id) }"
                    class="nav-link d-inline p-0 link-white"
                  >
                    <h5 class="text-h5 text-white font-weight-medium">
                      {{ item.name }}
                    </h5>
                  </router-link>
                </div>
                <div class="row m-0">
                  <div class="row grid-details m-0">
                    <div class="col-12 pt-0 descriptionBox">
                      <p class="mb-0">
                        {{ item.description }}
                      </p>
                    </div>

                    <div class="col-12">
                      <span
                        class="text-white opacity-7 d-flex justify-content-between align-content-center flex-wrap"
                        >Created on {{ updatedateformat(item.createdAt) }}
                      </span>
                    </div>

                    <div class="col-12">
                      <div class="iconBoxInner">
                        <!--Order Detail-->
                        <a
                          href="javascript:void(0)"
                          class="link-white"
                          data-toggle="modal"
                          data-target="#orderModal"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-original-title="Order Info"
                          @click="showOrder(item.id, item)"
                          ><i class="fas fa-info-square"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Order Modal popup-->
            <orderDetail />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import VmdInput from "@/components/VmdInput.vue";

import Pagination from "./Pagination";
import axiosInterceptor from "@/shared/axiosInterceptor";
import { Form } from "vee-validate";
import { markRaw } from "vue";
import * as Yup from "yup";
import store from "@/store";
import commonOrder from "../../Mixins/order";
import format from "../../Mixins/format";

import setTooltip from "@/assets/js/tooltip.js";
//import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import moment from "moment";
import orderDetail from "../orderscommon/orderDetail.vue";
import updateDimension from "@/Mixins/updateDimension";

export default {
  components: {
    VmdButton,
    Pagination,
    VmdInput,
    Form,
    orderDetail,
    //PerfectScrollbar,
  },
  mixins: [commonOrder, format, updateDimension],
  data() {
    return {
      showLoader: false,
      OrderInfo: [],
      listItems: [],
      showOrderId: 0,
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 10,
      search: "",
      noImage: null,
      value: [],
      template: true,
    };
  },
  computed: {
    encryptOrderId: function () {
      return (id) => this.OrderIdforURL(id);
    },
    /*updateOrderFormat: function () {
      store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },*/
    dimensionFormat() {
      let currentDimension = store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    schema() {
      return markRaw(
        Yup.object().shape({
          search: Yup.string(),
        })
      );
    },
  },
  mounted() {
    this.autoRefreshToken(9);
    this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
    this.noImage = this.$noImagePreview;
    this.loadItems();

    setTimeout(() => {
      window.addEventListener("click", () => {
        const allTooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        allTooltips.forEach((e) => {
          const tooltip = this.$store.state.bootstrap.Tooltip.getOrCreateInstance(e);
          tooltip.hide();
        });
      });
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.setTimer);
  },
  created() {
    document.addEventListener("click", function (e) {
      var orderModal = document.getElementById("orderModal");
      if (orderModal) {
        if (
          orderModal.style.display == "block" &&
          e.target.parentElement.parentElement.classList[0] != "modal-dialog" &&
          e.target.parentElement.parentElement.classList[0] != "modal-body" &&
          e.target.parentElement.parentElement.classList[0] != "modal-content" &&
          e.target.parentElement.parentElement.classList[0] != "table"
        ) {
          //console.log("clicked", e.target.parentElement.parentElement.classList[0]);
          const parentTR = document.querySelectorAll(".orderSelected");
          parentTR.forEach((element) => {
            element.classList.remove("orderSelected");
          });
        }
      }
    });
  },
  methods: {
    getResourceId(resourceData) {
      return this.encryptOrderId(resourceData[0].id);
    },
    getTemplateImage(resourceData) {
      let imageURL = this.noImage;
      var index = resourceData.findIndex(function (data) {
        return data.customId == "template-preview";
      });
      let resource = resourceData[index].previews;
      if (resource.length > 0) {
        var temp1 = resourceData[index].previews[0].previews.findIndex(function (data) {
          return data.usage == "template-preview";
        });
        if (temp1 >= 0) {
          imageURL = `${this.externalImage}/${resourceData[index].previews[0].previews[temp1].name}`;
        }
      }

      return imageURL;
    },
    async showOrder(id, value) {
      this.showOrderId = id;
      this.OrderInfo = value;
      var parentTR =
        event.target.parentElement.parentElement.parentElement.parentElement.parentElement
          .parentElement;
      parentTR.classList.add("orderSelected");
      setTimeout(() => {
        let checkLoaderExist = document.getElementsByClassName("modal-backdrop");
        if (checkLoaderExist.length > 0) {
          for (var i = 0; i < checkLoaderExist.length; i++) {
            //console.log(checkLoaderExist[i]);
            checkLoaderExist[i].classList.remove("show");
          }
        }
      }, 0.1);
    },
    async loadItems() {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      let searchvalue = "";
      if (this.search != "" && this.search != undefined) {
        searchvalue += `&filter=name||$contL||${this.search}`;
      }

      let joinForResources = `join=resources&join=resources.resourceData&join=resources.previews&join=jobStatus&join=jobStatus.orderId`;

      const result = await axiosInterceptor.get(
        `api/v1/orders?page=${this.page}&limit=${this.recordsPerPage}&join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants&${joinForResources}&getMilestones=true&getStatus=true&sort=id,DESC&${searchvalue}&filter=isTemplate||$eq||true`
      );
      if (result.status == 200) {
        this.listItems = result.data.data;
        this.totalPages = result.data.pageCount;
        this.totalRecords = result.data.total;
      } else {
        console.log(result);
      }
      loader.hide();
      setTimeout(() => {
        setTooltip(this.$store.state.bootstrap);
      }, 1000);
    },

    onPageChange(page) {
      this.page = parseInt(page);
      this.loadItems();
    },
    onChangeRecordsPerPage() {
      this.loadItems();
    },
    updatedateformat(value) {
      return moment(value).format(process.env.VUE_APP_DATE_FORMAT);
    },
  },
};
</script>
