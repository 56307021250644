<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div
        class="px-0 pt-4 pb-4 container custom-container custom-page-title align-items-center position-relative"
      >
        <div class="title-box">
          <div class="text-h4 text-white">
            {{ $t("order-grid.order-title") }}
          </div>
          <router-link
            :to="{
              path: '/template/templateGrid',
            }"
            class="box"
          >
            <vmd-button
              class="shadow-none rounded-pill font-weight-normal btn-sm"
              color="primary"
              >{{ $t("order-grid.new-order-btn") }}</vmd-button
            >
          </router-link>
        </div>

        <div class="position-absolute inner_toaster">
          <vmd-snackbar
            v-if="deleteOrder === 'success'"
            :description="deleteOrderMessage"
            :icon="{ color: 'white' }"
            :color="deleteOrder"
            @click="orderSnackbar()"
          />
          <vmd-snackbar
            v-if="mailMessage === 'successmail'"
            :description="sendMailMessage"
            :icon="{ color: 'white' }"
            color="success"
            @click="orderSnackbar(1)"
          />
        </div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-2 pb-6 px-0 container custom-container">
        <div class="row" style="margin: auto">
          <div class="col-12 px-0 d-flex top-page-section align-items-start">
            <!--<div class="d-sm-flex justify-between pt-1 btn-custom-group px-0 flex-wrap">
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal me-2 btn-sm"
                color="outline-grey"
                >{{ $t("order-grid.open-minded-btn") }}</vmd-button
              ><vmd-button
                class="shadow-none rounded-pill font-weight-normal me-2 btn-sm"
                color="outline-grey"
                >{{ $t("order-grid.in-progress-btn") }}</vmd-button
              >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="outline-grey"
                >{{ $t("order-grid.closed-btn") }}</vmd-button
              >
            </div>-->
          </div>
          <div class="d-flex p-0 new-grid-filter-cover">
            <div class="order-search-box d-flex align-items-start mb-4">
              <Searchbox></Searchbox>
            </div>
            <i class="fas fa-bars opacity-6" @click="listData()"></i>
          </div>
          <div class="row grid-box-container">
            <div class="top-page-section">
              <div
                class="right-page-section grid-page-right-section ms-auto d-flex align-items-center"
              >
                <vmd-checkbox id="customRadio1" name="flexRadioDefault" class="d-none" />
                <select v-model="recordsPerPage" @change="onChangeRecordsPerPage">
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="30">30</option>
                  <option :value="40">40</option>
                  <option :value="50">50</option>
                </select>
                <span class="page-info opacity-6">
                  <span :current-page="page" :total-pages="totalPages"
                    >{{ $t("order-grid.datatable-info1") }} {{ page }}
                    {{ $t("order-grid.datatable-info2") }} {{ totalPages }}</span
                  >
                </span>
                <Pagination
                  v-if="listItems"
                  :total-pages="totalPages"
                  :per-page="recordsPerPage"
                  :current-page="page"
                  @pagechanged="onPageChange"
                />
              </div>
            </div>
            <div v-if="totalRecords == 0" class="nodata text-center mt-4">
              <h3 style="font-family: 'Roboto'">
                {{ $t("order-grid.error-no-data") }}
              </h3>
            </div>
            <div v-for="item in listItems" :key="item.id" class="col-20per col">
              <div class="card-shadow custom-grid-card rounded-0 h-100">
                <div class="grid-box-image">
                  <div v-if="item.thumbnail != null" class="avatar">
                    <img :src="externalImage + item.thumbnail" alt="" />
                  </div>
                  <div v-else class="avatar">
                    <img :src="noImage" alt="" class="dummy-image" />
                  </div>
                  <span class="dropdown dropstart">
                    <a
                      id="dropdownMenuButton1"
                      href="javascript:void(0)"
                      class="table-menu-icon link-white"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="fas fa-ellipsis-h"></i
                    ></a>
                    <ul
                      class="px-2 py-3 dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                      data-bs-popper="none"
                    >
                      <li
                        v-if="!['released', 'finished'].includes(item.milestone)"
                        class="mb-2"
                      >
                        <router-link
                          :to="{ path: '/Uploader/' + encryptOrderId(item.id) }"
                          class="dropdown-item border-radius-md"
                        >
                          {{ $t("order-grid.uploader-link") }}
                        </router-link>
                      </li>
                      <li v-if="item.thumbnail != null" class="mb-2">
                        <router-link
                          :to="{ path: '/releaseprintdata/' + encryptOrderId(item.id) }"
                          class="dropdown-item border-radius-md"
                        >
                          {{ $t("order-grid.preview-link") }}
                        </router-link>
                      </li>
                      <li @click="confirmDeleteOrder(item.id)">
                        <a
                          href="javascript:void(0)"
                          class="dropdown-item border-radius-md"
                          >{{ $t("order-grid.delete-link") }}</a
                        >
                      </li>
                    </ul>
                  </span>
                </div>
                <div class="grid-box-title pb-0">
                  <router-link
                    :to="{ path: checkPath(encryptOrderId(item.id), item.thumbnail) }"
                    class="nav-link d-inline p-0 link-white"
                  >
                    <h5 class="text-h5 text-white font-weight-medium">
                      {{ item.name }}
                    </h5>
                  </router-link>
                  <span
                    class="mark-btn"
                    :title="item.status.toUpperCase()"
                    :class="{
                      'mark-danger': ['error', 'internalError'].includes(item.status),
                      'mark-success': item.status === 'done',
                      'mark-warning': [
                        'waitForFeedback',
                        'warning',
                        'processing',
                        'created',
                      ].includes(item.status),
                    }"
                  ></span>
                </div>
                <div class="row m-0">
                  <div class="row grid-details m-0">
                    <div class="col-12 pt-0 descriptionBox">
                      <p class="mb-0">
                        {{ item.description }}
                      </p>
                    </div>

                    <div class="col-12">
                      <span class="text-white opacity-7">
                        {{ $t("order-list.table-milestone-column") }}
                        {{ item.milestone }}</span
                      >

                      <span
                        class="text-white opacity-7 d-flex justify-content-between align-content-center flex-wrap"
                        >Created on {{ updatedateformat(item.createdAt) }}
                      </span>
                    </div>

                    <div class="col-12">
                      <div class="iconBoxInner">
                        <!--Order Detail-->
                        <a
                          href="javascript:void(0)"
                          class="link-white"
                          data-toggle="modal"
                          data-target="#orderModal"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-original-title="Order Info"
                          @click="showOrder(item.id, item)"
                          ><i class="fas fa-info-square"></i>
                        </a>
                        <!--Send link-->
                        <a
                          v-if="!['released', 'finished'].includes(item.milestone)"
                          href="javascript:void(0)"
                          class="link-white"
                          data-toggle="modal"
                          data-target="#new-board-modal"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-original-title="Send Link"
                          @click="
                            storeOrdeId(
                              item.id,
                              item.thumbnail,
                              item.name,
                              item.products[0].name,
                              item.products[0].type
                            )
                          "
                          ><i class="fas fa-envelope"></i>
                        </a>

                        <!-- lock order -->
                        <a
                          href="javascript:void(0)"
                          :class="
                            item.jobStatus && item.jobStatus.isJobLock == 1
                              ? ''
                              : 'd-none'
                          "
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-original-title="Lock Order"
                          @click="unlock"
                        >
                          <i
                            class="fa fa-lock"
                            :data-id="item.id"
                            :title="item.jobStatus != null ? item.jobStatus.userName : ''"
                            aria-hidden="true"
                          ></i>
                        </a>

                        <!--Download PDF-->
                        <!--<a
                          v-if="
                            item.milestone == 'released' || item.milestone == 'finished'
                          "
                          class="cursor-pointer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          data-bs-original-title="Download PDF"
                          @click="downloadFile(item.products[0].parts)"
                        >
                          <i
                            class="fa fa-download ml-auto"
                            href="javascript:void(0)"
                            download
                            aria-hidden="true"
                          ></i>
                        </a>-->
                        <span class="dropdown dropstart">
                          <a
                            v-if="['released', 'finished'].includes(item.milestone)"
                            id="dropdownMenuButton2"
                            href="javascript:void(0)"
                            class="table-menu-icon link-white"
                            :data-bs-toggle="
                              item.products[0].parts.length > 1 ? 'dropdown' : ''
                            "
                            aria-expanded="false"
                            @click="
                              item.products[0].parts.length > 1
                                ? ''
                                : downloadFile(
                                    item.products[0].parts[0].pageranges[0].variants[0]
                                      .id,
                                    item.name
                                  )
                            "
                            ><i class="fa fa-download"></i
                          ></a>

                          <ul
                            class="px-2 py-3 dropdown-menu dropdown-menu-start"
                            aria-labelledby="dropdownMenuButton2"
                            data-bs-popper="none"
                          >
                            <li v-for="(partsloop, i) in item.products[0].parts" :key="i">
                              <a
                                href="javascript:void(0)"
                                class="dropdown-item border-radius-md"
                                @click="
                                  downloadFile(
                                    partsloop.pageranges[0].variants[0].id,
                                    item.name,
                                    partsloop.type
                                  )
                                "
                              >
                                {{ partsloop.type }}
                              </a>
                            </li>
                          </ul>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Send link popup -->
            <SendLink></SendLink>
            <!-- Order Modal popup-->
            <orderDetail />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import VmdCheckbox from "@/components/VmdCheckbox.vue";
import VmdSnackbar from "@/components/VmdSnackbar.vue";
import Pagination from "./Pagination";
import axiosInterceptor from "@/shared/axiosInterceptor";
import moment from "moment";
import { markRaw } from "vue";
import * as Yup from "yup";
import store from "@/store";
import i18n from "@/i18n";
import Swal from "sweetalert2";
import updateDimension from "../../Mixins/updateDimension";
import commonOrder from "../../Mixins/order";
import format from "../../Mixins/format";
import Choices from "choices.js";
import setTooltip from "@/assets/js/tooltip.js";
import orderDetail from "../orderscommon/orderDetail.vue";
import SendLink from "../orderscommon/SendLink.vue";
import Searchbox from "../orderscommon/Searchbox.vue";
export default {
  name: "OrderGrid",
  components: {
    VmdButton,
    VmdCheckbox,
    Pagination,
    VmdSnackbar,
    orderDetail,
    SendLink,
    Searchbox,
  },
  mixins: [updateDimension, commonOrder, format],
  inject: ["eventBus"],
  data() {
    return {
      showLoader: false,
      OrderInfo: [],
      listItems: [],
      showOrderId: 0,
      page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 10,
      enterpageno: "",
      search: "",
      isShowing: false,
      default_mm_value: null,
      default_inch_value: null,
      noImage: null,
      snackbarColor: null,
      deleteOrder: null,
      initSearch: null,
      setTimer: null,
      value: [],
      milestoneValue: [],
      taggingStatus: [],
      taggingMilestone: [],
      modalInfo: [],
      modalOrderId: 0,
      modelOrderName: null,
      modelOrderImage: null,
      modelProductName: null,
      modelProductType: null,
      mailMessage: null,
    };
  },
  computed: {
    encryptOrderId: function () {
      // return (id) => this.updateDimension(id);
      return (id) => this.OrderIdforURL(id);
    },
    deleteOrderMessage() {
      return i18n.global.t("order-common.delete-order-success-message");
    },
    dimensionFormat() {
      let currentDimension = store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    searchschema() {
      return markRaw(
        Yup.object().shape({
          search: Yup.string(),
        })
      );
    },
    initialvaluesearch() {
      var checkInit = {
        search: "",
        //isDataValid: false,
      };
      // console.log("initial", checkInit);
      return checkInit;
    },
    sendMailMessage() {
      return i18n.global.t("order-common.order-popup-success-message");
    },
  },
  mounted() {
    this.autoRefreshToken(2);
    this.default_mm_value = process.env.VUE_APP_MM_VALUE;
    this.default_inch_value = process.env.VUE_APP_INCH_VALUE;
    this.noImage = this.$noImagePreview;
    this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
    this.choicesformat = new Choices(document.getElementById("orderformat"));
    this.choiceslang = new Choices(document.getElementById("orderlang"));

    this.loadItems();
    this.eventBus.on("loadlisting", (a) => {
      console.log(a);
      this.loadItems();
    });
    setTimeout(() => {
      window.addEventListener("click", () => {
        const allTooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        allTooltips.forEach((e) => {
          const tooltip = this.$store.state.bootstrap.Tooltip.getOrCreateInstance(e);
          tooltip.hide();
        });
      });
    }, 1000);
  },
  beforeUnmount() {
    this.eventBus.off("loadlisting");
    clearInterval(this.setTimer);
  },
  created() {
    document.addEventListener("click", function (e) {
      var orderModal = document.getElementById("orderModal");
      if (orderModal) {
        if (
          orderModal.style.display == "block" &&
          e.target.parentElement.parentElement.classList[0] != "modal-dialog" &&
          e.target.parentElement.parentElement.classList[0] != "modal-body" &&
          e.target.parentElement.parentElement.classList[0] != "modal-content" &&
          e.target.parentElement.parentElement.classList[0] != "table"
        ) {
          console.log("clicked", e.target.parentElement.parentElement.classList[0]);
          const parentTR = document.querySelectorAll(".orderSelected");
          parentTR.forEach((element) => {
            element.classList.remove("orderSelected");
          });
        }
      }
    });
  },
  methods: {
    updateBCC() {
      var ele = document.getElementById("sendme");
      this.showBCC = ele.checked;
    },
    async storeOrdeId(value, image, orderName, productname, producttype) {
      document.getElementById("frm").reset();
      //this.getChoices("expired");
      document.getElementById("sendme").checked = false;
      this.showBCC = false;

      this.single = document.getElementById("expired");
      this.choicesd = new Choices(this.single);
      this.choicesd.setChoiceByValue("d");

      this.locallang = localStorage.getItem("applang");
      this.localFormat = localStorage.getItem("format");

      // For Format
      this.choicesformat.setChoiceByValue(this.localFormat);

      // For language
      this.choiceslang.setChoiceByValue(this.locallang);

      if (image != null) {
        image = this.externalImage + image;
      } else {
        image = this.noImage;
      }
      this.modelOrderName = orderName;
      this.modelOrderImage = image;
      this.modelProductName = productname;
      this.modelProductType = producttype;
      this.modalOrderId = value;
    },
    unlock(element) {
      let orderId = element.target.dataset.id;
      Swal.fire({
        title: i18n.global.t("order-list.unlock-order-confirm-title"),
        text: i18n.global.t("order-list.unlock-order-confirm-text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.global.t("order-list.unlock-order-confirm-buttontext"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.unlockOrder(orderId, true);
          await this.loadItems();
          Swal.fire(
            "",
            i18n.global.t("order-list.unlock-order-success-message"),
            "success"
          );
        }
      });
    },
    async showOrder(id, value) {
      this.showOrderId = id;
      this.OrderInfo = value;
      var parentTR =
        event.target.parentElement.parentElement.parentElement.parentElement.parentElement
          .parentElement;
      parentTR.classList.add("orderSelected");
      setTimeout(() => {
        let checkLoaderExist = document.getElementsByClassName("modal-backdrop");
        if (checkLoaderExist.length > 0) {
          for (var i = 0; i < checkLoaderExist.length; i++) {
            //console.log(checkLoaderExist[i]);
            checkLoaderExist[i].classList.remove("show");
          }
        }
      }, 0.1);
    },
    reset() {
      this.choicesd.destroy();
      document.getElementById("frm").reset();
    },
    async loadItems() {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      let searchvalue = "";
      if (this.search != "" && this.search != undefined) {
        searchvalue += `&filter=name||$contL||${this.search}`;

        if (localStorage.getItem("status")) {
          let status = JSON.parse(localStorage.getItem("status"));
          let statusSearchResult = status.filter(
            (item) => item.toLowerCase().indexOf(this.search.trim().toLowerCase()) > -1
          );
          if (statusSearchResult.length > 0) {
            searchvalue = `&filter=status||$eqL||${statusSearchResult.toString()}`;
          }
        }

        if (localStorage.getItem("milestones")) {
          let milestone = JSON.parse(localStorage.getItem("milestones"));
          let milestoneSearchResult = milestone.filter(
            (item) => item.toLowerCase().indexOf(this.search.trim().toLowerCase()) > -1
          );
          if (milestoneSearchResult.length > 0) {
            searchvalue = `&filter=milestone||$in||${milestoneSearchResult.toString()}`;
          }
        }
      }

      // this.value refers to status filter
      if (this.taggingStatus.length > 0) {
        searchvalue += `&filter=status||$in||${this.taggingStatus.toString()}`;
      }

      // this.value refers to milestone filter
      if (this.taggingMilestone.length > 0) {
        searchvalue += `&filter=milestone||$in||${this.taggingMilestone.toString()}`;
      }

      //let joinForResources = `join=resources&join=resources.resourceData&join=resources.previews&join=jobStatus&join=jobStatus.orderId`;
      let joinForResources = ``;
      const result = await axiosInterceptor.get(
        `api/v1/orders?page=${this.page}&limit=${this.recordsPerPage}&join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants&${joinForResources}&getMilestones=true&getStatus=true&sort=id,DESC&${searchvalue}&filter=isTemplate||$eq||false`
      );
      if (result.status == 200) {
        if (typeof result.data.data[0] !== "undefined") {
          localStorage.setItem(
            "milestones",
            JSON.stringify(Object.values(result.data.allMilestones))
          );
        }

        if (typeof result.data.data[0] !== "undefined") {
          localStorage.setItem(
            "status",
            JSON.stringify(Object.values(result.data.allStatus))
          );
        }

        this.listItems = result.data.data;
        this.totalPages = result.data.pageCount;
        this.totalRecords = result.data.total;
      } else {
        console.log(result);
      }
      loader.hide();
      setTimeout(() => {
        setTooltip(this.$store.state.bootstrap);
      }, 1000);
    },
    gridData() {
      this.$router.push("/orders/grid");
    },
    listData() {
      this.$router.push("/orders/list");
    },
    onPageChange(page) {
      this.page = parseInt(page);
      this.loadItems();
    },
    onChangeRecordsPerPage() {
      this.loadItems();
    },
    updatedateformat(value) {
      return moment(value).format(process.env.VUE_APP_DATE_FORMAT);
    },
    gotoPage() {
      if (!isNaN(parseInt(this.enterpageno))) {
        this.page = parseInt(this.enterpageno);
        this.loadItems();
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/dist/vue-multiselect.css"></style>
