<template>
  <!-- <div
    class="input-group vmd-input"
    :class="`input-group-${variant} ${getStatus(error, success)}`"
  >-->
  <div
    class="input-group vmd-input"
    :class="`input-group-${variant} ${getStatus(error, success)} ${getValue(inputValue)}`"
  >
    <label v-if="label" :class="variant === 'static' ? '' : 'form-label'">{{
      label
    }}</label>
    <input
      :id="id"
      :type="type"
      class="form-control"
      :class="getClasses(size)"
      :name="name"
      :value="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      @input="handleChange"
      @blur="handleBlur"
      @click="handleChange"
    />
    <p
      v-show="(errorMessage || meta.valid) && !readonly"
      class="w-100 error-message text-sm text-danger"
      style="display: none"
    >
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js";
import { toRef } from "vue";
import { useField } from "vee-validate";
//useField.Validator.locale = `${language}`;

export default {
  name: "VmdInput",
  props: {
    variant: {
      type: String,
      default: "outline",
    },
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    successMessage: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    // use `toRef` to create reactive references to `name` prop which is passed to `useField`
    // this is important because vee-validte needs to know if the field name changes
    // https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
    const name = toRef(props, "name");

    // we don't provide any rules here because we are using form-level validation
    // https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
    const { value: inputValue, errorMessage, handleBlur, handleChange, meta } = useField(
      name,
      {
        initialValue: props.value,
      }
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  mounted() {
    setMaterialInput();

    //console.log(localStorage.getItem("applang"));
  },

  methods: {
    getClasses: (size) => {
      let sizeValue;

      sizeValue = size ? `form-control-${size}` : null;

      return sizeValue;
    },
    getValue(name) {
      if (name != "" && name != null && name != undefined) {
        return "is-filled";
      }
    },
    getStatus: (error, success) => {
      let isValidValue;

      if (success) {
        isValidValue = "is-valid";
      } else if (error) {
        isValidValue = "is-invalid";
      } else {
        isValidValue = null;
      }

      return isValidValue;
    },
  },
};
</script>
