<template>
  <div class="d-flex">
    <div class="language-box" :class="{ 'd-none': logged_in != true }">
      <label>Format</label>
      <select id="format_id" class="form-control" @change="setFormat($event)">
        <option v-for="i in format" :key="i.id" :value="i.value">
          {{ i.text }}
        </option>
      </select>
    </div>
    <div class="language-box">
      <label>Language</label>
      <select
        id="language_id"
        v-model="$i18n.locale"
        class="form-control"
        @change="setlanguage($event)"
      >
        <option
          v-for="i in locales"
          :key="`locale-${i}`"
          :value="i.code"
          :selected="i.code == 'en' || currentlanguage == i.code"
        >
          {{ i.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
//Select2 design
import Choices from "choices.js";
import { faLastfmSquare } from "@fortawesome/free-brands-svg-icons";
import store from "@/store";
import axiosInterceptor from "@/shared/axiosInterceptor";
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      currentlanguage: null,
      currentFormat: null,
      locales: [
        { id: 1, code: "at", text: "German", dbCode: "de" },
        { id: 2, code: "en", text: "English", dbCode: "en-us" },
      ],
      format: [
        { id: 1, code: "point", text: "POINT", value: "POINT", dbCode: "pt" },
        { id: 2, code: "mm", text: "MM", value: "MM", dbCode: "mm" },
        { id: 3, code: "inch", text: "INCH", value: "INCH", dbCode: "in" },
      ],
      logged_in: null,
    };
  },
  computed: {},
  mounted() {
    var path = this.$route.path;
    this.currentlanguage = localStorage.getItem("applang");
    if (this.currentlanguage == null) {
      this.currentlanguage = "en";
    }
    this.currentFormat = localStorage.getItem("format");
    this.getChoices("language_id");
    this.getChoices("format_id");
    let access_token = localStorage.getItem("access_token");
    if (access_token != null && path != "/login") {
      this.logged_in = true;
    }
  },
  methods: {
    getChoices(id) {
      if (document.getElementById(id)) {
        var element = "";
        var element_new = "";
        if (id == "language_id") {
          element = document.getElementById(id);
          element_new = new Choices(element, {
            searchEnabled: faLastfmSquare,
            shouldSort: false,
          });
          element_new.setChoiceByValue(this.currentlanguage);
        } else {
          element = document.getElementById(id);
          element_new = new Choices(element, {
            searchEnabled: faLastfmSquare,
            shouldSort: false,
          });
          element_new.setChoiceByValue(this.currentFormat);
        }
        return element_new;
      }
    },
    async setlanguage(event) {
      this.$t.setLocale = event.target.value;
      localStorage.setItem("applang", event.target.value);
      this.currentlanguage = event.target.value;
      let locales_index = this.locales.findIndex((x) => x.code === event.target.value);
      let passValue = this.locales[locales_index].dbCode;
      let authData = this.$store.getters["auth/getAuthData"];
      let userType = authData.userType;
      // API only trigger if normal user has been login
      if (userType == "User") {
        let payload = {};
        payload["service"] = "generic";
        payload["key"] = "language";
        payload["value"] = passValue;
        payload["public"] = 0;
        await axiosInterceptor.post(`api/v1/users/settings`, payload);
      }
    },
    async setFormat(event) {
      this.currentFormat = event.target.value;
      let format_index = this.format.findIndex((x) => x.value === event.target.value);
      let passValue = this.format[format_index].dbCode;
      let authData = this.$store.getters["auth/getAuthData"];
      let userType = authData.userType;
      // API only trigger if normal user has been login
      if (userType == "User") {
        let payload = {};
        payload["service"] = "generic";
        payload["key"] = "unit";
        payload["value"] = passValue;
        payload["public"] = 0;
        await axiosInterceptor.post(`api/v1/users/settings`, payload);
      }
      store.commit("auth/StoreDimensionFormat", event.target.value);
    },
  },
};
</script>
