<template>
  <div class="input-group input-group-outline vmd-input is-filled">
    <label :for="name" class="form-label">{{ label }}</label>
    <VueMultiselect
      :id="id"
      v-model="inputValue"
      :name="name"
      :value="inputValue"
      :options="options"
      :multiple="true"
      :taggable="taggable"
      tag-placeholder="Add this as new tag"
      @remove="onRemove($event)"
      @select="onChange($event)"
      @tag="addTag"
    >
    </VueMultiselect>

    <p v-show="errorMessage" class="w-100 error-message text-sm text-danger">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { useField } from "vee-validate";

export default {
  components: {
    VueMultiselect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
    },

    value: {
      type: Array,
      default: () => [],
    },
    showSelect: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { value: inputValue, handleChange, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    const onChange = (event) => {
      const value = event;
      handleChange(value);
    };

    const addTag = (newValue) => {
      let allOption = props.options;
      allOption.push(newValue);
      let currentValue = inputValue.value;
      currentValue.push(newValue);
    };

    return {
      onChange,
      addTag,
      errorMessage,
      inputValue,
    };
  },
  methods: {
    onRemove() {
      // console.log("remoxxxxve", option);
    },
  },
};
</script>

<style scoped>
.invalid-feedback-custom-label {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 100%;
  color: #dc3545;
}
</style>
