import axios from "axios";
import store from '../store/index';
import Swal from 'sweetalert2'


let isRefreshing = false;
let failedQueue = [];




//let checking;
const axiosInterceptor = axios.create({
  baseURL:process.env.VUE_APP_API_URL
});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}


//let temp={ responseType: 'json'};
axiosInterceptor.interceptors.request.use((config) => {
    const authData = store.getters["auth/getAuthData"];
    //{ responseType: 'json'}
    if(config.responseType){
      config.transformRequest['responseType']='blob';
    }

    if(config.orderId){
      config.headers.common["orderId"]=config.orderId
    }
    if (authData == null) {
      return config;
    }
    config.headers.common["Authorization"] = `bearer ${authData.token}`;
    if(authData.userType == 'Guest'){
      config.headers.common["session"] = authData.session;
    }

    if(localStorage.getItem('loginType') == 2){
      config.headers.common["idp_token"] = localStorage.getItem('zaikio_access_token');
    }
   // console.log(`headers`,config.headers.common)
    
    return config;
  });
  

  axiosInterceptor.interceptors.response.use(async function (response) {
    
    return response;
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      //console.log(`checking`,checking)

      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({resolve, reject})
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        })
      }

    originalRequest._retry = true;
    isRefreshing = true;


     // originalRequest._retry = true;
  
      const authData = store.getters["auth/getAuthData"];
        var payload = {
          access_token: authData.token,
          refresh_token: authData.refreshToken,
          api:1,
        };
      let apiName='api/auth/refresh';
      if(authData.userType == 'Guest'){
        payload={};
        let sessionId=authData.session;
        apiName=`api/v1/orders/verifySession?session=${sessionId}`;
        payload['api']=1;
       
      }

      if(localStorage.getItem("loginType")==2 && localStorage.getItem("user_type") =='User'){
        apiName='api/auth/refresh?called_from=kc_token';
      }
     
      return await axios.post(apiName, payload)
        .then(({data}) => {
          data.userType="User";
        if(authData.userType == 'Guest'){
          data.userType="Guest";
          data.session=localStorage.getItem("session");
          data.clientId=localStorage.getItem("clientId");
          data.client_secret=localStorage.getItem("client_secret");
          data.access_token=data.data.clientAccount;
        }

        data.loginType=authData.loginType;
        store.commit("auth/saveTokenData", data);
         axios.defaults.headers.common['Authorization'] = 'bearer ' + data.access_token;
          originalRequest.headers['Authorization'] = 'bearer ' + data.access_token;
          processQueue(null, data.access_token);
          return axios(originalRequest);
        }).catch(async function (error) {
          processQueue(error, null);
          console.log(error,"ddddd");
          store.dispatch("auth/signout")
         // store.commit("auth/RemoveTokenData");
          //router.push('/login');
          window.location.href="/login";
          //this.$router.replace("/login");
        }).finally(() => { isRefreshing = false });
    
    }
    else{
      var checkLoaderExist;
      let errorMessage;
      if(error.message){
        errorMessage=error.message
      }
      console.log('connection',error.message);  
      console.log('throwing error',error.response.statusText);  
      if(error.response.status === 400){
        //router.push('/login')
        checkLoaderExist = document.getElementsByClassName("vld-container");
      if (checkLoaderExist.length > 0) {
        for(var k = 0; k < checkLoaderExist.length; k++){
          //console.log(checkLoaderExist[i]);
          checkLoaderExist[k].style.visibility = "hidden";
        }
        
      }
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.statusText
       })
        //window.location.href="/login";
      }
      else{
        console.log("in else")
      checkLoaderExist = document.getElementsByClassName("vld-container");
      if (checkLoaderExist.length > 0) {
        for(var i = 0; i < checkLoaderExist.length; i++){
          //console.log(checkLoaderExist[i]);
          checkLoaderExist[i].style.visibility = "hidden";
        }
        
      }
      
      
      errorMessage = error.response.data ? error.response.data.message : error.response.statusText;
      
      // If backend has any error and stop working
      if(errorMessage==''){
        if(error.message){
          errorMessage=error.message
        }
      }

    
     Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage
     })
      }
      
      
    }
    
    return Promise.reject(error);
  });

 
  
  export default axiosInterceptor