import i18n from "@/i18n";
import Swal from "sweetalert2";
import axiosInterceptor from "@/shared/axiosInterceptor";
export default {
    methods: {
        confirmDeleteOrder(id){
            this.$swal({
                title: i18n.global.t("order-common.delete-order-confirm-title"),
                text: i18n.global.t("order-common.delete-order-confirm-text"),
                type: "warning",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: i18n.global.t("order-common.delete-order-confirm-buttontext"),
              }).then((result) => {
                if (result.value) {
                    this.deleteAPI(id);
                }
              });
        },
        async deleteAPI(id){
          var loaderDisplayPosition = document.body;
          let loader = this.$loading.show({
            container: loaderDisplayPosition,
          });
            const result = await axiosInterceptor.delete(
                `api/v1/orders/${id}`);
            if (result.status == 200) {
                this.loadItems();
                this.deleteOrder="success";
                this.autoCloseSnackbar();
            }
            else{
                this.deleteOrder="danger";
            }
            loader.hide();   
        },
        orderSnackbar(type=null) {
          console.log("orderSnackbar")
          if(type==null){
            type=0;
          }
          if(type==1){
            this.deleteOrder = null;
          }
          if(type==2){
            this.mailMessage=null;
          }
            
        },
        autoCloseSnackbar(type=1) {
          if(type==1){
            setTimeout(() => {
              this.deleteOrder = null;
          }, 5000);
          }
          if(type==2){
            setTimeout(() => {
              this.mailMessage = null;
          }, 5000);
          }
            
        },
        clearSearch() {
          document.getElementById("customSerachForm").reset();
          this.search = null;
          this.loadItems();
          this.initSearch = 1;
        },
        keyupSearch(e) {
          this.search = e.target.value;
          if (e.target.value == "") {
            this.search = null;
            if (this.initSearch == 0) {
              this.loadItems();
              this.initSearch = 1;
            }
          } else {
            this.initSearch = 0;
          }
        },
        getSearch(e) {
          this.search = e.target.value;
          //this.serach
          this.loadItems();
        },
        async unlockOrder(orderId,jobUnlockedByLoginUser = false) {
          try {
            let url = `api/v1/orders/${orderId}/jobStatus?jobLock=false`;
            if(jobUnlockedByLoginUser == true){
              url +='&jobUnlockByLoggedInUser=true';
            }
            await axiosInterceptor.post(url);               
            
            // console.log('Job locked API called!!',autoAssignResponse);   
          } catch (error) {
            console.log(error);
            return '/login';
          }            
        },
        checkPath(id, thumbnail) {
          let setURL;
          if (thumbnail == null) {
            setURL = "/Uploader/" + id;
          } else {
            setURL = "/releaseprintdata/" + id;
          }
          return setURL;
        },
        async downloadFile(variantId,orderName,partType) {

          /*let BodyFound=PartInfo.find(o => o.type === 'Body');
          let variantId=PartInfo[0].pageranges[0].variants[0]['id']
          if(BodyFound){
            variantId=BodyFound.pageranges[0].variants[0]['id']
          }*/
          //console.log(`variantId`,variantId)
          const getDownloaPDFlink = await axiosInterceptor.get(
            `/api/v1/variants/${variantId}/outputFileDownloadLink`
          );
          let downloadURL = null;
          orderName.replace(/[^a-zA-Z ]/g, "");
          if(partType){
            orderName=`${orderName}_${partType}`;
          } 
          var downlodfilename =orderName+".pdf";

          if (getDownloaPDFlink.status == 200) {
            downloadURL = getDownloaPDFlink.data.downloadURL;
            //console.log(`downloadURL`, downloadURL);
            if (downloadURL != "") {
              //var index = downloadURL.lastIndexOf("/") + 1;
              //downlodfilename = downloadURL.substr(index);
            } else {
              Swal.fire("", "Download URL is invalid", "error");
              return;
            }
          }
    
          let responseType = {
            responseType: "blob",
          };
    
          const result = await axiosInterceptor.get(downloadURL, responseType);
          //console.log(result);
          if (result.status == 200) {
            var fileURL = window.URL.createObjectURL(new Blob([result.data]));
            var fURL = document.createElement("a");
    
            fURL.href = fileURL;
           
        
            fURL.setAttribute("download", downlodfilename);
            document.body.appendChild(fURL);
    
            fURL.click();
          }
        },
        
    }
}