import { createRouter, createWebHistory } from "vue-router";

//import Default from "../views/dashboards/Default.vue";
import Illustration from "../views/auth/signin/login.vue";
import callback from "../views/auth/signin/Callback.vue";
import Forgetpassword from "../views/auth/forgetpassword/Forgetpassword.vue";
import Guestlogin from "../views/auth/signin/Guestlogin.vue";
import OrderListing from "../views/orders/OrderList.vue";

import OrderGrid from "../views/orders/OrderGrid.vue";
import Uploader from "../views/upload/Uploader.vue";
import tmpsocket from "../views/upload/Socketdemo.vue";
import Releaseprintdata from "../views/upload/Releaseprintdata.vue";
import commonOrder from "../Mixins/order";
import commonToken from "../Mixins/autoRefreshToken";
import Sitemap from "../views/sitemap/Sitemap.vue";


import templateGrid from "../views/template/templateGrid.vue";
import NewOrder from "../views/template/NewOrder.vue";
import createTemplate from "../views/template/createTemplate.vue";
import templateImage from "../views/template/templateImage.vue";


import createBrochure from "../views/Brochure/createBrochure.vue";
import BrochurePdf from "../views/Brochure/BrochurePdf.vue";
import Miniature from "../views/miniature/CreateMiniature.vue";


import test1 from "../views/upload/test_table.vue";

import i18n from '../i18n.js';
i18n.global.locale.value = localStorage.getItem("applang") || 'English';

import store from '@/store'
import axios from "axios";
import axiosInterceptor from "@/shared/axiosInterceptor";






const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",

  },
  
  /*{
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
  },*/
  {
    path: "/login",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/test1/:OrderId",
    name: "test1",
    component: test1,
  },
  
  {
    path: "/callback",
    name: "callback",
    component: callback,
  },
  {
    path: "/tmpsocket",
    name: "tmpsocket",
    component: tmpsocket,
  },
  
  {
    path: "/forgetpassword",
    name: "Forget Password",
    component: Forgetpassword,
  },
  {
    path: "/share",
    name: "GuestLogin",
    component:Guestlogin,
    meta: {
      requiresAuth: false,
      Userauth:false,
      Guestauth:false,
    }
  },
  {
    path: "/Uploader/:OrderId",
    name: "UploadImage",
    component: Uploader,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:true,
    }
  },
  {
    path: "/releaseprintdata/:OrderId",
    name: "Preview",
    component: Releaseprintdata,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:true,
    }
  },
  {
    path: "/orders/list",
    name: "OrderListing",
    component: OrderListing,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    }
  },
  {
    path: "/orders/grid",
    name: "OrderGrid",
    component: OrderGrid,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    },
  },
  {
    path: "/template/NewOrder/:templateId",
    name: "NewOrder",
    component: NewOrder,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    }
  },
  {
    path: "/template/templateGrid",
    name: "TemplateGrid",
    component: templateGrid,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    }
  },
  {
    path: "/template/createTemplate",
    name: "CreateTemplate",
    component: createTemplate,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    }
  },
  {
    path: "/template/templateImage/:templateId",
    name: "TemplateImage",
    component: templateImage,
    meta: {
      requiresAuth: true,
      Userauth:true,
      Guestauth:false,
    }
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: Sitemap,
  },
  {
    path: "/createBrochure",
    name: "createBrochure",
    component: createBrochure,
  },
  {
    path: "/Brochurepdf",
    name: "Brochurepdf",
    component: BrochurePdf,
  },
  {
    path: "/miniature",
    name: "miniature",
    component: Miniature,
  },
];


const router = createRouter({
  mode: 'history',
  //history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  //historyApiFallback: true,
  routes,
  linkActiveClass: "active",

});


router.beforeEach(async (to, from, next) => {
  const currentUser = localStorage.getItem("user_type");
  if (!store.getters["auth/getAuthData"].token) {
   
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    const user_type = localStorage.getItem("user_type");
    const session = localStorage.getItem("session");
    if (access_token && access_token!='undefined') {
      const data = {
        access_token: access_token,
        refresh_token: refresh_token,
        userType:user_type
      };
      if(user_type == 'Guest'){
        data['session']=session;
      }
      store.commit("auth/saveTokenData", data);
    }
  }    
   
  if(to.path!='/share' && to.path!='/releaseprintdata/'+localStorage.getItem('session') && (from.path.includes('releaseprintdata') || from.path.includes('ReleasePrintdata'))){             
    //console.log(`to.path In`);
    let orderId = from.params.OrderId;      
    if(orderId){
      var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/

      if (format.test(from.params.OrderId)) {
       //encrpted text
      }
      else{
       
        let orderHeader = {
          orderId: orderId,
        };
        
        const result = await axiosInterceptor.get(
          `api/v1/orders/${orderId}?join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants`,orderHeader
        );
        let orderStatus=result.data.status;
        let orderMilestone=result.data.milestone;
        // Prevent the Unlock order API If order status done and order milestone has been released
        if(orderStatus!='done' && orderMilestone!='released'){
          commonOrder.methods.unlockOrder(orderId);
        }
        
      }
    }        
  }


  let auth = store.getters["auth/isTokenActive"];
  let loginType=localStorage.getItem('loginType')
  //
    let Zaikioauth = store.getters["auth/isZaikioTokenActive"];
    if (!Zaikioauth && to.fullPath!='/login' && loginType==2){
      const ZaikioauthData = store.getters["auth/getZaikioAuthData"];
      console.log('Zaikioauth',ZaikioauthData)
      let zaikio_access_token=localStorage.getItem("zaikio_access_token");
       if (zaikio_access_token && currentUser == 'User' && loginType==2) {
        console.log('Zaikioauth in')
        await commonToken.methods.commonRefreshToken();
       }

    }
  if (!auth && to.fullPath!='/login') {
    const authData = store.getters["auth/getAuthData"];
    if (authData.token && currentUser == 'User') {
      await commonToken.methods.commonRefreshToken();
    }

    

    if (authData.token && currentUser == 'Guest') {
      let sessionId=localStorage.getItem('session');
    await axios.post(`api/v1/orders/verifySession?session=${sessionId}`).then(function (response) {
        response.data.userType="Guest";
        response.data.loginType=1;
        response.data.session=localStorage.getItem("session");
        response.data.access_token=response.data.data.clientAccount;
        store.commit("auth/saveTokenData", response.data);
        auth = true;
      }).catch(function (error) {
        store.dispatch("auth/signout")
        console.log(error);
        return '/login';
      });
    }
  }

  
  /*05-12*/
  if(to.meta.requiresAuth){
    const authUser = localStorage.getItem("user_type");
    let authAgain=store.getters["auth/isTokenActive"];
    
    //console.log(`authAgain`,authAgain)
    if(!authUser || !authAgain) {
      return next({ path: "/login" })
    }
    else if(to.meta.Userauth && !to.meta.Guestauth) {
      if(authUser=== 'User') {
        return next()
      }
      else{
        return next({ path: "/login" })
      }
    }

    else if(to.meta.Userauth && to.meta.Guestauth) {
      if(authUser=== 'User' || authUser=== 'Guest') {
        return next()
      }
      else{
        return next({ path: "/login" })
      }
    }
    else if(to.meta.Guestauth && !to.meta.Userauth) {
      if(authUser==='Guest') {
        return next()
      }
      else{
        return next({ path: "/login" })
      }
    }
  }
  else{
    return next();
  }
  /*05-12*/
  
  return next();

});

export default router;
