<template>
  <div class="notifications">
    <notification
      v-for="(notification, index) in notifications"
      :key="notification.text + index"
      :notification="notification"
      @close-notification="removeNotification"
    ></notification>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Notification from "./TheNotification";
export default {
  components: {
    Notification,
  },
  computed: {
    ...mapGetters("notifications", {
      notifications: "getListstate",
    }),
  },
  mounted() {},
  methods: {
    removeNotification: function (notification) {
      //console.log("yes im", notification);
      this.$store.commit("notifications/REMOVE_NOTIFICATION", notification);
    },
  },
};
</script>
