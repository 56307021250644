<template>
  <div class="d-flex w-100 flex-column">
    <div
      id="frontimage"
      class="card"
      @dragover.prevent
      @drop="this.$parent.$parent.displayfrontimage"
      @dragenter="this.$parent.$parent.dragenter(2)"
      @dragleave="this.$parent.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.$parent.isFrontDrop,
        }"
        :style="{
          'background-image': `url(${this.$parent.$parent.frontImage})`,
        }"
      ></div>
      <p class="card-content">
        <strong>1 Vorderseite</strong>
      </p>
      <a href="javascript:void(0)" class="drop_button"
        ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
      ></a>
    </div>
    <div
      id="backimage"
      class="card mt-6"
      @dragover.prevent
      @drop="this.$parent.$parent.displaybackimage"
      @dragenter="this.$parent.$parent.dragenter(3)"
      @dragleave="this.$parent.$parent.dragend($event)"
    >
      <div
        class="imagePreviewWrapper draggable"
        :class="{
          image_drop: this.$parent.$parent.isBackDrop,
        }"
        :style="{
          'background-image': `url(${this.$parent.$parent.backImage})`,
        }"
      ></div>
      <p class="card-content">
        <strong>2 Rückseite</strong>
      </p>
      <a href="javascript:void(0)" class="drop_button"
        ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
      ></a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Miniaturedata",
};
</script>
