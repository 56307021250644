<template>
  <div id="new-board-modal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-570 modal-dialog-centered">
      <div class="modal-content main-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("order-grid.order-popup-title") }}
          </h5>
          <a
            href="javascript:void(0)"
            class="close p-0 m-0 text-white h4"
            data-dismiss="modal"
            data-target="#new-board-modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </a>
        </div>
        <div class="modal-body">
          <div class="order-details d-flex align-items-center justify-content-between">
            <div class="order-image">
              <img alt="" :src="this.$parent.modelOrderImage" />
            </div>
            <div class="order-content">
              <div class="row">
                <div class="col-3 order-name">
                  <p>{{ $t("order-list.order-popup-order-name") }}</p>
                  <span>{{ this.$parent.modelOrderName }}</span>
                </div>
                <div class="col-3">
                  <p>{{ $t("order-list.order-popup-product-name") }}</p>
                  <span>
                    {{ this.$parent.modelProductName }}
                  </span>
                </div>
                <div class="col-3">
                  <p>{{ $t("order-list.order-popup-product-type") }}</p>
                  <span>{{ this.$parent.modelProductType }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="share-order">
            <div class="row">
              <Form
                id="frm"
                :validation-schema="schema"
                :initial-values="initialValues"
                autocomplete="off"
                @submit="SendMail"
                @invalid-submit="onInvalidSubmit"
              >
                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-recipient") }}</label>
                  <vmd-input
                    id="recipient"
                    v-model="this.$parent.recipient"
                    type="text"
                    :placeholder="$t('order-list.order-popup-placeholder-recipient')"
                    name="recipient"
                  />
                </div>
                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-CC") }}</label>
                  <vmd-input
                    id="cc"
                    type="text"
                    :placeholder="$t('order-list.order-popup-placeholder-CC')"
                    name="cc"
                  />
                </div>

                <div class="mb-2 form-group">
                  <label>{{ $t("order-list.order-popup-body") }}</label>
                  <vmd-textarea
                    id="sharetext"
                    :placeholder="$t('order-list.order-popup-placeholder-bodytext')"
                    name="bodyText"
                  >
                  </vmd-textarea>
                </div>

                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-format") }}</label>
                  <div class="input-group">
                    <select
                      id="orderformat"
                      class="form-control c-select"
                      name="orderformat"
                    >
                      <option
                        v-for="i in this.$parent.format"
                        :key="`${i}`"
                        :value="i.value"
                        data-custom-properties='{"placeholderValue": "Select payment method"}'
                        :selected="i.value == this.$parent.localFormat"
                      >
                        {{ i.value }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-language") }}</label>
                  <div class="input-group">
                    <select id="orderlang" class="form-control" name="orderlang">
                      <option
                        v-for="i in this.$parent.locales"
                        :key="`${i}`"
                        :value="i.code"
                        :selected="i.code == this.$parent.locallang"
                      >
                        {{ i.value }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-ExpiredIn") }}</label>
                  <div class="input-group">
                    <select id="expired" class="form-control" name="expired">
                      <option
                        v-for="i in setTime"
                        :key="`${i}`"
                        :value="i.code"
                        :selected="i.code == 'd'"
                      >
                        {{ i.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mb-3 form-group">
                  <label>{{ $t("order-list.order-popup-ExpiredTime") }}</label>
                  <vmd-input
                    id="expired_time"
                    type="text"
                    :placeholder="$t('order-list.order-popup-placeholder-expiredTime')"
                    name="expired_time"
                    value="7"
                  />
                </div>

                <div class="mb-3 form-group checkbox-box">
                  <label>
                    <vmd-checkbox
                      id="sendme"
                      name="sendme"
                      value="sendme"
                      @change="this.$parent.updateBCC()"
                    ></vmd-checkbox>
                    {{ $t("order-grid.order-popup-send_me_copy") }}
                  </label>
                </div>
                <div
                  class="mb-3 form-group"
                  :class="{ 'd-none': this.$parent.showBCC != true }"
                >
                  <label>{{ $t("order-list.order-popup-BCC") }}</label>
                  <vmd-input
                    id="bcc"
                    type="text"
                    :placeholder="$t('order-list.order-popup-placeholder-BCC')"
                    name="bcc"
                  />
                </div>
                <div class="modal-footer-buttons">
                  <vmd-button
                    class="shadow-none rounded-pill font-weight-normal btn-sm"
                    color="primary"
                    type="submit"
                    >{{ $t("order-grid.order-popup-send") }}</vmd-button
                  >
                  <vmd-button
                    id="abortButton"
                    ref="formdata"
                    class="shadow-none rounded-pill font-weight-normal ms-2 btn-sm"
                    type="button"
                    color="outline-grey"
                    data-dismiss="modal"
                    data-target="#new-board-modal"
                    @click="this.$parent.reset"
                    >{{ $t("order-grid.order-popup-abort") }}
                  </vmd-button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdInput from "@/components/VmdInput.vue";
import VmdTextarea from "@/components/VmdTextarea.vue";
import VmdButton from "@/components/VmdButton.vue";
import VmdCheckbox from "@/components/VmdCheckbox.vue";
import { Form } from "vee-validate";
import i18n from "@/i18n";
import * as Yup from "yup";
import { markRaw } from "vue";
import axiosInterceptor from "@/shared/axiosInterceptor";
import format from "../../Mixins/format";
import updateDimension from "../../Mixins/updateDimension";

export default {
  name: "SendLink",
  components: {
    VmdInput,
    VmdTextarea,
    VmdButton,
    VmdCheckbox,
    Form,
  },
  mixins: [updateDimension, format],
  computed: {
    initialValues() {
      var checkInit = {
        expired_time: 7,
      };
      return checkInit;
    },
    schema() {
      Yup.addMethod(Yup.number, "between1And10", function () {
        return this.test(
          "is-between-1-and-10",
          "Value MUST be a number between 1 and 10",
          (value) => value > 0 && value <= 10
        );
      });

      const isEmailSchema = Yup.string().email();

      return markRaw(
        Yup.object().shape({
          sendme: Yup.string(),
          bcc: Yup.string().when("sendme", (val) => {
            if (val == "sendme" || val == true || val == "true") {
              return (
                Yup.string()
                  // .email(i18n.global.t("order-list.order-popup-validemail-BCC"))
                  .required(i18n.global.t("order-list.order-popup-required-BCC"))
                  .test({
                    name: "emails",
                    test: function () {
                      //console.log(val);
                      var ele = document.getElementById("bcc");
                      let firstInvalidEmail;
                      if (ele != null) {
                        firstInvalidEmail = ele.value
                          .split(",")
                          .map((email) => email.trim())
                          .find((v) => !isEmailSchema.isValidSync(v));
                      }

                      return !firstInvalidEmail
                        ? true
                        : this.createError({
                            // message: `The email address '${firstInvalidEmail}' is invalid.`,
                            message: i18n.global.t(
                              "order-list.order-popup-validemail-BCC"
                            ),
                          });
                    },
                  })
              );
            } else return Yup.string().email().notRequired();
          }),
          recipient: Yup.string()
            .required(i18n.global.t("order-list.order-popup-required-recipient"))
            .test({
              name: "emails",
              test: function () {
                //console.log(value);
                var ele = document.getElementById("recipient");
                let firstInvalidEmail;
                if (ele != null) {
                  firstInvalidEmail = ele.value
                    .split(",")
                    .map((email) => email.trim())
                    .find((v) => !isEmailSchema.isValidSync(v));
                }

                return !firstInvalidEmail
                  ? true
                  : this.createError({
                      // message: `The email address '${firstInvalidEmail}' is invalid.`,
                      message: i18n.global.t("order-list.order-popup-validemail"),
                    });
              },
            }),
          cc: Yup.string()
            //.required(i18n.global.t("order-list.order-popup-required-recipient"))
            .test({
              name: "emails",
              test: function () {
                //console.log(value);
                var ele = document.getElementById("cc");

                let firstInvalidEmail;
                if (ele != null) {
                  firstInvalidEmail = ele.value
                    .split(",")
                    .map((email) => email.trim())
                    .find((v) => !isEmailSchema.isValidSync(v));
                }

                return !firstInvalidEmail
                  ? true
                  : this.createError({
                      message: i18n.global.t("order-list.order-popup-valid-CC-email"),
                    });
              },
            }),

          expired_time: Yup.number()
            .typeError(i18n.global.t("order-list.order-popup-validNumber"))
            .positive(i18n.global.t("order-list.order-popup-positive-number"))
            .min(1)
            .required(i18n.global.t("order-list.order-popup-requiredTime")),
        })
      );
    },
    setTime() {
      let seletboxData = [
        {
          id: 1,
          code: "s",
          text: i18n.global.t("order-list.order-popup-select-second"),
          value: "Second",
        },
        {
          id: 2,
          code: "m",
          text: i18n.global.t("order-list.order-popup-select-minute"),
          value: "Minute",
        },
        {
          id: 3,
          code: "h",
          text: i18n.global.t("order-list.order-popup-select-hour"),
          value: "Hour",
        },
        {
          id: 4,
          code: "d",
          text: i18n.global.t("order-list.order-popup-select-day"),
          value: "Day",
        },
      ];
      return seletboxData;
    },
  },
  methods: {
    onInvalidSubmit() {},
    async SendMail(formdata) {
      if (!("cc" in formdata)) {
        formdata["cc"] = "";
      }

      var loaderDisplayPosition = document.getElementById("new-board-modal");
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      //formdata["expired"] = document.getElementById("expired").value;
      if (formdata["sendme"] == true || formdata["sendme"] == "true") {
        formdata["sendme"] = "sendme";
      }
      if (formdata["sendme"] == "sendme") {
        let bccvalue = formdata["bcc"];
        formdata["bcc"] = bccvalue;
      } else {
        formdata["bcc"] = "";
      }

      formdata["format"] = document.getElementById("orderformat").value;
      formdata["language"] = document.getElementById("orderlang").value;

      let index = this.format.findIndex((x) => x.value === formdata["format"]);
      let language_id = this.locales.findIndex((x) => x.code === formdata["language"]);

      formdata["format"] = this.format[index].dbCode;
      formdata["language"] = this.locales[language_id].dbCode;

      let expiresIn = formdata["expired_time"] + document.getElementById("expired").value;
      delete formdata.expired_time;
      formdata["expiresIn"] = expiresIn;
      let getCurrentPort = window.location.port;
      let appendURL = "";
      let formdataurl = window.location.protocol + "//" + window.location.hostname;
      let ShareURL = "/share";

      if (getCurrentPort != "") {
        if (getCurrentPort != undefined) {
          appendURL = ":" + window.location.port;
        }
      }
      formdataurl = formdataurl + appendURL + ShareURL;
      formdata["url"] = formdataurl;
      const result = await axiosInterceptor.post(
        `api/v1/orders/${this.$parent.modalOrderId}/sendLink`,
        formdata
      );
      if (result.status == 201) {
        this.$parent.modalOrderId = 0;
        this.$parent.mailMessage = "successmail";
        document.getElementById("abortButton").click();
      } else {
        console.log("iiiiiii")
        this.$parent.mailMessage = "errormail";
        console.log("Error", result);
      }
      this.$parent.autoCloseSnackbar(2);

      loader.hide();
    },
  },
};
</script>
