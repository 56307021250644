import { io } from "socket.io-client";
let socket=io(`${process.env.VUE_APP_SOCKET_URL}`, {
  transports: ["websocket"],
  reconnection:true,
}); 

export default function createWebSocketPlugin(){
  return(store)=>{
    socket.on("msgToClient", async (response) => {
      console.log("action")
      store.dispatch("storeSocket",response);
    });
  }
}