<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div class="px-0 pt-4 pb-4 container custom-container position-relative">
        <div class="title-box-new">
          <div class="text-h4 text-white">
            {{ $t("create-template.create-template") }}
          </div>
          <div class="button-box d-flex gap-1">
            <router-link
              :to="{
                path: '/template/templateGrid',
              }"
              class="box"
            >
              <vmd-button
                class="shadow-none rounded-pill font-weight-normal btn-sm"
                color="primary"
                >{{ $t("create-template.template-listing") }}</vmd-button
              >
            </router-link>
          </div>
        </div>
        <div class="position-absolute inner_toaster"></div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-4 pb-6 px-0 container custom-container">
        <div class="row grid-box-container">
          <div class="col-12 createTemplateForm">
            <Form
              id="createTemplateForm"
              ref="form"
              :validation-schema="validationSchema"
              @submit="submitTemplate"
              @invalid-submit="onInvalid"
            >
              <div class="fileSelector">
                <div class="formContent">
                  <div class="mb-3 form-group">
                    <vmd-input-file
                      id="jsonFile"
                      v-model="jsonFile"
                      :label="$t('create-template.label-upload-json-file')"
                      type="file"
                      placeholder="Select Json file for the Order"
                      :buttontext="$t('create-template.button-text-file')"
                      :nofileselected="$t('create-template.no-file-selected')"
                      name="jsonFile"
                      accept="application/json"
                      @change="onFileChange($event)"
                    />
                    <vmd-button
                      v-if="Object.keys(fileData).length > 0"
                      id="resetbutton"
                      class="shadow-none rounded-pill font-weight-normal"
                      color="primary"
                      type="button"
                      @click="reset"
                      >{{ $t("create-template.label-reset-button") }}</vmd-button
                    >
                  </div>
                  <vmd-button
                    id="jsonsubmit"
                    class="shadow-none rounded-pill font-weight-normal mt-3"
                    color="primary"
                    type="submit"
                    >{{ $t("create-template.label-upload-button") }}</vmd-button
                  >
                </div>
                <div v-if="Object.keys(fileData).length > 0" class="previewPart">
                  <h3>Preview of uploaded file</h3>
                  <pre><code>{{ fileData }}</code></pre>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import VmdInputFile from "@/components/VmdInputFile.vue";
import VmdButton from "@/components/VmdButton.vue";
import i18n from "@/i18n";
import axiosInterceptor from "@/shared/axiosInterceptor";
import Swal from "sweetalert2";
import updateDimension from "../../Mixins/updateDimension";

export default {
  name: "CreateTemplate",
  components: {
    Form,
    VmdInputFile,
    VmdButton,
  },
  mixins: [updateDimension],
  data() {
    return {
      file: null,
      fileData: {},
      validFileExtensions: { json: ["json"] },
    };
  },
  computed: {
    updateOrderFormat: function () {
      this.$store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },
    validationSchema() {
      let FileExtensionslist = this.validFileExtensions;
      function isValidFileType(fileName, fileType) {
        return (
          fileName && FileExtensionslist[fileType].indexOf(fileName.split(".").pop()) > -1
        );
      }
      const Schema = Yup.object().shape({
        jsonFile: Yup.mixed()
          .test(
            "is-valid-type",
            i18n.global.t("create-template.error-valid-file"),
            (value) => isValidFileType(value && value.name.toLowerCase(), "json")
          )
          .required(i18n.global.t("create-template.error-file-required")),
      });
      return Schema;
    },
  },
  async mounted() {
    this.autoRefreshToken(7);
  },
  beforeUnmount() {
    clearInterval(this.setTimer);
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      // File is not present
      if (!files.length) return;
      let fileName = files[0].name;
      let fileType = "json";
      // Invalid File
      let fileValid =
        fileName &&
        this.validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1;
      if (!fileValid) {
        return;
      }
      this.readFile(files[0]);
    },
    readFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        e.target.result;
        JSON.parse(e.target.result);
        this.storedFileData(e.target.result);
      };
      reader.readAsText(file);
    },
    storedFileData(result) {
      this.fileData = JSON.parse(result);
    },
    async submitTemplate() {
      //  console.log(formdata);
      var loaderDisplayPosition = document.getElementById("new-board-modal");
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      let response = await axiosInterceptor.post(
        "api/v1/orders?isTemplate=true",
        this.fileData
      );

      loader.hide();
      if (response.status == 201) {
        Swal.fire({
          title: i18n.global.t("create-template.title-success"),
          text: i18n.global.t("create-template.message-success"),
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: i18n.global.t("create-template.confirm-button-text"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.replace("/template/templateGrid");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Some error occur",
        });
      }
    },
    async reset() {
      const file = document.querySelector("#jsonFile");
      file.value = "";
      this.fileData = {};
      this.$refs.form.resetForm();

      let labelFilename = document.querySelector("#nofileselected");
      labelFilename.innerHTML = i18n.global.t("create-template.label-upload-json-file");
    },
  },
};
</script>
