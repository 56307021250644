<template>
  <div class="vmd-input">
    <input
      :id="id"
      class="form-check-input border border-primary border-2"
      type="radio"
      :name="name"
      role="button"
      tabindex="0"
      :value="value"
      :checked="check"
      @click="onChange(name)"
      @input="onChange(name)"
    />
    <label class="custom-control-label m-0 ms-2 text-capitalize" :for="id">
      {{ label }}
      <slot />
    </label>
  </div>
</template>

<script>
import { toRefs } from "vue";
import { useField } from "vee-validate";
export default {
  name: "VmdRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    check: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { name } = toRefs(props);
    const { checked, handleChange } = useField(name, undefined, {
      type: "radio",
      checkedValue: props.value,
    });

    function onChange(getId) {
      let value = "";
      var choices = [];
      var els = document.getElementsByName(getId);
      for (var i = 0; i < els.length; i++) {
        if (els[i].checked) {
          value = els[i].value;
          choices.push(els[i].value);
        }
      }
      if (value == "") {
        value = "";
      }
      handleChange(value);
    }

    return {
      onChange,
      handleChange,
      // handleBlur,
      checked,
    };
  },
};
</script>
