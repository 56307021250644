<template>
  <div class="bg-white">
    <main class="mt-0 main-content auth-pages">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <router-link :to="{ name: 'Sitemap' }" class="auth-custom-logo">
                <i class="fas fa-arrow-up" aria-hidden="true"></i>
                <h4 class="text-h4">Upload Portal</h4>
              </router-link>
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto px-0 position-absolute top-0 start-0 text-center justify-content-center flex-column auth-image-section"
              >
                <div
                  class="position-relative h-100 px-7 d-flex flex-column justify-content-center bg-cover"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/illustrations/signup.png') + ')',
                  }"
                ></div>
              </div>
              <div
                class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto custom-auth-form"
              >
                <LocaleSwitcher />
                <div class="card card-plain">
                  <div class="pb-0 card-header bg-transparent mb-2">
                    <h4 class="font-weight-bolder">
                      {{ $t("pwd-forgot.forgot-password") }}
                    </h4>
                    <p class="mb-0">
                      {{ $t("pwd-forgot.Enter-your-username-for-Forget-Password") }}
                    </p>
                  </div>
                  <div class="card-body">
                    <Form
                      autocomplete="off"
                      :validation-schema="schema"
                      @submit="onSubmit"
                      @invalid-submit="onInvalidSubmit"
                    >
                      <div class="mb-3">
                        <vmd-input
                          id="username"
                          type="username"
                          :label="$t('pwd-forgot.username')"
                          name="username"
                        />
                      </div>
                      <div class="auth-link text-end">
                        <router-link
                          :to="{ name: 'Signin Illustration' }"
                          class="nav-link d-inline p-0 link-white text-decoration-underline"
                        >
                          {{ $t("pwd-forgot.Sign-in") }}</router-link
                        >
                      </div>
                      <div class="text-center">
                        <vmd-button
                          class="mt-4 shadow-none rounded-pill font-weight-normal submit-btn"
                          color="primary"
                          full-width
                          >{{ $t("pwd-forgot.btn-forget-password") }}
                        </vmd-button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import VmdInput from "@/components/VmdInput.vue";
import VmdButton from "@/components/VmdButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { Form } from "vee-validate";
import * as Yup from "yup";
import LocaleSwitcher from "../../LocaleSwitcher.vue";

import i18n from "@/i18n";
import { markRaw } from "vue";

export default {
  name: "ForgetPassword",
  components: {
    VmdInput,
    VmdButton,
    Form,
    LocaleSwitcher,
  },
  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    return {
      onSubmit,
    };
  },
  data() {},
  computed: {
    schema() {
      return markRaw(
        Yup.object().shape({
          username: Yup.string().required(
            i18n.global.t("login.please-enter-your-username")
          ),
        })
      );
    },
  },

  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    onInvalidSubmit() {
      const submitBtn = document.querySelector(".submit-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    },
  },
};
</script>
