import store from "@/store";
//import router1 from "@/router";
import axios from "axios";


export default {
  methods: {
    autoRefreshToken(type=0){
    
      console.log(type);
     let k=0;
      var v = this;
      v.setTimer=setInterval(async function () {
      v.commonRefreshToken();
      if(k==0){
        //authZaikio=false;
        //k++;
        //console.log(`router`,router1);
      }
      
      }, 10000);


    },
    async commonRefreshToken(){
      let auth = store.getters["auth/isTokenActive"];
      let authZaikio = store.getters["auth/isZaikioTokenActive"];
      let loginType=localStorage.getItem("loginType");
      let checkCall=localStorage.getItem("refreshcalltrigger");
      //console.log(`authZaikio`,authZaikio);
      //authZaikio=false;
      //console.log("in common",checkCall);
      //console.log("auth",auth);
      if (!auth && (checkCall==false || checkCall=='false')){
        //console.log("refresg call trigger")
        localStorage.setItem("refreshcalltrigger",true);
        //console.log(type);
        const authData = store.getters["auth/getAuthData"];
         //   console.log(`authData`,authData);
              if (authData.token) {
                var payload = {
                  access_token: authData.token,
                  auto_login:1,
                  refresh_token: authData.refreshToken,
                };
               
                let tokenURL='api/auth/refresh';
                if( localStorage.getItem("user_type") =='Guest'){
                  let sessionId=authData.session;
                  tokenURL=`api/v1/orders/verifySession?session=${sessionId}`;
                  payload={};
                  
                }
                
                if(localStorage.getItem("loginType")==2 && localStorage.getItem("user_type") =='User'){
                  tokenURL='api/auth/refresh?called_from=kc_token';
                }
                await axios.post(tokenURL, payload).then(function (response) {
                  response.data.userType=localStorage.getItem("user_type");
                  response.data.loginType=localStorage.getItem("loginType");
                  if(authData.userType=='Guest'){
                    response.data.session=localStorage.getItem("session");
                    response.data.access_token=response.data.data.clientAccount;
                  }
                  //console.log(`response.data`,response.data)
                  store.commit("auth/saveTokenData", response.data);
                  auth = true;
                  localStorage.setItem("refreshcalltrigger",false);
                }).catch(function (error) {
                  console.log(error);
                  store.dispatch("auth/signout")
                  return '/login';
                });
              }
      }
     
      if(!authZaikio){
        if(loginType == 2 && localStorage.getItem("user_type") =='User'){
          console.log("Zaikio");
          let refreshURL='api/auth/refresh?called_from=idp_token';
          const ZaikioAuthData = store.getters["auth/getZaikioAuthData"];
          let zaikio_refresh_token=localStorage.getItem("zaikio_refresh_token")
          let refreshPayload={
           access_token: ZaikioAuthData.token,
           auto_login:1,
           refresh_token: zaikio_refresh_token,
          };
         
          await axios.post(refreshURL, refreshPayload).then(function (response) {
            response.data.userType=localStorage.getItem("user_type");
             response.data.loginType=localStorage.getItem("loginType");
             //console.log(`response data Zaiki`,response.data);
             authZaikio=true;
             store.commit("auth/SaveZaikioTokenData", response.data);
             //console.log('after commit',store.getters["auth/getZaikioAuthData"]);
         }).catch(function (error) {
           console.log(error);

         //  store.commit("auth/RemoveZaikioTokenData");
           //router1.replace("/login");
           //return '/login';
         });
      }
    }
    }
  }
};