import axiosInterceptor from "@/shared/axiosInterceptor";


export default {
  methods: {
      async decryptOrderId(encodedId){
        try {
          let temp = decodeURIComponent(encodedId);
          var bytes = this.$CryptoJS.AES.decrypt(temp, process.env.VUE_APP_ENCRYPTION_KEY);
          var decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
          //console.log(`decryption`,decryptedData.id);
          return decryptedData.id;
        } catch (error) {
          //console.log("in errpr");
          return false;
          //this.$router.replace("/orders/list");
          
        }
      },
      async InvalidOrderId(){ 
        this.$router.replace("/orders/list");
      },
      async orderDetail(type){
            //type=1 Uploder
            //type=2 Preview page
            //type=3 Preview page prevent extra API
            if(this.$route.params.OrderId==undefined || this.$route.params.OrderId==null){
              return;
            }
            let joinResource='';
            if(type == 1){
              joinResource="&join=resources";
            }
            var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
            let validateOrderId=this.$route.params.OrderId;
            if (format.test(validateOrderId)) {
              validateOrderId=await this.decryptOrderId(validateOrderId)
            }

            let orderHeader = {
              orderId: validateOrderId,
            };
            
            const result = await axiosInterceptor.get(
              `api/v1/orders/${validateOrderId}?join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants&join=customDataFields${joinResource}`,orderHeader
            );


            
            //console.log(`orderDetail`,result)
            this.radixContainerId = result.data.radixContainerId;
            this.productType = result.data.products[0].type;
            let spreadType =
              result.data.products[0].parts[0].intent.layoutIntent.spreadType;
            // let dynamicva='finishedDimensions';
            this.orderFormatheight =
              result.data.products[0].parts[0].intent.layoutIntent.finishedDimensions.height;
            this.orderFormatwidth =
              result.data.products[0].parts[0].intent.layoutIntent.finishedDimensions.width;
            if (spreadType == "Spread") {
              this.orderFormatheight =
                result.data.products[0].parts[0].intent.layoutIntent.dimensions.height;
              this.orderFormatwidth =
                result.data.products[0].parts[0].intent.layoutIntent.dimensions.width;
            }
            
            if(type==1){
              if(result.data.resources.length > 0){
                this.resourceAvailable=true;
                
              }
              else{
                this.resourceAvailable=false;
              }
            }
            this.totalVariant =
            result.data.products[0].parts[0].pageranges[0].variants.length;
            this.orderFormatheight =
              result.data.products[0].parts[0].intent.layoutIntent.dimensions.height;
      
            this.orderFormatwidth =
              result.data.products[0].parts[0].intent.layoutIntent.dimensions.width;
      
            this.orderPages = result.data.products[0].parts[0].intent.layoutIntent.pages;
            this.orderSides = result.data.products[0].parts[0].intent.layoutIntent.sides;
            this.orderColourfullnessFront =
              result.data.products[0].parts[0].intent.colorIntent.surfaceColor.front.colorsUsed.length;
            this.orderColourfullnessBack =
              result.data.products[0].parts[0].intent.colorIntent.surfaceColor.back.colorsUsed.length;
              this.apiResponse = result.data.products.slice(0, 1);
              this.variantId =result.data.products[0].parts[0].pageranges[0].variants[0].id;
             
            // Prevent to trigger multiple time from preview page  
            if(type!=3){
              const containerInfo = await axiosInterceptor.get(
                `api/v1/containers/${this.radixContainerId}`
              );
              this.uuid = containerInfo.data.uuid;
            }
            
            
            this.orderStatus=result.data.status;
            //console.log(`orderStatus`,this.orderStatus)
            this.orderMilestone=result.data.milestone;
            
              
            
            
              if(type == 2 ){
              /*console.log(`preDefinedMultipart`,this.preDefinedMultipart)
              console.log(`productType`,this.productType)*/
              this.allProductData=result.data.products;
              if(this.preDefinedMultipart.includes(this.productType)){
                
              let ProductData=result.data.products[0].parts;
          
              let coverFound=ProductData.find(o => o.type === 'Cover');
                if(coverFound){
                this.coverPage=coverFound.intent.layoutIntent.pages;
                //console.log(`check`,result.data.products);
                if (Object.prototype.hasOwnProperty.call(result.data.products[0].parts[0].intent, "bindingIntent")) {
                  let getBinding=result.data.products[0].intent.bindingIntent;
                  this.bindingType=getBinding.bindingSide;
                }

                
                this.coverVariant=coverFound.pageranges[0].variants[0].id;
                // console.log(`this.coverVariant`,this.coverVariant)
                }
                else{
                // Cover is not found
                //console.log(`ProductData[0].intent.bindingIntent`,ProductData[0].intent)
              if (Object.prototype.hasOwnProperty.call(ProductData[0].intent, "bindingIntent")) {
               //if(ProductData[0].intent.bindingIntent.bindingSide){
                  this.bindingType=ProductData[0].intent.bindingIntent.bindingSide;
                }  
                }
                let BodyFound=ProductData.find(o => o.type === 'Body');
                if(BodyFound){
                this.bodyPage=BodyFound.intent.layoutIntent.pages;
                if(this.orderSides == 'OneSided'){
                  this.bodyPage=parseInt(this.bodyPage/2);
                }
                this.bodyVariant=BodyFound.pageranges[0].variants[0].id;
                // console.log(`this.bodyVariant`,this.bodyVariant)
                //this.bindingType="Left";
                }
              
              }
              else{
                this.bodyVariant=result.data.products[0].parts[0].pageranges[0].variants[0].id
                //console.log(`not multi`,this.bodyVariant)
              }
            
              
              this.cdf = result.data.customDataFields;
              //console.log(`this.cdf`,this.cdf);
              //Sort by type
              this.cdf.sort( function(a, b) {
                const nameA = a.type; // to avoid case while sort
                const nameB = b.type;
                if(nameA > nameB)
                    return 1;
                else if(nameB > nameA)
                    return -1;
                else
                    return 0;     
            })
            
          
            if(this.cdf.length > 0){
              var order = ['order','product','part'];   
                var orderDict =  order.reduce((acc, item, index) => {
                acc[item] = index;
                return acc;
                }, {});

              this.cdf.sort((a, b) => orderDict[a.type] - orderDict[b.type]);
            }
            let defaulEditData={};
            defaulEditData['type']='string';

            for (let i = 0; i < this.cdf.length; i++) {
              //console.log("checkkey",this.cdf[i]);

              if("editData" in this.cdf[i]){
                if(this.cdf[i].editData!=null){
                  // console.log("not null");
                  if("enum" in this.cdf[i].editData){
                    //console.log("yes enum")
                  }
                  else{
                    //sconsole.log("no enum",this.cdf[i].id)
                    this.cdf[i].editData['enum']=[];
                  }
                  if(this.cdf[i].editData['type'] == 'date'){
                    if(this.cdf[i].value!=''){
                      //console.log("llll");
                      //this.cdf[i].value=new Date(this.cdf[i].value);
                      /*let datetmp=new Date(this.cdf[i].value);
                      const day = datetmp.getDate();
                      const month = datetmp.getMonth() + 1;
                      const year = datetmp.getFullYear();
                      this.cdf[i].value=`${year}-${month}-${day}`;*/
                      //console.log(this.cdf[i].value);
                    }
                  }
                }
                else{
                  
                  this.cdf[i].editData={};
                  this.cdf[i].editData['type']='string';
                  this.cdf[i].editData['enum']=[];
                  this.cdf[i].editData['custom']=1;
                }
              }
              
              if(this.cdf[i].type== 'order' && this.cdfOrderId == 0 && this.cdf[i].hidden == false){
                  this.cdfOrderId=this.cdf[i].id
              }
              if(this.cdf[i].type== 'product' && this.cdfProductId == 0 && this.cdf[i].hidden == false ){
                  this.cdfProductId=this.cdf[i].id
              }
              if(this.cdf[i].type== 'part' && this.cdfPartId == 0 && this.cdf[i].hidden == false ){
                  this.cdfPartId=this.cdf[i].id
              }
            }
            // console.log(`order`,this.cdf);
            
              
              this.splitPDFData.push({
                  key: "orderId",
                  value: this.$route.params.OrderId,
                });
          
                this.splitPDFData.push({
                  key: "variantId",
                  value: this.apiResponse[0].parts[0].pageranges[0].variants[0].id,
                });
              } 
              
      },
      
    }
}