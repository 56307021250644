<template>
  <div v-for="(msg, index) in extraNotification" :key="index">
    <vmd-snackbar
      v-if="msg.status && msg.status === 'show'"
      :description="msg.translate == true ? $t(msg.message) : msg.message"
      :icon="{ color: 'white' }"
      :color="msg.icon"
      @click="closeNotification(index)"
    />
  </div>
</template>
<script>
import VmdSnackbar from "@/components/VmdSnackbar.vue";
export default {
  name: "ExtraNotification",
  components: {
    VmdSnackbar,
  },
  expose: ["childNotification"],
  data() {
    return {
      extraNotification: [],
      extraNotificationId: 0,
    };
  },
  methods: {
    closeNotification(index) {
      this.extraNotification.splice(index, 1);
    },
    childNotification(res) {
      // console.log("in child", type);
      this.extraNotificationId = this.extraNotificationId + 1;
      this.extraNotification.push({
        type: res.type,
        status: res.status,
        translate: res.translate,
        message: res.message,
        Id: this.extraNotificationId,
      });
      //console.log(`extraNotification`, this.extraNotification);
      this.NotificationTimeOutArray(this.extraNotificationId);
    },
    NotificationTimeOutArray(value) {
      setTimeout(() => {
        const deleteMsg = this.extraNotification.findIndex((object) => {
          return parseInt(object.id) == parseInt(value);
        });
        this.extraNotification.splice(deleteMsg, 1);
      }, 5000);
    },
  },
};
</script>
