<template>
  <div class="OrderComponent">
    <div class="page-title-section">
      <div class="px-0 pt-4 pb-4 container custom-container position-relative">
        <div class="text-h4 text-white">New Order</div>
        <router-link
          :to="{
            path: '/template/templateGrid',
          }"
          class="box"
        >
          <vmd-button
            class="shadow-none rounded-pill font-weight-normal btn-sm"
            color="primary"
            >Template Listing</vmd-button
          >
        </router-link>
        <div class="position-absolute inner_toaster"></div>
      </div>
    </div>

    <div class="page-custom-section">
      <div class="pt-4 pb-6 px-0 container custom-container">
        <div class="row grid-box-container">
          <div class="col-12 newOrderForm">
            <Form
              v-if="loadAllData == true"
              ref="form1"
              :validation-schema="validationSchema"
              :initial-values="initialValues"
              class="accordion"
              @submit="submitOrder"
              @invalid-submit="onInvalid"
            >
              <div class="expandCover ms-auto me-0">
                <button
                  id="expandBtn"
                  type="button"
                  class="btn m-0 bg-primary rounded-0 expandBtn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-original-title="Collapse All"
                >
                  <span id="collapseAll" class="collapseAll iconBox">
                    <i class="fas fa-chevron-down"></i>
                    <i class="fas fa-chevron-up"></i>
                  </span>
                  <span id="ExpandAll" class="ExpandAll iconBox d-none">
                    <i class="fas fa-chevron-up"></i>
                    <i class="fas fa-chevron-down"></i>
                  </span>
                </button>
              </div>
              <div
                v-for="(labelInfo, i) in labelArray"
                :key="labelInfo.name"
                class="mainCoverLabel w-100 accordion-item"
              >
                <div :id="'title' + i" class="formMainTitleLabel accordion-header">
                  <button
                    type="button"
                    class="accordion-button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#fieldcontent' + i"
                    aria-expanded="true"
                    :aria-controls="'accordion' + i"
                  >
                    {{ labelInfo.labelName
                    }}<i
                      class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                <div
                  v-if="labelInfo.children"
                  :id="'fieldcontent' + i"
                  :aria-labelledby="'title' + i"
                  class="formAllFieldCover accordion-collapse collapse show"
                >
                  <div
                    class="innerSection"
                    :class="{
                      contactSection: labelInfo.labelName == 'Job Contacts',
                    }"
                  >
                    <div
                      v-for="mainorder in labelInfo.children"
                      :key="mainorder.key"
                      class="formField"
                      :class="{
                        className: mainorder.class != null,
                        hasSubTile:
                          mainorder.subLabel != null && mainorder.subLabel.length > 0,
                        multiSelectField: mainorder.fieldType == 'multiselectbox',
                        newline:
                          mainorder.class == 'newline' && mainorder.subLabel != null,
                      }"
                    >
                      <div
                        v-if="mainorder.subLabel != null && mainorder.subLabel.length > 0"
                        class="formSubTitleLabel"
                      >
                        <h4 class="text-capitalize">
                          {{ mainorder.subLabel }}
                        </h4>
                      </div>
                      <div
                        class="single-info"
                        :class="{
                          hideSelectbox: mainorder.class == 'hideSelectbox',
                        }"
                      >
                        <label
                          class="form-label"
                          :class="mainorder.fieldType == 'radio' ? 'radiolabel' : ''"
                          >{{ mainorder.displayKey }}

                          <span v-if="mainorder.required == true" class="text-danger"
                            >*</span
                          >
                        </label>
                        <div v-if="mainorder.fieldType == 'text'">
                          <vmd-input
                            :id="mainorder.name"
                            value="mainorder.value"
                            type="text"
                            :name="mainorder.name"
                          />
                        </div>
                        <div v-if="mainorder.fieldType == 'selectbox'">
                          {{ mainorder.class }}
                          <vmd-select
                            :id="mainorder.name"
                            v-model="mainorder.value"
                            :value="mainorder.value"
                            :name="mainorder.name"
                            :items="mainorder.selectboxArray"
                            class="select"
                          />
                        </div>
                        <div v-if="mainorder.fieldType == 'multiselectbox'">
                          <VueMultiselect
                            :id="`${mainorder.id}`"
                            v-model="mainorder.value"
                            :value="mainorder.value"
                            :options="PredefinedColor"
                            :multiple="true"
                            :taggable="true"
                            :name="`${mainorder.name}`"
                          >
                          </VueMultiselect>
                        </div>

                        <div v-if="mainorder.fieldType == 'radio'" class="radioBox mb-4">
                          <span v-if="mainorder.displayKey == 'gender'">
                            <div class="d-flex">
                              <vmd-radio
                                v-for="(genderArray, index) in PedefinedGenderArray"
                                :id="genderArray"
                                :key="index"
                                :check="genderArray == mainorder.value"
                                :name="mainorder.name"
                                :value="genderArray"
                                :label="genderArray"
                                class="me-4"
                              ></vmd-radio>
                            </div>
                            <ErrorMessage
                              :name="mainorder.name"
                              class="error-message text-sm fw-light text-danger"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div v-if="mainorder.fieldType == 'select'" class="radioBox">iiiii</div>-->
              <vmd-button
                id="CDFSubmitButton"
                class="shadow-none rounded-pill font-weight-normal mt-3"
                :class="{
                  'd-none': loadAllData == false,
                }"
                color="primary"
                type="submit"
                >Create</vmd-button
              >
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { createYupSchema } from "../../Mixins/createYupSchema";
import updateDimension from "../../Mixins/updateDimension";
import orderInfomration from "../../Mixins/orderInfomration";
import format from "../../Mixins/format";
import VmdInput from "@/components/VmdInput.vue";
import VmdRadio from "@/components/VmdRadio.vue";
import VmdSelect from "@/components/VmdSelect.vue";
import VmdButton from "@/components/VmdButton.vue";
import VueMultiselect from "@/components/VmdMultiselect.vue";
//import VueMultiselect from "vue-multiselect";
import axiosInterceptor from "@/shared/axiosInterceptor";
import Swal from "sweetalert2";
import ordersPredefinedData from "./predefinedata";
import setTooltip from "@/assets/js/tooltip.js";
export default {
  name: "Neworder",
  components: {
    Form,
    ErrorMessage,
    VmdInput,
    VmdRadio,
    VmdButton,
    VmdSelect,
    VueMultiselect,
  },
  mixins: [updateDimension, orderInfomration, format],
  data() {
    return {
      setTimer: null,
      OrderType: 0,
      orderId: null,
      loadAllData: false,
      orderJson: {},
      productData: [],
      customer: {},
      contacts: [],
      mainArray: [],
      labelArray: [],
      default_mm_value: process.env.VUE_APP_MM_VALUE,
      default_inch_value: process.env.VUE_APP_INCH_VALUE,
      PedefinedGenderArray: ordersPredefinedData.PedefinedGender,
      PedefinedjobContactsRoles: ordersPredefinedData.PedefinedjobContactsRoles,
      PredefinedSides: ordersPredefinedData.PredefinedSides,
      PredefinedspreadType: ordersPredefinedData.PredefinedspreadType,
      PredefinedOrienation: ordersPredefinedData.PredefinedOrienation,
      PredefinedPrintStandard: ordersPredefinedData.PredefinedPrintStandard,
      GenderDefaultValue: null,
      FormatDefaultValue: "POINT",
      PredefinedColor: ordersPredefinedData.PredefinedColor,
      predefineMainOrderdelete: ordersPredefinedData.predefineMainOrderdelete,
      predefinedProductdelete: ordersPredefinedData.predefinedProductdelete,
      predefinedPartsDelete: ordersPredefinedData.predefinedPartsDelete,
      jobCustomerData: [],
      orderInfomationData: [],
      layoutIntentData: [],
      colorIntentData: [],
      testData: 100,
      storedId: [],
      prevDim: null,
      //specialChars2: new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/),
      //specialChars2: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~ ]/,
    };
  },
  computed: {
    dimensionFormat() {
      let currentDimension = this.$store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    /*updateOrderFormat: function () {
      let dimensionFormat = this.$store.getters["auth/getDimensionFormat"];
      console.log(dimensionFormat);
      return (id) => this.updateDimensionTest(id);
    },*/
    computedValue() {
      var dimensionFormat = this.$store.getters["auth/getDimensionFormat"];
      console.log(dimensionFormat);
      if (this.$refs.form1) {
        return (dimensionFormat) => this.updateDimension(dimensionFormat);
      } else {
        return "1";
      }
    },
    initialValues() {
      var obj = {};
      for (let k = 0; k < this.mainArray.length; k++) {
        let key = this.mainArray[k].key;
        let value = this.mainArray[k].value;
        // Required to change the value when set the value
        if (Object.prototype.hasOwnProperty.call(this.mainArray[k], "updateFormat")) {
          if (this.mainArray[k].updateFormat == true) {
            value = this.updateDimension(value);
          }
        }

        obj[key] = value;
      }

      //this.updateDimensionTest("dd");
      return obj;
    },
    validationSchema() {
      const fields = [];
      Yup.addMethod(Yup.number, "validatingPattern", function () {
        return this.test(
          "is-between-1-and-10",
          "Value MUST be a number between 1 and 10",
          (value) => (value + "").match(/^\d*\.{1}\d*$/)
        );
      });
      let maxStringCharcter = 100;
      let maxValueMessage;
      let maxInteger = 4;
      let maxFloat = 15;

      for (let k = 0; k < this.mainArray.length; k++) {
        let fieldType = this.mainArray[k].fieldType;
        let ValidationType = this.mainArray[k].validationType;
        let requiredStatus = this.mainArray[k].required;
        let displayKey = this.mainArray[k].displayKey;
        let validationsArray = [];

        // Set maxiumn character validation for string
        if (ValidationType == "string" && fieldType == "text") {
          maxValueMessage = `Please enter ${maxStringCharcter} characters or fewer`;
          validationsArray.push({
            type: "max",
            params: [maxStringCharcter, maxValueMessage],
          });
        }

        if (fieldType == "radio") {
          validationsArray.push({
            type: "oneOf",
            params: [this.PedefinedGenderArray, `Please select the ${displayKey}`],
          });
        }

        //Email Validation
        if (displayKey == "email") {
          validationsArray.push({
            type: "email",
            params: ["Please enter valid email address"],
          });
        }
        //let fisrt= /[0+]\d+/
        // ^[0+][0-9 \- ]+$
        //Allow only + or 0 in first character then allow digits,/- and space
        //phone
        if (displayKey == "phone") {
          validationsArray.push({
            type: "matches",
            params: [/^[0+][0-9-/ ]+$/, "Please enter valid mobile number"],
          });
        }

        //ValidationType = "string";
        if (ValidationType == "integer") {
          validationsArray.push({
            type: "typeError",
            params: ["Please enter valid number"],
          });

          validationsArray.push({
            type: "integer",
            params: ["Please enter integer number"],
          });

          // max limit for charcter of number
          maxValueMessage = `Please enter ${maxInteger} characters or fewer`;
          validationsArray.push({
            type: "test",
            params: [
              "maxLenght",
              maxValueMessage,
              (val) => !isNaN(val) && `${val}`.length <= maxInteger,
            ],
          });

          ValidationType = "number";
        } else if (ValidationType == "number") {
          validationsArray.push({
            type: "typeError",
            params: ["Please enter valid number"],
          });

          validationsArray.push({
            type: "number",
            params: ["Please enter number"],
          });

          // max limit for charcter of number
          maxValueMessage = `Please enter ${maxFloat} characters or fewer`;
          validationsArray.push({
            type: "test",
            params: [
              "maxLenght",
              maxValueMessage,
              (val) => !isNaN(val) && `${val}`.length <= maxFloat,
            ],
          });
        } else if (ValidationType == "array") {
          validationsArray.push({
            type: "nullable",
            params: [true],
          });

          validationsArray.push({
            type: "array",
            params: ["Please select value"],
          });

          let allowCharacter = /[^a-zA-Z0-9]/;
          validationsArray.push({
            type: "test",
            params: [
              "allowCharacter",
              "Only string and numbers are allowed",
              (val) =>
                Array.isArray(val) && !val.find((val1) => allowCharacter.test(val1)),
            ],
          });

          /*validationsArray.push({
            type: "matches",
            params: [/^[A-Za-z]*$/, "Please enter valid"],
          });*/

          if (requiredStatus == true) {
            validationsArray.push({
              type: "min",
              params: [1, "Please select atlest one option"],
            });
          }
          //ValidationType = "string";
        }

        if (requiredStatus == true) {
          validationsArray.push({
            type: "required",
            params: [displayKey + " is required"],
          });
        }

        fields.push({
          id: this.mainArray[k].id,
          name: this.mainArray[k].id,
          type: fieldType, //text,radio
          label: displayKey,
          // value: this.cdf[i].value,
          placeholder: "Enter " + displayKey,
          validationType: ValidationType,
          validations: validationsArray,
        });
      }
      const yepSchema = fields.reduce(createYupSchema, {});
      const validateSchema = Yup.object().shape(yepSchema);
      //console.log(fields);
      return validateSchema;
    },
  },
  watch: {
    // Watch for changes in the computedValue and update cdata1.value accordingly
    computedValue(newValue) {
      this.updateDimensionTest(newValue);
    },
  },
  async mounted() {
    this.autoRefreshToken(6);
    this.orderId = await this.decryptOrderId(this.$route.params.templateId);
    this.GenderDefaultValue = this.PedefinedGenderArray[0];
    await this.TypeInformation();
    setTooltip(this.$store.state.bootstrap);

    // Expand All Collapse All
    let expandBtn = document.getElementById("expandBtn"),
      collapseAll = document.getElementById("collapseAll"),
      expandAll = document.getElementById("ExpandAll"),
      tooltips = document.getElementsByClassName("tooltip"),
      accordionButton = document.getElementsByClassName("accordion-button");
    if (accordionButton) {
      [...accordionButton].forEach(function (d) {
        d.addEventListener("click", function () {
          const accordionButtonCollapsed = [...accordionButton].filter((but) =>
            but.classList.contains("collapsed")
          );
          if (accordionButton.length == accordionButtonCollapsed.length) {
            expandBtn.classList.add("allCollapsed");
            expandBtn.setAttribute("data-bs-original-title", "Expand All");
            expandAll.classList.remove("d-none");
            collapseAll.classList.add("d-none");
          } else {
            return;
          }
        });
      });
    }
    if (expandBtn) {
      expandBtn.addEventListener("click", function () {
        [...tooltips].forEach(function (e) {
          e.classList.remove("show");
        });
        const notClosedBtns = [...accordionButton].filter(
          (but) => !but.classList.contains("collapsed")
        );
        const closedBtns = [...accordionButton].filter((but) =>
          but.classList.contains("collapsed")
        );
        if (expandBtn.classList.contains("allCollapsed")) {
          closedBtns.forEach(function (a) {
            a.click();
          });
          expandBtn.classList.remove("allCollapsed");
          expandBtn.setAttribute("data-bs-original-title", "Collapse All");
          expandAll.classList.add("d-none");
          collapseAll.classList.remove("d-none");
        } else {
          notClosedBtns.forEach(function (a) {
            a.click();
          });
          expandBtn.classList.add("allCollapsed");
          expandBtn.setAttribute("data-bs-original-title", "Expand All");
          expandAll.classList.remove("d-none");
          collapseAll.classList.add("d-none");
        }
      });
    }
    this.prevDim = localStorage.getItem("format");
  },
  beforeUnmount() {
    clearInterval(this.setTimer);
  },
  methods: {
    updateDimensionTest(value) {
      value = localStorage.getItem("format");
      let resetFormData = {};
      if (this.loadAllData && this.$refs.form1) {
        for (let k = 0; k < this.storedId.length; k++) {
          let getValue = document.getElementById(this.storedId[k]).value;

          // Convert to point
          if (this.prevDim == "MM" && this.prevDim != value) {
            getValue = getValue * this.default_mm_value;
          } else if (this.prevDim == "INCH" && this.prevDim != value) {
            getValue = getValue * this.default_inch_value;
          }

          if (value == "MM") {
            getValue = getValue / this.default_mm_value;
            getValue = getValue.toFixed(1);
          } else if (value == "INCH") {
            getValue = getValue / this.default_inch_value;
            getValue = getValue.toFixed(3);
          } else {
            getValue = getValue.toFixed(2);
          }
          resetFormData[this.storedId[k]] = getValue;
        }
        this.$refs.form1.resetForm({ values: resetFormData });

        this.prevDim = value;

        //this.$refs.form1.resetForm({ values: { order_name: "dd123456" } });
      }
    },
    convertValue(type, value) {
      if (type == "MM") {
        value = value * this.default_mm_value;
      } else if (type == "INCH") {
        value = value * this.default_inch_value;
      }
      return value;
    },
    ShowErrorMessage(errorTitle, errorMessage) {
      Swal.fire({
        title: errorTitle,
        text: errorMessage,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Order Listing",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$router.replace("/orders/list");
        }
      });
    },
    async TypeInformation() {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      let lastIndexLabel;
      let validateOrderId = this.orderId;
      let orderHeader = {
        orderId: validateOrderId,
      };

      const result = await axiosInterceptor.get(
        `api/v1/orders/${validateOrderId}?join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants&join=customDataFields`,
        orderHeader
      );
      this.orderJson = result.data;
      if (!Object.prototype.hasOwnProperty.call(this.orderJson, "products")) {
        this.ShowErrorMessage("Invalid Json", "Product Key is missing from the json");
      }

      for (let k = 0; k < this.predefineMainOrderdelete.length; k++) {
        delete this.orderJson[this.predefineMainOrderdelete[k]];
      }
      // delete customDataFields
      delete this.orderJson["customDataFields"];

      //delete Product
      for (let k = 0; k < this.predefinedProductdelete.length; k++) {
        delete this.orderJson["products"][0][this.predefinedProductdelete[k]];
      }

      //predefinedPartsDelete
      for (let p = 0; p < this.orderJson["products"][0].parts.length; p++) {
        //let parts = mainOrderJson["products"][0].parts[p];
        for (let k = 0; k < this.predefinedPartsDelete.length; k++) {
          delete this.orderJson["products"][0].parts[p][this.predefinedPartsDelete[k]];
        }
      }

      this.productData = this.orderJson["products"];

      // Access order Information
      this.labelArray.push({
        labelName: "Order Information",
        children: [],
      });
      lastIndexLabel = this.labelArray.length - 1;
      var orderKeys = Object.keys(this.orderJson);
      if (Object.keys(orderKeys).length > 0) {
        Object.entries(this.orderJson).forEach(([orderkey, ordervalue]) => {
          //console.log(orderkey, ordervalue, orderindex);
          if (typeof ordervalue == "string") {
            let dynamicKey = `order_${orderkey}`;
            this.mainArray.push({
              fieldType: "text",
              validationType: "string",
              key: dynamicKey,
              value: ordervalue,
              name: dynamicKey,
              id: dynamicKey,
              displayKey: orderkey,
              Mainlabel: "Order Information",
              required: true,
              selectboxArray: [],
              subLabel: null,
              class: null,
            });
            this.labelArray[lastIndexLabel].children.push(
              this.mainArray[this.mainArray.length - 1]
            );
          }
        });
      }

      let type = "text";
      let subLabel = null;
      let mainLabel = null;
      let validationType = "string";
      let keydynamic;
      let select = [];
      let className;

      // Access Product Information
      var productKeyslist = Object.keys(this.productData[0]);
      if (Object.keys(productKeyslist).length > 0) {
        Object.entries(this.productData[0]).forEach(([key, value], index) => {
          if (index == 0) {
            mainLabel = "Product Information";
            this.labelArray.push({
              labelName: mainLabel,
              children: [],
            });
            lastIndexLabel = this.labelArray.length - 1;
          }
          let valueType = Array.isArray(value);
          if (valueType == false && key != "name" && key != "type") {
            type = "text";
            validationType = "string";
            if (key == "amount") {
              validationType = "number";
            }
            keydynamic = "product_" + key;
            select = [];
            subLabel = "";
            className = "";
            this.mainArray.push({
              fieldType: type,
              validationType: validationType,
              key: keydynamic,
              value: value,
              name: keydynamic,
              id: keydynamic,
              displayKey: key,
              Mainlabel: mainLabel,
              required: true,
              selectboxArray: select,
              subLabel: subLabel,
              class: className,
            });

            this.labelArray[lastIndexLabel].children.push(
              this.mainArray[this.mainArray.length - 1]
            );
          }
        });
      }

      // Access layout & color Intent data
      if (!Object.prototype.hasOwnProperty.call(this.orderJson["products"][0], "parts")) {
        this.ShowErrorMessage("Invalid Json", "Parts Key is missing from the json");
      }
      let allParts = this.orderJson["products"][0]["parts"];
      for (let p = 0; p < allParts.length; p++) {
        // Access layout Intent
        if (!Object.prototype.hasOwnProperty.call(allParts[p], "intent")) {
          this.ShowErrorMessage("Invalid Json", "Intent Key is missing from the json");
        }

        let intent = allParts[p].intent;

        if (!Object.prototype.hasOwnProperty.call(intent, "layoutIntent")) {
          this.ShowErrorMessage(
            "Invalid Json",
            "Layout intent Key is missing from the json"
          );
        }
        if (!Object.prototype.hasOwnProperty.call(intent, "colorIntent")) {
          this.ShowErrorMessage(
            "Invalid Json",
            "Color intent Key is missing from the json"
          );
        }
        let layoutIntent = intent.layoutIntent;
        let colorIntent = intent.colorIntent;
        let parttsType = allParts[p].type;

        var layoutKeys = Object.keys(layoutIntent);
        if (Object.keys(layoutKeys).length > 0) {
          Object.entries(layoutIntent).forEach(([key, value], index) => {
            select = [];
            keydynamic = `${key}_${p}_${parttsType}`;
            type = "text";
            subLabel = null;
            mainLabel = null;
            validationType = "string";
            if (key == "pages") {
              validationType = "integer";
            } else if (key == "bleed") {
              validationType = "number";
            }
            if (index == 0 && (p == 0 || p == 1)) {
              mainLabel = `Layout Intent - ${parttsType}`;
              this.labelArray.push({
                labelName: mainLabel,
                children: [],
              });
              lastIndexLabel = this.labelArray.length - 1;
            }

            // Sides and spereadtype
            if (key == "sides" || key == "spreadType" || key == "orientation") {
              type = "selectbox";
              if (key == "sides") {
                select = this.PredefinedSides;
              } else if (key == "spreadType") {
                select = this.PredefinedspreadType;
              } else if (key == "orientation") {
                select = this.PredefinedOrienation;
              }
            }

            // Dimension,finishedDimensions,numberUp
            if (key == "dimensions" || key == "finishedDimensions" || key == "numberUp") {
              if (typeof value === "object") {
                var dimensionsKey = Object.keys(value);
                if (Object.keys(dimensionsKey).length > 0) {
                  Object.entries(value).forEach(([dkey, dvalue], index1) => {
                    subLabel = null;
                    if (index1 == 0) {
                      subLabel = key;
                    }
                    type = "text";
                    validationType = "number";
                    keydynamic = `${key}_${dkey}_${p}_${parttsType}`;
                    className = null;
                    let updateFormat = null;
                    if (key == "dimensions" || key == "finishedDimensions") {
                      className = "sided-field";
                      updateFormat = true;
                    }

                    this.mainArray.push({
                      fieldType: type,
                      validationType: validationType,
                      key: keydynamic,
                      value: dvalue,
                      name: keydynamic,
                      id: keydynamic,
                      displayKey: dkey,
                      Mainlabel: mainLabel,
                      required: true,
                      selectboxArray: select,
                      subLabel: subLabel,
                      class: className,
                      updateFormat: updateFormat,
                    });
                    this.storedId.push(keydynamic);

                    this.labelArray[lastIndexLabel].children.push(
                      this.mainArray[this.mainArray.length - 1]
                    );

                    // Add Seletcbox
                    /*if (key == "dimensions" || key == "finishedDimensions") {
                      this.mainArray.push({
                        fieldType: "selectbox",
                        validationType: "string",
                        key: `${key}_${dkey}_${p}_select`,
                        value: this.FormatDefaultValue,
                        name: `${key}_${dkey}_${p}_select`,
                        id: `${key}_${dkey}_${p}_select`,
                        displayKey: `${dkey} Format`,
                        Mainlabel: "",
                        required: true,
                        selectboxArray: this.formatValue,
                        subLabel: "",
                        class: "",
                      });

                      this.labelArray[lastIndexLabel].children.push(
                        this.mainArray[this.mainArray.length - 1]
                      );
                    }*/
                  });
                }
              }
            } else {
              className = null;
              let tempFormat = null;
              if (key == "bleed") {
                className = "sided-field";
                tempFormat = true;
                //console.log("demo", value);
                // value = this.updateOrderFormat(value);
              }
              this.mainArray.push({
                fieldType: type,
                validationType: validationType,
                key: keydynamic,
                value: value,
                name: keydynamic,
                id: keydynamic,
                displayKey: key,
                Mainlabel: mainLabel,
                required: true,
                selectboxArray: select,
                subLabel: subLabel,
                class: className,
                updateFormat: tempFormat,
              });

              this.labelArray[lastIndexLabel].children.push(
                this.mainArray[this.mainArray.length - 1]
              );

              //Add New Select box for format
              if (key == "bleed") {
                this.storedId.push(keydynamic);
                this.mainArray.push({
                  fieldType: "selectbox",
                  validationType: "string",
                  key: `${keydynamic}_select`,
                  value: this.FormatDefaultValue,
                  name: `${keydynamic}_select`,
                  id: `${keydynamic}_select`,
                  displayKey: `${key} Format`,
                  Mainlabel: "",
                  required: true,
                  selectboxArray: this.formatValue,
                  subLabel: "",
                  class: "hideSelectbox",
                });

                this.labelArray[lastIndexLabel].children.push(
                  this.mainArray[this.mainArray.length - 1]
                );
              }
            }
          });
        }

        // Color Intent
        var colorKeys = Object.keys(colorIntent.surfaceColor);
        if (Object.keys(colorKeys).length > 0) {
          Object.entries(colorIntent).forEach(([ckey, cvalue], cindex) => {
            if (Object.keys(ckey).length > 0) {
              Object.entries(cvalue).forEach(([pkey, pvalue], pindex) => {
                if (cindex == 0 && pindex == 0) {
                  mainLabel = `Color Intent - ${parttsType}`;
                  this.labelArray.push({
                    labelName: mainLabel,
                    children: [],
                  });
                  lastIndexLabel = this.labelArray.length - 1;
                }
                subLabel = `surface ${pkey} color`;

                Object.entries(pvalue).forEach(([tkey, tvalue]) => {
                  let tvalueType = Array.isArray(tvalue);
                  if (tvalueType == true) {
                    // tvalue = tvalue.toString();
                  }

                  validationType = "string";
                  let requiredFlag = true;
                  if (tkey == "surface") {
                    return;
                  } else if (tkey == "printStandard") {
                    type = "selectbox";
                    select = this.PredefinedPrintStandard;
                  } else {
                    type = "multiselectbox";
                    select = this.PredefinedColor;
                    validationType = "array";
                    requiredFlag = true;
                  }

                  keydynamic = `${tkey}_${pindex}_${parttsType}`;
                  this.mainArray.push({
                    fieldType: type,
                    validationType: validationType,
                    key: keydynamic,
                    value: tvalue,
                    name: keydynamic,
                    id: keydynamic,
                    displayKey: tkey,
                    Mainlabel: mainLabel,
                    required: requiredFlag,
                    selectboxArray: select,
                    subLabel: subLabel,
                  });

                  this.labelArray[lastIndexLabel].children.push(
                    this.mainArray[this.mainArray.length - 1]
                  );

                  subLabel = "";
                  mainLabel = "";
                });
              });
            }
          });
        }
      }

      // Access Job customer data
      if (!Object.prototype.hasOwnProperty.call(this.orderJson, "jobCustomer")) {
        this.ShowErrorMessage(
          "Invalid Json",
          "Job Customer Key is missing from the json"
        );
      }

      this.customer = this.orderJson["jobCustomer"];
      if (Object.keys(this.customer).length > 0) {
        Object.entries(this.customer).forEach(([key, value]) => {
          let customerName = `customer_${key}`;
          this.labelArray.push({
            labelName: "Job Customer",
            children: [],
          });
          lastIndexLabel = this.labelArray.length - 1;
          this.mainArray.push({
            fieldType: "text",
            validationType: "string",
            key: customerName,
            value: value,
            name: customerName,
            id: customerName,
            displayKey: key,
            Mainlabel: "Job Customer",
            required: true,
            selectboxArray: [],
            subLabel: null,
          });

          this.labelArray[lastIndexLabel].children.push(
            this.mainArray[this.mainArray.length - 1]
          );
        });
      }

      // Access Job Contacts data
      if (!Object.prototype.hasOwnProperty.call(this.orderJson, "jobContacts")) {
        this.ShowErrorMessage(
          "Invalid Json",
          "Job Contacts Key is missing from the json"
        );
      }
      this.contacts = this.orderJson["jobContacts"];
      if (this.contacts.length > 0) {
        for (let i = 0; i < this.contacts.length; i++) {
          let contact = this.contacts[i];
          if (Object.keys(contact).length > 0) {
            Object.entries(contact).forEach(([key, value], index) => {
              let keydynamic = `${key}_${i}`;
              let select = [];
              let valueType = Array.isArray(value);
              let mainLabel = null;
              if (i == 0 && index == 0) {
                mainLabel = "Job Contacts";
                this.labelArray.push({
                  labelName: mainLabel,
                  children: [],
                });
                lastIndexLabel = this.labelArray.length - 1;
              }
              let type = "text";
              if (!valueType) {
                if (key == "gender") {
                  type = "radio";
                  if (value == "" || value == null) {
                    value = this.GenderDefaultValue;
                  }
                }
              } else {
                let roles = [];
                roles = value;
                value = roles.toString();
                type = "selectbox";
                select = this.PedefinedjobContactsRoles;
              }

              this.mainArray.push({
                fieldType: type,
                validationType: "string",
                key: keydynamic,
                value: value,
                name: keydynamic,
                id: keydynamic,
                displayKey: key,
                Mainlabel: mainLabel,
                required: true,
                selectboxArray: select,
                subLabel: null,
              });

              this.labelArray[lastIndexLabel].children.push(
                this.mainArray[this.mainArray.length - 1]
              );
            });
          }
        }
      }
      loader.hide();
      this.loadAllData = true;
    },
    async submitOrder(values) {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      let mainOrderJson = this.orderJson;
      let keydynamic;
      let oldValue;
      let currentFormat = localStorage.getItem("format");

      // Update order Information
      var orderKeys = Object.keys(this.orderJson);
      if (Object.keys(orderKeys).length > 0) {
        Object.entries(this.orderJson).forEach(([orderkey, ordervalue]) => {
          if (typeof ordervalue == "string") {
            let dynamicKey = `order_${orderkey}`;
            mainOrderJson[orderkey] = values[dynamicKey];
          }
        });
      }

      // Update Product Information
      let productInfo = mainOrderJson["products"][0];
      var productKeyslist = Object.keys(productInfo);
      if (Object.keys(productKeyslist).length > 0) {
        Object.entries(productInfo).forEach(([key, value]) => {
          let valueType = Array.isArray(value);
          if (valueType == false && key != "name" && key != "type") {
            keydynamic = "product_" + key;
            if (!isNaN(values[keydynamic])) {
              values[keydynamic] = parseFloat(values[keydynamic]);
            }
            productInfo[key] = values[keydynamic];
          }
        });
        mainOrderJson["products"][0] = productInfo;
      }

      // Update layout & color Intent data
      let allParts = mainOrderJson["products"][0]["parts"];
      for (let p = 0; p < allParts.length; p++) {
        let parttsType = allParts[p].type;
        let intent = allParts[p].intent;
        let layoutIntent = intent.layoutIntent;
        let colorIntent = intent.colorIntent;
        var layoutKeys = Object.keys(layoutIntent);
        // layout Intent
        if (Object.keys(layoutKeys).length > 0) {
          Object.keys(layoutIntent).forEach((key) => {
            oldValue = mainOrderJson["products"][0]["parts"][p].intent.layoutIntent[key];
            keydynamic = `${key}_${p}_${parttsType}`;
            if (key == "bleed") {
              //let formatKey = `${keydynamic}_select`;

              if (currentFormat != "POINT") {
                values[keydynamic] = this.convertValue(currentFormat, values[keydynamic]);
              }
            }

            if (typeof oldValue === "object") {
              var dimensionsKey = Object.keys(oldValue);
              // console.log(`dimensionsKey`, dimensionsKey);
              if (Object.keys(dimensionsKey).length > 0) {
                Object.keys(oldValue).forEach((dkey) => {
                  keydynamic = `${key}_${dkey}_${p}_${parttsType}`;
                  if (key == "dimensions" || key == "finishedDimensions") {
                    //let getFormat = `${key}_${dkey}_${p}_select`;
                    if (currentFormat != "POINT") {
                      values[keydynamic] = this.convertValue(
                        currentFormat,
                        values[keydynamic]
                      );
                    }
                  }

                  if (!isNaN(values[keydynamic])) {
                    values[keydynamic] = parseFloat(values[keydynamic]);
                  }
                  dimensionsKey[dkey] = values[keydynamic];
                  layoutIntent[key][dkey] = values[keydynamic];
                });
              }
              //console.log("yes object", layoutIntent[key]);
            } else {
              if (!isNaN(values[keydynamic])) {
                values[keydynamic] = parseFloat(values[keydynamic]);
              }
              layoutIntent[key] = values[keydynamic];
            }
            //console.log(`new value`, values[keydynamic]);
          });
        }

        //color Intent
        var colorKeys = Object.keys(colorIntent.surfaceColor);
        if (Object.keys(colorKeys).length > 0) {
          Object.entries(colorIntent).forEach(([ckey, cvalue]) => {
            if (Object.keys(ckey).length > 0) {
              Object.entries(cvalue).forEach(([pkey, pvalue], pindex) => {
                Object.entries(pvalue).forEach(([tkey]) => {
                  oldValue =
                    mainOrderJson["products"][0]["parts"][p].intent.colorIntent
                      .surfaceColor[pkey][tkey];
                  keydynamic = `${tkey}_${pindex}_${parttsType}`;
                  if (tkey == "surface") {
                    values[keydynamic] = oldValue;
                  }

                  colorIntent.surfaceColor[pkey][tkey] = values[keydynamic];
                });
              });
            }
          });
        }

        mainOrderJson["products"][0]["parts"][p].intent.layoutIntent = layoutIntent;
        mainOrderJson["products"][0]["parts"][p].intent.colorIntent = colorIntent;
      }

      // Update Job customer data
      let customerData = mainOrderJson["jobCustomer"];
      if (Object.keys(customerData).length > 0) {
        Object.entries(customerData).forEach(([key]) => {
          keydynamic = `customer_${key}`;
          customerData[key] = values[keydynamic];
        });
        mainOrderJson["jobCustomer"] = customerData;
      }

      // Update Job contact data
      let contacts = mainOrderJson["jobContacts"];
      if (contacts.length > 0) {
        for (let i = 0; i < contacts.length; i++) {
          let contact = contacts[i];
          if (Object.keys(contact).length > 0) {
            Object.entries(contact).forEach(([key, value]) => {
              keydynamic = `${key}_${i}`;

              // Radio button
              if (key == "gender") {
                values[keydynamic] = document.querySelector(
                  `input[name=${keydynamic}]:checked`
                ).value;
              }

              //Roles - Convert to array
              if (key == "roles" || Array.isArray(value)) {
                values[keydynamic] = values[keydynamic].split(",");
              }

              contact[key] = values[keydynamic];
            });
          }
        }
        mainOrderJson["jobContacts"] = contacts;
      }

      let validateOrderId = this.orderId;
      let orderHeader = {
        orderId: validateOrderId,
      };

      const result = await axiosInterceptor.post(
        `/api/v1/orders/${validateOrderId}/createNewOrderFromTemplate`,
        mainOrderJson,
        orderHeader
      );
      if (result.status == 201) {
        Swal.fire({
          title: "Success",
          text: "Order has been created successfully",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Order Listing",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.replace("/orders/list");
          }
        });
      }

      loader.hide();
    },
    onInvalid() {
      //this.PredefinedColor.push("test");
      //console.log(`ddd`, this.PredefinedColor);
      let expandBtn = document.getElementById("expandBtn"),
        expandAll = document.getElementById("ExpandAll"),
        collapseAll = document.getElementById("collapseAll");
      if (expandBtn.classList.contains("allCollapsed")) {
        expandBtn.classList.remove("allCollapsed");
        expandBtn.setAttribute("data-bs-original-title", "Collapse All");
        expandAll.classList.add("d-none");
        collapseAll.classList.remove("d-none");
      }
      let accordionButton = document.getElementsByClassName("accordion-button");
      if (accordionButton) {
        [...accordionButton].forEach(function (d) {
          if (d.classList.contains("collapsed")) {
            d.click();
          }
        });
      }
      setTimeout(() => {
        const errorFirst = this.$el.querySelector(
          ".error-message:not(:empty):first-of-type"
        );
        if (errorFirst) {
          const errorFirstInput = errorFirst.parentElement.querySelector("input, select");
          errorFirst.scrollIntoView({ behavior: "instant" });
          errorFirstInput.focus();
        }
      }, 300);
    },
  },
};
</script>
<style scoped>
.single-info.hideSelectbox {
  display: none;
}
</style>
