<template>
  <div class="customFilter d-flex gap-3 col-md-8">
    <div class="col-md-6">
      <p class="d-none">{{ this.$parent.getUploadProcess }}</p>
      <label class="ms-0">{{ $t("order-list.status-select-box") }} </label>
      <div class="inputCover">
        <VueMultiselect
          v-model="this.$parent.value"
          :options="statusList"
          :multiple="true"
          :taggable="true"
          :tag-placeholder="$t('order-list.status-placeholder')"
          :placeholder="$t('order-list.status-placeholder')"
          label="value"
          track-by="key"
          @remove="onRemove"
          @select="onChange"
        >
        </VueMultiselect>
        <div class="buttonBox">
          <vmd-button
            class="shadow-none font-weight-normal btn-active"
            color="outline-grey"
            @click="toggleStatus()"
            ><i class="fal fa-sliders-v"></i
          ></vmd-button>
          <vmd-button
            class="shadow-none font-weight-normal btn-active"
            color="outline-grey"
            @click="resetStatus()"
            ><i class="far fa-redo-alt"></i
          ></vmd-button>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <label class="ms-0">{{ $t("order-list.milestone-select-box") }}</label>
      <div class="inputCover">
        <VueMultiselect
          v-model="this.$parent.milestoneValue"
          :options="milestoneList"
          :multiple="true"
          :taggable="true"
          :tag-placeholder="$t('order-list.milestone-placeholder')"
          :placeholder="$t('order-list.milestone-placeholder')"
          label="value"
          track-by="key"
          @remove="onRemoveMilestone"
          @select="onChangeMilestone"
        >
        </VueMultiselect>
        <div class="buttonBox">
          <vmd-button
            class="shadow-none font-weight-normal btn-active"
            color="outline-grey"
            @click="toggleMilestone()"
            ><i class="fal fa-sliders-v"></i
          ></vmd-button>
          <vmd-button
            class="shadow-none font-weight-normal btn-active"
            color="outline-grey"
            @click="resetMilestone()"
            ><i class="far fa-redo-alt"></i
          ></vmd-button>
        </div>
      </div>
    </div>
  </div>
  <Form
    id="customSerachForm"
    :initial-values="this.$parent.initialvaluesearch"
    :validation-schema="this.$parent.searchschema"
    @submit.prevent="this.$parent.getSearch($event)"
  >
    <label class="ms-0">&nbsp;</label>
    <vmd-input
      id="search"
      v-model="search"
      type="text"
      :placeholder="$t('order-grid.grid-serach-placeholder')"
      name="search"
      @keyup.enter="this.$parent.getSearch($event)"
      @keyup="this.$parent.keyupSearch($event)"
    />
    <span
      v-if="search != null && search != ''"
      class="clear-search"
      @click="this.$parent.clearSearch()"
    >
      <i class="fal fa-times"></i>
    </span>
  </Form>
</template>
<script>
import VmdInput from "@/components/VmdInput.vue";
import VueMultiselect from "vue-multiselect";
import { Form } from "vee-validate";
import statuslist from "../../Mixins/statuslist";
import milestonelist from "../../Mixins/milestonelist";
import VmdButton from "@/components/VmdButton.vue";

export default {
  name: "Searchbox",
  components: {
    VmdInput,
    VueMultiselect,
    Form,
    VmdButton,
  },
  mixins: [statuslist, milestonelist],
  data() {
    return {
      predefineIndexofStatus: [0, 1, 5, 6],
      // predefineIndexofStatus: [0, 1],
      predefineIndexofMilestone: [0, 2, 4, 7, 8],
      storedIndexStatus: [],
      storedIndexMilestone: [],
    };
  },
  mounted() {
    /* Push data for predefine status */
    this.storedIndexStatus = this.predefineIndexofStatus;
    this.storedIndexMilestone = this.predefineIndexofMilestone;
    const predefinedStatus = localStorage.getItem("predefineStatus");
    let statusLength = predefinedStatus ? predefinedStatus.length : 0;
    if (statusLength > 0) {
      this.predefineIndexofStatus = JSON.parse(predefinedStatus);
    } else {
      this.predefineIndexofStatus = Object.values(this.predefineIndexofStatus);
      localStorage.setItem(
        "predefineStatus",
        JSON.stringify(this.predefineIndexofStatus)
      );
    }

    const predefinedMilestone = localStorage.getItem("predefineMilestone");
    let milestoneLength = predefinedMilestone ? predefinedMilestone.length : 0;

    if (milestoneLength > 0) {
      this.predefineIndexofMilestone = JSON.parse(predefinedMilestone);
    } else {
      this.predefineIndexofMilestone = Object.values(this.predefineIndexofMilestone);
      localStorage.setItem(
        "predefineMilestone",
        JSON.stringify(this.predefineIndexofMilestone)
      );
    }

    this.$parent.value.push(
      ...this.predefineIndexofStatus.map((index) => this.statusList[index])
    );
    //used only key
    this.$parent.taggingStatus.push(
      ...this.predefineIndexofStatus.map((index) => this.statusList[index].key)
    );

    /* Push data for predefine milestone */
    this.$parent.milestoneValue.push(
      ...this.predefineIndexofMilestone.map((index) => this.milestoneList[index])
    );

    this.$parent.taggingMilestone.push(
      ...this.predefineIndexofMilestone.map((index) => this.milestoneList[index].key)
    );
  },
  methods: {
    findPositions(array, valuesToFind) {
      const positions = [];

      valuesToFind.forEach((value) => {
        const index = array.indexOf(value);
        if (index !== -1) {
          positions.push(index);
        }
      });

      return positions;
    },

    async onChange(option) {
      this.$parent.taggingStatus.push(option.key);
      const ifExist = this.statusList.findIndex((object) => {
        return object.key == option.key;
      });

      var predefineStatus = JSON.parse(localStorage.getItem("predefineStatus"));
      predefineStatus.push(ifExist);

      localStorage.setItem(
        "predefineStatus",
        JSON.stringify(Object.values(predefineStatus))
      );

      await this.resetTableData();
      // this.$parent.loadItems();
    },
    async onRemove(option) {
      this.$parent.taggingStatus = this.$parent.taggingStatus.filter(
        (item) => item !== option.key
      );

      const ifExist = this.statusList.findIndex((object) => {
        return object.key == option.key;
      });

      var predefineStatus = JSON.parse(localStorage.getItem("predefineStatus"));
      const index = predefineStatus.indexOf(ifExist);
      predefineStatus.splice(index, 1);
      localStorage.setItem(
        "predefineStatus",
        JSON.stringify(Object.values(predefineStatus))
      );

      await this.resetTableData();
      //await this.$parent.loadItems();
    },
    async toggleStatus() {
      let indexesNotInArray2;
      if (this.$parent.taggingStatus.length == 0) {
        this.predefineIndexofStatus = this.storedIndexStatus;
        this.$parent.value.push(
          ...this.predefineIndexofStatus.map((index) => this.statusList[index])
        );
        //used only key
        this.$parent.taggingStatus.push(
          ...this.predefineIndexofStatus.map((index) => this.statusList[index].key)
        );
      } else {
        this.$parent.value = this.statusList.filter(
          (e) => !this.$parent.taggingStatus.includes(e.key)
        );
        this.$parent.taggingStatus = this.$parent.value.map((el) => el.key);
      }
      //console.log(`this.$parent.value`, this.$parent.value);
      indexesNotInArray2 = this.statusList
        .map((obj, index) =>
          this.$parent.value.some((o) => o.key === obj.key) ? index : null
        )
        .filter((index) => index !== null);

      localStorage.setItem(
        "predefineStatus",
        JSON.stringify(Object.values(indexesNotInArray2))
      );

      await this.resetTableData();
    },
    async resetStatus() {
      this.$parent.taggingStatus = [];
      this.$parent.value = [];
      localStorage.setItem(
        "predefineStatus",
        JSON.stringify(Object.values(this.$parent.value))
      );
      await this.resetTableData();
    },
    async onChangeMilestone(value) {
      this.$parent.taggingMilestone.push(value.key);

      const ifExist = this.milestoneList.findIndex((object) => {
        return object.key == value.key;
      });

      if (ifExist !== -1) {
        var predefineMilestone =
          JSON.parse(localStorage.getItem("predefineMilestone")) || [];
        predefineMilestone.push(ifExist);
        console.log(`predefineMilestone`, predefineMilestone);
        localStorage.setItem(
          "predefineMilestone",
          JSON.stringify(Object.values(predefineMilestone))
        );
      }

      await this.resetTableData();
      // this.$parent.loadItems();
    },
    async onRemoveMilestone(value) {
      this.$parent.taggingMilestone = this.$parent.taggingMilestone.filter(
        (item) => item !== value.key
      );

      const ifExist = this.milestoneList.findIndex((object) => {
        return object.key == value.key;
      });

      var predefineMilestone = JSON.parse(localStorage.getItem("predefineMilestone"));
      const index = predefineMilestone.indexOf(ifExist);
      predefineMilestone.splice(index, 1);
      localStorage.setItem(
        "predefineMilestone",
        JSON.stringify(Object.values(predefineMilestone))
      );

      //this.$parent.loadItems();
      await this.resetTableData();
    },

    async toggleMilestone() {
      if (this.$parent.taggingMilestone.length == 0) {
        this.predefineIndexofMilestone = this.storedIndexMilestone;
        this.$parent.milestoneValue.push(
          ...this.predefineIndexofMilestone.map((index) => this.milestoneList[index])
        );
        //used only key
        this.$parent.taggingMilestone.push(
          ...this.predefineIndexofMilestone.map((index) => this.milestoneList[index].key)
        );
      } else {
        this.$parent.milestoneValue = this.milestoneList.filter(
          (e) => !this.$parent.taggingMilestone.includes(e.key)
        );
        this.$parent.taggingMilestone = this.$parent.milestoneValue.map((el) => el.key);
      }
      await this.resetTableData();
    },
    async resetMilestone() {
      this.$parent.taggingMilestone = [];
      this.$parent.milestoneValue = [];
      localStorage.setItem(
        "predefineMilestone",
        JSON.stringify(Object.values(this.$parent.milestoneValue))
      );
      await this.resetTableData();
    },
    async resetTableData() {
      //console.log(`this.$parent.$options.name`, this.$parent.$options.name);
      if (this.$parent.$options.name == "OrderList") {
        this.$parent.serverParams.currentPage = 1;
        this.$parent.serverParams.page = 1;
        this.$parent.$refs.mygoodtable.reset();
      } else {
        this.$parent.page = 1;
      }

      await this.$parent.loadItems();
    },
  },
};
</script>
