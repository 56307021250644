<template>
  <div class="h-100 sitemap-page d-flex align-items-center justify-content-center">
    <div class="container custom-container py-5">
      <h1>Upload Portal Sitemap</h1>
      <h4>
        <a href="javascript:void(0)" @click="Orders()"
          ><span>01</span> Orders (Auftragsdaten / Admin)</a
        >
      </h4>
      <h4>
        <a href="javascript:void(0)" @click="businesscard()"
          ><span>02</span> Upload (Produktionsdaten / Kunde)</a
        >
      </h4>
      <h4>
        <a href="javascript:void(0)" @click="releaseprintdata()"
          ><span>03</span> Assign File (SinglePart)</a
        >
      </h4>

      <h4>
        <a href="javascript:void(0)" @click="Login()"><span>04</span> Login (Kunde)</a>
      </h4>
    </div>
  </div>
</template>
<script>
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

export default {
  name: "Sitemap",
  mounted() {},
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    Orders() {
      this.$router.push("/orders/grid");
    },
    businesscard() {
      this.$router.push("/businesscard");
    },
    releaseprintdata() {
      this.$router.push("/releaseprintdata");
    },
    Login() {
      this.$router.push("/login");
    },
  },
};
</script>
