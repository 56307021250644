export default {
    data() {
        return {
            format: [
                { id: 1, code: "point", text: "POINT", value: "POINT", dbCode: "pt" },
                { id: 2, code: "mm", text: "MM", value: "MM", dbCode: "mm" },
                { id: 3, code: "inch", text: "INCH", value: "INCH", dbCode: "in" },
              ],
              formatValue: ['POINT','MM','INCH']
        }
    }
   
};