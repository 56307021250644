<template>
  <div
    class="py-4 px-0 container custom-container upload-page-container print-page-container"
  >
    <div class="position-fixed inner_toaster">
      <div v-if="processingMsg && processingMsg.length > 0">
        <div v-for="(msg, index) in processingMsg" :key="index">
          <vmd-snackbar
            v-if="msg.status && msg.status === 'show'"
            :description="msg.translate == true ? $t(msg.message) : msg.message"
            :icon="{ color: 'white' }"
            :color="msg.icon"
            @click="closeSnackbarArray(index)"
          />
        </div>
      </div>
      <ExtraNotification ref="extraNotificationRef"></ExtraNotification>
    </div>

    <div class="page-title-new">
      <span class="divider"></span>
      <h6 :class="color">{{ $t("release-print-data.page-title") }}</h6>
    </div>
    <div class="row">
      <div
        class="position-fixed floating-sidebar-left"
        :class="{
          dragDisabled: lockScreen == 1,
          //loadingBox: preventAPI == true,
        }"
      >
        <div
          id="scrollTopDetect"
          :class="{
            imageBoxDragging: isDrag != thumbnailsIndex,
          }"
          @dragover="scrollPage(-30)"
        ></div>
        <perfect-scrollbar
          :class="{
            sliderDragging: isDrag != thumbnailsIndex,
          }"
        >
          <div
            class="sidebar-left-dropbox"
            :class="{
              showdz: showDropzone != false,
            }"
          >
            <div id="productImg" style="width: 100%" class="form-control border dropzone">
              <div id="template-container" style="display: none">
                <div class="main-upload">
                  <div class="image-upload">
                    <img data-dz-thumbnail />
                    <!-- Hide it bacause of Cannot POST error -->
                    <!-- <div class="dz-error-message" data-dz-errormessage></div> -->
                  </div>
                  <div class="upload-content">
                    <div class="dz-filename">
                      <h5 data-dz-name></h5>
                      <!-- <span class="text-white opacity-6" data-dz-size></span> -->
                    </div>
                    <div class="dz-error-message" data-dz-errormessage></div>
                    <div class="dz-progress">
                      <span class="dz-upload" data-dz-uploadprogress></span>
                    </div>
                    <div class="upload-status">
                      <div class="dz-success-mark">
                        <i class="fas fa-check"></i>
                      </div>
                      <div class="dz-error-mark">
                        <i class="fas fa-times"></i>
                      </div>
                    </div>
                    <div class="messageBox">
                      <div class="dz-success-message"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dz-message" data-dz-message>
                <span v-html-safe="$t('release-print-data.dropzone-text')"></span>
              </div>
            </div>
          </div>

          <!--All Image -->
          <div
            v-for="(image, thumbnailsIndex) in images"
            :id="thumbnailsIndex"
            :key="image.radixResourceId"
            :data-partType="image.partType"
            :draggable="EnableDrag"
            class="draggable selectable-image"
            data-boxtype="data-draggable"
            :class="{
              selected_image: isActivearray.includes(image.sortSequence),
              selected_image:
                (showMiniature && image.sortSequence > 0) ||
                (isdisplay == image.sortSequence && canvasComponents == false) ||
                (displayInCanvas == image.sortSequence && canvasComponents == true),
              image_drag: isDrag == thumbnailsIndex,
              add_hr: image.sortSequence == showLine && thumbnailsIndex == showLineIndex,
              image_loader: image.dummyImage == true,
            }"
            @dragstart="
              image.dummyImage == false ? dragStart(thumbnailsIndex, $event) : ''
            "
            @dragover.prevent
            @dragenter="dragEnter"
            @dragleave="dragLeave"
            @dragend="dragEnd"
            @drop="
              coverpage > 0
                ? dragFinish(thumbnailsIndex, $event, image.partType)
                : dragFinish(thumbnailsIndex, $event, image.partType)
            "
            @mousedown="selectMultiple($event, thumbnailsIndex)"
          >
            <span
              v-if="image.dummyImage == false"
              class="dropdown dropstart imageOptions"
            >
              <a
                id="dropdownMenuButton1"
                href="javascript:void(0)"
                class="table-menu-icon link-white"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="fas fa-ellipsis-h"></i
              ></a>
              <ul
                class="px-2 py-3 dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdownMenuButton1"
                data-bs-popper="none"
              >
                <li v-if="image.sortSequence > 0">
                  <a
                    href="javascript:void(0)"
                    class="dropdown-item border-radius-md"
                    @click="confirmUnassignResource(image.sortSequence, image.partType)"
                    >{{ $t("release-print-data.unassign-link") }}</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0)"
                    class="dropdown-item border-radius-md"
                    @click="confirmDeleteResource(image.singlePagesID)"
                    >{{ $t("release-print-data.delete-link") }}</a
                  >
                </li>
              </ul>
            </span>

            <span v-if="image.sortSequence != null" class="image-id">
              <span v-if="image.partType == 'Cover'">U</span>
              {{ image.sortSequence }}</span
            >
            <img
              :src="
                image.dummyImage == true
                  ? this.$loaderimageProcessing
                  : externalImage + image.thumbnail
              "
              :draggable="EnableDrag"
              v-on="
                showMiniature == false &&
                image.sortSequence > 0 &&
                MutipartMiniaturePreview == false
                  ? {
                      click: () =>
                        imageAssigned(
                          thumbnailsIndex,
                          image.sortSequence,
                          image.partType
                        ),
                    }
                  : { click: ($event) => $event.preventDefault() }
              "
            />
          </div>
        </perfect-scrollbar>

        <div
          id="scrollBottomDetect"
          :class="{
            imageBoxDragging: isDrag != thumbnailsIndex,
          }"
          @dragover="scrollPage(30)"
        ></div>
        <div class="add-new-image">
          <vmd-button
            class="shadow-none font-weight-normal submit-btn w-100"
            color="primary"
            :disabled="lockScreen == 1"
            @click="showFile()"
            >Add</vmd-button
          >

          <vmd-button
            class="shadow-none font-weight-normal submit-btn w-100 mt-2"
            color="primary"
            :disabled="lockScreen == 1 || uploadbtn == false"
            @click="uploadFile()"
            >Upload</vmd-button
          >
        </div>
      </div>
      <div
        class="col-lg-8 main-content"
        :class="{
          show_both_side: showMiniature == true,
          //loadingBox: preventAPI == true,
        }"
      >
        <a
          v-if="nextimageicon"
          href="javascript:void(0)"
          class="arrow next"
          @click="nextimage()"
        >
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </a>
        <a
          v-if="previmageicon"
          href="javascript:void(0)"
          class="arrow prev"
          @click="previmage()"
        >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </a>

        <!--Drop Area -->
        <div class="card box-loading-style" style="display: none"></div>

        <SinglePartview v-if="multiPart == false" />
        <MultiPartview v-if="multiPart == true && MutipartMiniaturePreview == false" />
        <MultipartMiniature v-if="MutipartMiniaturePreview == true" />
        <Frontback v-if="showMiniature == true" />
        <PDFTool v-if="canvasComponents == true" />
        <div class="card-preview-selector">
          <a
            v-if="
              multiPart == false &&
              (orderSides != 'OneSided' ||
                (orderSides == 'OneSided' && requiredPage == 1))
            "
            href="javascript:void(0);"
            :class="{
              'link-active': clickedItem == 1,
            }"
            @click="
              previmage();
              clickedItem = 1;
            "
            >{{ $t("release-print-data.footerlink-front") }}</a
          >
          <a
            v-if="multiPart == false && orderSides != 'OneSided'"
            href="javascript:void(0);"
            :class="{
              'link-active': clickedItem == 2,
            }"
            @click="
              nextimage();
              clickedItem = 2;
            "
            >{{ $t("release-print-data.footerlink-back") }}</a
          >

          <span v-if="requiredPage > 2 || multiPart == true" class="number-box">
            <span
              class="arrow"
              :class="{
                disable_icon: isdisplay == 1,
              }"
              @click="previmage()"
            >
              <i class="fal fa-angle-left"></i>
            </span>
            <span
              v-if="canvasComponents == false"
              class="number first-number link-active"
              >{{ isdisplay }}</span
            >

            <!--CanvasComponents is true and order has not multipart -->
            <span
              v-if="multiPart == false && canvasComponents == true"
              class="number first-number link-active"
              >{{ displayInCanvas }}</span
            >

            <!--CanvasComponents is true and order has only body part -->
            <span
              v-if="multiPart == true && coverPage == 0 && canvasComponents == true"
              class="number first-number"
              >{{ displayInCanvas }}</span
            >

            <!--CanvasComponents is true and order has cover part and currently display  body part-->
            <span
              v-if="
                multiPart == true &&
                coverPage > 0 &&
                coverPage >= displayInCanvas &&
                canvasComponents == true
              "
              class="number first-number"
              >{{ displayInCanvas }}</span
            >

            <!--CanvasComponents is true and order has cover part and currently display cover part-->
            <span
              v-if="
                multiPart == true &&
                coverPage > 0 &&
                displayInCanvas > coverPage &&
                canvasComponents == true
              "
              class="number first-number"
              >{{ displayInCanvas - coverPage }}</span
            >

            <!--last number for cover-->

            <span
              v-if="coverPage > 0 && coverDisplay == true && canvasComponents == false"
              class="number last-number"
              >{{ coverPage }}</span
            >
            <span
              v-else-if="
                coverPage > 0 && canvasComponents == true && coverPage >= displayInCanvas
              "
              class="number last-number"
              >{{ coverPage }}</span
            >

            <!--last number for body-->
            <span v-else class="number last-number">{{ bodyPage }} </span>
            <span
              class="arrow"
              :class="{
                disable_icon: isdisplay == bodyPage,
              }"
              @click="nextimage()"
            >
              <i class="fal fa-angle-right"></i>
            </span>
          </span>

          <span class="divider"></span>
          <!-- Cover -->

          <a
            v-if="multiPart == true && coverPage > 0"
            href="#"
            :class="{
              'link-active': clickedItem === 5,
            }"
            @click="multiPart == true ? showCurrentPage(1) : ''"
            >Cover</a
          >

          <!-- pages -->
          <a
            href="#"
            :class="{
              'link-active': clickedItem === 3 || clickedItem === 2 || clickedItem === 1,
            }"
            @click="multiPart == true ? showCurrentPage() : showSinglePage()"
            >{{ $t("release-print-data.footerlink-pages") }}</a
          >
          <a
            href="javascript:void(0);"
            :class="{
              'link-active': clickedItem === 4,
            }"
            @click="multiPart == false ? showbothside() : showMultiPart()"
            @mouseleave="resetHover()"
            >{{ $t("release-print-data.footerlink-miniature") }}</a
          >

          <input id="showZoom" type="hidden" name="showZoom" value="100%" />
        </div>
      </div>
      <div class="position-fixed floating-sidebar-right bg-dark">
        <div class="print-box p-3">
          <vmd-button
            class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
            color="primary"
            :disabled="
              (severityError == true && dataCheck == false) ||
              releaseBtnDisable == true ||
              lockScreen == 1 ||
              preventAPI == true
            "
            @click="setvalidation"
            >{{ $t("release-print-data.release-print-data-btn") }}
          </vmd-button>

          <vmd-button
            v-if="refreshBtn == true"
            class="shadow-none rounded-pill font-weight-normal submit-btn w-100 mt-2"
            color="primary"
            @click="resourcesImage"
            >{{ $t("release-print-data.refresh-btn") }}
          </vmd-button>
        </div>
        <div v-if="mergePDFStatus != null" class="print-box p-3">
          <span id="mergePDFspan" class="mergePDF customMessageDiv">
            <i class="fas fa-file-pdf"></i>Prepare PDF : {{ mergePDFStatus }}
          </span>
        </div>

        <perfect-scrollbar>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingOne" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#datacheck"
                  aria-expanded="false"
                  aria-controls="datacheck"
                >
                  {{ $t("release-print-data.data-check-title") }}

                  <!--<div>{{ $t("titletest") }}</div> -->

                  <i v-if="dataCheck == true" class="fa fa-check" aria-hidden="true"></i>
                  <i v-else class="fa fa-times" aria-hidden="true"></i>

                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="datacheck"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div v-for="(value, key, index) in plausibilityCheck" :key="key">
                  <div class="accordion-body text-sm">
                    <div class="accordion-item">
                      <h5 id="headingOne1" class="accordion-header">
                        <button
                          class="accordion-button border-bottom collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="'#collapse' + index"
                          aria-expanded="false"
                          :aria-controls="'collapse' + index"
                        >
                          <i
                            class="fas fa-circle"
                            :class="{
                              'mark-danger-text': value.status == false,
                              'mark-success-text': value.status == true,
                            }"
                          ></i>
                          {{ value.label }}
                          <i
                            class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0"
                            aria-hidden="true"
                          ></i>
                          <i
                            class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </h5>
                      <div
                        :id="'collapse' + index"
                        class="accordion-collapse collapse"
                        :aria-labelledby="headingOne + index"
                        style=""
                      >
                        <div class="accordion-body">
                          <span v-if="key == 'cdfError'">
                            <p v-for="(value1, key1) in value.customData" :key="key1">
                              {{ value1 }}
                            </p>
                          </span>
                          <p v-else>{{ value.customData.displayMessage }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingSecond" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#order_information"
                  aria-expanded="false"
                  aria-controls="order_information"
                >
                  {{ $t("release-print-data.order-information") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="order_information"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <!-- <h5>{{ $t("upload-image.business-card-title") }}</h5> -->
                <div class="accordion-body text-sm basic-infos">
                  <!--<div class="single-info">
                    <h6>{{ $t("release-print-data.label-finishing") }}</h6>
                    <p>{{ finishing1 }}</p>
                  </div>
                  <div class="single-info">
                    <h6>{{ $t("release-print-data.label-processing") }}</h6>
                    <p>{{ finishing2 }}</p>
                  </div> -->

                  <div class="single-info upperView">
                    <p>
                      <strong class="opacity-6 fw-bold">{{
                        $t("release-print-data.label-status")
                      }}</strong
                      >:
                      <i
                        class="fas fa-circle"
                        :title="orderStatus"
                        :class="{
                          'text-danger': ['error', 'internalError'].includes(orderStatus),
                          'text-success': orderStatus === 'done',
                          'text-warning': [
                            'waitForFeedback',
                            'warning',
                            'processing',
                            'created',
                          ].includes(orderStatus),
                        }"
                        data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        :data-bs-original-title="orderStatus"
                      ></i>
                    </p>
                  </div>

                  <div class="single-info upperView">
                    <p>
                      <strong class="opacity-6">{{
                        $t("release-print-data.label-milestone")
                      }}</strong
                      >: {{ orderMilestone }}
                    </p>
                  </div>

                  <!--new div-->
                  <div
                    class="order-details d-flex align-items-center justify-content-between"
                  >
                    <div class="order-content">
                      <div
                        v-for="producttype in allProductData"
                        :key="producttype.id"
                        class="orderPreviewDetails"
                      >
                        <div class="orderDetailTitle">
                          <p>
                            {{ $t("order-common.orderdetail-modal-productType") }}:
                            <strong class="text-white">{{ producttype.type }}</strong>
                          </p>
                        </div>
                        <div
                          v-for="productparts in producttype.parts"
                          :key="productparts.id"
                          class="orderDetailContent"
                        >
                          <h5>{{ productparts.type }}</h5>
                          <div class="innerDetailContent">
                            <div class="single-info">
                              <h6>{{ $t("release-print-data.label-motifs") }}</h6>
                              <p>
                                {{ productparts.pageranges[0].variants.length }}
                                {{ $t("release-print-data.text-variant") }}
                              </p>
                            </div>
                            <div class="single-info">
                              <h6>{{ $t("release-print-data.label-format") }}</h6>
                              <p>
                                {{
                                  updateOrderFormat(
                                    productparts.intent.layoutIntent.dimensions.width
                                  )
                                }}
                                *
                                {{
                                  updateOrderFormat(
                                    productparts.intent.layoutIntent.dimensions.height
                                  )
                                }}

                                {{ dimensionFormat }}
                              </p>
                            </div>
                            <div class="single-info">
                              <h6>{{ $t("release-print-data.label-noofpages") }}</h6>
                              <p>
                                {{
                                  updateOrderPages(
                                    productparts.intent.layoutIntent.sides,
                                    productparts.intent.layoutIntent.pages
                                  )
                                }}
                                {{ $t("release-print-data.text-pages") }}
                              </p>
                            </div>
                            <div class="single-info">
                              <h6>{{ $t("release-print-data.label-colourfulness") }}</h6>
                              <p>
                                {{
                                  productparts.intent.colorIntent.surfaceColor.front
                                    .colorsUsed.length
                                }}
                                /

                                {{
                                  productparts.intent.colorIntent.surfaceColor.back
                                    .colorsUsed.length
                                }}
                              </p>
                            </div>
                            <div class="single-info">
                              <h6>{{ $t("release-print-data.label-material") }}</h6>
                              <p>{{ material }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cdf.length > 0" id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingFive" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  {{ $t("release-print-data.CDF-title") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="collapsefive"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div class="accordion-body text-sm">
                  <div class="text-end">
                    <a href="javascript:void(0)" class="link-white">
                      <i
                        v-if="showCustom == false && lockScreen == 0"
                        class="far fa-edit"
                        @click="customshow()"
                      ></i>
                      <i
                        v-if="showCustom == true"
                        class="customShowSaveIcon fal fa-save btn-primary p-2 rounded-circle me-2"
                        @click="saveIconCDF()"
                      ></i>
                      <i
                        v-if="showCustom == true"
                        class="customShowCloseIcon fa fa-times bg-danger p-2 rounded-circle"
                        @click="customshow()"
                      ></i>
                    </a>
                  </div>
                  <div v-if="showCustom == false">
                    <div v-for="(cdata, index) in cdf" :key="index" class="single-info">
                      <div
                        v-if="
                          cdata.type == 'order' &&
                          cdata.usage == 'customData' &&
                          cdata.hidden == false
                        "
                        :class="{
                          cdfBoxSingle: cdfOrderId == cdata.id,
                        }"
                      >
                        <h6 v-if="cdfOrderId == cdata.id" class="fieldTitle">Order</h6>
                        <span v-if="cdata.editData.type == 'boolean'" class="radioBox">
                          <label>{{ cdata.displayKey }}</label>
                          <vmd-checkbox
                            :id="'noeditcheckbox' + cdata.id"
                            :name="'noeditcheckbox' + cdata.id"
                            :value="cdata.value"
                            :disabled="true"
                            :check="cdata.value == true || cdata.value == 'true'"
                          ></vmd-checkbox>
                        </span>
                        <span v-else>
                          <h6>{{ cdata.displayKey }}</h6>
                          <p
                            v-if="
                              cdata.value == NULL ||
                              cdata.value == '' ||
                              cdata.value == undefined
                            "
                          >
                            empty value
                          </p>
                          <p v-else>{{ cdata.value }}</p>
                        </span>
                      </div>

                      <div
                        v-if="
                          cdata.type == 'product' &&
                          cdata.usage == 'customData' &&
                          cdata.hidden == false
                        "
                        :class="{
                          cdfBoxSingle: cdfProductId == cdata.id,
                        }"
                      >
                        <h6 v-if="cdfProductId == cdata.id" class="fieldTitle">
                          Product
                        </h6>

                        <span v-if="cdata.editData.type == 'boolean'" class="radioBox">
                          <label>{{ cdata.displayKey }}</label>
                          <vmd-checkbox
                            :id="'noeditcheckbox' + cdata.id"
                            :name="'noeditcheckbox' + cdata.id"
                            :value="cdata.value"
                            :disabled="true"
                            :check="cdata.value == true || cdata.value == 'true'"
                          ></vmd-checkbox>
                        </span>
                        <span v-else>
                          <h6>{{ cdata.displayKey }}</h6>
                          <p
                            v-if="
                              cdata.value == NULL ||
                              cdata.value == '' ||
                              cdata.value == undefined
                            "
                          >
                            empty value
                          </p>
                          <p v-else>{{ cdata.value }}</p>
                        </span>
                      </div>
                      <div
                        v-if="
                          cdata.type == 'part' &&
                          cdata.usage == 'customData' &&
                          cdata.hidden == false
                        "
                        :class="{
                          cdfBoxSingle: cdfPartId == cdata.id,
                        }"
                      >
                        <h6 v-if="cdfPartId == cdata.id" class="fieldTitle">Part</h6>
                        <span v-if="cdata.editData.type == 'boolean'" class="radioBox">
                          <label>{{ cdata.displayKey }}</label>
                          <vmd-checkbox
                            :id="'noeditcheckbox' + cdata.id"
                            :name="'noeditcheckbox' + cdata.id"
                            :value="cdata.value"
                            :disabled="true"
                            :check="cdata.value == true || cdata.value == 'true'"
                          ></vmd-checkbox>
                        </span>
                        <span v-else>
                          <h6>{{ cdata.displayKey }}</h6>
                          <p
                            v-if="
                              cdata.value == NULL ||
                              cdata.value == '' ||
                              cdata.value == undefined
                            "
                          >
                            empty value
                          </p>
                          <p v-else>{{ cdata.value }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <Form
                    id="CDFForm"
                    :class="{ 'd-none': showCustom != true }"
                    :validation-schema="CDFSchema"
                    :initial-values="initialCDFValues"
                    autocomplete="off"
                    class="customFormData"
                    @submit="CDFSubmit"
                    @invalid-submit="onInvalidCDF(0)"
                  >
                    <div v-for="cdata1 in cdf" :key="cdata1.id" class="single-info">
                      <div
                        v-if="cdata1.hidden == false"
                        :class="{
                          cdfBoxSingle:
                            cdfOrderId == cdata1.id ||
                            cdata1.id == cdfProductId ||
                            cdata1.id == cdfPartId,
                        }"
                      >
                        <div v-if="cdata1.id == cdfOrderId">
                          <h6 class="fieldTitle">Order</h6>
                        </div>
                        <div v-if="cdata1.id == cdfProductId">
                          <h6 class="fieldTitle">Product</h6>
                        </div>
                        <div v-if="cdata1.id == cdfPartId">
                          <h6 class="fieldTitle">Part</h6>
                        </div>
                        <div
                          v-if="
                            ((cdata1.editData.type == 'string' &&
                              cdata1.editData.enum.length == 0) ||
                              cdata1.editData.type == 'number' ||
                              cdata1.editData.type == 'integer' ||
                              cdata1.editData.type == 'object') &&
                            cdata1.usage == 'customData'
                          "
                          class="mb-3"
                        >
                          <label>{{ cdata1.displayKey }} </label>
                          <vmd-input
                            :id="'inputcdf' + cdata1.id"
                            value="allCDF['inputname' + cdata1.id]"
                            type="text"
                            :name="'inputname' + cdata1.id"
                            :readonly="cdata1.readOnly == true"
                          />
                        </div>
                        <div
                          v-else-if="
                            cdata1.editData.type == 'string' &&
                            cdata1.editData.enum.length > 0
                          "
                          class="mb-3"
                        >
                          <label>{{ cdata1.displayKey }}</label>
                          <vmd-select
                            :id="'inputcdf' + cdata1.id"
                            v-model="cdata1.value"
                            :name="'inputname' + cdata1.id"
                            :items="cdata1.editData.enum"
                            class="select"
                          />
                        </div>
                        <div
                          v-else-if="
                            cdata1.editData.type == 'boolean' &&
                            (cdata1.value == 'true' || cdata1.value == true)
                          "
                          class="mb-3 radioBox"
                        >
                          <label :for="'inputcdf' + cdata1.id">{{
                            cdata1.displayKey
                          }}</label>
                          <vmd-checkbox
                            :id="'inputcdf' + cdata1.id"
                            :name="'inputname' + cdata1.id"
                            :value="cdata1.value"
                            :check="cdata1.value"
                          ></vmd-checkbox>

                          <ErrorMessage
                            :name="'inputname' + cdata1.id"
                            class="error-message text-sm fw-light text-danger"
                          />
                        </div>
                        <div
                          v-else-if="
                            cdata1.editData.type == 'boolean' &&
                            (cdata1.value == 'false' ||
                              cdata1.value == '' ||
                              cdata1.value == false)
                          "
                          class="mb-3 radioBox"
                        >
                          <label :for="'inputcdf' + cdata1.id">{{
                            cdata1.displayKey
                          }}</label>
                          <vmd-checkbox
                            :id="'inputcdf' + cdata1.id"
                            :name="'inputname' + cdata1.id"
                            :value="cdata1.value"
                          ></vmd-checkbox>

                          <ErrorMessage
                            :name="'inputname' + cdata1.id"
                            class="error-message text-sm fw-light text-danger"
                          />
                        </div>
                        <div
                          v-else-if="
                            cdata1.editData.type == 'date' ||
                            cdata1.editData.type == 'datetime'
                          "
                          class="refreshforce"
                        >
                          <label>{{ cdata1.displayKey }}</label>

                          <!--<Datepicker
                            :id="'inputcdf' + cdata1.id"
                            format="dd/MM/yy"
                            :disabled-dates="state.disabledDates"
                            v-model="cdata1.value"
                            required
                            :name="'inputname' + cdata1.id"
                            @opened="opened(cdata1.id)"
                            @selected="selected"
                            @click="demo(cdata1.id)"
                          >
                          </Datepicker>
                          <span @click="clearedDate(cdata1.id)">X</span> -->

                          <Datepicker
                            :id="'inputcdf1' + cdata1.id"
                            v-model="cdata1.value"
                            :format="
                              cdata1.editData.type == 'date'
                                ? 'yyyy-MM-dd'
                                : 'yyyy-MM-dd HH:mm:ss'
                            "
                            :value="cdata1.value"
                            :enable-time-picker="
                              cdata1.editData.type == 'date' ? false : true
                            "
                            :enable-seconds="
                              cdata1.editData.type == 'date' ? false : true
                            "
                            :start-time="
                              cdata1.editData.type == 'date' ? '' : defaultTime
                            "
                            :name="'inputname' + cdata1.id"
                            dark
                            @open="opened(cdata1.id)"
                            @focus="opened(cdata1.id)"
                            @update:modelValue="selected"
                            @cleared="clearedDate(cdata1.id)"
                          >
                          </Datepicker>
                          <vmd-input
                            :id="'inputcdf' + cdata1.id"
                            :value="cdata1.value"
                            type="text"
                            :name="'inputname' + cdata1.id"
                          />
                          <!--<span
                            v-if="
                              (cdata1.value == '' ||
                                cdata1.value == null ||
                                cdata1.value == undefined) &&
                              (cdata1.required == true || cdata1.required == 'true')
                            "
                            class="error-message text-sm fw-light text-danger"
                          >
                            {{ cdata1.displayKey }} is required 55555
                          </span>
                          <ErrorMessage
                            :name="'inputname' + cdata1.id"
                            class="error-message text-sm fw-light text-danger"
                          />-->
                        </div>
                      </div>
                    </div>
                    <vmd-button
                      id="CDFSubmitButton"
                      class="shadow-none rounded-pill font-weight-normal btn-sm mt-3 d-none"
                      color="primary"
                      type="submit"
                      >Update</vmd-button
                    >
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="remarkList.length > 0"
            id="accordionRental"
            class="accordion border-top"
          >
            <div class="accordion-item">
              <h5 id="headingThird" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsThird"
                  aria-expanded="false"
                  aria-controls="collapsThird"
                >
                  {{ $t("release-print-data.label-remarks") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="collapsThird"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <ul v-for="(listremark, index) in remarkList" :key="index">
                  <li>{{ listremark.value }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="accordionRental" class="accordion border-top">
            <div class="accordion-item">
              <h5 id="headingFour" class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  {{ $t("release-print-data.label-faq") }}
                  <i
                    class="collapse-close fa fa-plus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                  <i
                    class="collapse-open fa fa-minus text-xs position-absolute end-0 me-3"
                    aria-hidden="true"
                  ></i>
                </button>
              </h5>
              <div
                id="collapsefour"
                class="accordion-collapse collapse preview-details-box"
                aria-labelledby="headingOne"
                style=""
              >
                <div class="accordion-body text-sm">Forth</div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div id="new-board-modal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-570 modal-dialog-centered">
        <div class="modal-content main-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("release-print-data.modal-label") }}
            </h5>
            <a
              href="javascript:void(0)"
              class="close p-0 m-0 text-white h4"
              data-dismiss="modal"
              data-target="#new-board-modal"
              aria-label="Close"
              @click="reset()"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div class="modal-body">
            <vmd-snackbar
              v-if="orderModalErrorMessage != null"
              :description="orderModalErrorMessage"
              :icon="{ color: 'white' }"
              color="danger"
              @click="closeSnackbar(4)"
            />
            <vmd-snackbar
              v-if="cdfInvalidMessage != null"
              :description="cdfInvalidMessage"
              :icon="{ color: 'white' }"
              color="danger"
              @click="closeSnackbar(8)"
            />
            <div class="order-details d-flex align-items-start justify-content-between">
              <div class="order-image">
                <div v-if="popup_image != null">
                  <img alt="" :src="popup_image" />
                </div>
                <div v-else>
                  <img alt="" />
                </div>
              </div>
              <div class="order-content">
                <div class="row">
                  <!-- <span v-if="orderModalErrorMessage != null">{{
                    orderModalErrorMessage
                  }}</span> -->
                  <div class="col-3 order-name">
                    <p>{{ $t("release-print-data.modal-name") }}</p>
                    <span>{{ productType }}</span>
                  </div>
                  <div class="col-3">
                    <p>{{ $t("release-print-data.modal-format") }}</p>
                    <span>
                      {{ updateOrderFormat(orderFormatwidth) }}
                      *
                      {{ updateOrderFormat(orderFormatheight) }}

                      ({{ dimensionFormat }})</span
                    >
                  </div>
                  <div class="col-3">
                    <p>{{ $t("release-print-data.modal-pages") }}</p>
                    <span>{{ updateOrderPages(orderSides, orderPages) }}</span>
                  </div>
                  <div class="col-3">
                    <p>{{ $t("release-print-data.modal-colourfulness") }}</p>
                    <span
                      >{{ orderColourfullnessFront }} /
                      {{ orderColourfullnessBack }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="share-order release-print-data-modal">
              <div class="row">
                <Form
                  id="releasePrintForm"
                  :validation-schema="schema"
                  :initial-values="initialValues"
                  autocomplete="off"
                  @submit="onSubmitCheck"
                  @invalid-submit="onInvalidSubmit"
                >
                  <div class="mb-2 form-group">
                    <label>{{ $t("release-print-data.modal-label-textbox") }}</label>
                    <vmd-input
                      id="remarks"
                      :placeholder="
                        $t('release-print-data.modal-label-textbox-placeholder')
                      "
                      name="remarks"
                    >
                    </vmd-input>
                  </div>
                  <div class="mb-2 form-group d-none">
                    <vmd-input
                      id="isDataValid"
                      v-model="isValidFormSubmit"
                      name="isDataValid"
                      type="text"
                    />
                  </div>
                  <div class="mb-2 form-group checkbox-box">
                    <label class="fw-normal">
                      <vmd-checkbox id="isContact" name="isContact" value="isTrue" />
                      {{ $t("release-print-data.modal-label-checkbox") }}
                    </label>
                    <ErrorMessage
                      name="isContact"
                      class="error-message text-sm fw-light text-danger"
                    />
                  </div>

                  <div class="modal-footer-buttons">
                    <vmd-button
                      class="shadow-none rounded-pill font-weight-normal btn-sm"
                      color="primary"
                      type="submit"
                      >{{ $t("release-print-data.modal-label-submitbutton") }}</vmd-button
                    >
                    <vmd-button
                      ref="formdata"
                      class="shadow-none rounded-pill font-weight-normal ms-2 btn-sm modal-close"
                      type="button"
                      color="outline-grey"
                      data-dismiss="modal"
                      data-target="#new-board-modal"
                      @click="reset"
                      >{{ $t("release-print-data.modal-label-closebutton") }}
                    </vmd-button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import VmdInput from "@/components/VmdInput.vue";
import VmdCheckbox from "@/components/VmdCheckbox.vue";
import VmdSelect from "@/components/VmdSelect.vue";
import { jwtDecrypt } from "@/shared/jwtHelper";
import moment from "moment";

//import VmdRadiobutton from "@/components/VmdRadio.vue";
//import VmdRadiobutton from "@/components/VmdCheckbox.vue";

import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import setTooltip from "@/assets/js/tooltip.js";
import Jquery from "jquery";
import Frontback from "./Frontbackview.vue";
import PDFTool from "./PDFTool.vue";
import SinglePartview from "./SinglePartview.vue";
import MultiPartview from "./MultiPartview.vue";
import axiosInterceptor from "@/shared/axiosInterceptor";
import VmdSnackbar from "@/components/VmdSnackbar.vue";

import i18n from "@/i18n";
import store from "@/store";
import { mapGetters, mapActions } from "vuex";

import Dropzone from "dropzone";

import { markRaw } from "vue";

import { Form, ErrorMessage } from "vee-validate";
import updateDimension from "../../Mixins/updateDimension";
import orderInfomration from "../../Mixins/orderInfomration";
import commonDropzone from "../../Mixins/commonDropzone";
import { createYupSchema } from "../../Mixins/createYupSchema";

import * as Yup from "yup";
import { fabric } from "fabric";

import Choices from "choices.js";
import Swal from "sweetalert2";
import axios from "axios";
//import Datepicker from "vuejs3-datepicker";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MultipartMiniature from "./MultipartMiniature1.vue";
import ExtraNotification from "./ExtraNotification.vue";

export default {
  name: "Previeww",
  components: {
    VmdButton,
    PerfectScrollbar,
    Frontback,
    VmdSnackbar,
    VmdInput,
    VmdCheckbox,
    Form,
    ErrorMessage,
    PDFTool,
    SinglePartview,
    MultiPartview,
    MultipartMiniature,
    //VmdRadiobutton,
    VmdSelect,
    Datepicker,
    ExtraNotification,
  },
  mixins: [updateDimension, orderInfomration, commonDropzone, createYupSchema],
  inject: ["eventBus"],
  data: function () {
    return {
      preDefinedMultipart: ["Booklet"],
      socketPreviewOrderId: 0,
      testK: 0,
      multiPart: false,
      multiPartArray: [0, 1],
      showCustom: false,
      lockScreen: 0,
      lockScreenMessage: 0,
      preDefinedMilestone: ["released", "finished", "archived"],
      preDefinedStatus: ["done"],
      EnableDrag: true,
      enabled: true,
      snackbar: null,
      dragging: -1,
      images: [],
      loadImageArray:[],
      previewImage: "",
      previewImageArray: [],
      thumbnailArray: [],
      coverThumbnailArray: [],
      coverLastIndex: 1,
      bodyLastIndex: 1,
      backImage: "",
      frontImage: "",
      material: "",
      finishing1: "",
      finishing2: "",
      totalVariant: "",
      crowd: "",
      currentindex: "",
      nextimageicon: true,
      previmageicon: false,
      isActive: "",
      isDrag: null,
      isDrop: null,
      isFrontDrop: null,
      isBackDrop: null,
      clickedItem: 1,
      showMiniature: false,
      MutipartMiniaturePreview: false,
      tmp: [],
      isActivearray: [],
      isdisplay: 0,
      isdisplayMutipart: 0,
      activeItem: {},
      singleimageindex: 0,
      frontimageindex: 0,
      backimageindex: 1,
      resourceId: 0,
      activeNames: null,
      fromPDFindex: null,
      fromSingleIndex: null,
      fromPreviewsIndex: null,
      fromThumbnailsIndex: null,
      toPDFindex: null,
      toSingleIndex: null,
      toPreviewsIndex: null,
      toThumbnailsIndex: null,
      requiredPage: 0,
      assignedPage: 0,
      coverPage: 0,
      coverDisplay: false,
      bodyPage: 0,
      bindingType: null,
      coverPageArray: [],
      coverImageArray: [],
      CoverImageWithThumb: [],
      ImageWithThumb: [],
      coverVariant: 0,
      bodyVariant: 0,
      MainMinatureArray: [],
      main: [],
      allProductData: [],
      requiredPageMessage: null,
      dataCheck: null,
      plausibilityCheck: {},
      showLine: 0,
      showLineIndex: 0,
      showDropzone: false,
      updatepageAssignType: 0,
      severityLevel: null,
      severityLevelMessage: null,
      files: [],
      myDropzone: "",
      radixContainerId: null,
      uuid: null,
      apiResponse: null,
      splitPDFData: [],
      DropzoneInit: false,
      fileuploadFlag: 0,
      socketClientId: null,
      fileList: [],
      validFile: 0,
      fileUploadComplete: 0,
      spiltPDFCompleted: 0,
      socketStart: false,
      errorFile: 0,
      resetFile: 0,
      allPDFUploaded: false,
      loader: null,
      canvasText: false,
      predefineUploadText:
        "<i class='fas fa-upload'></i>" +
        i18n.global.t("release-print-data.predefine-upload-text") +
        " : ",
      predefineSplitPDFText:
        "<i class='fas fa-file-pdf'></i>" +
        i18n.global.t("release-print-data.predefine-splitPDF-text") +
        " : ",
      predefineSaveImageText:
        "<i class='fas fa-image'></i>" +
        i18n.global.t("release-print-data.predefine-saveAsImage-text") +
        " : ",
      isValidFormSubmit: true,
      productType: null,
      orderFormatheight: null,
      orderFormatwidth: null,
      orderPages: null,
      orderMilestone: null,
      orderStatus: null,
      orderColourfullnessFront: null,
      orderColourfullnessBack: null,
      orderModalErrorMessage: null,
      checked: null,
      checkedNew: "",
      checkInit: {
        isDataValid: false,
      },
      PDFGenerateColor: null,
      PDFGenerate: null,
      severityError: null,
      mergePDFStatus: null,
      variantId: null,
      mountLoader: null,
      releaseBtnDisable: null,
      connectionLost: null,
      default_mm_value: null,
      default_inch_value: null,
      popup_image: null,
      orderSides: null,
      setTimer: null,
      checksocketconnect: null,
      cdf: [],
      cdfOrderId: 0,
      cdfProductId: 0,
      cdfPartId: 0,
      canvasComponents: false,
      isHeight: false,
      isWidth: false,
      isMediaboxPresent: [],
      isBleedboxPresent: [],
      isTrimboxPresent: [],
      isCropboxPresent: [],
      isArtboxPresent: [],
      canvas_check: [],
      MediaVisiable: [],
      TrimVisiable: [],
      BleedVisiable: [],
      CropVisiable: [],
      ArtVisiable: [],
      Mediarect: [],
      MediaBoxHeight: [],
      MediaBoxWidth: [],
      MediaBoxLeft: [],
      MediaBoxBottom: [],
      Bleedrect: [],
      BleedBoxHeight: [],
      BleedBoxWidth: [],
      BleedBoxLeft: [],
      BleedBoxBottom: [],
      Trimrect: [],
      TrimBoxHeight: [],
      TrimBoxWidth: [],
      TrimBoxLeft: [],
      TrimBoxBottom: [],
      Croprect: [],
      CropBoxHeight: [],
      CropBoxWidth: [],
      CropBoxLeft: [],
      CropBoxBottom: [],
      Artrect: [],
      ArtBoxHeight: [],
      ArtBoxWidth: [],
      ArtBoxLeft: [],
      ArtBoxBottom: [],
      bleedAreaVisiable: [],
      isbleedAreaPresent: [],
      overlayRect: [],
      destinationRect: [],
      panning: [],
      group_array: [],
      group: [],
      zoomFlag: [],
      dynamicZoom: [],
      zoomArray: [10, 25, 50, 75, 100, 200, 400],
      displayInCanvas: null,
      errorForZoom: null,
      backLocation: null,
      dynamicBorderValue: [],
      effectiveMediaHeight: [],
      effectiveMediaWidth: [],
      prevCanvasIcon: false,
      nextCanvasIcon: false,
      blurboxLeft: [],
      blurboxTop: [],
      blurboxWidth: [],
      blurboxHeight: [],
      minBox: [],
      single: null,
      choicesd: null,
      cdfStatus: null,
      remarkList: [],
      remarksCount: 0,
      allCDF: [],
      cdfConfirmFlag: 0,
      selecteddate: null,
      selecteddateId: null,
      CdfDateValid: [],
      cdfValid: false,
      cdfInvalidMessage: null,
      CdfErrorlist: {},
      defaultTime: { hours: 0, minutes: 0, seconds: 0 },
      testsocketid: null,
      preventAPI: false,
      imageProcessingStatus: null,
      undoArray: [],
      undoThumbArray: [],
      undoCoverArray: [],
      extraPage: 0,
      coverPageCount: 0,
      bodyPageCount: 0,
      tmpK: 0,
      processingMsg: [],
      mergeMessage: 0,
      submitDropzone: false,
      totalFile: 0,
      allFileList: [],
      uploadbtn: false,
      refreshBtn: false,
      selectMultipleImage: [],
      singleSelect: false,
    };
  },
  computed: {
    isDragging() {
      return this.dragging > -1;
    },
    ...mapGetters("auth", {
      getterLoginStatus1: "getRadixData",
    }),
    /*cdfMessage() {
      return i18n.global.t("release-print-data.CDF-update-message");
    },*/
    /* minimumFile() {
      return i18n.global.t("release-print-data.error-message-minimumfile");
    },*/
    initialValues() {
      var checkInit = {
        isDataValid: this.isValidFormSubmit,
        //isDataValid: false,
      };
      // console.log("initial", checkInit);
      return checkInit;
    },
    /*imageProcessing() {
      return i18n.global.t("release-print-data.image-processing-msg");
    },
    releaseLockMessage() {
      return i18n.global.t("release-print-data.show-release-lock-message");
    },*/
    initialCDFValues() {
      var obj = {};
      let p = 0;
      //console.log(`this.cdf`, this.cdf);
      for (let i = 0; i < this.cdf.length; i++) {
        if (this.cdf[i].hidden == false || this.cdf[i].usage == "customData") {
          if (this.cdf[i].editData.type == "boolean" && this.cdf[i].value == "false") {
            p = p + 1;
          } else {
            if (this.cdf[i].editData.type == "date") {
              this.setDateValid(this.cdf[i].id);
            }
            let key = "inputname" + this.cdf[i].id;
            let value = this.cdf[i].value;
            obj[key] = value;
          }
        }
      }
      //console.log(`initialCDFValues`, obj);
      return obj;
    },

    schema() {
      return markRaw(
        Yup.object().shape({
          isDataValid: Yup.boolean(),
          remarks: Yup.string().when("isDataValid", {
            is: true,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) =>
              schema.required(i18n.global.t("release-print-data.please-enter-remarks")),
          }),
          isContact: Yup.string().when("isDataValid", {
            is: true,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) =>
              schema.required(i18n.global.t("release-print-data.please-checked-contact")),
          }),
        })
      );
    },
    CDFSchema() {
      /*console.log(i18n.global.t("demo"));
      console.log(this.$t("demo", "at"));*/

      Yup.addMethod(
        Yup.string,
        "validatingPattern",
        function validateEmail(message, value) {
          /*  console.log(message, updateKey);
          message.replace("must", "must1");*/
          return this.matches(new RegExp(value), {
            message,
            name: "string",
            excludeEmptyString: true,
          });
        }
      );

      let k = 0;
      const fields = [];
      for (let i = 0; i < this.cdf.length; i++) {
        if (this.cdf[i].hidden == false) {
          let updateType = this.cdf[i].editData.type;
          let ValidationType = this.cdf[i].editData.type;
          let validationsArray = [];

          if (this.cdf[i].editData.type == "boolean") {
            updateType = "radio";
            ValidationType = "string";
          } else if (
            this.cdf[i].editData.type == "string" &&
            this.cdf[i].editData.enum.length > 0
          ) {
            updateType = "text";
            ValidationType = "string";
          } else if (
            this.cdf[i].editData.type == "string" &&
            this.cdf[i].editData.enum.length == 0
          ) {
            updateType = "text";
            ValidationType = "string";
          } else if (this.cdf[i].editData.type == "object") {
            updateType = "text";
            ValidationType = "object";
            validationsArray.push({
              type: "typeError",
              params: ["Please enter valid object"],
            });
          } else if (
            this.cdf[i].editData.type == "number" ||
            this.cdf[i].editData.type == "integer"
          ) {
            updateType = "text";
            ValidationType = "number";

            validationsArray.push({
              type: "typeError",
              params: ["Please enter valid number"],
            });

            if (this.cdf[i].editData.type == "integer") {
              validationsArray.push({
                type: "integer",
                params: ["Please enter integer number"],
              });
            }
          } else if (
            this.cdf[i].editData.type == "date" ||
            this.cdf[i].editData.type == "datetime"
          ) {
            ValidationType = "string";
            updateType = "text";
          }

          //if field is not required and type is number,integer or object
          if (
            this.cdf[i].required == false &&
            (this.cdf[i].editData.type == "number" ||
              this.cdf[i].editData.type == "integer" ||
              this.cdf[i].editData.type == "object")
          ) {
            validationsArray.push({
              type: "nullable",
              params: [true],
            });

            if (this.cdf[i].editData.type == "object") {
              validationsArray.push({
                type: "transform",
                params: [(_, val) => (val !== "" ? val : null)],
              });
            } else {
              validationsArray.push({
                type: "transform",
                params: [(_, val) => (val !== "" ? Number(val) : null)],
              });
            }
          }

          //pattern
          if (this.cdf[i].editData.pattern != "") {
            let pattern_test = this.cdf[i].editData.pattern;
            let setmessage =
              this.cdf[i].displayKey + " must match the following:" + pattern_test;
            validationsArray.push({
              type: "validatingPattern",
              params: [setmessage, new RegExp(pattern_test)],
            });
          }

          //min validation
          if (
            this.cdf[i].editData.min != undefined &&
            this.cdf[i].editData.type != "date" &&
            this.cdf[i].editData.type != "datetime"
          ) {
            // console.log("min val", this.cdf[i].editData.min, this.cdf[i].id);
            let minValueMessage = `${this.cdf[i].displayKey} must be greater than or equal to ${this.cdf[i].editData.min}`;
            if (ValidationType == "string") {
              minValueMessage += " Character";
            }

            validationsArray.push({
              type: "min",
              params: [this.cdf[i].editData.min, minValueMessage],
            });
          }
          //max validation
          if (
            this.cdf[i].editData.max != "" &&
            this.cdf[i].editData.max != undefined &&
            this.cdf[i].editData.type != "date" &&
            this.cdf[i].editData.type != "datetime"
          ) {
            let maxValueMessage = `${this.cdf[i].displayKey} must be less than or equal to ${this.cdf[i].editData.max}`;
            if (ValidationType == "string") {
              maxValueMessage += " Charecter";
            }

            validationsArray.push({
              type: "max",
              params: [this.cdf[i].editData.max, maxValueMessage],
            });
          }

          //required
          if (this.cdf[i].required == true) {
            validationsArray.push({
              type: "required",
              params: [this.cdf[i].displayKey + " is required"],
            });
            /*if (this.cdf[i].editData.type == "date") {
              validationsArray.push({
                type: "nullable",
                params: [true],
              });
            }*/
          }

          fields.push({
            id: "inputname" + this.cdf[i].id,
            name: "inputname" + this.cdf[i].id,
            type: updateType, //text,radio
            label: this.cdf[i].displayKey,
            // value: this.cdf[i].value,
            placeholder: "Enter " + this.cdf[i].displayKey,
            validationType: ValidationType,
            validations: validationsArray,
          });

          //console.log(`loop`, fields);
          k = k + 1;
        }
      }
      // console.log(fields);

      /*temp.setValue(["Four", "Five", "Six"]);*/

      //console.log(fields);
      const yepSchema = fields.reduce(createYupSchema, {});
      //  console.log(yepSchema);
      const validateSchema = Yup.object().shape(yepSchema);

      //console.log(validateSchema);
      return validateSchema;
    },
    updateOrderFormat: function () {
      store.getters["auth/getDimensionFormat"];
      return (id) => this.updateDimension(id);
    },
    customDataLabel1: function () {
      return (id) => this.customDataLabel(id);
    },
    dimensionFormat() {
      let currentDimension = store.getters["auth/getDimensionFormat"];
      if (currentDimension == "") {
        currentDimension = localStorage.getItem("format");
      }
      return currentDimension;
    },
    getCanvasMessage() {
      return i18n.global.t("release-print-data.Canvas-Error-message");
    },
    getApproveMessage() {
      return i18n.global.t("release-print-data.approve-succcess-message");
    },
    getClosebuttonText() {
      return i18n.global.t("release-print-data.close-button");
    },
    getCDFConfirmMessage() {
      return i18n.global.t("release-print-data.CDF-confirm-message");
    },
    getSwalCDFSave() {
      return i18n.global.t("release-print-data.CDF-swal-save-Button");
    },
    getCDFCancelbutton() {
      return i18n.global.t("release-print-data.CDF-swal-cancel-Button");
    },
    uplodProcessInfo() {
      let statusUpload = this.$store.getters["auth/getUploadProcess"];
      return statusUpload;
    },
    successMessageForPDF() {
      return i18n.global.t("order-common.socket-PDF-upload");
    },
  },
  async mounted() {
    // New save as image response
    this.eventBus.on("loadImages", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.loadImagesOrders(response);
      }
    });

    //hideFileDropzone
    this.eventBus.on("hideFileDropzone", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.RemovedFileFromDropzone(response);
      }
    });

    this.eventBus.on("errorInSaveImage", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.errorInSaveImage(response);
      }
    });

    this.eventBus.on("generateExtraNotification", async (response) => {
      //var child = this.$refs.extraNotificationRef;
      //console.log(`chid`, child);
      //child.demo();
      console.log("ojiijij");
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.response.message.orderId
      );
      if (checkStatus == false && this.$route.name == "Preview") {
        await this.PassNotificationData(response);
        /*this.extraNotificationId = this.extraNotificationId + 1;
        this.extraNotification.push({
          type: response.type,
          status: response.status,
          translate: response.translate,
          message: response.message,
          Id: this.extraNotificationId,
        });
        console.log(`this.extraNotification`, this.extraNotification);
        this.NotificationTimeOutArray(this.extraNotificationId);*/
      }
    });

    // Event bus
    /*this.eventBus.on("updateRequiredFlags", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.updateRequiredFlags(response);
      }
    });

    // Spilt PDF Socket response  using event bus
    this.eventBus.on("increaseSplitPDF", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.increaseSplitPDF();
      }
    });

    // Save as Image Socket response using event bus
    this.eventBus.on("increaseSaveAsImage", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.increaseSaveAsImage();
      }
    });

    // Error in upload process Socket response using event bus
    this.eventBus.on("errorInUploadProcess", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.errorInUploadProcess();
      }
    });*/

    // Merge PDF response using socket and event bus
    this.eventBus.on("mergePDFaction", async (response) => {
      let checkStatus = await this.ValidateOpenURL(
        this.$route.params.OrderId,
        response.message.orderId
      );
      if (checkStatus == true) {
        this.mergePDFAction();
      }
    });

    let getOrderId = await this.decryptOrderId(this.$route.params.OrderId);
    //console.log(`getOrderId`, getOrderId);
    if (getOrderId != false) {
      this.OrderId = await this.decryptOrderId(this.$route.params.OrderId);
      this.$route.params.OrderId = this.OrderId;
      this.socketPreviewOrderId = this.OrderId;
    } else {
      await this.InvalidOrderId();
      return;
    }
    let orderProcessStatus = null;
    await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
      //console.log(`res`, res);
      orderProcessStatus = res.status;
    });

    this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
    if (orderProcessStatus == true) {
      this.imageProcessingStatus = "success";
      this.AddProcessingMsg(
        "imageProcessingMsg",
        "release-print-data.image-processing-msg",
        "success",
        false,
        true
      );
    } else {
      this.preventAPI = false;
    }

    this.popup_image = this.$noImagePreview;
    this.autoRefreshToken(4);
    this.showLine = 0;
    this.preDefinedMultipart = ["Booklet"];
    await this.getRadixData();

    //this.resourcesImage();

    this.isdisplay = 1;
    if (this.isdisplay == this.requiredPage) {
      this.nextimageicon = false;
    }
    this.isDrag = null;
    //this.showLine = 0;
    this.default_mm_value = process.env.VUE_APP_MM_VALUE;
    this.default_inch_value = process.env.VUE_APP_INCH_VALUE;

    setTooltip(this.$store.state.bootstrap);

    Jquery(".accordion-button").click(function () {
      if (Jquery(this).hasClass("collapsed")) {
        Jquery(this)
          .parents(".accordion-item")
          .find(".accordion-item .accordion-button:not(.collapsed)")
          .click();
      }
    });

    this.socketClientId = this.$socket.id;

    this.$socket.on("connect_error", (error) => {
      //this.connectionLost = true;
      console.log("connection_error", error);

      this.AddProcessingMsg(
        "connection_error",
        "Socket Connection Lost.Please refresh the page",
        "danger",
        false,
        false
      );

      //this.socket.connect();
      //this.socket.emit("msgToServer", {});
    });
  },
  beforeUnmount() {
    this.socketPreviewOrderId = 0;
    clearInterval(this.setTimer);
    this.checksocketconnect = "";
    //Need to off otherwise trigger multiple times
    this.eventBus.off("generateExtraNotification");
  },
  created() {
    const yourElement = document.querySelector("#logoofsite");

    // make sure the element is exists first
    if (yourElement) {
      yourElement.addEventListener("click", async (event) => {
        if (this.showCustom == true) {
          await this.swalForCDF(1);
          event.preventDefault();
        }
      });
    }
  },

  methods: {
    ...mapActions({
      RemoveUploadProcessData: "auth/RemoveUploadProcessData",
      imageGenerate: "auth/imageGenerate",
      AddallOrderInfo: "auth/AddallOrderInfo",
      RemoveOrderId: "auth/RemoveOrderId",
    }),
    async PassNotificationData(response) {
      console.log(`Object.keys(response).length`, Object.keys(response).length);
      if (Object.keys(response).length > 0) {
        console.log("ininirrrrrr");
        await this.$refs.extraNotificationRef.childNotification(response);
      }
    },
    closeSnackbarArray(index) {
      this.processingMsg[index].status = "hide";
    },
    closeNotification(index) {
      this.extraNotification.splice(index, 1);
    },
    AddProcessingMsg(messageType, message, type, timeoutFlag, translate = true) {
      //using for removed message after the 5 second
      this.mergeMessage = this.mergeMessage + 1;
      const ifExist = this.processingMsg.findIndex((object) => {
        return object.type == messageType;
      });
      const newMessage = {
        message,
        type: messageType,
        status: "show",
        id: this.mergeMessage,
        icon: type,
        translate,
      };

      if (ifExist >= 0) {
        //update current message
        Object.assign(this.processingMsg[ifExist], newMessage);
      } else {
        this.processingMsg.push(newMessage);
      }
      if (timeoutFlag == true) {
        this.snackbarTimeOutArray(this.mergeMessage);
      }
    },
    snackbarTimeOutArray(value) {
      setTimeout(() => {
        const deleteMsg = this.processingMsg.findIndex((object) => {
          return parseInt(object.id) == parseInt(value);
        });
        this.processingMsg.splice(deleteMsg, 1);
      }, 5000);
    },
    async loadImagesOrders(response) {
      // Enable the API call
      this.preventAPI = false;
      this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;

      // Extract values from the response
      const { resourceId, pagerange, workflow, status } = response.message;

      // Load resources images
      await this.resourcesImage(resourceId, pagerange, workflow, status);
    },
    async RemovedFileFromDropzone(response) {
      const storeAllFile = this.$store.getters["auth/getallOrderInfo"];
      const { message } = response;

      const completed = storeAllFile.filter(
        (element) => element.uniqueId === message.uniqueId
      );

      //var myDropzoneAbort = Dropzone.forElement("#productImg");
      // Hide the div from the dropzone
      for (const item of completed) {
        const elementId = `mainDivDrop${item.resourceId}`;
        const element = document.getElementById(elementId);

        if (element && item.saveasimage != "error") {
          element.remove();
        }
      }

      //Check any file exist in dropzone
      const box = document.getElementById("productImg");
      if (box.querySelector(".dz-image-preview") == null) {
        var myDropzoneAbort = Dropzone.forElement("#productImg");
        myDropzoneAbort.removeAllFiles(true);
        this.showDropzone == true;
      }
    },
    async errorInSaveImage(response) {
      let orderProcessStatus = null;
      await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
        //console.log(`res`, res);
        orderProcessStatus = res.status;
      });
      let ifExistError = null;
      if (response.message.status == "error") {
        ifExistError = this.allFileList.findIndex((object) => {
          return parseInt(object.uplResourceId) === parseInt(response.message.resourceId);
        });
        if (ifExistError >= 0) {
          this.allFileList[ifExistError]["status"] = "error";
        }
      }

      if (orderProcessStatus == false) {
        const ifExist = this.processingMsg.findIndex(
          (msg) => msg.type === "imageProcessingMsg"
        );
        if (ifExist > 0) {
          this.processingMsg.splice(ifExist, 1);
        }
      }
    },
    /*updateRequiredFlags(response) {
      this.fileuploadFlag += parseInt(1);
      this.fileList.push({
        fileName: response.message.fileName,
        fileRadixResourceId: response.message.radixResourceId,
        processing: false,
        splitPDF: null,
        saveAsImage: null,
      });

      this.validFile += parseInt(1);
    },
    increaseSplitPDF() {
      this.spiltPDFCompleted = this.spiltPDFCompleted + 1;
      //console.log("split", this.validFile, this.spiltPDFCompleted);
      if (this.validFile === this.spiltPDFCompleted && this.spiltPDFCompleted > 0) {
        //console.log("In split PDF");
        this.isActive = true;
      }
    },
    async increaseSaveAsImage() {
      this.fileUploadComplete = this.fileUploadComplete + 1;
      //console.log(`savimage`, this.validFile, this.fileUploadComplete);
      if (this.validFile === this.fileUploadComplete && this.fileUploadComplete > 0) {
        //console.log("alluploaded");
        this.allPDFUploaded = true;
        //this.loader.hide();
        this.main = [];
        this.images = [];

        this.resourcesImage();
        await this.orderDetail(2);
        this.socketStart = false;
        this.showDropzone = false;
        var myDropzoneAbort = Dropzone.forElement("#productImg");
        myDropzoneAbort.removeAllFiles(true);
        this.snackbarTimeOut(3);
      }
      if (this.preventAPI == true) {
        let orderProcessStatus = null;
        await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
          //console.log(`res`, res);
          orderProcessStatus = res.status;
        });

        //console.log(`orderProcessStatus`, orderProcessStatus);
        if (orderProcessStatus == false) {
          this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
          this.preventAPI = false;
          await this.getRadixData();
        }
      }
    },
    async errorInUploadProcess(orderId) {
      //console.log("in error");
      this.validFile -= parseInt(1);
      this.errorFile += parseInt(1);
      if (
        this.errorFile === this.totalFile ||
        this.fileUploadComplete == this.validFile
      ) {
        //console.log("in abort con");
        this.isAbortActive = true;
      }
      await this.RemoveOrderId(orderId);
    },*/
    async mergePDFAction() {
      this.releaseBtnDisable = false;
      this.mergePDFStatus = "Done";
      this.PDFGenerateColor = "success";
      this.PDFGenerate = i18n.global.t("release-print-data.PDF-Generate-message");
      this.AddProcessingMsg(
        "preparePDF",
        "release-print-data.PDF-Generate-message",
        this.PDFGenerateColor,
        true,
        true
      );

      setTimeout(async () => {
        await this.orderDetail(2);
      }, 2000);

      this.snackbarTimeOut(4);
    },
    async ValidateOpenURL(URLOrderId, responseOrderId) {
      var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      if (format.test(URLOrderId)) {
        URLOrderId = await this.decryptOrderId(URLOrderId);
      }
      if (this.socketPreviewOrderId == responseOrderId && this.$route.name == "Preview") {
        return true;
      } else {
        return false;
      }
    },
    convertDate(value) {
      Dropzone.forElement("#productImg");
      return value;
    },
    setDateValid(id) {
      this.CdfDateValid[id] = null;
    },
    opened(id) {
      this.selecteddateId = id;
      this.CdfDateValid[this.selecteddateId] = false;
    },
    selected(selectValue) {
      let autoId = this.selecteddateId;
      var index = this.cdf
        .map(function (o) {
          return o.id;
        })
        .indexOf(parseInt(autoId));

      if (selectValue != null && index > 0) {
        let converIntodate = "";
        if (this.cdf[index].editData.type == "date") {
          converIntodate = moment(selectValue).format("YYYY-MM-DD");
        } else {
          converIntodate = moment(selectValue).format("YYYY-MM-DD HH:mm:ss");
        }

        this.cdf[index].value = converIntodate;
      }
    },
    clearedDate(id) {
      this.selecteddateId = id;

      //this.CdfDateValid[id] = "";
      let autoId = id;
      var index = this.cdf
        .map(function (o) {
          return o.id;
        })
        .indexOf(parseInt(autoId));

      this.cdf[index].value = "";
    },
    async confirmDeleteResource(deleteId) {
      Swal.fire({
        title: i18n.global.t("release-print-data.delete-resource-title"),
        text: i18n.global.t("release-print-data.delete-resource-message"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.global.t("release-print-data.delete-confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteResourceAPI(deleteId);
        }
      });
    },
    async deleteResourceAPI(id) {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      const result = await axiosInterceptor.delete(`/api/v1/resources/pageDelete/${id}`);
      if (result.status == 200 || result.status == "OK") {
        this.images = [];
        await this.resourcesImage();
        loader.hide();
        Swal.fire(
          "",
          i18n.global.t("release-print-data.delete-resource-success-message"),
          "success"
        );
      }
    },
    async confirmUnassignResource(sortSequence, partType) {
      Swal.fire({
        title: i18n.global.t("release-print-data.unassign-resource-title"),
        text: i18n.global.t("release-print-data.unassign-resource-message"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: i18n.global.t("release-print-data.unassign-confirm"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.UnassignResourceAPI(sortSequence, partType);
        }
      });
    },
    async UnassignResourceAPI(sequence, part) {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      let findArrayIndex = this.images.findIndex(
        this.getOldSortSequence(this.images, sequence, part)
      );
      let imageInfo = this.images[findArrayIndex];
      const temp = imageInfo;
      let partType = temp["partType"];
      let sortSequence = temp["sortSequence"];

      imageInfo["partType"] = "";
      imageInfo["sortSequence"] = null;
      imageInfo["variantId"] = null;
      let unAssignArray = [];
      unAssignArray.push(imageInfo);
      let orderHeader = {
        orderId: this.OrderId,
      };
      console.log("in 6");
      const result = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&plausibilityCheck=true&jobLock=true`,
        unAssignArray,
        orderHeader
      );
      if (result.status == 201) {
        // Delete images based on type
        if (partType == "Cover") {
          delete this.CoverImageWithThumb[sortSequence];
        } else {
          delete this.thumbnailArray[sortSequence];
          delete this.previewImageArray[sortSequence];
        }
        await this.SortImageorder();
        await this.maxAssignednumber();
        await this.CheckValidation(result.data.plausibilityCheck, 2);
        loader.hide();
        Swal.fire(
          "",
          i18n.global.t("release-print-data.unassign-resource-success-message"),
          "success"
        );
      }
    },
    async checkOrderLockStatus() {
      if (this.OrderId == null || this.OrderId == undefined) {
        return;
      }
      // If order has relased and done then lock the screen and show the relased message insted of any other user access the order
      if (this.orderStatus == "done" && this.orderMilestone == "released") {
        this.lockScreen = 1;
        this.lockScreenMessage = 1;
        this.AddProcessingMsg(
          "relasedOrderlock",
          "release-print-data.show-release-lock-message",
          "danger",
          false,
          true
        );
        return;
      }

      try {
        const userInfo = await axiosInterceptor.get(`api/auth/userInfo`);
        const authData = store.getters["auth/getAuthData"];
        var config = {
          headers: { Authorization: `Bearer ${authData.token}` },
        };

        if (localStorage.getItem("loginType") == 2) {
          config.headers["idp_token"] = localStorage.getItem("zaikio_access_token");
        }

        config.headers["orderId"] = this.OrderId;
        let orderLockReponse = await axios.get(
          `/api/v1/orders/${this.OrderId}/jobStatus`,
          config
        );
        if (orderLockReponse.status == 200 && orderLockReponse.data.isJobLock == "1") {
          // If guest user has been login
          if (localStorage.getItem("session")) {
            let sessionData = jwtDecrypt(localStorage.getItem("session"));
            if (
              sessionData.guestId &&
              sessionData.guestId != orderLockReponse.data.userId
            ) {
              this.lockScreen = 1;
              this.AddProcessingMsg(
                "orderlockbyUser",
                "release-print-data.show-lock-message",
                "danger",
                false,
                true
              );
            }
          } else {
            let ValidateUserId = userInfo.data.id;
            if (localStorage.getItem("loginType") == 2) {
              ValidateUserId = userInfo.data.personId;
            }

            if (orderLockReponse.data.userId != ValidateUserId) {
              this.lockScreen = 1;
              this.AddProcessingMsg(
                "orderlockbyUser",
                "release-print-data.show-lock-message",
                "danger",
                false,
                true
              );
            }
          }
        }
        return false;
      } catch (error) {
        console.log(error);
      }
    },
    getChoices(value) {
      console.log(value);

      //var element = document.getElementsByClassName("dynamicselect");
      var element = document.getElementById("inputcdf41");
      var initElement = new Choices(element, {
        // searchEnabled: faLastfmSquare,
        shouldSort: false,
      });
      return initElement;
    },

    customDataLabel(value) {
      console.log(value);
      //if (this.cdfOrderId == 0) {
      this.cdfOrderId = this.cdfOrderId + 1;
      //}
    },
    customshow() {
      if (this.showCustom == false) {
        this.showCustom = true;
        this.showDropzone = false;
      } else {
        this.showCustom = false;
      }
    },
    onInvalidSubmit(values) {
      console.log("onInvalidSubmit", values);
    },
    saveIconCDF() {
      document.querySelector("#CDFSubmitButton").click();
    },
    async CDFSubmit(values) {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      for (var key in values) {
        if (key.includes("inputname")) {
          let temp = key;
          this.allCDF[temp] = values[key];
          let dynamicObj = {};
          if (values[key] == "") {
            values[key] = undefined;
          }

          var matches = key.match(/(\d+)/);
          let autoId = matches[0];
          var index = this.cdf
            .map(function (o) {
              return o.id;
            })
            .indexOf(parseInt(autoId));
          // console.log(this.cdf[index]);
          if (
            this.cdf[index].editData["type"] == "boolean" &&
            (values[key] == "" || values[key] == undefined)
          ) {
            values[key] = "false";
          }
          //console.log(`autoId`, autoId, `values[key]`, values[key]);
          if (
            (this.cdf[index].editData["type"] == "date" ||
              this.cdf[index].editData["type"] == "datetime") &&
            values[key] != undefined
          ) {
            //values[key] = moment(values[key]).format("YYYY-MM-DD");
            //values[key] = this.cdf[index]["value"];
          }
          // console.log(`autoId`, autoId, `values[key]`, values[key]);
          let test = values[key];
          if (
            (this.cdf[index].editData["type"] == "date" ||
              this.cdf[index].editData["type"] == "datetime") &&
            values[key] == undefined
          ) {
            test = null;
          }

          dynamicObj["value"] = test;
          dynamicObj["orderId"] = parseInt(this.OrderId);

          //console.log(`index`, index);
          var APIresponse = await axiosInterceptor.patch(
            `api/v1/customDataField/${autoId}`,
            dynamicObj
          );
          if (APIresponse.status == 200) {
            this.cdf[index]["value"] = values[key];
          }
        }
        await this.validateCDF(values);
        //console.log(APIresponse);
      }
      //console.log(this.allCDF);
      //this.getCustomData();
      loader.hide();

      this.cdfStatus = "success";
      this.AddProcessingMsg(
        "cdfStatus",
        "release-print-data.CDF-update-message",
        this.cdfStatus,
        true,
        true
      );

      document.getElementById("CDFForm").reset();
      this.showCustom = false;
    },
    async onInvalidCDF(dafaultClick) {
      const form = document.querySelector("#CDFForm");
      if (form != undefined && form != null) {
        const values = Object.fromEntries(new FormData(form).entries());

        //console.log(values);
        await this.validateCDF(values);

        if (dafaultClick == 0) {
          this.CDFSubmit(values);
        }
      }
    },
    async validateCDF(values) {
      let errors = {};

      await this.CDFSchema.validate(values, { abortEarly: false })
        .then(() => {
          //this.errors = {};
          // login the user
        })
        .catch(async (err) => {
          err.inner.forEach((error) => {
            errors[error.path] = error.message;
          });
        });
      // console.log(errors);
      this.CdfErrorlist = errors;

      let validCDF = false;
      this.cdfInvalidMessage = null;
      if (Object.keys(this.CdfErrorlist).length > 0) {
        validCDF = false;
        this.cdfValid = false;
        this.cdfInvalidMessage = "Please enter valid values in Custom date field";
      } else {
        validCDF = true;
        this.cdfValid = true;
        this.CdfErrorlist["valid"] = "All Custom data has valid values";
      }
      this.plausibilityCheck["cdfError"] = {};
      this.plausibilityCheck["cdfError"]["label"] = "Custom Data Field";
      this.plausibilityCheck["cdfError"]["customData"] = this.CdfErrorlist;
      this.plausibilityCheck["cdfError"]["status"] = validCDF;
      this.plausibilityCheck["cdfError"]["message"] = "";

      await this.CheckValidation(this.plausibilityCheck, 3);
      //console.log(this.plausibilityCheck);
      //console.log(`CdfErrorlist`, this.CdfErrorlist);
    },
    async onSubmitCheck(values) {
      await this.checkOrderLockStatus();
      if (this.lockScreen == 0) {
        var loaderDisplayPosition = document.body;
        let loader = this.$loading.show({
          container: loaderDisplayPosition,
        });
        var submitArray = [];

        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].sortSequence == null) {
            break;
          }
          if (this.images[i] != undefined) {
            if (this.images[i].variantId == null) {
              this.images[i].variantId = this.variantId;
            }
            submitArray.push({
              id: this.images[i].radixResourceId,
              variantId: this.images[i].variantId,
              //radixResourceId: tmp,
            });
          }
        }
        //console.log(`variant`, submitArray);
        //values.isDataValid = undefined;
        if (values.remarks == undefined) {
          values.remarks = "";
        }
        if (values.isContact == undefined) {
          values.isContact = "";
        }
        values.resources = submitArray;
        /*let checkr = [];
        checkr["resources"] = submitArray;*/
        //console.log(values);

        let orderHeader = {
          orderId: this.OrderId,
        };

        /*let variantId = this.variantId;
        if (this.multiPart == true) {
          variantId = this.bodyVariant;
        }
        // console.log(`this.bodyVariant`, this.bodyVariant);
        console.log(values, variantId);*/
        var APIresponse = await axiosInterceptor.post(
          `api/v1/resources/releasePrintData?orderId=${this.OrderId}&clientId=${this.socketClientId}`,
          values,
          orderHeader
        );

        const mergepdfresponse = document.getElementById("mergePDFspan");
        if (mergepdfresponse != null) {
          if (mergepdfresponse.classList.contains("messageSuccess")) {
            mergepdfresponse.classList.remove("messageSuccess");
          }
        }

        if (APIresponse.status == "created" || APIresponse.status == 201) {
          this.releaseBtnDisable = true;
          this.mergePDFStatus = "In Progress";
          if (values.remarks != "") {
            this.remarksCount = this.remarksCount + 1;
            let remarksObj = {};
            let editdataObj = {};
            editdataObj["type"] = "string";
            editdataObj["pattern"] = "";
            editdataObj["enum"] = [];
            remarksObj["key"] = "remark" + this.remarksCount;
            remarksObj["usage"] = "remark";
            remarksObj["displayKey"] = "Remark";
            remarksObj["value"] = values.remarks;
            remarksObj["orderId"] = parseInt(this.OrderId);
            remarksObj["referenceId"] = parseInt(this.OrderId);
            remarksObj["editData"] = editdataObj;

            await axiosInterceptor.post(`api/v1/customDataField`, remarksObj);
            this.remarkList.push(remarksObj);
          }
          await this.orderDetail(2);
          let already_login = this.$store.getters["auth/getAuthData"];

          this.lockScreen = 1;
          this.lockScreenMessage = 1;
          this.EnableDrag = false;
          Swal.fire({
            title: "",
            text: this.getApproveMessage,
            icon: "info",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonText: this.getClosebuttonText,
          }).then((result) => {
            if (result.isConfirmed) {
              //redirection code
              if (already_login.userType == "User") {
                this.$router.replace("/orders/list");
              } else {
                this.$router.replace("/login");
              }
            }
          });
          /*this.PDFGenerateColor = "success";
          this.PDFGenerate = i18n.global.t("release-print-data.PDF-Generate-message");*/
        } else {
          this.PDFGenerateColor = "danger";
          this.PDFGenerate = i18n.global.t("release-print-data.PDF-Error-message");
          this.AddProcessingMsg(
            "preparePDF",
            "release-print-data.PDF-Error-message",
            this.PDFGenerateColor,
            true,
            true
          );
        }
        //hide modal
        // document.querySelector("#new-board-modal").classList.remove("show");
        // document.querySelector("body").classList.remove("modal-open");
        // const mdbackdrop = document.querySelector(".modal-backdrop");
        // if (mdbackdrop) {
        //   mdbackdrop.classList.remove("modal-backdrop", "show");
        // }

        document.querySelector(".modal-close").click();

        loader.hide();
      } else {
        document.querySelector(".modal-close").click();
      }
    },

    async getRadixData() {
      var loaderDisplayPosition = document.body;
      //console.log("in loader1");
      this.mountLoader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      //mixing method

      //await this.getCustomData();
      //console.log(`ggggg`, this.preventAPI, this.OrderId, this.preventAPI);
      if (this.preventAPI == false) {
        if (this.OrderId > 0) {
          await this.orderDetail(2);
          // if (this.productType == "Booklet") {
          if (this.preDefinedMultipart.includes(this.productType)) {
            this.multiPart = true;
            this.isdisplayMutipart = 0;
            if (this.coverPage > 0) {
              this.clickedItem = 5;
            } else {
              this.clickedItem = 1;
            }
          } else {
            this.multiPart = false;
          }
          await this.initDropzone();
          await this.resourcesImage();
          await this.getRemarkList();
        }
      } else {
        this.mountLoader.hide();
      }
    },
    async getRemarkList() {
      let orderHeader1 = {
        orderId: this.OrderId,
      };

      let sData = `?s={"orderId":${this.OrderId}, "usage":"remark"}`;
      const remarkData = await axiosInterceptor.get(
        `/api/v1/customDataField${sData}`,
        orderHeader1
      );
      if (remarkData.status == 200) {
        this.remarkList = remarkData.data;
        this.remarksCount = this.remarkList.length;
      }
    },
    async CheckValidation(Validatedata, updateMessage = 0) {
      if ("cdfError" in this.plausibilityCheck && this.cdf.length > 0) {
        if ("cdfError" in Validatedata) {
          //
        } else {
          Validatedata["cdfError"] = {};
          Validatedata["cdfError"]["label"] = this.plausibilityCheck["cdfError"]["label"];
          Validatedata["cdfError"]["customData"] = this.plausibilityCheck["cdfError"][
            "customData"
          ];
          Validatedata["cdfError"]["status"] = this.plausibilityCheck["cdfError"][
            "status"
          ];
          Validatedata["cdfError"]["message"] = this.plausibilityCheck["cdfError"][
            "message"
          ];
        }
      }

      let processDataCheck = false;
      this.requiredPage = Validatedata.isAllPagesAssigned.customData.requiredPages;

      this.assignedPage = Validatedata.isAllPagesAssigned.customData.pagesAssigned;
      if (this.coverPage == 0) {
        this.bodyPage = this.requiredPage;
        if (parseInt(this.bodyPage) % 2 == 0) {
          this.extraPage = 2;
        }
      }

      //updateMessage =3 submit from CDF
      let requiredPagemessage;
      const deleteMsg = this.processingMsg.findIndex((object) => {
        return object.type == "requiredpagemessage";
      });
      if (deleteMsg >= 0) {
        //console.log(`deleteMsg`, deleteMsg);
        //await this.processingMsg.splice(deleteMsg, 1);
      }

      if (this.requiredPage > this.assignedPage) {
        processDataCheck = false;

        //console.log(`updateMessage`, updateMessage);
        if (updateMessage != 3) {
          //this.snackbar = "danger";
          requiredPagemessage =
            this.assignedPage +
            " / " +
            this.requiredPage +
            " page is not assign.Please assign.";
          this.orderModalErrorMessage = requiredPagemessage;
          await this.AddProcessingMsg(
            "requiredpagemessage",
            requiredPagemessage,
            "danger",
            false,
            false
          );
        }
      } else {
        processDataCheck = true;
        this.severityError = null;
        this.orderModalErrorMessage = null;
        if (updateMessage == 2) {
          //console.log("in snakbar");]
          // this.snackbar = "success";
          requiredPagemessage = "All pages are assigned.";
          this.orderModalErrorMessage = null;
          await this.AddProcessingMsg(
            "requiredpagemessage",
            requiredPagemessage,
            "success",
            true,
            false
          );
        }
      }

      // For updating the status and milestone
      await this.orderDetail(3);

      this.snackbarTimeOut(1);

      //console.log(`this.cdf.length`, this.cdf.length);
      if (this.cdf.length > 0 && "cdfError" in this.plausibilityCheck) {
        if (this.plausibilityCheck.cdfError.status == false && processDataCheck == true) {
          processDataCheck = false;
        }
      }
      this.plausibilityCheck = Validatedata;
      this.dataCheck = processDataCheck;

      /*if (updateMessage == 1) {
        this.plausibilityCheck = Validatedata;
      }*/
    },
    snackbarTimeOut(value) {
      setTimeout(() => {
        if (value == 1) {
          this.snackbar = null;
        }
        if (value == 2) {
          this.severityLevel = null;
        }
        if (value == 3) {
          this.allPDFUploaded = null;
        }
        if (value == 4) {
          this.PDFGenerate = null;
          this.PDFGenerateColor = null;
        }
        if (value == 6) {
          this.errorForZoom = null;
        }
      }, 5000);
    },
    AssignedImageInArray(
      coverPage,
      partType,
      mainImage,
      thumbnail,
      sortSequence,
      dummyImageFlag
    ) {
      this.previewImageArray[sortSequence] = mainImage;
      //Find image array based on partType
      const imageArray =
        coverPage > 0 && partType === "Cover"
          ? this.CoverImageWithThumb
          : this.thumbnailArray;
      imageArray[sortSequence] = {};
      imageArray[sortSequence]["main"] = mainImage;
      imageArray[sortSequence]["thumb"] = thumbnail;
      imageArray[sortSequence]["dummyImage"] = dummyImageFlag;

      // Assigned image for the popup
      if (sortSequence == 1) {
        this.popup_image = this.externalImage + thumbnail;
      }

      //For center area if image is assigned then show proceesing image
      if (sortSequence > 0) {
        if (partType === "Cover") {
          //Cover image
          this.CoverImageWithThumb[sortSequence] = {};
          this.CoverImageWithThumb[sortSequence]["main"] = mainImage;
          this.CoverImageWithThumb[sortSequence]["thumb"] = thumbnail;
          this.CoverImageWithThumb[sortSequence]["dummyImage"] = dummyImageFlag;
        } else {
          //body image
          this.thumbnailArray[sortSequence] = {};
          this.thumbnailArray[sortSequence]["main"] = mainImage;
          this.thumbnailArray[sortSequence]["thumb"] = thumbnail;
          this.thumbnailArray[sortSequence]["dummyImage"] = dummyImageFlag;
        }
      }
    },
    appendImage(singleImageElement, partType, thumbnail, mainImage, dummyImage) {
      const {
        id,
        resourceData,
        originalPageSequence,
        information,
        pageAssignType,
      } = singleImageElement;

      const { sortSequence, variantId } = resourceData;

      this.images.push({
        singlePagesID: id,
        resourceId: id,
        originalPageSequence: originalPageSequence,
        sortSequence: sortSequence,
        thumbnail: thumbnail,
        mainImage: mainImage,
        pageAssignType: pageAssignType,
        lastAssignedImage: false,
        quickCheck: information,
        radixResourceId: id,
        variantId: variantId,
        canvasGenerated: false,
        dummyImage: dummyImage,
        partType: partType,
      });

      //Assigned image for center area based on type
      if (sortSequence != null) {
        this.AssignedImageInArray(
          this.coverPage,
          partType,
          mainImage,
          thumbnail,
          sortSequence,
          dummyImage
        );
      }
    },
    async resourcesImage(processId = 0, pageRange = 0, workflow = null, processStatus) {
      //if (processId == 0) {
      await this.checkOrderLockStatus();
      //}

      if (this.lockScreen == 0) {
        // Prevent the extra call using workflow.only one time trigger when page load
        if (workflow == null) {
          const autoAssignResponse = await axiosInterceptor.post(
            `/api/v1/resources/pageAutoAssign?orderId=${this.OrderId}&variantId=${this.variantId}&join=previews&join=resourceData&jobLock=true`,
            {},
            {
              orderId: this.OrderId,
            }
          );
          if (autoAssignResponse.data.statusCode != 299) {
            this.severityLevelMessage = autoAssignResponse.data.pageAutoAssign.message;
            if (autoAssignResponse.data.pageAutoAssign.severityLevel == 3) {
              let splitMesage = autoAssignResponse.data.pageAutoAssign.message.split(
                "\n"
              );
              this.severityError = true;
              this.severityLevelMessage = splitMesage[0];
              this.severityLevel = "danger";
            } else if (autoAssignResponse.data.pageAutoAssign.severityLevel == 2) {
              this.severityLevel = "warning";
            } else {
              this.severityLevel = "success";
            }

            this.AddProcessingMsg(
              "severityLevel",
              this.severityLevelMessage,
              this.severityLevel,
              true,
              false
            );

            //this.snackbarTimeOut(2);
          }
        }
      }
      let imageArrayAvalilable = false;
      if (this.images.length > 0 && this.refreshBtn == false) {
        imageArrayAvalilable = true;
      }

      // If image array has already value then prevent the API
      if (imageArrayAvalilable == false) {
        let orderHeaderInfo = {
          orderId: this.OrderId,
        };
        const result = await axiosInterceptor.get(
          `api/v1/resources?join=previews&join=resourceData&orderId=${this.OrderId}&plausibilityCheck=true&join=resourceData.variant&join=resourceData.variant.pagerange&join=resourceData.variant.pagerange.part`,
          orderHeaderInfo
        );

        //live data
        this.main = result.data.resources;
        if (this.main.length <= 0) {
          this.mountLoader.hide();
          return;
        }

        this.plausibilityCheck = result.data.plausibilityCheck;
        const form = document.querySelector("#CDFForm");
        if (form != undefined && form != null) {
          const values = Object.fromEntries(new FormData(form).entries());
          //console.log(values);
          await this.validateCDF(values);
        }

        this.CheckValidation(this.plausibilityCheck, 1);
      }

      /* if order has in processing stage */
      /* Prevent the code page open usin pURL*/

      let orderProcessStatus;
      if (this.refreshBtn == false) {
        await this.$store.dispatch("auth/imageGenerate", this.OrderId).then((res) => {
          orderProcessStatus = res.status;
        });
      }

      //Unset the array because resource delete
      if (imageArrayAvalilable == false) {
        this.images = [];
        this.previewImageArray = [];
        this.CoverImageWithThumb = [];
        this.thumbnailArray = [];
      }

      //any quickcheck has error
      let ifExistError = null;
      if (processStatus == "error") {
        ifExistError = this.allFileList.findIndex((object) => {
          return parseInt(object.uplResourceId) === parseInt(processId);
        });
        if (ifExistError >= 0) {
          this.allFileList[ifExistError]["status"] = "error";
        }
      }

      if (processId > 0 && !orderProcessStatus) {
        if (this.showDropzone == true) {
          for (let file of this.allFileList) {
            //Checking any quickcheck has error when PDF upload from preview page
            if (file.status !== "error") {
              const element = document.getElementById(
                `mainDivDrop+${file.uplResourceId}`
              );
              if (element) {
                element.style.display = "none";
              }
            }
          }
        }

        // If no file has error then hide the div of dropzone
        if (ifExistError === -1 && this.showDropzone == true) {
          this.showDropzone = false;
          const myDropzoneAbort = Dropzone.forElement("#productImg");
          myDropzoneAbort.removeAllFiles(true);
        }

        const checkAnyFileHasComplete = this.allFileList.findIndex(
          (file) => file.status !== "error"
        );

        // Display message if order PDF has been completed save as image when PDF upload in preview page
        if (
          checkAnyFileHasComplete >= 0 &&
          !orderProcessStatus &&
          this.allFileList.length > 0
        ) {
          this.AddProcessingMsg(
            "imageProcessingMsg",
            "release-print-data.image-complete",
            "success",
            true,
            true
          );
          this.releaseBtnDisable = false;
        } else if (!orderProcessStatus && this.allFileList.length == 0) {
          // When PDf upload from uploader page
          this.AddProcessingMsg(
            "imageProcessingMsg",
            "release-print-data.image-complete",
            "success",
            true,
            true
          );
          this.releaseBtnDisable = false;
        } else {
          console.log("inininininppp");
          const ifExist = this.processingMsg.findIndex(
            (msg) => msg.type === "imageProcessingMsg"
          );
          if (ifExist !== -1) {
            this.processingMsg.splice(ifExist, 1);
            this.releaseBtnDisable = false;
          }
        }
      }

      // if order is in process or image array has any value
      if (orderProcessStatus || imageArrayAvalilable) {
        this.tmpK = this.tmpK + 1;
        //PDF is in processing
        let mainImage;
        let thumbnail;
        let dummyImage;
        //No image is not genareted then based on single pages show dummy image
        if (imageArrayAvalilable == false) {
          this.releaseBtnDisable = true;
          for (let i = 0; i < this.main.length; i++) {
            for (let j = 0; j < this.main[i].singlePages.length; j++) {
              let p = 0;
              if (this.main[i].singlePages[j].previews[p]) {
                //Image generated
                const preview = this.main[i].singlePages[j].previews[p];
                mainImage = preview.previews[0].path;
                thumbnail = preview.thumbnails[0].path;
                dummyImage = false;
              } else {
                //dummy image
                mainImage = this.$imageProcessing;
                thumbnail = this.$imageProcessing;
                dummyImage = true;
              }
              let partType = "";
              const resourceData = this.main[i].singlePages[j].resourceData;
              if (resourceData.variant != null && this.multiPart) {
                partType = resourceData.variant.pagerange.part.type;
              }

              this.appendImage(
                this.main[i].singlePages[j],
                partType,
                thumbnail,
                mainImage,
                dummyImage
              );
              p++;
            }
          }
        } else {
          // Image append
          if (parseInt(processId) > 0) {
            let orderHeaderInfo = {
              orderId: this.OrderId,
            };
            let singleResource = await axiosInterceptor.get(
              `api/v1/resources/${processId}?join=previews&join=resourceData`,
              orderHeaderInfo
            );
            let dataAppend = singleResource.data.singlePages;
            //console.log(`dataAppend`, dataAppend);
            // if save as image get
            if (workflow == "saveasimgs") {
              let substr = "-";
              let moreThanOnePage = pageRange.includes(substr);
              let startloop;
              let endloop;
              //need to split
              if (moreThanOnePage) {
                let splitData = pageRange.split("-");
                startloop = parseInt(splitData[0]) - parseInt(1);
                endloop = parseInt(splitData[1]);
              } else {
                startloop = parseInt(pageRange) - parseInt(1);
                endloop = parseInt(pageRange);
              }

              let dummyImageStart;
              //loop for data append
              for (let k = startloop; k < endloop; k++) {
                if (dataAppend[k]["previews"][0]["previews"]) {
                  // Find index of image based on singlePagesID
                  dummyImageStart = this.images.findIndex((object) => {
                    return object.singlePagesID === dataAppend[k].id;
                  });

                  this.images[dummyImageStart]["thumbnail"] =
                    dataAppend[k]["previews"][0]["thumbnails"][0]["path"];

                  this.images[dummyImageStart]["mainImage"] =
                    dataAppend[k]["previews"][0]["previews"][0]["path"];

                  this.images[dummyImageStart]["dummyImage"] = false;

                  await this.AssignedImageInArray(
                    this.coverPage,
                    this.images[dummyImageStart]["partType"],
                    dataAppend[k]["previews"][0]["previews"][0]["path"],
                    dataAppend[k]["previews"][0]["thumbnails"][0]["path"],
                    dataAppend[k].resourceData.sortSequence,
                    false
                  );
                }
              }
            } else if (workflow == "quickcheck") {
              //Quickcheck
              for (let j = 0; j < dataAppend.length; j++) {
                let partType =
                  dataAppend[j].resourceData.variant != null && this.multiPart == true
                    ? dataAppend[j].resourceData.variant.pagerange.part.type
                    : "";

                this.appendImage(
                  dataAppend[j],
                  partType,
                  this.$imageProcessing,
                  this.$imageProcessing,
                  true
                );
              }
            }
          }
        }

        //sorting dummy image
        await this.dummyImageSorting();
      } else {
        this.externalImage = process.env.VUE_APP_EXTERNAL_IMAGE_URL;
        let dummyImageCount = 0;
        // NO PDF in processing for normal user
        // But for pURL user check the condition if PDF has in processing stage
        for (let i = 0; i < this.main.length; i++) {
          for (let j = 0; j < this.main[i].singlePages.length; j++) {
            let totalPreviewlength = this.main[i].singlePages[j].previews.length;
            let imageExistPreview = true;
            if (totalPreviewlength == 0) {
              imageExistPreview = false;
              totalPreviewlength = 1;
            }
            for (let k = 0; k < totalPreviewlength; k++) {
              let totalthumbnailslength;

              if (this.main[i].singlePages[j].previews[k]) {
                if (this.main[i].singlePages[j].previews[k].thumbnails) {
                  totalthumbnailslength = this.main[i].singlePages[j].previews[k]
                    .thumbnails.length;
                } else {
                  totalthumbnailslength = 1;
                }
              } else {
                totalthumbnailslength = 1;
              }

              for (let l = 0; l < totalthumbnailslength; l++) {
                if (
                  this.main[i].singlePages[j].resourceData.sortSequence >
                  this.requiredPage
                ) {
                  this.main[i].singlePages[j].resourceData.sortSequence = null;
                }

                if (
                  this.main[i].singlePages[j].resourceData.sortSequence == 1 &&
                  this.main[i].singlePages[j].previews[k]
                ) {
                  if (this.main[i].singlePages[j].previews[k].length > 0) {
                    this.popup_image =
                      this.externalImage +
                      this.main[i].singlePages[j].previews[k].thumbnails[l].path;
                  }
                }
                let isCoverImage = false;
                //console.log(this.main[i].singlePages[j].resourceData.variant);
                if (this.main[i].singlePages[j].resourceData.variant != null) {
                  if (
                    this.main[i].singlePages[j].resourceData.variant.pagerange.part
                      .type == "Cover"
                  ) {
                    isCoverImage = true;
                  }
                }

                /*for purl if image is in processing*/
                let mainImage;
                let thumbnail;
                let dummyImage;
                if (imageExistPreview == true) {
                  //Image generated
                  const preview = this.main[i].singlePages[j].previews[k];
                  mainImage = preview.previews[0].path;
                  thumbnail = preview.thumbnails[0].path;
                  dummyImage = false;
                } else {
                  //dummy image
                  dummyImageCount = dummyImageCount + 1;
                  mainImage = this.$imageProcessing;
                  thumbnail = this.$imageProcessing;
                  dummyImage = true;
                }

                /**/

                this.images.push({
                  singlePagesID: this.main[i].singlePages[j].id,
                  resourceId: this.main[i].singlePages[j].id,
                  originalPageSequence: this.main[i].singlePages[j].originalPageSequence,
                  sortSequence: this.main[i].singlePages[j].resourceData.sortSequence,
                  thumbnail: thumbnail,
                  mainImage: mainImage,
                  pageAssignType: this.main[i].singlePages[j].resourceData.pageAssignType,
                  lastAssignedImage: false,
                  quickCheck: this.main[i].singlePages[j].information,
                  //quickCheck: this.main[i].singlePages[j].previews[k].quickcheck.name,
                  radixResourceId: this.main[i].singlePages[j].id,
                  variantId: this.main[i].singlePages[j].resourceData.variantId,
                  canvasGenerated: false,
                  dummyImage: dummyImage,
                  partType:
                    this.main[i].singlePages[j].resourceData.variant != null &&
                    this.multiPart == true
                      ? this.main[i].singlePages[j].resourceData.variant.pagerange.part
                          .type
                      : "",
                });

                let temp = this.main[i].singlePages[j].resourceData.sortSequence;
                if (this.main[i].singlePages[j].resourceData.sortSequence != null) {
                  this.previewImageArray[temp] = mainImage;

                  //Cover

                  if (this.coverPage > 0 && isCoverImage == true) {
                    this.CoverImageWithThumb[temp] = {};
                    this.CoverImageWithThumb[temp]["main"] = mainImage;
                    this.CoverImageWithThumb[temp]["thumb"] = thumbnail;
                    this.CoverImageWithThumb[temp]["dummyImage"] = dummyImage;
                  } else {
                    //body image
                    this.thumbnailArray[temp] = {};
                    this.thumbnailArray[temp]["main"] = mainImage;
                    this.thumbnailArray[temp]["thumb"] = thumbnail;
                    this.thumbnailArray[temp]["dummyImage"] = dummyImage;
                  }
                }
              }
            }
          }
        }

        if (dummyImageCount == 0) {
          this.refreshBtn = false;
        } else {
          this.refreshBtn = true;
        }
      }

      await this.SortImageorder();
      this.maxAssignednumber();
      this.loadImageArray=this.images;
      console.log(`this.images`, this.images);
      console.log(`this.load`,this.loadImageArray)
      // console.log(`this.thumbnailArray`, this.thumbnailArray);
      /*console.log(`this.CoverImageWithThumbqq`, this.CoverImageWithThumb);*/
      //console.log(`this.images`, this.images);
      /*console.log(`this.previewImageArray`, this.previewImageArray);
      console.log(`this.thumbnailArray`, this.thumbnailArray);
      console.log(`this.CoverImageWithThumbqq`, this.CoverImageWithThumb);*/

      if (this.multiPart == true) {
        this.MainMinatureArray = [];
        // We have show the 2 div because of this reason we have create new array and used this array in minature view of multipart & because of this.images array we have created the array as below.
        this.MainMinatureArray.push({
          i: -1,
          j: 0,
        });

        //Checking body page is odd/even if even then need to add 1 extra page in body page
        let extraBodyPage = 0;
        if (this.bodyPage % 2 == 0) {
          extraBodyPage = 1;
        }

        for (let i = 1, j = 2; i < this.bodyPage + extraBodyPage; i = i + 2, j = j + 2) {
          this.MainMinatureArray.push({
            i: i,
            j: j,
          });
        }

        // Create cover array

        if (this.coverPage > 0) {
          this.coverPageArray = [];
          this.coverDisplay = true;
          this.multiPartArray = [this.coverPage, 1];
          this.coverPageArray.push({
            coverStart: this.coverPage,
            coverMiddle: "",
            coverEnd: 1,
          });

          for (let l = 2, p = 3; l < this.coverPage; l = l + 2, p = p + 2) {
            this.coverPageArray.push({
              coverStart: l,
              coverMiddle: "",
              coverEnd: p,
            });
          }
        }
      }

      if (this.cdf.length > 0) {
        this.showCustom = true;
        if (imageArrayAvalilable == false) {
          this.onInvalidCDF(1);
        }
      } else {
        this.cdfValid = true;
      }

      this.showCustom = false;
      if (processId == 0) {
        this.mountLoader.hide();
      }

      //this.customshow();
      //this.showCustom = false;
    },
    async getCustomData() {
      let sData = `?s={"orderId":${this.OrderId}}`;
      this.cdf = await axiosInterceptor.get(`/api/v1/customDataField${sData}`);
      this.cdf = this.cdf.data;
      //console.log(this.cdf);
    },
    nextimage() {
      this.updateCanvasFlag();
      let last_index = this.bodyPage;
      if (this.multiPart == true) {
        //cover array start from cover page
        if (
          this.coverPage > 0 &&
          this.coverDisplay == true &&
          this.multiPartArray[0] == this.coverPage
        ) {
          this.multiPartArray[0] = this.multiPartArray[1] + 1;
        } else {
          //if (this.multiPartArray[0] != this.bodyPage) {
          this.multiPartArray[0] = this.multiPartArray[0] + 2;
          // }
        }

        this.multiPartArray[1] = this.multiPartArray[1] + 2;
        if (last_index % 2 != 0) {
          last_index = last_index - 1;
        }

        if (
          this.coverPage > 0 &&
          this.coverDisplay == true &&
          this.multiPartArray[1] >= this.coverPage
        ) {
          this.coverDisplay = false;

          this.multiPartArray = [0, 1];
          this.isdisplay = 1;
          if (this.coverPage > 2) {
            last_index = this.coverPage - 1;
          }
          this.clickedItem = 2;
        } else {
          this.isdisplay = this.multiPartArray[0];
        }
      } else {
        if (this.showMiniature != true && last_index > this.isdisplay) {
          this.isdisplay = this.isdisplay + 1;
        } else if (this.showMiniature == true && this.isdisplay == 1) {
          this.isdisplay = this.isdisplay + 1;
        } else {
          if (this.isdisplay == 0) {
            this.isdisplay = this.requiredPage;
          }
        }
      }

      this.showMiniature = false;
      this.MutipartMiniaturePreview = false;
      this.canvasComponents = false;
      this.isActivearray[0] = this.isdisplay;
      this.nextimageicon = true;
      this.previmageicon = true;
      //console.log(`this.isdisplay`, this.isdisplay);
      //console.log(`last_index`, last_index);
      if (last_index == this.isdisplay) {
        if (this.coverDisplay == false) {
          this.nextimageicon = false;
        }
        this.clickedItem = 2;
      }
      if (this.isdisplay == 1) {
        if (this.coverPage == 0) {
          this.previmageicon = false;
          this.clickedItem = 1;
        } else if (this.coverPage > 0 && this.coverDisplay == true) {
          this.previmageicon = false;
          this.clickedItem = 5;
        }
      }
    },
    previmage(multipartback = 0) {
      this.updateCanvasFlag();
      if (this.multiPart == true) {
        if (multipartback == 0) {
          // when cover is visible
          if (
            this.coverPage > 0 &&
            this.coverDisplay == true &&
            this.multiPartArray[0] == 2
          ) {
            this.multiPartArray[0] = this.coverPage;
            this.multiPartArray[1] = 1;
          } else {
            if (this.multiPartArray[0] != 0) {
              this.multiPartArray[0] = this.multiPartArray[0] - 2;
              this.multiPartArray[1] = this.multiPartArray[1] - 2;
              this.isdisplay = this.multiPartArray[1];
              // console.log(`gggthis.isdisplay`, this.isdisplay);
            } else {
              if (
                this.coverPage > 0 &&
                this.coverDisplay == false &&
                this.multiPartArray[0] <= 0
              ) {
                // console.log("In21121212sss in");
                this.coverDisplay = true;
                if (this.coverPage == 2) {
                  this.multiPartArray[0] = this.coverPage;
                } else {
                  this.multiPartArray[0] = this.coverPage - 2;
                }
                this.multiPartArray[1] = this.coverPage - 1;
                // console.log(this.multiPartArray);
                this.isdisplay = this.coverPage - 1;
              }
            }
          }
        }
      }

      if (this.showMiniature != true && this.isdisplay > 1) {
        this.isdisplay = this.isdisplay - 1;
      } else {
        this.isdisplay = 1;
      }
      this.showMiniature = false;
      this.MutipartMiniaturePreview = false;
      this.canvasComponents = false;
      this.isActivearray[0] = this.isdisplay;
      let first_index = 1;
      /*let previndex = parseInt(this.currentindex) - 1;
      this.getimage(previndex);*/
      this.previmageicon = true;
      this.nextimageicon = true;
      if (first_index == this.isdisplay) {
        if (this.coverPage == 0) {
          this.previmageicon = false;
          this.clickedItem = 1;
        } else if (this.coverPage > 0 && this.coverDisplay == true) {
          this.previmageicon = false;
          this.clickedItem = 5;
        }
      }
      if (this.requiredPage == this.isdisplay) {
        this.nextimageicon = false;
        this.clickedItem = 2;
      }
    },
    drag(ev, imageindex) {
      ev.dataTransfer.setData("imageindex", imageindex);
      this.isDrag = parseInt(imageindex) == this.isDrag ? null : parseInt(imageindex);
      //this.isDrop = true;

      //e.scrollIntoView(true);
    },
    dragEnd() {
      this.isDrop = null;
      this.isDrag = null;
      this.isFrontDrop = null;
      this.isBackDrop = null;
    },
    closeSnackbar(value) {
      if (value == 1) {
        this.snackbar = null;
      } else if (value == 2) {
        this.severityLevel = null;
      } else if (value == 3) {
        this.allPDFUploaded = false;
      } else if (value == 4) {
        this.PDFGenerate = null;
        this.PDFGenerateColor = null;

        this.orderModalErrorMessage = null;
      } else if (value == 6) {
        this.errorForZoom = null;
      } else if (value == 7) {
        this.cdfStatus = null;
      } else if (value == 8) {
        this.cdfInvalidMessage = null;
      } else if (value == 9) {
        this.imageProcessingStatus = null;
      }
    },

    showfront() {
      //this.backImage = "";
      this.showMiniature = false;
      this.MutipartMiniaturePreview = false;
      this.canvasComponents = false;
      this.isActivearray[0] = "";
      this.isActivearray[1] = "";
      this.isActivearray[2] = this.singleimageindex;
      /*this.tmp[0] = "";
      this.tmp[1] = "";*/
    },

    showbothside() {
      // this.isdisplay = -1;
      this.showMiniature = true;
      this.MutipartMiniaturePreview = false;
      this.clickedItem = 4;
      this.previmageicon = false;
      this.nextimageicon = false;
      this.canvasComponents = false;
    },

    async showMultiPart() {
      this.clickedItem = 4;
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      this.MutipartMiniaturePreview = true;
      this.showMiniature = false;
      this.previmageicon = false;
      this.nextimageicon = false;
      this.canvasComponents = false;
      if (this.coverDisplay == true) {
        this.coverLastIndex = this.isdisplay;
        this.bodyLastIndex = 1;
      } else {
        this.coverLastIndex = 1;
        this.bodyLastIndex = this.isdisplay;
      }
      loader.hide();
    },

    resetHover() {
      //this.hoveredItem = "";
    },
    scrollPage(diff) {
      var dummyDive = document.querySelector(".ps.ps--active-y");
      dummyDive.scrollTop += diff;
    },
    selectMultiple(ev, index) {
      if (ev.ctrlKey && this.MutipartMiniaturePreview == true) {
        console.log(`index`, index);
        // if index has value
        if (index >= 0) {
          // Check if index is already present and user again click then removed from the array and removed the style
          if (this.selectMultipleImage.includes(index)) {
            this.selectMultipleImage = this.selectMultipleImage.filter(
              (item) => item !== index
            );
            ev.target.style.border = "";
          } else {
            ev.target.style.border = "5px solid #6533dd";
            //ev.target.style.border = "5px solid red";
            this.selectMultipleImage.push(index);
            console.log(`this.selectMultipleImage`, this.selectMultipleImage);
          }
        }
      }
    },
    dragMultiple(ev) {
      console.log("yes in multiple");
      //let singleImage = true;
      ev.dataTransfer.setData("text", ev.target.id);

      if (ev.ctrlKey) {
        console.log("if", ev.target.id);
        this.selectMultipleImage.push(ev.target.id);
        //singleImage = false;
      } else {
        console.log("else", this.selectMultipleImage, this.selectMultipleImage.length);
        //single select
        if (this.selectMultipleImage.length == 0) {
          console.log("else if");
          this.singleSelect = true;
          this.dragStart(this.dragging, ev);
          return;
        }
      }

      // Create a new element to use as the drag image
      var dragImage = document.createElement("div");

      dragImage.classList.add("mulipleDragimage");
      dragImage.style.position = "absolute";
      dragImage.style.top = "-1000px";
      // Add a clone of each selected item to the drag image
      for (var i = 0; i < this.selectMultipleImage.length; i++) {
        /*if (i == 5) {
          var lineBreak = document.createElement("br");
          dragImage.appendChild(lineBreak);
        }*/
        var nodeCopy = document
          .getElementById(this.selectMultipleImage[i])
          .querySelector("img");

        var imgSrc = nodeCopy.src;
        // Log the src value
        //console.log(imgSrc);
        var imgElement = document.createElement("img");

        // Set the src attribute
        imgElement.src = imgSrc;

        dragImage.appendChild(imgElement);
      }
      console.log(`dragImage`, dragImage);

      // Add the drag image to the body
      document.body.appendChild(dragImage);

      // Use the drag image as the drag preview
      ev.dataTransfer.setDragImage(dragImage, 0, 0);
    },
    dragStart(which, ev) {
      //console.log(`which`, which);
      this.dragging = which;
      this.isDrag = which;
      if (this.singleSelect == true) {
        //extucode code
      } else {
        if (this.MutipartMiniaturePreview == true) {
          this.dragMultiple(ev);
          return;
        }
      }

      //console.log(`dragStart`);
      //console.log("in start", which, ev);
      ev.dataTransfer.setData("Text", which);
      //console.log(ev.dataTransfer.getData("Text"));
      ev.dataTransfer.dropEffect = "move";

      // console.log("this.dragging", this.dragging);
    },
    dragFinish(to, ev, dropPartType = "") {
      var data = ev.dataTransfer.getData("Text");
      if (document.getElementById(data) != null) {
        this.isDrag = null;
        if (this.multiPart == true) {
          this.moveCoverItem(this.dragging, to, to, dropPartType);
        } else {
          this.moveItem(this.dragging, to);
        }
      }
    },
    showSinglePage() {
      this.isdisplay = 1;
      this.showMiniature = false;
      this.canvasComponents = false;
      if (this.requiredPage > 1) {
        this.nextimageicon = true;
        this.previmageicon = false;
      }
    },
    showCurrentPage(cover = 0) {
      this.MutipartMiniaturePreview = false;
      this.canvasComponents = false;
      this.clickedItem = 3;
      if (cover == 1) {
        this.clickedItem = 5;
        this.coverDisplay = true;
      } else {
        this.coverDisplay = false;
      }
      if (this.multiPart == true) {
        if (cover == 1) {
          this.isdisplay = this.coverLastIndex;
          this.coverLastIndex = 1;
        } else {
          this.isdisplay = this.bodyLastIndex;
          this.bodyLastIndex = 1;
        }
        let currentIndex = this.isdisplay;
        // console.log(`currentIndex`, currentIndex);
        if (currentIndex == 1 && cover == 1) {
          this.multiPartArray = [this.coverPage, currentIndex];
        } else {
          if (currentIndex > this.coverPage && cover == 1) {
            this.multiPartArray = [this.coverPage, 1];
            this.isdisplay = 1;
          } else {
            if (currentIndex % 2 == 0) {
              this.multiPartArray = [currentIndex, currentIndex + 1];
            } else {
              this.multiPartArray = [currentIndex - 1, currentIndex];
            }
          }
        }

        if (this.isdisplay == 1) {
          if (this.coverPage > 0) {
            this.previmageicon = cover == 0 ? true : false;
            /*if (cover == 0) {
              this.previmageicon = true;
            } */
          } else {
            this.previmageicon = false;
          }

          this.nextimageicon = true;
        }

        // if (this.requiredPage == this.isdisplay) {
        if (
          this.requiredPage == this.isdisplay ||
          this.requiredPage == this.isdisplay + 1
        ) {
          this.nextimageicon = false;
          this.previmageicon = true;
        } else {
          this.nextimageicon = true;
          if (this.isdisplay != 1) {
            this.previmageicon = true;
          }
        }
      }
    },
    async imageAssigned(index, sortSequence, partType) {
      // PDFTool is visiable in center view
      if (this.canvasComponents == true) {
        if (this.images[index].canvasGenerated == false) {
          await this.ShowCanvas(sortSequence, sortSequence, partType);
        } else {
          this.displayInCanvas = sortSequence;
          this.isHeight = false;
          if (
            this.effectiveMediaHeight[this.displayInCanvas] >
            this.effectiveMediaWidth[this.displayInCanvas]
          ) {
            this.isHeight = true;
          }
          this.canvas_check[this.displayInCanvas].renderAll();
        }
      } else {
        if (this.multiPart == true) {
          if (this.coverPage > 0) {
            this.clickedItem = 3;
            this.coverDisplay = false;
            if (partType == "Cover") {
              this.coverDisplay = true;
              this.clickedItem = 5;
            }
          }
          let newIndex = sortSequence;
          // Checking if user click on 1st image or last image of cover and cover display flag is true
          if (
            this.coverDisplay == true &&
            partType == "Cover" &&
            (sortSequence == this.coverPage || sortSequence == 1)
          ) {
            this.multiPartArray = [this.coverPage, 1];
          } else {
            if (sortSequence % 2 == 0) {
              this.multiPartArray = [newIndex, newIndex + 1];
            } else {
              this.multiPartArray = [newIndex - 1, newIndex];
            }
          }

          this.isdisplay = newIndex;
          this.previmageicon = true;
          if (this.isdisplay == 1) {
            if (this.coverPage > 0) {
              if (partType == "Body") {
                this.previmageicon = true;
              } else {
                this.previmageicon = false;
              }
            } else {
              this.previmageicon = false;
            }

            this.nextimageicon = true;
          }

          if (this.requiredPage == this.isdisplay || this.bodyPage == this.isdisplay) {
            this.nextimageicon = false;
          } else {
            this.nextimageicon = true;
          }
        } else {
          this.isdisplay = index + 1;
          if (this.isdisplay == 1) {
            this.previmageicon = false;
            this.nextimageicon = true;
            this.clickedItem = 1;
          } else if (this.isdisplay == this.requiredPage) {
            this.clickedItem = 2;
            this.previmageicon = true;
            this.nextimageicon = false;
          }
        }
      }
    },
    getOldSortSequence(arr, index, partType) {
      return function (arr) {
        return arr["sortSequence"] === index && arr["partType"] === partType;
      };
    },
    getindexbasedonimage(arr, thumb, partType) {
      return function (arr) {
        return arr["thumbnail"] == thumb && arr["partType"] == partType;
      };
    },
    dummyImageSorting() {
      // if dummy image is false then it comes first as per below function
      this.images.sort((a, b) => Number(a.dummyImage) - Number(b.dummyImage));
    },
    SortImageorder() {
      this.images.sort((teamA, teamB) => {
        if (teamA.sortSequence === null) return 1;
        if (teamB.sortSequence === null) return -1;
        return (
          teamB.partType.localeCompare(teamA.partType) ||
          teamA.sortSequence - teamB.sortSequence
        );
      });

      //  console.log(`before`, this.images);
      if (this.coverPage > 0) {
        const index = this.images.findIndex((object) => {
          return object.sortSequence === this.coverPage && object.partType === "Cover";
        });
        //if cover has last assigned image then move to as 1st Image using pop method
        if (index >= 0) {
          this.images.unshift(this.images[index]);
          delete this.images[index + 1];
          //re index the array
          this.images = this.images.filter(function () {
            return true;
          });
        }
      }

      if (this.coverImageArray.length >= 0) {
        this.coverImageArray.sort((a, b) => {
          var keyA = a.sortSequence,
            keyB = b.sortSequence;
          if (keyA === null) {
            return 1;
          }

          if (keyB === null) {
            return -1;
          }

          if (keyA === keyB) {
            return 0;
          }
          return keyA < keyB ? -1 : 1;
        });
        // Find index if cover has last asssigend image or not
        const index = this.coverImageArray.findIndex((object) => {
          return object.sortSequence === this.coverPage;
        });

        //if cover has last assigned image then move to as 1st Image using pop method
        if (index >= 0) {
          this.coverImageArray.unshift(this.coverImageArray.pop());
        }
      }

      //console.log(`sorting`, this.images);
    },
    maxAssignednumber() {
      if (this.coverPage > 0) {
        var bodykeys = Object.keys(this.thumbnailArray);
        var lastbody = bodykeys[bodykeys.length - 1];

        if (lastbody != undefined) {
          const bodyIndex = this.images.findIndex((object) => {
            return object.sortSequence == lastbody && object.partType != "Cover";
          });
          this.showLineIndex = bodyIndex;
          this.showLine = lastbody;
        }

        //Cover
        if (lastbody == undefined) {
          var keys = Object.keys(this.CoverImageWithThumb);
          var last = keys[keys.length - 1];
          if (last == this.coverPage) {
            last = keys[keys.length - 2];
            if (last == undefined) {
              last = this.coverPage;
            }
          }
          if (last != undefined) {
            const coverIndex = this.images.findIndex((object) => {
              return object.sortSequence == last && object.partType == "Cover";
            });

            this.showLineIndex = coverIndex;
            this.showLine = last;
          }
        }
      }

      if (this.coverPage == 0) {
        const max_val_index = this.images.reduce(
          (max_index, val, i, arr) =>
            val.sortSequence > arr[max_index].sortSequence ? i : max_index,
          0
        );

        if (this.images[max_val_index]["sortSequence"] != null) {
          this.showLineIndex = max_val_index;
          this.showLine = this.images[max_val_index]["sortSequence"];
        }
      }
    },

    manualpageAssignType() {
      if (this.updatepageAssignType == 0) {
        this.updatepageAssignType = 1;
        this.images.map(({ key, value }) => ({ [key]: value }));

        this.images = this.images.map((updateImage) => ({
          ...updateImage,
          pageAssignType: "manual",
        }));
      }
    },
    async moveItem(from, to, currentDisplay = -1, dropPartType = "") {
      console.log(from, to, currentDisplay);
      await this.checkOrderLockStatus();

      let currentImageDiv = this.isdisplay;

      if (currentDisplay > -1) {
        currentImageDiv = currentDisplay;
      }
      //const getVariantId = document.querySelector("#variant_" + currentImageDiv);

      if (this.lockScreen == 0) {
        let loaderDisplayPosition = document.body;

        let loader = this.$loading.show({
          container: loaderDisplayPosition,
        });

        if (to === -1) {
          //this.getimage(from, this.clickedItem);
          let OldSortSequence = this.images.findIndex(
            this.getOldSortSequence(this.images, currentImageDiv, dropPartType)
          );
          // if image is already assign
          //console.log(`OldSortSequence`, OldSortSequence);
          if (OldSortSequence >= 0) {
            if (
              this.images[OldSortSequence].sortSequence > 0 &&
              this.images[OldSortSequence].sortSequence == currentImageDiv
            ) {
              this.images[OldSortSequence].sortSequence = this.images[from].sortSequence;
              this.images[OldSortSequence].variantId = this.images[from].variantId;
              if (this.images[from].sortSequence > 0) {
                this.previewImageArray[
                  this.images[from].sortSequence
                ] = this.previewImageArray[currentImageDiv];

                //set drop image
                this.thumbnailArray[this.images[from].sortSequence][
                  "main"
                ] = this.thumbnailArray[currentImageDiv]["main"];
                this.thumbnailArray[this.images[from].sortSequence][
                  "thumb"
                ] = this.thumbnailArray[currentImageDiv]["thumb"];
              }
            } else {
              //console.log("else 1");
              this.images[OldSortSequence].sortSequence = null;
              this.images[OldSortSequence].variantId = null;
              this.previewImageArray[currentImageDiv] = null;
              this.thumbnailArray[currentImageDiv] = null;
            }
          }

          //if image is already assign and drop assign image in diffrent index
          if (OldSortSequence == -1) {
            //console.log("if 3", this.images[from].sortSequence, from, currentImageDiv);
            let removeAssignImage = this.images[from].sortSequence;
            // console.log(`removeAssignImage`, removeAssignImage);
            delete this.previewImageArray[removeAssignImage];
            delete this.thumbnailArray[removeAssignImage];
            this.images[from].sortSequence = null;
            // this.previewImageArray.splice(removeAssignImage, 1);
          }

          this.images[from].sortSequence = currentImageDiv;
          this.images[from].variantId =
            dropPartType == "Cover" ? this.coverVariant : this.bodyVariant;
          this.images[from].partType = dropPartType;

          this.previewImageArray[currentImageDiv] = this.images[from].mainImage;
          this.thumbnailArray[currentImageDiv] = {};
          this.thumbnailArray[currentImageDiv]["main"] = this.images[from].mainImage;
          this.thumbnailArray[currentImageDiv]["thumb"] = this.images[from].thumbnail;

          await this.SortImageorder();
          this.maxAssignednumber();
          await this.manualpageAssignType();
          this.isActivearray[0] = currentImageDiv;
          // console.log(`ddd`, this.images);
          //console.log(this.previewImageArray);
          //console.log(this.images);
          let orderHeader = {
            orderId: this.OrderId,
          };
          console.log("in 1");
          const result1 = await axiosInterceptor.post(
            `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&plausibilityCheck=true&jobLock=true`,
            this.images,
            orderHeader
          );

          this.CheckValidation(result1.data.plausibilityCheck, 2);
          //this.nextimage();

          loader.hide();
        } else {
          //if both image has not assign any sortSequence
          if (
            this.images[from]["sortSequence"] == null &&
            this.images[to]["sortSequence"] == null
          ) {
            this.images.splice(to, 0, this.images.splice(from, 1)[0]);
          } else {
            //if to image has assign any sortSequence
            if (
              this.images[from]["sortSequence"] == null &&
              this.images[to]["sortSequence"] != null
            ) {
              this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
              this.images[from].variantId = this.bodyVariant;
              this.previewImageArray[this.images[to]["sortSequence"]] = this.images[
                from
              ].mainImage;
              this.thumbnailArray[this.images[to]["sortSequence"]]["thumb"] = this.images[
                from
              ].thumbnail;
              this.thumbnailArray[this.images[to]["sortSequence"]]["main"] = this.images[
                from
              ].mainImage;

              this.images[to]["sortSequence"] = null;
            } else if (
              this.images[to]["sortSequence"] == null &&
              this.images[from]["sortSequence"] != null
            ) {
              //console.log("elseif1", this.images[from]["sortSequence"]);
              this.images[to]["sortSequence"] = this.images[from]["sortSequence"];
              this.images[to].variantId = this.bodyVariant;
              this.previewImageArray[this.images[from]["sortSequence"]] = this.images[
                to
              ].mainImage;
              this.thumbnailArray[this.images[from]["sortSequence"]][
                "thumb"
              ] = this.images[to].thumbnail;
              this.thumbnailArray[this.images[from]["sortSequence"]][
                "main"
              ] = this.images[to].mainImage;
              /* this.thumbnailArray[this.images[from]["sortSequence"]][
                "thumb"
              ] = this.images[to].thumbnail;*/

              this.images[from]["sortSequence"] = null;
              this.images[from]["variantId"] = null;
            } else {
              //inter swap assigned
              let old = this.images[from]["sortSequence"];

              let oldsort = this.images[from]["sortSequence"];
              let newsort = this.images[to]["sortSequence"];

              let oldsordata = this.previewImageArray[oldsort];
              let newsordata = this.previewImageArray[newsort];

              this.previewImageArray[oldsort] = newsordata;
              this.previewImageArray[newsort] = oldsordata;

              this.thumbnailArray[oldsort]["main"] = newsordata;
              this.thumbnailArray[oldsort]["thumb"] = this.images[to]["thumbnail"];
              this.thumbnailArray[newsort]["main"] = oldsordata;
              this.thumbnailArray[newsort]["thumb"] = this.images[from]["thumbnail"];

              this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
              this.images[from]["variantId"] = this.images[to]["variantId"];
              this.images[to]["sortSequence"] = old;
            }

            this.images.splice(to, 0, this.images.splice(from, 1)[0]);

            await this.SortImageorder();
            this.maxAssignednumber();
            this.manualpageAssignType();

            this.isActivearray[0] = currentImageDiv;

            //console.log(this.images);
          }
          let orderHeader = {
            orderId: this.OrderId,
          };
          console.log("in 2");
          const result1 = await axiosInterceptor.post(
            `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&variantId=${this.variantId}&plausibilityCheck=true&jobLock=true`,
            this.images,
            orderHeader
          );

          this.CheckValidation(result1.data.plausibilityCheck, 2);
          loader.hide();
        }
        if (this.images[0]["sortSequence"] == 1) {
          this.popup_image = this.externalImage + this.images[0]["thumbnail"];
        } else {
          this.popup_image = this.$noImagePreview;
        }
      }
    },
    async autoMoving(elementIndex, newPosition, dragPartType, dropPartType) {
      /*console.log(dragPartType, dropPartType);
      console.log(`thumbnailArraydd`, this.thumbnailArray.length, this.requiredPage);*/
      let bodyLastPage = this.thumbnailArray.length + 1;
      // If body last page is greater then the required page then set the required page as body last page
      if (bodyLastPage > this.bodyPage) {
        bodyLastPage = this.bodyPage;
      }

      let start = newPosition;
      let end = bodyLastPage;
      if (dropPartType === "Cover") {
        end = this.coverPage + bodyLastPage;
      }

      //let partType = "Body";
      let partType = dragPartType;
      let payload = [];
      if (elementIndex > newPosition && dragPartType == dropPartType) {
        start = newPosition;
        end = elementIndex;
      }

      let lastImage = this.images.findIndex(
        this.getOldSortSequence(this.images, elementIndex, partType)
      );

      // Assigned image from old postion to new postion
      if (
        (dragPartType == "Body" && dropPartType == "Body") ||
        (dragPartType == "Cover" && dropPartType == "Body")
      ) {
        this.thumbnailArray[newPosition] = [];
        this.thumbnailArray[newPosition]["thumb"] = this.images[lastImage]["thumbnail"];
        this.thumbnailArray[newPosition]["main"] = this.images[lastImage]["mainImage"];
        this.previewImageArray[newPosition] = this.images[lastImage]["mainImage"];
        this.images[lastImage]["sortSequence"] = null;
        // When drag part is cover then need to delete image from the diffrent array
        if (dragPartType == "Cover") {
          delete this.CoverImageWithThumb[elementIndex];
        } else {
          delete this.thumbnailArray[elementIndex];
        }
      } else if (dragPartType == "Body" && dropPartType == "Cover") {
        this.CoverImageWithThumb[newPosition] = [];
        this.CoverImageWithThumb[newPosition]["thumb"] = this.images[lastImage][
          "thumbnail"
        ];
        this.CoverImageWithThumb[newPosition]["main"] = this.images[lastImage][
          "mainImage"
        ];
        this.images[lastImage]["partType"] = dropPartType;
        this.images[lastImage]["sortSequence"] = null;
        delete this.thumbnailArray[elementIndex];
      } else if (dragPartType == "Cover" && dropPartType == "Cover") {
        this.CoverImageWithThumb[newPosition] = [];
        this.CoverImageWithThumb[newPosition]["thumb"] = this.images[lastImage][
          "thumbnail"
        ];
        this.CoverImageWithThumb[newPosition]["main"] = this.images[lastImage][
          "mainImage"
        ];
        this.images[lastImage]["sortSequence"] = null;
        delete this.CoverImageWithThumb[elementIndex];
      }

      // Assigned only thumbnail image using main image array for cover
      let coverloopbreak = false;
      if (dropPartType == "Cover") {
        //newPosition
        start = 0;
        end = this.coverPage + bodyLastPage;
        for (let p = newPosition; p < this.coverPage; p++) {
          // console.log(`p`, p);
          let OldSortSequence = this.images.findIndex(
            this.getOldSortSequence(this.images, p, "Cover")
          );
          if (OldSortSequence >= 0) {
            this.CoverImageWithThumb[p + 1] = [];
            this.CoverImageWithThumb[p + 1]["thumb"] = this.images[OldSortSequence][
              "thumbnail"
            ];
            this.CoverImageWithThumb[p + 1]["main"] = this.images[OldSortSequence][
              "mainImage"
            ];
          } else {
            coverloopbreak = true;
            break;
          }
        }
      }

      // Assigned only thumbnail image using main image array for body
      if (coverloopbreak == false) {
        for (let k = start; k < end; k++) {
          let tmpIndex = k;
          partType = "Body";
          // console.log(`tmpIndex----`, tmpIndex);
          if (k == 0 && dropPartType == "Cover") {
            //console.log("yes in in");
            tmpIndex = this.coverPage;
            partType = "Cover";
            //console.log(tmpIndex, partType);
          }
          // console.log(tmpIndex, partType);
          let OldSortSequence = this.images.findIndex(
            this.getOldSortSequence(this.images, tmpIndex, partType)
          );
          //console.log(`OldSortSequence`, OldSortSequence);
          if (OldSortSequence >= 0) {
            //console.log(this.images[OldSortSequence]["thumbnail"]);
            this.thumbnailArray[k + 1] = [];
            this.thumbnailArray[k + 1]["thumb"] = this.images[OldSortSequence][
              "thumbnail"
            ];
            this.thumbnailArray[k + 1]["main"] = this.images[OldSortSequence][
              "mainImage"
            ];
            this.previewImageArray[k + 1] = this.images[OldSortSequence]["mainImage"];
          } else {
            break;
          }
        }
      }
      //console.log(`start`, start, `end`, end);
      await this.manualpageAssignType();
      //Assigned cover image in main Image array
      if (dropPartType == "Cover") {
        start = newPosition;
      }

      // based on new thumbnail image assigne sortSequence to main image for cover & body
      for (let k = start; k <= end; k++) {
        let imageName = null;
        let ImageIndex = k;
        let assignPartType = "Body";
        let findImagePartType = "Body";
        //Checking cover is present and drop part type is cover or not and k value is less than or equal to cover page
        if (k <= this.coverPage && dropPartType == "Cover") {
          if (this.CoverImageWithThumb[ImageIndex]) {
            imageName = this.CoverImageWithThumb[ImageIndex]["thumb"];
          }
          findImagePartType = "Cover";
          assignPartType = "Cover";
        } else {
          //console.log("yes validkkkk");
          assignPartType = "Body";
          findImagePartType = "Body";
          /// When drag part is cover and drop part is body
          // dropp image index is same as k the need to find image from the cover part
          if (dragPartType == "Cover" && dropPartType == "Body" && k == newPosition) {
            findImagePartType = "Cover";
          }
          if (this.coverPage > 0 && dropPartType == "Cover") {
            ImageIndex = k - this.coverPage;
            if (ImageIndex == 1) {
              findImagePartType = "Cover";
            }
          }
          if (this.thumbnailArray[ImageIndex]) {
            imageName = this.thumbnailArray[ImageIndex]["thumb"];
          }
        }
        if (imageName != null) {
          let getImageIndex = this.images.findIndex(
            this.getindexbasedonimage(this.images, imageName, findImagePartType)
          );

          if (getImageIndex >= 0) {
            //console.log(`ImageIndex`, ImageIndex, `partType`, partType);
            // Removed Previous sortSequence - unassigned the image
            let previousSortSequence = this.images.findIndex(
              this.getOldSortSequence(this.images, ImageIndex, assignPartType)
            );
            if (previousSortSequence >= 0) {
              if (
                this.images[previousSortSequence].sortSequence < start ||
                this.images[previousSortSequence].sortSequence >= end
              ) {
                payload.push(this.images[previousSortSequence]);
              }
              this.images[previousSortSequence].sortSequence = null;
            }

            let variantId =
              assignPartType == "Cover" ? this.coverVariant : this.bodyVariant;
            this.images[getImageIndex].sortSequence = ImageIndex;
            this.images[getImageIndex].partType = assignPartType;
            this.images[getImageIndex].variantId = variantId;
            payload.push(this.images[getImageIndex]);
          }
        }
      }

      await this.SortImageorder();
      await this.maxAssignednumber();
      this.singleSelect = false;
      let orderHeader = {
        orderId: this.OrderId,
      };
      console.log("in 3");
      const result1 = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&plausibilityCheck=true&jobLock=true`,
        payload,
        orderHeader
      );

      await this.CheckValidation(result1.data.plausibilityCheck, 2);
    },
    async storedUndo() {
      // if multiple image pushed in array thenblank the multiple image array
      /*if (this.selectMultipleImage >= 0) {
        for (let k = 0; k < this.selectMultipleImage.length; k++) {
          let imgStyle = document
            .getElementById(this.selectMultipleImage[k])
            .querySelector("img");
          imgStyle.removeAttribute("style");
        }
        this.selectMultipleImage = [];
      }*/
      localStorage.setItem(`mainImage`, JSON.stringify(this.images));
      localStorage.setItem(`thumb`, JSON.stringify(this.thumbnailArray));

      // let tmpmainImage = [...this.images];
      let tmpmainImage = JSON.parse(localStorage.getItem("mainImage"));

      const tmpThumb = Array.from(this.thumbnailArray);
      const tmpCover = Array.from(this.CoverImageWithThumb);

      this.undoArray.push(tmpmainImage);
      this.undoThumbArray.push(tmpThumb);

      if (this.coverPage > 0) {
        this.undoCoverArray.push(tmpCover);
      }
    },
    RemovedDataFromMultipleImage() {
      console.log("klklklklklkl");
      // if multiple image pushed in array thenblank the multiple image array
      if (this.selectMultipleImage.length >= 0) {
        console.log("iinininin");
        for (let k = 0; k < this.selectMultipleImage.length; k++) {
          let imgStyle = document
            .getElementById(this.selectMultipleImage[k])
            .querySelector("img");
          imgStyle.removeAttribute("style");
        }
        this.selectMultipleImage = [];
      }
    },
    async UndoProcess() {
      console.log(`UndoProcessdddd  `, this.selectMultipleImage);
      await this.RemovedDataFromMultipleImage();
      let lastElementMainArray = [];
      lastElementMainArray = this.undoArray.slice(-1);

      let interMain = lastElementMainArray[0];
      this.images = [];
      this.images = interMain;
      this.undoArray.pop();

      let lastElementThumbArray = [];
      lastElementThumbArray = this.undoThumbArray.slice(-1);
      let interThumb = lastElementThumbArray[0];
      this.thumbnailArray = [];
      this.thumbnailArray = interThumb;
      this.undoThumbArray.pop();

      if (this.coverPage > 0) {
        let lastElementCoverArray = [];
        lastElementCoverArray = this.undoCoverArray.slice(-1);
        let interCover = lastElementCoverArray[0];
        this.CoverImageWithThumb = [];
        this.CoverImageWithThumb = interCover;
        this.undoCoverArray.pop();
      }

      let orderHeader = {
        orderId: this.OrderId,
      };
      const orderResponse = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&plausibilityCheck=true&jobLock=true`,
        this.images,
        orderHeader
      );

      await this.SortImageorder();
      this.maxAssignednumber();
      this.manualpageAssignType();
      this.CheckValidation(orderResponse.data.plausibilityCheck, 2);
    },
    async moveCoverItem(from, to, currentDisplay, dropPartType) {
      console.log(`moveCoverItem`, from, to, currentDisplay, dropPartType);
      await this.checkOrderLockStatus();
      let from_dragId;
      let to_dropId;
      let dragPage = this.images[from].sortSequence;
      let dropPage;
      let diffrence;
      let payloadArray = [];
      let currentImageDiv = this.isdisplay;
      // This is used when order has multi part
      if (currentDisplay > -1) {
        currentImageDiv = currentDisplay;
      }
      if (this.lockScreen == 0) {
        // If Minature  view is open
        if (this.clickedItem == 4) {
          await this.storedUndo();
        }
        let loaderDisplayPosition = document.body;
        let loader = this.$loading.show({
          container: loaderDisplayPosition,
        });
        // When minature view & center view is open
        if (to === -1) {
          //this.getimage(from, this.clickedItem);
          let OldSortSequence = this.images.findIndex(
            this.getOldSortSequence(this.images, currentImageDiv, dropPartType)
          );

          // if image is already assign
          let dragPartType = this.images[from].partType;

          if (dragPartType == "") {
            dragPartType = dropPartType;
          }

          if (OldSortSequence >= 0) {
            if (
              this.images[OldSortSequence].sortSequence > 0 &&
              this.images[OldSortSequence].sortSequence == currentImageDiv
            ) {
              to_dropId = OldSortSequence;
              dropPage = this.images[OldSortSequence].sortSequence;
              //Checking the diffrence of drag and drop image sortSequence
              if (dragPage > 0 && dropPage > 0) {
                // if drag and drop part type are different
                if (dragPartType != dropPartType) {
                  diffrence = -1;
                } else {
                  if (dragPage > dropPage) {
                    diffrence = dragPage - dropPage;
                  } else {
                    diffrence = dropPage - dragPage;
                  }
                }
              }

              if (this.clickedItem == 4 && (diffrence > 1 || diffrence == -1)) {
                await this.autoMoving(dragPage, dropPage, dragPartType, dropPartType);
                loader.hide();
                return;
              }

              this.images[OldSortSequence].sortSequence = this.images[from].sortSequence;
              this.images[OldSortSequence].variantId = this.images[from].variantId;
              this.images[OldSortSequence].partType = this.images[from].partType;
              from_dragId = from;
              let fromSortSequence = this.images[from].sortSequence;
              // console.log(this.images);
              if (fromSortSequence > 0) {
                if (dragPartType == "Cover" && dropPartType == "Cover") {
                  this.CoverImageWithThumb[fromSortSequence] = {};
                  this.CoverImageWithThumb[fromSortSequence][
                    "main"
                  ] = this.CoverImageWithThumb[currentImageDiv]["main"];

                  this.CoverImageWithThumb[fromSortSequence][
                    "thumb"
                  ] = this.CoverImageWithThumb[currentImageDiv]["thumb"];
                } else if (dragPartType == "Body" && dropPartType == "Body") {
                  this.thumbnailArray[fromSortSequence] = {};
                  this.thumbnailArray[fromSortSequence]["thumb"] = this.thumbnailArray[
                    currentImageDiv
                  ]["thumb"];

                  this.thumbnailArray[fromSortSequence]["main"] = this.thumbnailArray[
                    currentImageDiv
                  ]["main"];
                } else if (dragPartType == "Cover" && dropPartType == "Body") {
                  let OldData = this.CoverImageWithThumb[fromSortSequence];
                  this.CoverImageWithThumb[fromSortSequence] = {};
                  this.CoverImageWithThumb[fromSortSequence] = this.thumbnailArray[
                    currentImageDiv
                  ];
                  this.thumbnailArray[currentImageDiv] = {};
                  this.thumbnailArray[currentImageDiv] = OldData;
                } else if (dragPartType == "Body" && dropPartType == "Cover") {
                  this.thumbnailArray[fromSortSequence] = {};
                  this.thumbnailArray[fromSortSequence][
                    "thumb"
                  ] = this.CoverImageWithThumb[currentImageDiv]["thumb"];

                  this.thumbnailArray[fromSortSequence][
                    "main"
                  ] = this.CoverImageWithThumb[currentImageDiv]["main"];
                } else {
                  //test
                }
                //console.log(`this.CoverImageWithThumb45454`, this.CoverImageWithThumb);
              }
            } else {
              from_dragId = OldSortSequence;
              this.images[OldSortSequence].sortSequence = null;
              this.images[OldSortSequence].variantId = null;
              this.previewImageArray[currentImageDiv] = null;
              this.thumbnailArray[currentImageDiv] = null;
            }
          }

          //if image is already assign and drop assign image in diffrent index
          if (OldSortSequence == -1) {
            from_dragId = from;
            let removeAssignImage = this.images[from].sortSequence;
            if (dragPartType == "Cover") {
              delete this.CoverImageWithThumb[removeAssignImage];
            } else {
              delete this.previewImageArray[removeAssignImage];
              delete this.thumbnailArray[removeAssignImage];
            }

            // this.previewImageArray.splice(removeAssignImage, 1);
          }

          this.images[from].sortSequence = currentImageDiv;
          this.images[from].variantId =
            dropPartType == "Cover" ? this.coverVariant : this.bodyVariant;
          this.images[from].partType = dropPartType;

          this.previewImageArray[currentImageDiv] = this.images[from].mainImage;

          //Drop logic

          if (dragPartType == "Cover" && dropPartType == "Cover") {
            this.CoverImageWithThumb[currentImageDiv] = {};
            this.CoverImageWithThumb[currentImageDiv]["main"] = this.images[
              from
            ].mainImage;
            this.CoverImageWithThumb[currentImageDiv]["thumb"] = this.images[
              from
            ].thumbnail;
          } else if (dragPartType == "Body" && dropPartType == "Body") {
            //console.log("in valid");
            this.thumbnailArray[currentImageDiv] = {};
            this.thumbnailArray[currentImageDiv]["main"] = this.images[from].mainImage;
            this.thumbnailArray[currentImageDiv]["thumb"] = this.images[from].thumbnail;
          } else if (dropPartType == "Cover" && dragPartType == "Body") {
            this.CoverImageWithThumb[currentImageDiv] = {};
            this.CoverImageWithThumb[currentImageDiv]["main"] = this.images[
              from
            ].mainImage;

            this.CoverImageWithThumb[currentImageDiv]["thumb"] = this.images[
              from
            ].thumbnail;
            //this.thumbnailArray[currentImageDiv] = OldData;
          } else {
            //console.log(`aaadrop part type body`);
            this.thumbnailArray[currentImageDiv] = {};
            this.thumbnailArray[currentImageDiv]["main"] = this.images[from].mainImage;
            this.thumbnailArray[currentImageDiv]["thumb"] = this.images[from].thumbnail;
          }

          await this.manualpageAssignType();
          payloadArray[0] = this.images[from_dragId];
          if (to_dropId >= 0) {
            payloadArray[1] = this.images[to_dropId];
          }

          await this.SortImageorder();
          this.maxAssignednumber();
          this.isActivearray[0] = currentImageDiv;

          let orderHeader = {
            orderId: this.OrderId,
          };

          const result1 = await axiosInterceptor.post(
            `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&plausibilityCheck=true&jobLock=true`,
            payloadArray,
            orderHeader
          );

          this.CheckValidation(result1.data.plausibilityCheck, 2);
          //this.nextimage();

          loader.hide();
        } else {
          // Used for swap from left panel
          let dragPartTypeleft = this.images[from].partType;
          if (dragPartTypeleft == "") {
            dragPartTypeleft = dropPartType;
          }

          //if both image has not assign any sortSequence
          if (
            this.images[from]["sortSequence"] == null &&
            this.images[to]["sortSequence"] == null
          ) {
            this.images.splice(to, 0, this.images.splice(from, 1)[0]);
          } else {
            //if to image has assign any sortSequence
            if (
              this.images[from]["sortSequence"] == null &&
              this.images[to]["sortSequence"] != null
            ) {
              from_dragId = from;
              to_dropId = to;
              this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
              this.images[from].partType = this.images[to].partType;
              this.images[from].variantId =
                this.images[from].partType == "Cover"
                  ? this.coverVariant
                  : this.bodyVariant;
              this.previewImageArray[this.images[to]["sortSequence"]] = this.images[
                from
              ].mainImage;

              if (this.images[to]["partType"] == "Cover") {
                this.CoverImageWithThumb[this.images[to]["sortSequence"]][
                  "main"
                ] = this.images[from].mainImage;
                this.CoverImageWithThumb[this.images[to]["sortSequence"]][
                  "thumb"
                ] = this.images[from].thumbnail;
              } else {
                this.thumbnailArray[this.images[to]["sortSequence"]] = {};
                this.thumbnailArray[this.images[to]["sortSequence"]][
                  "main"
                ] = this.images[from].mainImage;
                this.thumbnailArray[this.images[to]["sortSequence"]][
                  "thumb"
                ] = this.images[from].thumbnail;
              }

              this.images[to]["sortSequence"] = null;
            } else if (
              this.images[to]["sortSequence"] == null &&
              this.images[from]["sortSequence"] != null
            ) {
              from_dragId = from;
              to_dropId = to;
              //console.log("elseif1", this.images[from]["sortSequence"]);
              this.images[to]["sortSequence"] = this.images[from]["sortSequence"];
              this.images[to].variantId = this.bodyVariant;
              this.previewImageArray[this.images[from]["sortSequence"]] = this.images[
                to
              ].mainImage;
              this.thumbnailArray[this.images[from]["sortSequence"]][
                "main"
              ] = this.images[to].mainImage;
              this.thumbnailArray[this.images[from]["sortSequence"]][
                "thumb"
              ] = this.images[to].thumbnail;

              this.images[from]["sortSequence"] = null;
              this.images[from]["variantId"] = null;
            } else {
              //inter swap assigned
              //Both images has assigned
              from_dragId = from;
              to_dropId = to;
              let old = this.images[from]["sortSequence"];

              let oldsort = this.images[from]["sortSequence"];
              let newsort = this.images[to]["sortSequence"];
              if (dragPartTypeleft == "Cover" && dropPartType == "Cover") {
                let oldsordata = this.CoverImageWithThumb[oldsort];
                let newsordata = this.CoverImageWithThumb[newsort];

                this.CoverImageWithThumb[oldsort] = newsordata;
                this.CoverImageWithThumb[newsort] = oldsordata;

                this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
                this.images[from]["variantId"] = this.coverVariant;
                this.images[to]["sortSequence"] = old;
              } else if (dragPartTypeleft == "Body" && dropPartType == "Body") {
                let oldsordata = this.thumbnailArray[oldsort];
                let newsordata = this.thumbnailArray[newsort];

                this.thumbnailArray[oldsort] = newsordata;
                this.thumbnailArray[newsort] = oldsordata;

                this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
                this.images[from]["variantId"] = this.bodyVariant;
                this.images[to]["sortSequence"] = old;
              } else if (dragPartTypeleft == "Body" && dropPartType == "Cover") {
                let oldsordata = this.thumbnailArray[oldsort];
                let newsordata = this.CoverImageWithThumb[newsort];

                this.thumbnailArray[oldsort] = newsordata;
                this.CoverImageWithThumb[newsort] = oldsordata;

                this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
                this.images[from]["variantId"] = this.coverVariant;
                this.images[from]["partType"] = "Cover";
                this.images[to]["sortSequence"] = old;
                this.images[to]["partType"] = "Body";
                this.images[to]["variantId"] = this.bodyVariant;
              } else {
                let oldsordata = this.CoverImageWithThumb[oldsort];
                let newsordata = this.thumbnailArray[newsort];

                this.CoverImageWithThumb[oldsort] = newsordata;
                this.thumbnailArray[newsort] = oldsordata;

                this.images[from]["sortSequence"] = this.images[to]["sortSequence"];
                this.images[from]["variantId"] = this.bodyVariant;
                this.images[from]["partType"] = "Body";
                this.images[to]["sortSequence"] = old;
                this.images[to]["partType"] = "Cover";
                this.images[to]["variantId"] = this.coverVariant;
              }
            }

            //console.log(`from_dragId`, from_dragId, `to_dropId`, to_dropId);
            payloadArray[0] = this.images[from_dragId];
            if (to_dropId >= 0) {
              payloadArray[1] = this.images[to_dropId];
            }
            this.images.splice(to, 0, this.images.splice(from, 1)[0]);

            await this.SortImageorder();
            this.maxAssignednumber();
            await this.manualpageAssignType();

            this.isActivearray[0] = currentImageDiv;

            //console.log(this.images);
          }
          /* let orderHeader = {
            orderId: this.OrderId,
          };

          const result1 = await axiosInterceptor.post(
            `/api/v1/resources/pageSortAndAssign?orderId=${this.OrderId}&variantId=${this.variantId}&plausibilityCheck=true&jobLock=true`,
            payloadArray,
            orderHeader
          );

          this.CheckValidation(result1.data.plausibilityCheck, 2);*/
          loader.hide();
        }
        if (this.images[0]["sortSequence"] == 1) {
          this.popup_image = this.externalImage + this.images[0]["thumbnail"];
        } else {
          this.popup_image = this.$noImagePreview;
        }
      }
      this.singleSelect = false;
      //console.log("assign", this.images);
    },
    async setvalidation() {
      if (this.showCustom == true) {
        await this.swalForCDF(2);
        return;
      }
      const bootstrap = this.$store.state.bootstrap;
      var myModal = document.getElementById("new-board-modal");
      var modal = bootstrap.Modal.getOrCreateInstance(myModal);
      modal.show();

      document.getElementById("releasePrintForm").reset();
      this.isValidFormSubmit = this.dataCheck;
      //this.isValidFormSubmit = false;
    },
    reset() {
      const bootstrap = this.$store.state.bootstrap;
      var myModal = document.getElementById("new-board-modal");
      var modal = bootstrap.Modal.getOrCreateInstance(myModal);
      modal.hide();
    },
    async showFile() {
      this.socketClientId = this.$socket.id;
      //this.socket.emit("msgToServer", {});
      if (this.showCustom == true) {
        await this.swalForCDF(1);
        return;
      }
      this.submitDropzone = true;
      this.showDropzone = true;
      var dummyDive = document.querySelector(".ps.ps--active-y");
      dummyDive.scrollTop = 0;
      let l = document.getElementById("productImg");
      l.click();
    },
    uploadFile() {
      if (this.totalFile == this.errorFile) {
        this.AddProcessingMsg(
          "invalidPDFmessage",
          "release-print-data.error-message-minimumfile",
          "danger",
          true,
          true
        );
        console.log("invalid");
        return;
      }

      this.myDropzone.processQueue();
      this.AddProcessingMsg(
        "imageProcessingMsg",
        "release-print-data.image-processing-msg",
        "success",
        false,
        true
      );
      this.uploadbtn = false;
    },
    swalForCDF(type) {
      console.log(`swal`, type);
      //let status = false;
      Swal.fire({
        title: "",
        text: this.getCDFConfirmMessage,
        icon: "info",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.getSwalCDFSave,
        cancelButtonText: this.getCDFCancelbutton,
      }).then((result) => {
        if (result.isConfirmed) {
          //Call CDF API
          //status = true;
          this.cdfConfirmFlag = 1;
          document.querySelector("#CDFSubmitButton").click();
          //console.log("isConfirmed");
        } else {
          //this.showCustom = false;
          this.cdfConfirmFlag = 2;

          /* console.log("isNOTConfirmed");*/
        }
      });
      //return status;
    },
    async initDropzone() {
      this.commonDropzone(2);
    },
    updateCanvasFlag() {
      for (let k = 0; k < this.showLine; k++) {
        if (this.images[k]) {
          this.images[k].canvasGenerated = false;
        }
      }
    },
    async ShowCanvas(indexCanvas, lastLocation, partType = "") {
      this.clickedItem = "";
      if (lastLocation != "next") {
        this.backLocation = lastLocation;
      }

      // When click from the left panel && imageassigned function call and parttype is body and order has cover
      if (partType == "Body" && this.coverPage > 0) {
        indexCanvas = indexCanvas + this.coverPage;
      }
      //this.backLocation = lastLocation;

      this.displayInCanvas = indexCanvas;
      if (indexCanvas != null) {
        this.displayInCanvas = indexCanvas;
      }
      let imageExist;
      if (this.coverPage == 0) {
        imageExist = this.previewImageArray[this.displayInCanvas];
        if (this.previewImageArray[1] == undefined) {
          this.prevCanvasIcon = false;
        }
        if (this.previewImageArray[this.displayInCanvas + 1] == undefined) {
          this.nextCanvasIcon = false;
        }
      } else {
        //It is body image when order has cover part
        if (this.displayInCanvas > this.coverPage) {
          let getValidIndex = this.displayInCanvas - this.coverPage;
          //console.log(`getValidIndex`, this.thumbnailArray[getValidIndex]["main"]);

          imageExist = this.thumbnailArray[getValidIndex]
            ? this.thumbnailArray[getValidIndex]["main"]
            : undefined;
        } else {
          // It is cover logic
          imageExist = this.CoverImageWithThumb[this.displayInCanvas]
            ? this.CoverImageWithThumb[this.displayInCanvas]["main"]
            : undefined;
        }
      }

      if (imageExist == undefined) {
        await this.showCanvasImage(imageExist, "");
      } else {
        this.showMiniature = false;
        this.MutipartMiniaturePreview = false;
        this.canvasComponents = true;
        //this.clickedItem = 5;
        this.previmageicon = false;
        this.nextimageicon = false;

        //Checking canvas generated or not
        //body page
        let canvasGenerated = true;
        let imageCanvasIndex;
        if (this.displayInCanvas > this.coverPage) {
          let getValidIndex = this.displayInCanvas - this.coverPage;
          const canvasImageIndexBody = this.images.findIndex((object) => {
            return (
              object.sortSequence == getValidIndex &&
              (object.partType == "Body" || object.partType == "")
            );
          });

          canvasGenerated = this.images[canvasImageIndexBody].canvasGenerated;
          imageCanvasIndex = canvasImageIndexBody;
        } else {
          const canvasImageIndexCover = this.images.findIndex((object) => {
            return (
              object.sortSequence === this.displayInCanvas && object.partType != "Body"
            );
          });
          canvasGenerated = this.images[canvasImageIndexCover].canvasGenerated;
          imageCanvasIndex = canvasImageIndexCover;
        }

        if (canvasGenerated == false) {
          this.images[imageCanvasIndex].canvasGenerated = true;
          //console.log(this.images[this.displayInCanvas - 1].quickCheck);
          this.dynamicZoom[this.displayInCanvas] = 100;
          const showZoom = document.getElementById("showZoom");
          showZoom.value = `${Math.round(this.dynamicZoom[this.displayInCanvas])}%`;
          this.panning[this.displayInCanvas] = false;
          this.zoomFlag[this.displayInCanvas] = null;
          let passImage = this.previewImageArray[this.displayInCanvas];
          let quickcheckfile = this.images[imageCanvasIndex].quickCheck;
          if (this.coverPage > 0) {
            // It is body image
            if (this.displayInCanvas > this.coverPage) {
              let getValidIndex = this.displayInCanvas - this.coverPage;
              const mainImageIndexBody = this.images.findIndex((object) => {
                return object.sortSequence == getValidIndex && object.partType == "Body";
              });
              quickcheckfile = this.images[mainImageIndexBody].quickCheck;
              passImage = this.thumbnailArray[getValidIndex]["main"];
            } else {
              passImage = this.CoverImageWithThumb[this.displayInCanvas]["main"];
              const mainImageIndexCover = this.images.findIndex((object) => {
                return (
                  object.sortSequence === this.displayInCanvas &&
                  object.partType != "Body"
                );
              });
              quickcheckfile = this.images[mainImageIndexCover].quickCheck;
            }
          }

          await this.showCanvasImage(passImage, quickcheckfile);
          /*setTimeout(async () => {
            await this.showCanvasImage(passImage, quickcheckfile);
          }, 5000);*/
        } else {
          // Based on below codition need to required class for height & width
          this.isHeight = false;
          if (
            this.effectiveMediaHeight[this.displayInCanvas] >
            this.effectiveMediaWidth[this.displayInCanvas]
          ) {
            this.isHeight = true;
          }
          this.isWidth = false;
          if (
            this.effectiveMediaWidth[this.displayInCanvas] >
            this.effectiveMediaHeight[this.displayInCanvas] * 2
          ) {
            this.isWidth = true;
          }

          this.canvasText = false;
          if (imageExist == undefined) {
            this.canvasText = true;
          }
          this.canvas_check[this.displayInCanvas].renderAll();
        }
      }
    },
    async showCanvasImage(image, quickCheckJsonFile) {
      this.canvasText = false;
      if (image == undefined) {
        this.isHeight = false;
        let dynamicCanvas = `dynamicCanvas` + this.displayInCanvas;

        // Dispose the canvas if it is already generated
        if (this.canvas_check[this.displayInCanvas]) {
          this.canvas_check[this.displayInCanvas].dispose();
        }

        this.canvas_check[this.displayInCanvas] = new fabric.Canvas(dynamicCanvas, {
          selection: false,
        });
        this.canvas_check[this.displayInCanvas].setWidth(450);
        this.canvas_check[this.displayInCanvas].setHeight(290);
        var textObj = new fabric.IText(
          i18n.global.t("release-print-data.Canvas-Error-message"),
          {
            selection: false,
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
            width: 450,
            height: 290,
            left: 0,
            top: 0,
            fontFamily: "Roboto",
            fontSize: 16,
            fill: "#ffffff",
            textAlign: "center",
            originX: "center",
          }
        );
        this.canvas_check[this.displayInCanvas].add(textObj);
        this.canvas_check[this.displayInCanvas].centerObject(textObj);
        this.canvas_check[this.displayInCanvas].renderAll();
        this.canvasText = true;
        return;
      }
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });

      //const resultData = await axiosInterceptor.get(quickCheckJsonFile);
      const resultData = quickCheckJsonFile;
      const showZoom = document.getElementById("showZoom");
      showZoom.value = `${Math.round(1 * 100)}%`;

      this.group_array = [];

      //let geometryData = resultData.data.aggregated.pages.page[0].geometry;
      let geometryData = resultData.geometry;
      let MediaBox = geometryData.MediaBox;
      let BleedBox = geometryData.BleedBox;
      let TrimBox = geometryData.TrimBox;
      let ArtBox = geometryData.ArtBox;
      let CropBox = geometryData.CropBox;

      this.isMediaboxPresent[this.displayInCanvas] = geometryData.MediaBox.present;
      this.isBleedboxPresent[this.displayInCanvas] = geometryData.BleedBox.present;
      this.isTrimboxPresent[this.displayInCanvas] = geometryData.TrimBox.present;
      this.isCropboxPresent[this.displayInCanvas] = geometryData.CropBox.present;
      this.isArtboxPresent[this.displayInCanvas] = geometryData.ArtBox.present;
      this.dynamicBorderValue[this.displayInCanvas] = 1;

      this.blurboxLeft[this.displayInCanvas] = 0;
      this.blurboxTop[this.displayInCanvas] = 0;
      this.blurboxWidth[this.displayInCanvas] = 0;
      this.blurboxHeight[this.displayInCanvas] = 0;

      /*this.effectiveMediaHeight[this.displayInCanvas] =
        resultData.data.aggregated.pages.effective_MediaBox.height_max;
      this.effectiveMediaWidth[this.displayInCanvas] =
        resultData.data.aggregated.pages.effective_MediaBox.width_max;*/

      this.effectiveMediaHeight[this.displayInCanvas] = MediaBox.height_eff;
      this.effectiveMediaWidth[this.displayInCanvas] = MediaBox.width_eff;

      let canvasmul = 1;
      let pointTopx = this.$pointTopx;
      this.MediaBoxHeight[this.displayInCanvas] = MediaBox.height_eff;
      this.MediaBoxWidth[this.displayInCanvas] = MediaBox.width_eff;
      this.MediaBoxLeft[this.displayInCanvas] = MediaBox.left;
      this.MediaBoxBottom[this.displayInCanvas] = MediaBox.left;

      //Covert point to px
      this.MediaBoxHeight[this.displayInCanvas] =
        this.MediaBoxHeight[this.displayInCanvas] * pointTopx;
      this.MediaBoxWidth[this.displayInCanvas] =
        this.MediaBoxWidth[this.displayInCanvas] * pointTopx;
      this.MediaBoxLeft[this.displayInCanvas] =
        this.MediaBoxLeft[this.displayInCanvas] * pointTopx;
      this.MediaBoxBottom[this.displayInCanvas] =
        this.MediaBoxBottom[this.displayInCanvas] * pointTopx;

      let canvasMediaBoxHeight = this.MediaBoxHeight[this.displayInCanvas] * canvasmul;
      let canvasMediaBoxWidth = this.MediaBoxWidth[this.displayInCanvas] * canvasmul;

      this.MediaBoxHeight[this.displayInCanvas] =
        this.MediaBoxHeight[this.displayInCanvas] * canvasmul;
      this.MediaBoxWidth[this.displayInCanvas] =
        this.MediaBoxWidth[this.displayInCanvas] * canvasmul;
      this.MediaBoxLeft[this.displayInCanvas] =
        this.MediaBoxLeft[this.displayInCanvas] * canvasmul;
      this.MediaBoxBottom[this.displayInCanvas] =
        this.MediaBoxBottom[this.displayInCanvas] * canvasmul;

      this.isHeight = false;
      if (
        this.effectiveMediaHeight[this.displayInCanvas] >
        this.effectiveMediaWidth[this.displayInCanvas]
      ) {
        this.isHeight = true;
      }

      this.isWidth = false;
      if (
        this.effectiveMediaWidth[this.displayInCanvas] >
        this.effectiveMediaHeight[this.displayInCanvas] * 2
      ) {
        this.isWidth = true;
      }

      var drawCanvasImage = this.externalImage + image;
      let dynamicCanvas = `dynamicCanvas` + this.displayInCanvas;
      this.canvas_check[this.displayInCanvas] = new fabric.Canvas(
        dynamicCanvas,
        await {
          selection: false,
        }
      );
      this.canvas_check[this.displayInCanvas].setWidth(canvasMediaBoxWidth);
      this.canvas_check[this.displayInCanvas].setHeight(canvasMediaBoxHeight);
      //const center = this.canvas_check.getCenter();

      this.MediaVisiable[this.displayInCanvas] = false;

      if (this.isMediaboxPresent[this.displayInCanvas] == true) {
        this.Mediarect[this.displayInCanvas] = new fabric.Rect({
          top: this.MediaBoxBottom[this.displayInCanvas],
          left: this.MediaBoxLeft[this.displayInCanvas],
          width:
            this.MediaBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          height:
            this.MediaBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          stroke: "blue",
          strokeWidth: this.dynamicBorderValue[this.displayInCanvas],
          fill: "",
          selectable: true,
          id: "rectMedia" + this.displayInCanvas,
        });

        this.canvas_check[this.displayInCanvas].add(this.Mediarect[this.displayInCanvas]);
        this.group_array.push(this.Mediarect[this.displayInCanvas]);
        this.MediaVisiable[this.displayInCanvas] = true;
      }

      this.BleedVisiable[this.displayInCanvas] = false;
      if (this.isBleedboxPresent[this.displayInCanvas] == true) {
        this.BleedBoxHeight[this.displayInCanvas] = BleedBox.height_eff;
        this.BleedBoxWidth[this.displayInCanvas] = BleedBox.width_eff;
        this.BleedBoxLeft[this.displayInCanvas] = BleedBox.left;
        this.BleedBoxBottom[this.displayInCanvas] = BleedBox.left;

        this.BleedBoxHeight[this.displayInCanvas] =
          this.BleedBoxHeight[this.displayInCanvas] * pointTopx * canvasmul;
        this.BleedBoxWidth[this.displayInCanvas] =
          this.BleedBoxWidth[this.displayInCanvas] * pointTopx * canvasmul;
        this.BleedBoxLeft[this.displayInCanvas] =
          this.BleedBoxLeft[this.displayInCanvas] * pointTopx * canvasmul;
        this.BleedBoxBottom[this.displayInCanvas] =
          this.BleedBoxBottom[this.displayInCanvas] * pointTopx * canvasmul;
        this.Bleedrect[this.displayInCanvas] = new fabric.Rect({
          top: this.BleedBoxBottom[this.displayInCanvas],
          left: this.BleedBoxLeft[this.displayInCanvas],
          width:
            this.BleedBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          height:
            this.BleedBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          stroke: "#ff0000",
          strokeWidth: this.dynamicBorderValue[this.displayInCanvas],
          fill: "",
          selectable: true,
          id: "rectBleed" + this.displayInCanvas,
        });

        this.canvas_check[this.displayInCanvas].add(this.Bleedrect[this.displayInCanvas]);
        this.group_array.push(this.Bleedrect[this.displayInCanvas]);
        this.BleedVisiable[this.displayInCanvas] = true;

        this.blurboxLeft[this.displayInCanvas] = this.BleedBoxLeft[this.displayInCanvas];
        this.blurboxTop[this.displayInCanvas] = this.BleedBoxBottom[this.displayInCanvas];
        this.blurboxWidth[this.displayInCanvas] = this.BleedBoxWidth[
          this.displayInCanvas
        ];
        this.blurboxHeight[this.displayInCanvas] = this.BleedBoxHeight[
          this.displayInCanvas
        ];
        this.minBox[this.displayInCanvas] = "Bleedrect";
      }

      if (this.isTrimboxPresent[this.displayInCanvas] == true) {
        this.TrimBoxHeight[this.displayInCanvas] = TrimBox.height_eff;
        this.TrimBoxWidth[this.displayInCanvas] = TrimBox.width_eff;
        this.TrimBoxLeft[this.displayInCanvas] = TrimBox.left;
        this.TrimBoxBottom[this.displayInCanvas] = TrimBox.left;

        this.TrimBoxHeight[this.displayInCanvas] =
          this.TrimBoxHeight[this.displayInCanvas] * pointTopx * canvasmul;
        this.TrimBoxWidth[this.displayInCanvas] =
          this.TrimBoxWidth[this.displayInCanvas] * pointTopx * canvasmul;
        this.TrimBoxLeft[this.displayInCanvas] =
          this.TrimBoxLeft[this.displayInCanvas] * pointTopx * canvasmul;
        this.TrimBoxBottom[this.displayInCanvas] =
          this.TrimBoxBottom[this.displayInCanvas] * pointTopx * canvasmul;

        this.Trimrect[this.displayInCanvas] = new fabric.Rect({
          top: this.TrimBoxBottom[this.displayInCanvas],
          left: this.TrimBoxLeft[this.displayInCanvas],
          width:
            this.TrimBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          height:
            this.TrimBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          stroke: "#04AA6D",
          strokeWidth: this.dynamicBorderValue[this.displayInCanvas],
          fill: "",
          selectable: true,
          id: "rectTrim" + this.displayInCanvas,
        });
        this.canvas_check[this.displayInCanvas].add(this.Trimrect[this.displayInCanvas]);
        this.group_array.push(this.Trimrect[this.displayInCanvas]);
        this.TrimVisiable[this.displayInCanvas] = true;
        if (
          this.blurboxWidth[this.displayInCanvas] >
            this.TrimBoxWidth[this.displayInCanvas] &&
          this.blurboxHeight[this.displayInCanvas] >
            this.TrimBoxHeight[this.displayInCanvas]
        ) {
          this.blurboxLeft[this.displayInCanvas] = this.TrimBoxLeft[this.displayInCanvas];
          this.blurboxTop[this.displayInCanvas] = this.TrimBoxBottom[
            this.displayInCanvas
          ];
          this.blurboxWidth[this.displayInCanvas] = this.TrimBoxWidth[
            this.displayInCanvas
          ];
          this.blurboxHeight[this.displayInCanvas] = this.TrimBoxHeight[
            this.displayInCanvas
          ];
          this.minBox[this.displayInCanvas] = "Trimrect";
        }
      }

      this.CropVisiable[this.displayInCanvas] = false;
      if (this.isCropboxPresent[this.displayInCanvas] == true) {
        this.CropBoxHeight[this.displayInCanvas] = CropBox.height_eff;
        this.CropBoxWidth[this.displayInCanvas] = CropBox.width_eff;
        this.CropBoxLeft[this.displayInCanvas] = CropBox.left;
        this.CropBoxBottom[this.displayInCanvas] = CropBox.left;

        this.CropBoxHeight[this.displayInCanvas] =
          this.CropBoxHeight[this.displayInCanvas] * pointTopx * canvasmul;
        this.CropBoxWidth[this.displayInCanvas] =
          this.CropBoxWidth[this.displayInCanvas] * pointTopx * canvasmul;
        this.CropBoxLeft[this.displayInCanvas] =
          this.CropBoxLeft[this.displayInCanvas] * pointTopx * canvasmul;
        this.CropBoxBottom[this.displayInCanvas] =
          this.CropBoxBottom[this.displayInCanvas] * pointTopx * canvasmul;

        this.Croprect[this.displayInCanvas] = new fabric.Rect({
          top: this.CropBoxBottom[this.displayInCanvas],
          left: this.CropBoxLeft[this.displayInCanvas],
          width:
            this.CropBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          height:
            this.CropBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          stroke: "#971d6e",
          strokeWidth: this.dynamicBorderValue[this.displayInCanvas],
          fill: "",
          selectable: true,
          id: "rectCrop" + this.displayInCanvas,
        });
        this.canvas_check[this.displayInCanvas].add(this.Croprect[this.displayInCanvas]);
        this.group_array.push(this.Croprect[this.displayInCanvas]);
        this.CropVisiable[this.displayInCanvas] = true;

        if (
          this.blurboxWidth[this.displayInCanvas] >
            this.CropBoxWidth[this.displayInCanvas] &&
          this.blurboxHeight[this.displayInCanvas] >
            this.CropBoxHeight[this.displayInCanvas]
        ) {
          this.blurboxLeft[this.displayInCanvas] = this.CropBoxLeft[this.displayInCanvas];
          this.blurboxTop[this.displayInCanvas] = this.CropBoxBottom[
            this.displayInCanvas
          ];
          this.blurboxWidth[this.displayInCanvas] = this.CropBoxWidth[
            this.displayInCanvas
          ];
          this.blurboxHeight[this.displayInCanvas] = this.CropBoxHeight[
            this.displayInCanvas
          ];
          this.minBox[this.displayInCanvas] = "Croprect";
        }
      }

      if (this.isArtboxPresent[this.displayInCanvas] == true) {
        this.ArtBoxHeight[this.displayInCanvas] = ArtBox.height_eff;
        this.ArtBoxWidth[this.displayInCanvas] = ArtBox.width_eff;
        this.ArtBoxLeft[this.displayInCanvas] = ArtBox.left;
        this.ArtBoxBottom[this.displayInCanvas] = ArtBox.left;

        this.ArtBoxHeight[this.displayInCanvas] =
          this.ArtBoxHeight[this.displayInCanvas] * pointTopx * canvasmul;
        this.ArtBoxWidth[this.displayInCanvas] =
          this.ArtBoxWidth[this.displayInCanvas] * pointTopx * canvasmul;
        this.ArtBoxLeft[this.displayInCanvas] =
          this.ArtBoxLeft[this.displayInCanvas] * pointTopx * canvasmul;
        this.ArtBoxBottom[this.displayInCanvas] =
          this.ArtBoxBottom[this.displayInCanvas] * pointTopx * canvasmul;

        if (
          this.ArtBoxWidth[this.displayInCanvas] > this.TrimBoxWidth[this.displayInCanvas]
        ) {
          this.ArtBoxWidth[this.displayInCanvas] =
            this.ArtBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas];
          this.ArtBoxHeight[this.displayInCanvas] =
            this.ArtBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas];
        }

        this.Artrect[this.displayInCanvas] = new fabric.Rect({
          top: this.ArtBoxBottom[this.displayInCanvas],
          left: this.ArtBoxLeft[this.displayInCanvas],
          width:
            this.ArtBoxWidth[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          height:
            this.ArtBoxHeight[this.displayInCanvas] -
            this.dynamicBorderValue[this.displayInCanvas],
          stroke: "#964B00",
          strokeWidth: this.dynamicBorderValue[this.displayInCanvas],
          fill: "",
          selectable: true,
          id: "rectArt" + this.displayInCanvas,
        });
        this.canvas_check[this.displayInCanvas].add(this.Artrect[this.displayInCanvas]);
        this.group_array.push(this.Artrect[this.displayInCanvas]);
        this.ArtVisiable[this.displayInCanvas] = true;
        if (
          this.blurboxWidth[this.displayInCanvas] >
            this.ArtBoxWidth[this.displayInCanvas] &&
          this.blurboxHeight[this.displayInCanvas] >
            this.ArtBoxHeight[this.displayInCanvas]
        ) {
          this.blurboxLeft[this.displayInCanvas] = this.ArtBoxLeft[this.displayInCanvas];
          this.blurboxTop[this.displayInCanvas] = this.ArtBoxBottom[this.displayInCanvas];
          this.blurboxWidth[this.displayInCanvas] = this.ArtBoxWidth[
            this.displayInCanvas
          ];
          this.blurboxHeight[this.displayInCanvas] = this.ArtBoxHeight[
            this.displayInCanvas
          ];
          this.minBox[this.displayInCanvas] = "Artrect";
        }

        //this.canvas_check.remove(Trimrect);
      }
      this.bleedAreaVisiable[this.displayInCanvas] = false;
      this.isbleedAreaPresent[this.displayInCanvas] = false;
      if (
        this.MediaBoxLeft[this.displayInCanvas] != this.blurboxLeft[this.displayInCanvas]
      ) {
        this.bleedAreaVisiable[this.displayInCanvas] = true;
        this.isbleedAreaPresent[this.displayInCanvas] = true;
        this.overlayRect[this.displayInCanvas] = new fabric.Rect({
          left: this.MediaBoxLeft[this.displayInCanvas] - 1,
          top: this.MediaBoxBottom[this.displayInCanvas] - 1,
          width: this.MediaBoxWidth[this.displayInCanvas] + 1,
          height: this.MediaBoxHeight[this.displayInCanvas] + 1,
          fill: "#00000050",
          selectable: false,
          globalCompositeOperation: "source-over",
          id: "rectOverlay" + this.displayInCanvas,
          //visible: false,
        });
        this.canvas_check[this.displayInCanvas].add(
          this.overlayRect[this.displayInCanvas]
        );
        this.group_array.push(this.overlayRect[this.displayInCanvas]);

        this.destinationRect[this.displayInCanvas] = new fabric.Rect({
          left:
            this.blurboxLeft[this.displayInCanvas] +
            this.dynamicBorderValue[this.displayInCanvas],
          top:
            this.blurboxTop[this.displayInCanvas] +
            this.dynamicBorderValue[this.displayInCanvas],
          width: this.blurboxWidth[this.displayInCanvas] - 4,
          height: this.blurboxHeight[this.displayInCanvas] - 4,
          fill: "#451245",
          globalCompositeOperation: "destination-out",
          id: "rectDestination" + this.displayInCanvas,
          //visible: false,
        });
        this.canvas_check[this.displayInCanvas].add(
          this.destinationRect[this.displayInCanvas]
        );
        this.group_array.push(this.destinationRect[this.displayInCanvas]);
      }

      var canvasImage = null;
      // var group;
      let t = this;
      fabric.Image.fromURL(drawCanvasImage, function (myImg) {
        //i create an extra var for to change some image properties
        canvasImage = myImg.set({
          left: 0,
          top: 0,
          selectable: true,
          fill: "",
          globalCompositeOperation: "destination-atop",
        });

        canvasImage.scaleToWidth(canvasMediaBoxWidth);
        canvasImage.scaleToHeight(canvasMediaBoxHeight);
        t.canvas_check[t.displayInCanvas].sendToBack(canvasImage);
        t.canvas_check[t.displayInCanvas].setOverlayImage(
          canvasImage,
          t.canvas_check[t.displayInCanvas].renderAll.bind(
            t.canvas_check[t.displayInCanvas]
          )
        );

        // group_array.push(rect1);
        t.group_array.unshift(canvasImage); //Set on 0 index.It is required.

        t.group[t.displayInCanvas] = new fabric.Group(t.group_array, {
          // lockMovementY: true,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
          hasRectangle: true,
          originX: "center",
          originY: "center",
          objectCaching: false,
        });
        //console.log(group);
        t.canvas_check[t.displayInCanvas].add(t.group[t.displayInCanvas]);
      });
      this.canvas_check[this.displayInCanvas].setZoom(1);
      this.canvas_check[this.displayInCanvas].renderAll();

      this.canvas_check[this.displayInCanvas].on("mouse:down", async function (options) {
        if (
          t.panning[t.displayInCanvas] != false &&
          t.zoomFlag[t.displayInCanvas] != null
        ) {
          await t.updatePannning(false);
        }

        if (
          t.panning[t.displayInCanvas] == false &&
          t.zoomFlag[t.displayInCanvas] != null
        ) {
          if (t.zoomFlag[t.displayInCanvas] == 2) {
            t.ZoomInNew(-0.1, 2, options.e.layerX, options.e.layerY);
          } else if (t.zoomFlag[t.displayInCanvas] == 1) {
            t.ZoomInNew(0.1, 1, options.e.layerX, options.e.layerY);
          }
        }
      });
      loader.hide();
    },
    setZoom(zoomTextValue = 0) {
      let point = { x: 0, y: 0 };
      // console.log(`this.dynamicZoom`, this.dynamicZoom);
      const showZoom = document.getElementById("showZoom");
      if (zoomTextValue > 0) {
        this.dynamicZoom[this.displayInCanvas] = zoomTextValue;
      }
      /*  console.log(
        `this.displayInCanvas`,
        this.displayInCanvas,
        this.dynamicZoom[this.displayInCanvas]
      );*/
      /*if (this.dynamicZoom <= 0) {
        this.dynamicZoom = 1;
        showZoom.value = `${Math.round(this.dynamicZoom)}%`;
        this.errorForZoom = true;
        this.snackbarTimeOut(6);
        return;
      }*/

      let newZoom = this.dynamicZoom[this.displayInCanvas];
      point = {
        x: this.canvas_check[this.displayInCanvas].width / 2,
        y: this.canvas_check[this.displayInCanvas].height / 2,
      };

      if (this.dynamicZoom[this.displayInCanvas] == 1) {
        this.dynamicZoom[this.displayInCanvas] = 1;
        newZoom = this.dynamicZoom[this.displayInCanvas];
      } else if (this.dynamicZoom[this.displayInCanvas] <= 1) {
        this.dynamicZoom[this.displayInCanvas] = 1;
        /*this.errorForZoom = true;
        this.snackbarTimeOut(6);*/
        this.AddProcessingMsg(
          "errorForZoom",
          "release-print-data.Zoom-Error-message",
          "danger",
          true,
          true
        );
        newZoom = this.dynamicZoom[this.displayInCanvas];
      }

      newZoom = newZoom / 100;
      /*console.log(`point`, point);
      cosole.log(`newZoom`, newZoom);*/

      if (this.dynamicZoom[this.displayInCanvas] >= 100) {
        let calcValue = this.dynamicZoom[this.displayInCanvas] - 100;
        calcValue = calcValue / 100;
        calcValue = 1 - calcValue;
        if (calcValue <= 0.2) {
          calcValue = 0.3;
        }
        this.dynamicBorderValue[this.displayInCanvas] = calcValue + 0.1;
        this.getAllCanvasobject(0.1);
      } else if (this.dynamicZoom[this.displayInCanvas] < 100) {
        this.dynamicZoom[this.displayInCanvas] = 1.1;
        this.getAllCanvasobject(-0.1);
      }

      this.canvas_check[this.displayInCanvas].zoomToPoint(point, newZoom);
      showZoom.value = `${Math.round(this.dynamicZoom[this.displayInCanvas])}%`;
      document.getElementById("showZoomNew").blur();
    },
    ZoomIn(value, zoomflag1) {
      console.log(value);

      if (this.panning[this.displayInCanvas] == true) {
        this.updatePannning(false);
      }
      this.zoomFlag[this.displayInCanvas] = zoomflag1;
    },
    ZoomInNew(value, zoomflag, xPoint, yPoint) {
      let point = { x: xPoint, y: yPoint };
      let canvasCenter = this.canvas_check[this.displayInCanvas].getCenter();
      const showZoom = document.getElementById("showZoom");

      if (
        `${Math.round(
          (this.canvas_check[this.displayInCanvas].getZoom() + value) * 100
        )}` == 1
      ) {
        this.dynamicZoom[this.displayInCanvas] = 1;
        point = { x: canvasCenter.left, y: canvasCenter.top };
        this.canvas_check[this.displayInCanvas].zoomToPoint(point, 0.01);
        return;
      } else if (
        `${Math.round(
          (this.canvas_check[this.displayInCanvas].getZoom() + value) * 100
        )}` <= 1
      ) {
        this.dynamicZoom[this.displayInCanvas] = 1;
        point = { x: canvasCenter.left, y: canvasCenter.top };
        /*this.errorForZoom = true;
        this.snackbarTimeOut(6);*/
        this.AddProcessingMsg(
          "errorForZoom",
          "release-print-data.Zoom-Error-message",
          "danger",
          true,
          true
        );
        this.canvas_check[this.displayInCanvas].zoomToPoint(point, 0.01);
        return;
      }
      if (this.zoomFlag[this.displayInCanvas] == 1) {
        this.getAllCanvasobject(value);
      } else if (
        this.zoomFlag[this.displayInCanvas] == 2 &&
        this.dynamicZoom[this.displayInCanvas] <= 200
      ) {
        this.getAllCanvasobject(value);
      }

      const newZoom = this.canvas_check[this.displayInCanvas].getZoom() + value;
      //direct center code
      let object = this.group[this.displayInCanvas];
      let objWidth = object.getScaledWidth();
      let objHeight = object.getScaledHeight();
      if (newZoom <= 1.09) {
        point = { x: canvasCenter.left, y: canvasCenter.top };
        this.canvas_check[this.displayInCanvas].zoomToPoint(point, newZoom);
        this.canvas_check[this.displayInCanvas].requestRenderAll();

        let zoom = this.canvas_check[this.displayInCanvas].getZoom();
        let panX =
          (this.canvas_check[this.displayInCanvas].getWidth() / zoom / 2 -
            object.aCoords.tl.x -
            objWidth / 2) *
          zoom;
        let panY =
          (this.canvas_check[this.displayInCanvas].getHeight() / zoom / 2 -
            object.aCoords.tl.y -
            objHeight / 2) *
          zoom;

        this.canvas_check[this.displayInCanvas].setViewportTransform([
          zoom,
          0,
          0,
          zoom,
          panX,
          panY,
        ]);

        showZoom.value = `${Math.round(newZoom * 100)}%`;
        this.dynamicZoom[this.displayInCanvas] = `${Math.round(newZoom * 100)}`;
        // this.canvas_check.requestRenderAll();
        // return;
      }

      //end direct center code

      if (`${Math.round(newZoom * 100)}` < 100) {
        point = { x: canvasCenter.left, y: canvasCenter.top };
      }
      this.canvas_check[this.displayInCanvas].zoomToPoint(point, newZoom);
      showZoom.value = `${Math.round(newZoom * 100)}%`;
      this.dynamicZoom[this.displayInCanvas] = `${Math.round(newZoom * 100)}`;
    },
    getAllCanvasobject(value) {
      if (
        this.dynamicBorderValue[this.displayInCanvas] - value > 0.2 &&
        this.dynamicBorderValue[this.displayInCanvas] - value <= 1.1
      ) {
        this.dynamicBorderValue[this.displayInCanvas] =
          this.dynamicBorderValue[this.displayInCanvas] - value;
        let t = this;
        //  console.log(`border value`, this.dynamicBorderValue[this.displayInCanvas]);
        this.canvas_check[this.displayInCanvas].getObjects().filter(function (o) {
          if (o.get("type") === "rect") {
            if (o.get("id") === "rectMedia" + t.displayInCanvas) {
              return o.set({
                strokeWidth: t.dynamicBorderValue[t.displayInCanvas],
                width:
                  t.MediaBoxWidth[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                height:
                  t.MediaBoxHeight[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
              });
            }
            if (o.get("id") === "rectBleed" + t.displayInCanvas) {
              return o.set({
                strokeWidth: t.dynamicBorderValue[t.displayInCanvas],
                width:
                  t.BleedBoxWidth[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                height:
                  t.BleedBoxHeight[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
              });
            }
            if (o.get("id") === "rectTrim" + t.displayInCanvas) {
              return o.set({
                /*top: t.Trimrect[t.displayInCanvas].top,
                  left: t.Trimrect[t.displayInCanvas].left,*/
                strokeWidth: t.dynamicBorderValue[t.displayInCanvas],
                width:
                  t.TrimBoxWidth[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                height:
                  t.TrimBoxHeight[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
              });
            }
            if (o.get("id") === "rectCrop" + t.displayInCanvas) {
              return o.set({
                strokeWidth: t.dynamicBorderValue[t.displayInCanvas],
                width:
                  t.CropBoxWidth[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                height:
                  t.CropBoxHeight[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
              });
            }
            if (o.get("id") === "rectArt" + t.displayInCanvas) {
              return o.set({
                strokeWidth: t.dynamicBorderValue[t.displayInCanvas],
                width:
                  t.ArtBoxWidth[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                height:
                  t.ArtBoxHeight[t.displayInCanvas] -
                  t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
              });
            }
            if (
              o.get("id") === "rectDestination" + t.displayInCanvas &&
              t.dynamicBorderValue[t.displayInCanvas] <= 1.59
            ) {
              var checkMinbox = [];

              // minBox
              //  let test = t.minBox[t.displayInCanvas];
              checkMinbox = t[t.minBox[t.displayInCanvas]][t.displayInCanvas];
              //console.log(`checkMinbox`, checkMinbox);
              let top_v = checkMinbox.top + t.dynamicBorderValue[t.displayInCanvas];

              let left_v = checkMinbox.left + t.dynamicBorderValue[t.displayInCanvas];

              return o.set({
                left: left_v - 0.1,
                top: top_v,
                width: checkMinbox.width - 0.6 - t.dynamicBorderValue[t.displayInCanvas],
                height: checkMinbox.height - 1 - t.dynamicBorderValue[t.displayInCanvas],
                objectCaching: false,
                globalCompositeOperation: "destination-out",
              });
            }
          }
        });
      }
      this.canvas_check[this.displayInCanvas].renderAll();
    },
    bleedShow() {
      if (this.BleedVisiable[this.displayInCanvas] == true) {
        this.Bleedrect[this.displayInCanvas].set({
          visible: false,
        });
        this.BleedVisiable[this.displayInCanvas] = false;
      } else {
        this.Bleedrect[this.displayInCanvas].set({
          visible: true,
        });
        this.BleedVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },
    mediaShow() {
      if (this.MediaVisiable[this.displayInCanvas] == true) {
        this.Mediarect[this.displayInCanvas].set({
          visible: false,
        });
        this.MediaVisiable[this.displayInCanvas] = false;
      } else {
        this.Mediarect[this.displayInCanvas].set({
          visible: true,
        });
        this.MediaVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },
    trimShow() {
      if (this.TrimVisiable[this.displayInCanvas] == true) {
        this.Trimrect[this.displayInCanvas].set({
          visible: false,
        });
        this.TrimVisiable[this.displayInCanvas] = false;
      } else {
        this.Trimrect[this.displayInCanvas].set({
          visible: true,
        });
        this.TrimVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },
    cropShow() {
      if (this.CropVisiable[this.displayInCanvas] == true) {
        this.Croprect[this.displayInCanvas].set({
          visible: false,
        });
        this.CropVisiable[this.displayInCanvas] = false;
      } else {
        this.Croprect[this.displayInCanvas].set({
          visible: true,
        });
        this.CropVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },
    artShow() {
      if (this.ArtVisiable[this.displayInCanvas] == true) {
        this.Artrect[this.displayInCanvas].set({
          visible: false,
        });
        this.ArtVisiable[this.displayInCanvas] = false;
      } else {
        this.Artrect[this.displayInCanvas].set({
          visible: true,
        });
        this.ArtVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },
    bleedAreaShow() {
      if (this.bleedAreaVisiable[this.displayInCanvas] == true) {
        this.overlayRect[this.displayInCanvas].set({
          visible: false,
        });
        this.destinationRect[this.displayInCanvas].set({
          visible: false,
        });
        this.bleedAreaVisiable[this.displayInCanvas] = false;
      } else {
        this.overlayRect[this.displayInCanvas].set({
          visible: true,
        });
        this.destinationRect[this.displayInCanvas].set({
          visible: true,
        });
        this.bleedAreaVisiable[this.displayInCanvas] = true;
      }

      this.canvas_check[this.displayInCanvas].renderAll();
    },

    async resetCanvas() {
      this.panning[this.displayInCanvas] = false;
      this.zoomFlag[this.displayInCanvas] = null;
      this.dynamicZoom[this.displayInCanvas] = 100;
      this.dynamicBorderValue[this.displayInCanvas] = 1;

      const showZoom = document.getElementById("showZoom");
      showZoom.value = `${Math.round(this.dynamicZoom[this.displayInCanvas])}%`;
      this.canvas_check[this.displayInCanvas].dispose();
      this.images[this.displayInCanvas - 1].canvasGenerated = false;
      await this.ShowCanvas(this.displayInCanvas);
    },
    updatePannning(panningvalue = "") {
      let setPan;
      if (panningvalue != "") {
        this.panning[this.displayInCanvas] = false;
        setPan = true;
      } else {
        this.zoomFlag[this.displayInCanvas] = null;
        if (this.panning[this.displayInCanvas] == true) {
          setPan = true;
          this.panning[this.displayInCanvas] = false;
        } else {
          setPan = false;
          this.panning[this.displayInCanvas] = true;
        }
      }
      this.group[this.displayInCanvas].set({
        lockMovementX: setPan,
        lockMovementY: setPan,
      });
      this.canvas_check[this.displayInCanvas].renderAll();
    },
    async nextCanvas() {
      this.displayInCanvas = this.displayInCanvas + 1;
      const showZoom = document.getElementById("showZoom");
      showZoom.value = `${Math.round(this.dynamicZoom[this.displayInCanvas])}%`;
      await this.ShowCanvas(this.displayInCanvas, "next");
    },
    async prevCanvas() {
      this.displayInCanvas = this.displayInCanvas - 1;
      const showZoom = document.getElementById("showZoom");
      showZoom.value = `${Math.round(this.dynamicZoom[this.displayInCanvas])}%`;
      await this.ShowCanvas(this.displayInCanvas, "next");
    },
    goBack() {
      this.updateCanvasFlag();
      var exampleTriggerEl = document.getElementById("goBack");
      var tooltip = this.$store.state.bootstrap.Tooltip.getInstance(exampleTriggerEl);
      tooltip.hide();
      //back from miniature view
      if (this.backLocation == "miniature") {
        if (this.multiPart == false) {
          this.images[this.displayInCanvas - 1].canvasGenerated = false;
          this.showbothside();
        } else {
          this.showMultiPart();
        }
      } else if (this.requiredPage == this.backLocation) {
        this.clickedItem = 3;
        if (this.multiPart == true) {
          let currentIndex = this.isdisplay;
          currentIndex = currentIndex - 2;
          if (currentIndex % 2 == 0) {
            this.multiPartArray = [currentIndex, currentIndex + 1];
            this.isdisplay = currentIndex - 1;
          } else {
            this.multiPartArray = [currentIndex - 1, currentIndex];
            this.isdisplay = currentIndex - 1;
          }
          this.isdisplay = this.isdisplay + 1;
        } else {
          this.isdisplay = this.isdisplay - 1;
        }

        this.nextimage();
      } else {
        this.clickedItem = 3;
        if (this.multiPart == true) {
          let currentIndex = this.isdisplay;
          currentIndex = currentIndex + 1;
          if (currentIndex % 2 == 0) {
            this.multiPartArray = [currentIndex, currentIndex + 1];
          } else {
            this.multiPartArray = [currentIndex - 1, currentIndex];
          }
          this.isdisplay = this.isdisplay + 1;
          this.previmage();
        } else {
          this.isdisplay = this.isdisplay + 1;
          this.previmage();
        }
      }
    },
  },
};
</script>
