import i18n from "@/i18n";
export default {
    computed: {
        milestoneList() {
            let mileStoneArray = [
              {
                value: i18n.global.t("milestone-list.Created"),
                key: "created",
              },
              {
                value: i18n.global.t("milestone-list.Partly-Uploaded"),
                key: "partlyUploaded",
              },
              {
                value: i18n.global.t("milestone-list.Uploaded"),
                key: "uploaded",
              },
              {
                value: i18n.global.t("milestone-list.Partly-Assigned"),
                key: "partlyAssigned",
              },
              {
                value: i18n.global.t("milestone-list.Assigned"),
                key: "assigned",
              },
              {
                value: i18n.global.t("milestone-list.Partly-Released"),
                key: "partlyReleased",
              },
              {
                value: i18n.global.t("milestone-list.Released"),
                key: "released",
              },
              {
                value: i18n.global.t("milestone-list.Finished"),
                key: "finished",
              },
              {
                value: i18n.global.t("milestone-list.Archived"),
                key: "archived",
              },
              {
                value: i18n.global.t("milestone-list.Deleted"),
                key: "deleted",
              },
            ];
            return mileStoneArray;
          },
    },
};