import { jwtDecrypt, tokenAlive,ZaikiotokenAlive } from "../shared/jwtHelper";
import axios from "axios";


//login type
//0 :Normal Login
//1 :pURL login
//2: Zaikio login

const state = () => ({
  authData: {
    token: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
    userType:"",
    session:"",
    loginType:"",
    userSettings:""
    
  },
  zaikioAuthData:{
    token: "",
    refreshToken: "",
    tokenExp: "",
    idpToken:""
  },
  uploadProcess:[],
  loginStatus: "",
  loginFailedError:"",
  radix:0,
  dimension:"",
  allorderInfo:[],
  isOrderPocess:false
});

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getAuthData(state) {
    return state.authData;
  },
  getZaikioAuthData(state) {
    return state.zaikioAuthData;
  },
  isTokenActive(state) {
    
    if (!state.authData.tokenExp) {
      localStorage.setItem("token_active",false);
      return false;
    }
    
    localStorage.setItem("token_active",true);
    /*const originalDate = new Date(state.authData.tokenExp * 1000);

    // Subtract 3 minutes (3 * 60 * 1000 milliseconds)
    const newDate = new Date(originalDate.getTime() - 4 * 60 * 1000);

    // Convert the new date back to a timestamp (divide by 1000 to get seconds)
    const newTimestamp = Math.floor(newDate.getTime() / 1000);
    return tokenAlive(newTimestamp);*/
    return tokenAlive(state.authData.tokenExp);
  },
  isZaikioTokenActive(state) {
    if (!state.zaikioAuthData.tokenExp && localStorage.getItem("loginType")!= 2) {
      localStorage.setItem("zaikio_token_active",false);
      return false;
    }
    localStorage.setItem("zaikio_token_active",true);
    let jwtDecodedValue = jwtDecrypt(localStorage.getItem("zaikio_access_token"));
    return ZaikiotokenAlive(jwtDecodedValue.exp);
  },
  getRadixData(state) {
    return state.radix;
  },
  getDimensionFormat(state) {
    return state.dimension;
  },
  getUploadProcess(state) {
    return state.uploadProcess;
  },
  getallOrderInfo(state) {
    return state.allorderInfo;
  },
  getOrderStatus(state){
    return state.isOrderPocess;
  },
  getloginFailedError(state){
    return state.loginFailedError;
  }

  
};

const actions = {
  async signin({ commit }, payload) {
    commit("setLoginFailedMessage", '');
    const response = await axios
      .post("api/auth/login", payload)
      .catch((err) => {
        if(err.response.status!=401){
          commit("setLoginFailedMessage", err.message);
        }
        
      });
    if (response && response.data) {
      response.data.userType="User";
      response.data.loginType=0; 
      localStorage.setItem("loginType", 0);
      commit("saveTokenData", response.data);
      commit("setLoginStatu", "success");
    } else {
      commit("setLoginStatu", "failed");
    }
  },
  async ZaikiosignIn() {
    const response = await axios
      .post("api/auth/login?called_from=zaikio")
      .catch((err) => {
        console.log(err);
      });
    if (response && response.data) {
      return response;
    } else {
      //commit("setLoginStatu", "failed");
    }
  },
 async ZaikioCallback({ commit },payload){
  const queryParam = new URLSearchParams(payload).toString();
  await axios.get(`${process.env.VUE_APP_API_URL}api/auth/callback?${queryParam}`)
  .then(response => {         
    let kc_token=response.data.kc_token;
    let idp_token=response.data.idp_token;
    if ("error" in kc_token) {
      //invalid Token
    }
    else{
      kc_token['userType']="User"; 
      kc_token['loginType']=2;
      localStorage.setItem("loginType", 2);
      commit("saveTokenData", kc_token);
      commit("SaveZaikioTokenData",idp_token);
      commit("setLoginStatu", "success");
    }
  })
  .catch(e => {        
       console.log(e);
  })
 
 },
  async guestSignin({ commit },payload) {
    let accessToken=payload['clientAccount'];
    let response=[];  
    response.userType="Guest";
    response.loginType=1;
    response.session=payload.session;
    response.access_token=accessToken;
    localStorage.setItem("loginType", 1);
    commit("saveTokenData", response);
    commit("setLoginStatu", "success");
  },
  

  async signout({commit}){
    if(localStorage.getItem('loginType') == 2){
     let payload={};
     payload['idp_access_token']=localStorage.getItem("zaikio_access_token");
     payload['idTokenHint']=localStorage.getItem("zaikio_idp_token");
     let frontendURL=process.env.VUE_APP_FRONTEND_URL;
     let getCurrentPort = process.env.VUE_APP_FRONTEND_PORT;
     let appendURL='';
     if (getCurrentPort != "") {
      if (getCurrentPort != undefined && getCurrentPort > 0) {
        appendURL = ":" + getCurrentPort;
      }
    }
    frontendURL = frontendURL + appendURL; 
    payload['redirectUrl']=frontendURL+"/login";
    const authData = localStorage.getItem('access_token');
    const config = {
       headers: { Authorization: `Bearer ${authData}` },
     };
    await axios.post("/api/auth/logout",payload,config).then((result) => {                                                             
      commit("RemoveZaikioTokenData");
      commit("RemoveTokenData");
      commit("setLoginStatu", "failed");
      window.location = result.data;
    }); 

    
    }
    commit("RemoveTokenData");
    commit("setLoginStatu", "failed");
     
  },
  StoreRadix1({commit},value){
    commit("StoreRadix",value);
  },
  saveUploadProcessData({commit},value){
    let newob={}
    newob=value;
    commit("StoreUploadProcessData",newob);
  },
  RemoveUploadProcessData({commit},value){ 
    commit("DeleteUploadProcessData",value);   
  },
  AddallOrderInfo({commit},value){
    let newob={};
    newob=value;
    commit("StoreAddallorder",newob);
  },
  UpdateResourceId({commit},value){
    if(parseInt(value) > 0){
      commit("updateResourceStatus",value);
    }
  },
  DeleteResourceId({commit},value){
    if(parseInt(value) > 0){
      commit("DeleteResourceId",value);
    }
  },
  RemoveOrderusingUniqueId({commit},value){
      commit("DeleteOrderUniqueId",value);
  },
  orderInProgress({state},orderId){
    let obj={};
    obj['status']=false;
    const filter = {
      orderId: orderId,
     // processStatus:'in progress',
      quickcheck:null,
    };
    let tempArray=state.allorderInfo;
    let users = tempArray.filter((item) => {
      for (let key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) return false;
      }
      return true;
    });
    
    
    if(users.length > 0){
      state.isOrderPocess=true;
      obj['status']=true;
    }
    else{
      state.isOrderPocess=false;
      obj['status']=false;
    }

    return obj;
  },
  imageGenerate({state},orderId){
    //Check the image is geerated or not using order id
    let obj={};
    obj['status']=false;
    const filter = {
      orderId: orderId,
      saveasimage:null
    };
    let tempArray=state.allorderInfo;
    let users = tempArray.filter((item) => {
      for (let key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) return false;
      }
      return true;
    });
    
    if(users.length > 0){
      obj['status']=true;
    }
    else{
      obj['status']=false;
    }
    return obj;
  },
  getUploadFileName({state},resourceId){
    //based on resourceId get the file names
    let findInOrder=state.allorderInfo;
    const index = findInOrder.findIndex((object) => {
      return object.resourceId === resourceId;
    });
    let getfileName=[];
    getfileName['uploadedFileName']=findInOrder[index].fileName;
    getfileName['quickcheck']=findInOrder[index].quickcheck;
    getfileName['errorMessage']=findInOrder[index].errorMessage;
    return getfileName;
  }


};

const mutations = {
  saveTokenData(state, data) {
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("user_type", data.userType);
    let getLoginType=localStorage.getItem('loginType');
    if(data.access_token == null || data.access_token == 'null'){
      return '/login';
    }
    const jwtDecodedValue = jwtDecrypt(data.access_token);
    const newTokenData = {
      token: data.access_token,
      refreshToken: data.refresh_token,
      tokenExp: jwtDecodedValue.exp,
      userId: jwtDecodedValue.sub,
      userName: jwtDecodedValue.preferred_username,
      userType:data.userType,
      loginType:getLoginType,
      userSetting:data.userSettings,
    };
    
    if(data.userType == 'Guest'){
      localStorage.setItem("session", data.session);
      newTokenData['session']=data.session;
     
    }
    state.authData = newTokenData;
  },
  SaveZaikioTokenData(state, data) {
    //console.log(`SaveZaikioTokenData`)
    localStorage.setItem("zaikio_access_token", data.access_token);
    localStorage.setItem("zaikio_refresh_token", data.refresh_token);
    localStorage.setItem("zaikio_idp_token", data.id_token);
    const jwtDecodedValue = jwtDecrypt(data.access_token);
    const ZaikionewTokenData = {
      token: data.access_token,
      refreshToken: data.refresh_token,
      idpToken:data.id_token,
      tokenExp: jwtDecodedValue.exp,
    };

    state.zaikioAuthData = ZaikionewTokenData;
  },
  setLoginStatu(state, value) {
    state.loginStatus = value;
  },
  setLoginFailedMessage(state, value) {
    state.loginFailedError = value;
  },
  RemoveTokenData(state) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("session");
    localStorage.removeItem("user_type");
    localStorage.removeItem("loginType");
    const RemoveToken = {
      token: "",
      refreshToken: "",
      tokenExp: "",
      userId: "",
      userName: "",
      userType:"",
      loginType:"",
      userSettings:""
    };
    state.authData = RemoveToken;
  },

  RemoveZaikioTokenData(state) {
    localStorage.removeItem("zaikio_refresh_token");
    localStorage.removeItem("zaikio_access_token");
    
    const RemoveZaikioToken = {
      token:'',
      refreshToken: '',
      tokenExp: '',
    };

    state.zaikioAuthData = RemoveZaikioToken;
  },
  StoreRadix(state,value){
    state.radix=value;
  },
  StoreDimensionFormat(state,value){
    state.dimension=value;
    localStorage.setItem("format", value);
  },
  StoreUploadProcessData(state,value){
    var index = state.uploadProcess.map(function (t) { return t.orderId; }).indexOf(parseInt(value['orderId']));
    if(index >= 0){
      state.uploadProcess[index]['status']=value['status'];
      state.uploadProcess[index]['resourceAvailable']=value['resourceAvailable'];
      state.uploadProcess[index]['validFile']=value['validFile'];
      state.uploadProcess[index]['fileuploadFlag']=value['fileuploadFlag'];
    }
    else{
      state.uploadProcess.push({
        orderId:parseInt(value['orderId']),
        status:value['status'],
        resourceAvailable:value['resourceAvailable'],
        validFile:value['validFile'],
        fileuploadFlag:value['fileuploadFlag'],
      })
    }
   
  },
  StoreAddallorder(state,value){
    var index = state.allorderInfo.map(function (t) { return t.resourceId; }).indexOf(value['resourceId']);
    if(parseInt(index) >= 0){
      if(value['mergePDF']){
      state.allorderInfo[index]['mergePDF']=value['mergePDF'];
      }
      if(value['quickcheck']){
      state.allorderInfo[index]['quickcheck']=value['quickcheck'];
      }
      if(value['quickcheck']=='error'){
        state.allorderInfo[index]['errorMessage']=value['errorMessage'];
      }
      if(value['saveasimage']){
        state.allorderInfo[index]['saveasimage']=value['saveasimage'];
      }
      if(value['resourceId']){
        state.allorderInfo[index]['resourceId']=value['resourceId'];
      }
      if(value['uniqueId']){
        state.allorderInfo[index]['uniqueId']=value['uniqueId'];
      }
      
    // console.log(`state.allorderInfo`,state.allorderInfo)
    }
    else{
      state.allorderInfo.push({
        fileRadixResourceId:0,
        mergePDF:value['mergePDF'],
        quickcheck:value['quickcheck'],
        orderId:parseInt(value['orderId']),
        uniqueId:value['uniqueId'],
        //uploadedFilesName:value['uploadedFilesName'],
        errorMessage:null,
        saveasimage:value['saveasimage'],
        resourceId:parseInt(value['resourceId']),
        fileName:value['fileName'],
        processStatus:'in process'

      })
      //console.log(`state.allorderInfo`,state.allorderInfo)
    }
    //console.log(`state.allorderInfo`,state.allorderInfo)
  },
  updateResourceStatus(state,id){
    var deleteIndex = state.allorderInfo.map(function (t) { return t.resourceId; }).indexOf(parseInt(id));

    if(deleteIndex >= 0){
      //delete state.allorderInfo[deleteIndex];
      state.allorderInfo[deleteIndex]['processStatus']='error';
   }
  },
  DeleteResourceId(state,id){
    var deleteIndex = state.allorderInfo.map(function (t) { return t.resourceId; }).indexOf(parseInt(id));

    if(deleteIndex >= 0){
      delete state.allorderInfo[deleteIndex];
   }
  },
  DeleteUploadProcessData(state,id){
    var deleteIndex = state.uploadProcess.map(function (t) { return t.orderId; }).indexOf(parseInt(id));
   if(deleteIndex >= 0){
    delete state.uploadProcess[deleteIndex];
   }
  },
  DeleteOrderUniqueId(state,uniqueId){
    state.allorderInfo = state.allorderInfo.filter(order => order.uniqueId !== uniqueId);
  },  
  CheckingOrderStatus(state,orderId){
    const filter = {
      orderId: orderId,
      saveAsImage:null
    };
    let tempArray=state.allorderInfo;
    let users = tempArray.filter((item) => {
      for (let key in filter) {
        if (item[key] === undefined || item[key] != filter[key]) return false;
      }
      return true;
    });
    if(users.length > 0){
      state.isOrderPocess=true;
    }
    else{
      state.isOrderPocess=false;
    }

  }
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};