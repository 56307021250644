<template>
  <nav
    id="navbarBlur"
    class="shadow-none navbar navbar-main navbar-expand-lg bg-dark pb-0 position-fixed w-100"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-0 py-1 container custom-container position-relative">
      <router-link :to="{ name: 'OrderListing' }" class="site-logo">
        <span id="logoofsite" class="d-flex align-items-center logoofsite"
          ><i class="fas fa-arrow-up"></i>
          <h4>Upload Portal</h4></span
        >

        <breadcrumbs :current-page="currentRouteName" :color="color" />
      </router-link>
      <notifications></notifications>
      <div id="navbar" class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav justify-content-end">
          <li class="align-items-center"><LocaleSwitcher /></li>
        </ul>
        <ul
          class="navbar-nav justify-content-end"
          :class="{ 'd-none': userType == 'Guest' }"
        >
          <li class="ps-30 nav-item dropdown d-flex align-items-center user-menu-profile">
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link lh-1"
              :class="[color, showMenu ? 'show' : '']"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <!-- <img src="@/assets/img/marie.jpg" alt="" class="rounded-circle" /> -->
              <span
                class="d-flex justify-content-center align-items-center rounded-circle bg-primary"
                >{{ gettersAuthData.userName.charAt(0).toUpperCase() }}</span
              >
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
              data-bs-popper="none"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  {{ $t("navbar.my-profile") }}
                </a>
              </li>
              <li>
                <a
                  id="logoofsite1"
                  class="dropdown-item border-radius-md logoofsite"
                  href="javascript:;"
                  @click="logout()"
                >
                  {{ $t("navbar.logout") }} ( {{ gettersAuthData.userName }})
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import LocaleSwitcher from "../../views/LocaleSwitcher.vue";
import Breadcrumbs from "../Breadcrumbs.vue";
import commonOrderlogout from "../../Mixins/order";
import Notifications from "../../views/TheNotificaitons.vue";

export default {
  name: "Navbar",
  components: { LocaleSwitcher, Breadcrumbs, Notifications },
  props: {
    minNav: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: "text-body",
    },
  },

  data() {
    return {
      showMenu: false,
      username: localStorage.getItem("username"),
      userType: null,
    };
  },

  computed: {
    ...mapState(["isRTL", "isAbsolute"]),

    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    ...mapGetters("notifications", {
      notifications: "getListstate",
    }),
  },
  mounted() {},
  created() {
    this.minNav;
    this.userType = localStorage.getItem("user_type");
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),

    ...mapActions({
      SignoutAction: "auth/signout",
    }),
    toggleSidebar() {
      this.navbarMinimize();
    },
    async logout() {
      let currentPath = this.$route.path;
      if (
        currentPath.includes("releaseprintdata") ||
        currentPath.includes("ReleasePrintdata")
      ) {
        let getOrderId = this.$route.params.OrderId;
        if (getOrderId) {
          await commonOrderlogout.methods.unlockOrder(getOrderId);
        }
      }
      let checkloginType = localStorage.getItem("loginType");
      await this.SignoutAction().then(() => {
        if (checkloginType != 2) {
          this.$router.push("/login");
        }
      });
    },
  },
};
</script>
