import { createI18n } from 'vue-i18n'

function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
        
        //VeeValidate.Validator.setLocale(locales);
       // messages[locale].validation = getVeeValidateLocale(locale).messages
       //messages[locale].validation = getVeeValidateLocale(locale).messages

      }
    })
    return messages
  }
  
  

  export default createI18n({
    legacy: false,
    globalInjection: true,
    warn: false, // disable warning HTML in message
    warnHtmlMessage:false,
    missingWarn: false, fallbackWarn: false,
    /*silentTranslationWarn: true,
    nonExplicitWhitelist: false,*/
    locale: localStorage.getItem("applang")  || process.env.VUE_APP_I18N_LOCALE || "at",
    fallbackLocale:localStorage.getItem("applang")  ||  process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(),
  });

  
