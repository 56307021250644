import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueSweetalert2 from "vue-sweetalert2";
import MaterialDashboard from "./material-dashboard";



import i18n from "@/i18n"

//jwt token
//require('@/store/subscriber');
/*store.dispatch('auth/attempt',localStorage.getItem('token'));*/

//Custom css
import "./assets/css/all.min.css";
import "./assets/scss/custom.scss";



import "./assets/js/custom.js";


//import Jquery from "jquery";


import Axios from 'axios';
//Axios.defaults.withCredentials = true
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;

import VueGoodTable from "vue-good-table-next";

//Loader
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

//encryption-decryption
import VueCryptojs from 'vue-cryptojs'


import VueSecureHTML from 'vue-html-secure';


import autoRefreshToken from './Mixins/autoRefreshToken'
import { io } from "socket.io-client";


import mitt from 'mitt'
const eventBus = mitt()

const appInstance = createApp(App);
appInstance.config.globalProperties.$noImagePreview = require("@/assets/img/upload/dummy-image.jpg");
appInstance.config.globalProperties.$imageProcessing = require("@/assets/img/upload/image-processing.png");
appInstance.config.globalProperties.$loaderimageProcessing = require("@/assets/img/upload/loader.gif");

appInstance.config.globalProperties.$pointTopx = 1.33;
//console.log(`xx`,appInstance.config.globalProperties.$pointTopx);
appInstance.use(store);
appInstance.use(router);
//appInstance.use(VueEasySession);
appInstance.use(VueSweetalert2);
appInstance.use(MaterialDashboard);
appInstance.use(i18n);
appInstance.use(VueGoodTable);

//appInstance.use(BootstrapVueNext);

appInstance.use(VueCryptojs);
appInstance.use(Loading, {
  color: '#6731E2',
  lockScroll: true,
});

appInstance.config.globalProperties.$socket =io(`${process.env.VUE_APP_SOCKET_URL}`, {
  transports: ["websocket"],
  reconnection:true,
});

appInstance.use(io);
//appInstance.use(ClientTable);
//appInstance.component("EasyDataTable", Vue3EasyDataTable);




if(appInstance.config.globalProperties.$socket.connected==false){
  appInstance.config.globalProperties.$socket.on("connect", async () => {
  });
}



appInstance.provide('eventBus', eventBus);



appInstance.config.globalProperties.$safeHTML = VueSecureHTML.safeHTML;
appInstance.config.globalProperties.$escapeHTML = VueSecureHTML.escapeHTML;
appInstance.config.globalProperties.$removeHTML = VueSecureHTML.removeHTML;
appInstance.use(VueSecureHTML);

//disable warning
appInstance.config.warnHandler = function () {
  return null
}




//Use for all componenets
appInstance.mixin(autoRefreshToken);
appInstance.mount("#app");
