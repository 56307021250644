<template>
  <vmd-snackbar
    v-if="notification.status && this.$route.name != 'Preview'"
    :description="notification.text"
    :icon="{ color: 'white' }"
    :color="notification.type ? notification.type : 'secondary'"
    :class="{
      ordersnackbar: this.$route.name == 'UploadImage' || this.$route.name == 'Preview',
      'd-none': sameOrderInProcessing == true,
    }"
    @click="triggerClose(notification)"
  />
</template>

<script>
import VmdSnackbar from "@/components/VmdSnackbar.vue";
import orderInfomration from "../Mixins/orderInfomration";

export default {
  components: {
    VmdSnackbar,
  },
  mixins: [orderInfomration],
  inject: ["eventBus"],
  //props: ["notification"],
  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: {
    "close-notification": null,
  },
  data() {
    return {
      timer: null,
      checkkOrderId: null,
    };
  },
  computed: {
    sameOrderInProcessing() {
      let sameOrder = false;
      var format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
      if (this.$route.name == "UploadImage" || this.$route.name == "Preview") {
        let decodeOrderId = this.$route.params.OrderId;
        if (format.test(decodeOrderId)) {
          decodeOrderId = this.decryptOrderId(this.$route.query);
        }

        if (decodeOrderId == this.notification.orderId) {
          sameOrder = true;
        }
      }
      return sameOrder;
    },
  },
  mounted() {
    //console.log(`testst`, this.notification);
    let timeout = "timeout" in this.notification ? this.notification.timeout : true;

    if (timeout) {
      let delay = this.notification.delay || 3000;
      // console.log("yes in in");
      this.timer = setTimeout(
        function () {
          this.triggerClose(this.notification);
        }.bind(this),
        delay
      );
    }
  },
  methods: {
    triggerClose: function (notification) {
      clearTimeout(this.timer);
      this.$emit("close-notification", notification);
    },
  },
};
</script>
