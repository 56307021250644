<template>
  <div class="bg-white">
    <main class="mt-0 main-content auth-pages">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div>
                <router-link
                  :to="{ name: 'Signin Illustration' }"
                  class="auth-custom-logo"
                >
                  <i class="fas fa-arrow-up" aria-hidden="true"></i>
                  <h4 class="text-h4">Upload Portal</h4>
                </router-link>
              </div>
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto px-0 position-absolute top-0 start-0 text-center justify-content-center flex-column auth-image-section"
              >
                <div
                  class="position-relative h-100 px-7 d-flex flex-column justify-content-center bg-cover"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/illustrations/signup.png') + ')',
                  }"
                ></div>
              </div>
              <div
                class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto custom-auth-form"
              >
                <div class="card card-plain">
                  <div class="card-header bg-transparent">
                    <img
                      :src="require('@/assets/img/loader.svg')"
                      style="max-width: 100px; margin: 0 auto 10px; display: block"
                      :class="{
                        'd-none': tokenValid == false,
                      }"
                    />

                    <img
                      :src="require('@/assets/img/cross-svgrepo-com.svg')"
                      style="
                        max-width: 90px;
                        margin: 0px auto 20px;
                        display: block;
                        padding: 20px;
                        border: 4px solid #fff;
                        border-radius: 50%;
                      "
                      :class="{
                        'd-none': tokenValid == true,
                      }"
                    />
                    <h4
                      class="font-weight-bolder text-center"
                      style="font-size: 25px !important"
                    >
                      {{ displayMessage }}
                    </h4>
                    <!-- <p class="mb-0">Enter your email and password to sign in</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import Axios from "axios";
import Swal from "sweetalert2";
import axiosInterceptor from "@/shared/axiosInterceptor";
import updateDimension from "../../../Mixins/updateDimension";

export default {
  name: "GuestLogin",
  components: {},
  mixins: [updateDimension],
  //inject: ["eventBus"],
  data() {
    return {
      orderId: 0,
      displayMessage: "Please wait while we proccess",
      tokenValid: true,
      locales: [
        { id: 1, code: "at", value: "German", text: "German", dbCode: "de" },
        { id: 2, code: "en", value: "English", text: "English", dbCode: "en-us" },
      ],
      format: [
        { id: 1, code: "point", text: "POINT", value: "POINT", dbCode: "pt" },
        { id: 2, code: "mm", text: "MM", value: "MM", dbCode: "mm" },
        { id: 3, code: "inch", text: "INCH", value: "INCH", dbCode: "in" },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", {
      getterLoginStatus: "getLoginStatus",
    }),
    apiError() {
      return i18n.global.t("login.login-failed-wrong-username-or-password");
    },
  },
  mounted() {
    let getDimensionFormat = this.$store.getters["auth/getDimensionFormat"];
    if (getDimensionFormat == "") {
      this.$store.commit("auth/StoreDimensionFormat", "POINT");
    }
    this.verifySession();
  },

  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    //use for axios call
    ...mapActions({ guestSignin: "auth/guestSignin", SignoutAction: "auth/signout" }),
    async AutoLogin(sessionId, clientAccount) {
      const payload = {
        session: sessionId,
        clientAccount: clientAccount,
      };

      await this.guestSignin(payload);
    },
    async getOrderDetail() {
      const result = await axiosInterceptor.get(
        `api/v1/orders/${this.orderId}?join=products&join=products.parts&join=products.parts.pageranges&join=products.parts.pageranges.variants&join=resources&join=resources.resourceData&join=resources.previews`
      );
      let encodedOrder = this.OrderIdforURL(this.orderId);
      if (result.data.resources.length > 0) {
        this.$router.replace(`/releaseprintdata/${encodedOrder}`);
      } else {
        this.$router.replace(`/Uploader/${encodedOrder}`);
      }
    },
    async verifySession() {
      //Auto Signout when share page open
      await this.SignoutAction();
      //  await this.guestSignin();
      let sessionId = this.$route.query.session;
      let clientAccount;

      const self = this;
      await Axios.post(`api/v1/orders/verifySession?session=${sessionId}`)
        .then(async (response) => {
          if (response.data.status == "OK") {
            this.orderId = response.data.data.orderId;
            clientAccount = response.data.data.clientAccount;
            console.log(`response.data`, response.data);
            let unit = response.data.data.unit;
            let lang = response.data.data.language;

            // Format select
            let index = this.format.findIndex((x) => x.dbCode === unit);
            if (index >= 0) {
              let getDimensionFormat = this.format[index].value;
              this.$store.commit("auth/StoreDimensionFormat", getDimensionFormat);
            }

            //Language select
            let locales_index = this.locales.findIndex((x) => x.dbCode === lang);
            if (locales_index >= 0) {
              this.$t.setLocale = this.locales[locales_index].code;
              localStorage.setItem("applang", this.locales[locales_index].code);
              i18n.global.locale.value = localStorage.getItem("applang");
            }

            await this.AutoLogin(sessionId, clientAccount);
            if (this.getterLoginStatus == "success") {
              this.getOrderDetail();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Some Error Occur",
            });
          }
        })
        .catch(function (error) {
          console.log(`catch`, error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Token has been expired",
          });
          self.displayMessage = "Token has been expired";
          self.tokenValid = false;
        });
    },
  },
};
</script>
