<template>
  <div
    v-for="index in this.$parent.requiredPage"
    :id="'front' + index"
    :key="index"
    class="card"
    :class="{
      drag: this.$parent.isDragging,
      display_image:
        this.$parent.isdisplay == index &&
        !this.$parent.showMiniature &&
        !this.$parent.canvasComponents &&
        !this.$parent.multiPart,
    }"
    @dragover.prevent
    @drop="this.$parent.dragFinish(-1, $event)"
  >
    <div
      v-if="
        (this.$parent.dragging > -1 || this.$parent.isdisplay == index) &&
        !this.$parent.showMiniature &&
        !this.$parent.canvasComponents
      "
      :id="`variant_` + this.$parent.isdisplay"
      :data-variant="this.$parent.bodyVariant"
      class="imagePreviewWrapper draggable"
      :class="{
        image_drop: this.$parent.isDrop,
      }"
      :style="[
        this.$parent.thumbnailArray[this.$parent.isdisplay] &&
        this.$parent.thumbnailArray[this.$parent.isdisplay]['dummyImage'] == true
          ? `background-image:url(${
              this.$parent.previewImageArray[this.$parent.isdisplay]
            }
            )`
          : `background-image:url(${
              this.$parent.externalImage +
              this.$parent.previewImageArray[this.$parent.isdisplay]
            }
            )`,
      ]"
    >
      <p class="card-content">
        <strong v-if="this.$parent.requiredPage > 2"
          >{{ this.$parent.isdisplay }}
        </strong>
        <span
          v-if="
            this.$parent.isdisplay == 1 &&
            (this.$parent.orderSides != 'OneSided' ||
              (this.$parent.orderSides == 'OneSided' && this.$parent.requiredPage == 1))
          "
          >{{ $t("release-print-data.image-single-title") }}</span
        >
        <span
          v-else-if="
            this.$parent.isdisplay == this.$parent.requiredPage &&
            this.$parent.orderSides != 'OneSided'
          "
          >{{ $t("release-print-data.image-back-title") }}</span
        >
        <span v-else></span>
      </p>
      <div
        v-if="
          this.$parent.previewImageArray[index] != null &&
          this.$parent.thumbnailArray[index]['dummyImage'] != true
        "
        class="drop_button dropdown dropstart"
      >
        <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false"
          ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
        ></a>
        <ul
          class="px-2 py-3 dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuButton1"
          data-bs-popper="none"
        >
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="this.$parent.ShowCanvas(index, index)"
              >PDFTool</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="this.$parent.confirmUnassignResource(index, '')"
              >{{ $t("release-print-data.unassign-link") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Singlepartview",
};
</script>
