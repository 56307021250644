<template>
  <div class="bg-white">
    <main class="mt-0 main-content auth-pages">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div>
                <router-link :to="{ name: 'Sitemap' }" class="auth-custom-logo">
                  <i class="fas fa-arrow-up" aria-hidden="true"></i>
                  <h4 class="text-h4">Upload Portal</h4>
                </router-link>
              </div>
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto px-0 position-absolute top-0 start-0 text-center justify-content-center flex-column auth-image-section"
              >
                <div
                  class="position-relative h-100 px-7 d-flex flex-column justify-content-center bg-cover"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/illustrations/signup.png') + ')',
                  }"
                ></div>
              </div>
              <div
                class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto custom-auth-form"
              >
                <LocaleSwitcher />
                <div class="card card-plain">
                  <div class="pb-0 card-header bg-transparent mb-2">
                    <h4 class="font-weight-bolder">
                      {{ $t("login.hello-again") }}
                    </h4>
                    <!-- <p class="mb-0">Enter your email and password to sign in</p> -->
                  </div>
                  <div class="card-body">
                    <Form
                      :validation-schema="schema"
                      :initial-values="initialValues"
                      autocomplete="off"
                      @submit="onSubmit"
                      @invalid-submit="onInvalidSubmit"
                    >
                      <div class="mb-3">
                        <vmd-input
                          id="username"
                          name="username"
                          type="text"
                          :label="$t('login.username')"
                        />
                      </div>
                      <div class="mb-3">
                        <vmd-input
                          id="password"
                          type="password"
                          :label="$t('login.password')"
                          name="password"
                        />
                        <p v-if="!valid_data" class="error-message text-sm text-danger">
                          {{ apiError }}
                        </p>
                      </div>
                      <div class="mb-3 form-group checkbox-box rememberBox d-none">
                        <label>
                          <vmd-checkbox
                            id="rememberme"
                            v-model="remembermeModel"
                            name="rememberme"
                            value="true"
                            :check="remembermeModel"
                          ></vmd-checkbox>
                          {{ $t("login.Remember-Me") }}
                        </label>
                      </div>

                      <div class="auth-link text-end">
                        <router-link
                          :to="{ name: 'Forget Password' }"
                          class="nav-link d-inline p-0 link-white text-decoration-underline"
                          >{{ $t("login.Forgot-password") }}?</router-link
                        >
                      </div>
                      <div class="text-center">
                        <vmd-button
                          class="submit-btn mt-4 shadow-none rounded-pill font-weight-normal"
                          color="primary"
                          full-width
                          >{{ $t("login.btn-sign-in") }}
                        </vmd-button>

                        <vmd-button
                          type="button"
                          class="submit-btn mt-4 shadow-none rounded-pill font-weight-normal btn-light"
                          full-width
                          @click="loginWithZaikio"
                        >
                          <img :src="zaikioIcon" class="me-1" />
                          {{ $t("login.Continue-with-Zaikio") }} Zaikio
                        </vmd-button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import VmdInput from "@/components/VmdInput.vue";
import VmdButton from "@/components/VmdButton.vue";
const body = document.getElementsByTagName("body")[0];
import { mapMutations, mapActions, mapGetters } from "vuex";
import LocaleSwitcher from "../../LocaleSwitcher.vue";
import i18n from "@/i18n";
import { markRaw } from "vue";

import { Form } from "vee-validate";
import * as Yup from "yup";
import VmdCheckbox from "@/components/VmdCheckbox.vue";
import zaikioIcon from "@/assets/img/zaikio.svg";
//import axiosInterceptor from "@/shared/axiosInterceptor";

export default {
  name: "SigninIllustration",
  components: {
    VmdInput,
    VmdButton,
    Form,
    LocaleSwitcher,
    VmdCheckbox,
  },
  data() {
    const valid_data = true;

    return {
      zaikioIcon: zaikioIcon,
      valid_data,
      remembermeModel: false,
      encrypt_key: process.env.VUE_APP_ENCRYPTION_KEY,
      rememberUsername: "",
      rememberPassword: "",
      rememberCheck: false,
      locales: [
        { id: 1, code: "at", text: "German", dbCode: "de" },
        { id: 2, code: "en", text: "English", dbCode: "en-us" },
      ],
      format: [
        { id: 1, code: "point", text: "POINT", value: "POINT", dbCode: "pt" },
        { id: 2, code: "mm", text: "MM", value: "MM", dbCode: "mm" },
        { id: 3, code: "inch", text: "INCH", value: "INCH", dbCode: "in" },
      ],
      checkInit: {
        username: this.rememberUsername,
        password: this.rememberPassword,
      },
    };
  },
  computed: {
    ...mapGetters("auth", {
      getterLoginStatus: "getLoginStatus",
    }),
    schema() {
      return markRaw(
        Yup.object().shape({
          username: Yup.string().required(
            i18n.global.t("login.please-enter-your-username")
          ),
          password: Yup.string().required(
            i18n.global.t("login.please-enter-your-password")
          ),
        })
      );
    },
    apiError() {
      if (this.$store.getters["auth/getloginFailedError"] != "") {
        return this.$store.getters["auth/getloginFailedError"];
      } else {
        return i18n.global.t("login.login-failed-wrong-username-or-password");
      }
    },
    initialValues() {
      var checkInit = {
        username: this.rememberUsername,
        password: this.rememberPassword,
      };
      //console.log(`checkInit`, checkInit);
      return checkInit;
    },
  },
  mounted() {
    this.autoLogout();
    let already_login = this.$store.getters["auth/getAuthData"];
    let getDimensionFormat = localStorage.getItem("format");
    if (
      getDimensionFormat == "" ||
      getDimensionFormat == null ||
      getDimensionFormat == "null"
    ) {
      this.$store.commit("auth/StoreDimensionFormat", "POINT");
    } else {
      this.$store.commit("auth/StoreDimensionFormat", getDimensionFormat);
    }
    let access_token = localStorage.getItem("access_token");

    if (access_token == undefined) {
      let getLocalremember = localStorage.getItem("remember");
      if (getLocalremember == 1) {
        this.remembermeModel = true;
        this.rememberCheck = true;
        let getLocalUserdata = localStorage.getItem("userdata");
        var bytes = this.$CryptoJS.AES.decrypt(getLocalUserdata, this.encrypt_key);
        var decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
        this.rememberUsername = decryptedData["username"];
        this.rememberPassword = decryptedData["password"];
      } else {
        localStorage.removeItem("remember");
        localStorage.removeItem("userdata");
      }
      this.$router.replace("/login");
    } else if (
      already_login.token &&
      access_token != "" &&
      already_login.userType == "User"
    ) {
      this.$router.replace("/orders/list");
    }
    var checkLoaderExist = document.getElementsByClassName("vld-container");
    if (checkLoaderExist.length > 0) {
      const ele = document.getElementsByClassName("vld-container");
      ele[0].style.visibility = "hidden";
    }
  },

  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    //use for axios call
    ...mapActions({
      signIn: "auth/signin",
      SignoutAction: "auth/signout",
      ZaikiosignIn: "auth/ZaikiosignIn",
    }),
    async autoLogout() {
      let already_login = this.$store.getters["auth/getAuthData"];
      if (already_login.userType == "Guest") {
        await this.SignoutAction();
      }
    },
    onInvalidSubmit() {
      const submitBtn = document.querySelector(".submit-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    },
    async onSubmit(values) {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      this.valid_data = true;
      await this.signIn(values);
      if (this.getterLoginStatus == "success") {
        if (document.getElementById("rememberme").checked == true) {
          // if (this.remembermeModel == true || this.remembermeModel == "true") {
          let storeLogindata = {};
          storeLogindata["username"] = values["username"];
          storeLogindata["password"] = values["password"];

          let userdata = this.$CryptoJS.AES.encrypt(
            JSON.stringify({
              username: values["username"],
              password: values["password"],
            }),
            this.encrypt_key
          ).toString();
          localStorage.setItem("remember", 1);
          localStorage.setItem("userdata", userdata);
        } else {
          localStorage.removeItem("remember");
          localStorage.removeItem("userdata");
        }
        localStorage.setItem("refreshcalltrigger", false);
        let auth = this.$store.getters["auth/getAuthData"];
        //language
        if ("language" in auth.userSetting) {
          let locales_index = this.locales.findIndex(
            (x) => x.dbCode === auth.userSetting.language
          );
          this.$t.setLocale = this.locales[locales_index].code;
          localStorage.setItem("applang", this.$t.setLocale);
          i18n.global.locale.value = localStorage.getItem("applang");
        }

        // Format
        if ("unit" in auth.userSetting) {
          let format_index = this.format.findIndex(
            (x) => x.dbCode === auth.userSetting.unit
          );
          this.$store.commit(
            "auth/StoreDimensionFormat",
            this.format[format_index].value
          );
        }

        this.$router.replace("/orders/list");
      } else {
        this.valid_data = false;
        if (this.$store.getters["auth/getloginFailedError"] != "") {
          this.apiError = this.$store.getters["auth/getloginFailedError"];
        } else {
          this.apiError = i18n.global.t("login.login-failed-wrong-username-or-password");
        }
      }
      loader.hide();
    },
    async loginWithZaikio() {
      var loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      let response = await this.ZaikiosignIn();
      console.log(response);

      if (response.status == 200) {
        console.log(`data`, response.data);
        window.location = response.data;
      } else {
        loader.hide();
      }

      //loader.hide();
    },
  },
};
</script>
