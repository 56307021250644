<template>
  <div
    class="d-flex w-100"
    :class="{
      flatWorkMiniature: this.$parent.requiredPage > 2,
    }"
  >
    <div
      v-for="index in this.$parent.requiredPage"
      :id="'frontimage' + index"
      :key="index"
      class="card d-block draggable selectable-image drag"
      data-boxtype="data-draggable"
      :draggable="EnableDrag"
      @dragover.prevent
      @dragstart="dragStartMinature(index, $event)"
      @dragover="highlightDropArea(index)"
      @drop="dragFinishMinature(-1, index, $event)"
      @mousedown="this.$parent.selectMultiple($event, index, '', 1)"
    >
      <div
        :id="`variant_` + index"
        class="imagePreviewWrapper draggable"
        draggable="false"
        :class="{
          image_drop: this.$parent.isFrontDrop,
          highlightDiv: highlightArray.includes(index),
        }"
        :data-variant="this.$parent.bodyVariant"
      >
        <img
          v-if="this.$parent.previewImageArray[index] != undefined"
          :src="
            this.$parent.thumbnailArray[index] &&
            this.$parent.thumbnailArray[index]['dummyImage'] == true
              ? this.$parent.previewImageArray[index]
              : this.$parent.externalImage + this.$parent.previewImageArray[index]
          "
        />
      </div>
      <p class="card-content">
        <strong v-if="this.$parent.requiredPage > 2">{{ index }}</strong>
        <span
          v-if="
            index == 1 &&
            (this.$parent.orderSides != 'OneSided' ||
              (this.$parent.orderSides == 'OneSided' && this.$parent.requiredPage == 1))
          "
          >{{ $t("release-print-data.image-single-title") }}</span
        >
        <span
          v-else-if="
            index == this.$parent.requiredPage && this.$parent.orderSides != 'OneSided'
          "
          >{{ $t("release-print-data.image-back-title") }}</span
        >
      </p>

      <div
        v-if="
          this.$parent.thumbnailArray[index] != undefined &&
          !this.$parent.thumbnailArray[index]['dummyImage']
        "
        class="drop_button dropdown dropstart"
      >
        <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false"
          ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
        ></a>
        <ul
          class="px-2 py-3 dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuButton1"
          data-bs-popper="none"
        >
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="this.$parent.ShowCanvas(index, 'miniature')"
              >PDFTool</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="this.$parent.confirmUnassignResource(index, '')"
              >{{ $t("release-print-data.unassign-link") }}</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="floatingBtn">
      <!-- Undo Button -->
      <vmd-button
        v-show="this.$parent.undoArray.length > 0"
        id="undoBtn"
        class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
        color="primary"
        @click="this.$parent.UndoProcess()"
        >{{ $t("multipart-minature.button-Undo") }}
      </vmd-button>

      <!-- Save Button -->
      <vmd-button
        v-show="this.$parent.showSaveBtnInMinature == true"
        id="saveDropData"
        class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
        color="primary"
        @click="saveDropData()"
        >{{ $t("multipart-minature.button-Save") }}
      </vmd-button>
    </div>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import axiosInterceptor from "@/shared/axiosInterceptor";
export default {
  name: "Frontback",
  components: {
    VmdButton,
  },
  data: function () {
    return {
      highlightArray: [],
      payload: [],
      minatureFlag: false,
    };
  },
  methods: {
    highlightDropArea(seq) {
      this.highlightArray = [];
      this.highlightArray.push(seq);
      //increase the index based on selected images
      const numImages = this.$parent.selectMultipleImage.length;
      if (numImages > 1) {
        const increaseSeqArray = Array.from(
          { length: numImages - 1 },
          (_, index) => seq + index + 1
        );
        this.highlightArray.push(...increaseSeqArray);
      }
    },
    RemovehighlightDropArea() {
      this.highlightArray = [];
    },
    dragStartMinature(index, ev) {
      let findArrayIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, index, "")
      );

      if (this.$parent.singleSelect == true) {
        //extucode code
      } else {
        this.minatureFlag = true;
        this.$parent.dragMultiple(ev, 1, index, "");
        return;
      }

      ev.dataTransfer.setData("Text", findArrayIndex);
      ev.dataTransfer.dropEffect = "move";
      this.$parent.dragging = findArrayIndex;
      this.$parent.isDrag = findArrayIndex;
    },
    async dragFinishMinature(to, dropimageindex, ev) {
      var data = ev.dataTransfer.getData("Text");
      if (
        document.getElementById(data) != null ||
        this.$parent.selectMultipleImage.length > 0
      ) {
        await this.$parent.storedUndo();
        this.$parent.showSaveBtnInMinature = true;
        localStorage.setItem(`ShowSaveBtn_${btoa(this.$parent.OrderId)}`, true);
        this.$parent.isdisplay = dropimageindex;
        if (this.$parent.selectMultipleImage.length > 0) {
          await this.dropMultipleImage("", "", dropimageindex, "");
        } else {
          await this.$parent.moveItem(this.$parent.dragging, to);
        }
      }
    },
    dropMultiple(dropimageindex) {
      console.log(dropimageindex);
    },
    async dropMultipleImage(dragging, to, dropimageindex, dropPartType) {
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      let dragImagelength = this.$parent.selectMultipleImage.length;
      const allSeq = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].sortSequence
      );

      /* const allDragPart = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].partType
      );*/

      const dragArray = this.$parent.selectMultipleImage.map((index) => {
        const image = this.$parent.images[index];
        return {
          sortSequence: image.sortSequence,
          partType: image.partType,
        };
      });

      //let temp = this.$parent.selectMultipleImage[0];

      let storeDropIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );

      let dropSeq;
      //image is not assigned
      if (storeDropIndex != -1) {
        dropSeq = this.$parent.images[storeDropIndex].sortSequence;
      } else {
        dropSeq = dropimageindex;
      }

      let dropPart = dropPartType;

      /*drop array list*/
      let dropArrayList = [];
      let dropArraySeq = dropSeq;
      let dropArrayPart = dropPart;
      //let onlyOnceCover = 0;
      for (let t = 0; t < dragImagelength; t++) {
        dropArrayList.push({
          sortSequence: dropArraySeq,
          partType: dropArrayPart,
        });
        dropArraySeq = dropArraySeq + 1;
      }

      const commonByBoth = dragArray.filter((item1) =>
        dropArrayList.some(
          (item2) =>
            item1.sortSequence === item2.sortSequence && item1.partType === item2.partType
        )
      );

      /*Next logic Start*/
      let startLoop = dropimageindex;
      let endLoop;
      let startIndex = this.$parent.selectMultipleImage[0];
      let startDragPart = this.$parent.images[startIndex].partType;
      let startSeq = this.$parent.images[startIndex].sortSequence;

      // Only change Covers

      if (startSeq > dropimageindex) {
        endLoop = startSeq;
      } else {
        endLoop = this.$parent.bodyPage;
      }

      // console.log(`startLoop`, startLoop);
      // console.log(`endLoop`, endLoop);
      let currSeq = startLoop;
      let currSeqArray = [];
      let currSeqPart = [];
      let newSeq;
      let newSeqArray = [];
      let newSeqPart = [];
      // let firstLoop = 0;
      //let updatedK;
      let currPart = startDragPart;
      let updateDragLength = dragImagelength;
      updateDragLength = updateDragLength - commonByBoth.length;
      // SET NEW PART START SEQ IS GREATER THAN COVER PAGE
      let newPart = startDragPart;
      /*let oneTime = 0;
      let LastImageCover = 0;*/
      let dropArrayIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );

      let seqMainArray = [];
      let seqPart = [];
      let findImagePartType = [];
      let findoldSeq = [];
      newSeq = startLoop + updateDragLength;

      if (dropArrayIndex != -1) {
        for (let k = startLoop; k < endLoop; k++) {
          // if current seq and drag part is same as selcted seq then skip that
          if (allSeq.includes(currSeq)) {
            // It is required when drag image from B1,c2 and drop to C3
            //console.log(`continue`, currSeq, currPart);
            currSeq = currSeq + 1;
            newSeq = newSeq + 1;
            continue;
          }

          //console.log(`newSeq`, newSeq);
          if (newSeq > this.$parent.requiredPage) {
            //console.log("break");
            break;
          }

          currSeqArray.push(currSeq);
          currSeqPart.push(currPart);
          newSeqArray.push(newSeq);
          newSeqPart.push(newPart);
          newSeq = newSeq + 1;
          currSeq += 1;
        }
        /* console.log(`currSeq`, currSeqArray);
        console.log(`currPart`, currSeqPart);

        console.log(`newSeq`, newSeqArray);
        console.log(`newPart`, newSeqPart);
        console.log(`allSeq`, allSeq, allDragPart);*/

        let p = 0;
        // create the curr seq loop and if any image came blank in beetween then break the loop otherwise assign the image for next logic
        for (let k = 0; k < newSeqArray.length; k++) {
          let oldSeq = currSeqArray[k];
          let oldPart = currSeqPart[k];

          let newSeq = newSeqArray[k];
          let newPart = newSeqPart[k];

          let getOldSortSequence = this.$parent.getOldSortSequence;

          let currentIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, oldSeq, oldPart)
          );

          let nextIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, newSeq, newPart)
          );

          //break the loop if any blank image came
          if (nextIndexImage == -1) {
            break;
          }

          if (currentIndexImage != -1) {
            seqMainArray.push(newSeq);
            seqPart.push(newPart);
            findoldSeq.push(oldSeq);
            findImagePartType.push(oldPart);
            const { thumbnail, mainImage } = this.$parent.images[currentIndexImage];
            const targetArray =
              newPart === "Cover"
                ? this.$parent.CoverImageWithThumb
                : this.$parent.thumbnailArray;
            /*console.log(`targetArray`, targetArray);
            console.log(`newSeq`, newSeq);*/
            targetArray[newSeq] = {
              thumb: thumbnail,
              main: mainImage,
              dummyImage: false,
            };

            this.$parent.previewImageArray[newSeq] = mainImage;
            // this condition required becuase it delete the newly assign value
            if (p < updateDragLength && !seqMainArray.includes(newSeq)) {
              delete this.$parent.thumbnailArray[oldSeq];
              delete this.$parent.previewImageArray[oldSeq];
              p++;
            }
          }
        }

        await this.updateMainImageArray(
          seqMainArray,
          seqPart,
          findImagePartType,
          findoldSeq
        );
      }
      /*Next logic End*/
      // console.log(`image`, this.$parent.images);
      /******* Drop image logic start****************************/
      let onlyOnce = 0;
      let pushDropArray = [];
      console.log(onlyOnce, pushDropArray);
      //let updateDropIndex = dropArrayIndex;
      for (let d = 0; d < dragImagelength; d++) {
        let getIndex = this.$parent.selectMultipleImage[d];
        let dragSeq = this.$parent.images[getIndex].sortSequence;
        let dragPart = this.$parent.images[getIndex].partType;

        if (dropPart.trim() == null || dropPart.trim() == "") {
          dropPart = dropPartType;
        }

        //Drop Logic//
        let checkSeq = dropSeq;
        let imgStyle;
        if (this.minatureFlag == true) {
          let id = "variant_" + allSeq[d];
          imgStyle = document.getElementById(id).querySelector("img");
        } else {
          imgStyle = document
            .getElementById(this.$parent.selectMultipleImage[d])
            .querySelector("img");
        }

        if (checkSeq > this.$parent.bodyPage) {
          await this.$parent.RemovedDataFromMultipleImage();
          break;
        }

        if (dragSeq == dropSeq && dragPart == dropPart) {
          dropSeq = dropSeq + 1;
          continue;
        }

        // OLD SEQ REMOVE
        let currentIndexImage = this.$parent.images.findIndex(
          this.$parent.getOldSortSequence(this.$parent.images, dropSeq, dropPart)
        );
        if (currentIndexImage != -1) {
          this.$parent.images[currentIndexImage]["sortSequence"] = null;
          this.$parent.images[currentIndexImage]["partType"] = "";
          this.$parent.images[currentIndexImage]["variantId"] = "";
          this.payload.push(this.$parent.images[currentIndexImage]);
          pushDropArray.push({
            sortSequence: null,
            partType: "",
          });
        }

        //NEW SEQ ASSIGN

        this.$parent.images[getIndex]["sortSequence"] = dropSeq;
        this.$parent.images[getIndex]["partType"] = dropPart;
        this.$parent.images[getIndex]["variantId"] =
          dropPart == "Cover" ? this.$parent.coverVariant : this.$parent.bodyVariant;
        this.payload.push(this.$parent.images[getIndex]);
        pushDropArray.push({
          sortSequence: dropSeq,
          partType: dropPart,
        });
        // CHECK OLD IMAGE HAS ALREAY ASSIGNE tHEN DELETE FROM REQUIRED PLACES
        if (dragSeq != null) {
          // Handle Cover-related operations

          this.$parent.thumbnailArray[dropSeq] = this.$parent.thumbnailArray[dragSeq];
          delete this.$parent.thumbnailArray[dragSeq];
          delete this.$parent.previewImageArray[dragSeq];

          const { main } = this.$parent.thumbnailArray[dropSeq]; // Destructuring
          this.$parent.previewImageArray[dropSeq] = main;
        } else {
          const { mainImage, thumbnail } = this.$parent.images[getIndex];
          const targetArray =
            dropPart === "Cover"
              ? this.$parent.CoverImageWithThumb
              : this.$parent.thumbnailArray;
          targetArray[dropSeq] = {
            main: mainImage,
            thumb: thumbnail,
            dummyImage: false,
          };

          this.$parent.previewImageArray[dropSeq] = targetArray[dropSeq]["main"];
        }

        dropSeq = dropSeq + 1;

        //Removed the border
        /*let imgStyle = document.getElementById(this.$parent.selectMultipleImage[d])
          .querySelector("img");*/
        imgStyle.removeAttribute("style");
      }

      //console.log(`image`, this.$parent.images);
      /************************* Drop image logic end*******************/

      this.highlightArray = [];
      await this.$parent.SortImageorder();
      this.$parent.selectMultipleImage = [];
      this.payload = await this.removeDuplicates(this.payload);
      this.$parent.showSaveBtnInMinature = true;
      await this.$parent.dataCheckMessage();
      await this.$parent.passDataBetweenTab();
      this.payload = [];
      loader.hide();
    },
    updateMainImageArray(seqMainArray, seqPart, oldPartList, oldSeqList) {
      console.log(`oldSeqList`, oldSeqList);
      for (let l = 0; l < seqMainArray.length; l++) {
        let p = seqMainArray[l];
        //console.log(`p`, p);
        //Found the image name
        let imageName;
        let findImagePartType = oldPartList[l];
        let assignPartType = seqPart[l];

        if (this.$parent.thumbnailArray[p]) {
          imageName = this.$parent.thumbnailArray[p]["thumb"];
        }

        // console.log(`imageName`, imageName);
        if (imageName != null) {
          //console.log(`this.$parent.images`, this.$parent.images);
          let getImageIndex = this.$parent.images.findIndex(
            this.$parent.getindexbasedonimage(
              this.$parent.images,
              imageName,
              findImagePartType
            )
          );
          //console.log(`getImageIndex`, getImageIndex, this.$parent.images[getImageIndex]);
          if (getImageIndex != -1) {
            let previousSortSequenceIndex = this.$parent.images.findIndex(
              this.$parent.getOldSortSequence(this.$parent.images, p, assignPartType)
            );
            // console.log(`previousSortSequence`, previousSortSequenceIndex);
            if (previousSortSequenceIndex != -1) {
              this.$parent.images[previousSortSequenceIndex]["sortSequence"] = null;
              this.$parent.images[previousSortSequenceIndex]["variantId"] = "";
              //this.$parent.images[previousSortSequenceIndex]["partType"] = "";
              this.payload.push(this.$parent.images[previousSortSequenceIndex]);
            }

            this.$parent.images[getImageIndex]["sortSequence"] = p;
            this.$parent.images[getImageIndex]["partType"] = assignPartType;
            this.$parent.images[getImageIndex]["variantId"] =
              assignPartType == "Cover"
                ? this.$parent.coverVariant
                : this.$parent.bodyVariant;
            //console.log(`after`, this.$parent.images[getImageIndex]["sortSequence"]);
            this.payload.push(this.$parent.images[getImageIndex]);
          }
        }
      }

      //console.log(`next pyload`, this.payload);
    },
    removeDuplicates(array) {
      const uniqueArray = array.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.singlePagesID === item.singlePagesID)
      );
      return uniqueArray;
    },
    findArrayDifference(array1, array2, property) {
      const map = new Map(array1.map((obj) => [obj[property], obj]));

      // Filter the second array to get only items not present in the first array
      const differenceArray = array2.filter((obj) => {
        const key = obj[property];
        const existingObj = map.get(key);

        return !existingObj || !(JSON.stringify(existingObj) === JSON.stringify(obj));
      });

      return differenceArray;
    },
    async saveDropData() {
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      //Hide the save btn
      this.$parent.showSaveBtnInMinature = false;
      const propertyToCompare = "singlePagesID";
      const difference = await this.findArrayDifference(
        JSON.parse(localStorage.getItem(`loadImageArray_${btoa(this.$parent.OrderId)}`)),
        this.$parent.images,
        propertyToCompare
      );

      /*console.log(
        `main`,
        JSON.parse(localStorage.getItem(`loadImageArray_${btoa(this.$parent.OrderId)}`))
      );
      console.log(`update`, this.$parent.images);
      console.log(`difference`, difference);*/
      let orderHeader = {
        orderId: this.$parent.OrderId,
      };

      const result1 = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.$parent.OrderId}&plausibilityCheck=true&jobLock=true`,
        difference,
        orderHeader
      );
      await this.$parent.CheckValidation(result1.data.plausibilityCheck, 2);

      let saveFlag = 1;
      if (localStorage.getItem(`saveFlag_${btoa(this.$parent.OrderId)}`)) {
        saveFlag =
          parseInt(localStorage.getItem(`saveFlag_${btoa(this.$parent.OrderId)}`)) + 1;
      }
      localStorage.setItem(`saveFlag_${btoa(this.$parent.OrderId)}`, saveFlag);
      localStorage.setItem(`ShowSaveBtn_${btoa(this.$parent.OrderId)}`, false);

      loader.hide();
    },
  },
};
</script>
<style scoped>
.highlightDiv {
  border: 3px solid #6533dd;
}
</style>
