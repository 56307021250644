import store from "@/store";

//import axios from "axios";

export default {
  data() {
    return {
      locales: [
        { id: 1, code: "at", value: "German", text: "German", dbCode: "de" },
        { id: 2, code: "en", value: "English", text: "English", dbCode: "en-us" },
      ],
      /*format: [
        { id: 1, code: "point", text: "POINT", value: "POINT", dbCode: "pt" },
        { id: 2, code: "mm", text: "MM", value: "MM", dbCode: "mm" },
        { id: 3, code: "inch", text: "INCH", value: "INCH", dbCode: "in" },
      ],*/
    }
    },
  methods: {
    clicked(value) {
      console.log(value);
    },
    updateDimension(id) {
      //console.log(type); 
      //type=1 order listing
      //type=2 order grid
      //type=3 Uploader
      //type=4 Preview
      let default_value_mm = process.env.VUE_APP_MM_VALUE;
      let default_value_inch = process.env.VUE_APP_INCH_VALUE;

      
        var orderFormat = id;
        var getDimensionFormat = store.getters["auth/getDimensionFormat"];
        if (getDimensionFormat == "") {
          getDimensionFormat = localStorage.getItem("format");
        }
        
        if (getDimensionFormat == "MM") {
          orderFormat = orderFormat / default_value_mm;
          orderFormat = orderFormat.toFixed(1);
        } else if (getDimensionFormat == "INCH") {
          orderFormat = orderFormat / default_value_inch;
          orderFormat = orderFormat.toFixed(3);
        } else {
          if (orderFormat != null) {
            orderFormat = orderFormat.toFixed(2);
          }
        }
        
        return orderFormat;
    },
    updateOrderPages(sides, pages) {
      if (sides == "OneSided") {
        pages = parseInt(pages / 2);
      }
      return pages;
    },
    OrderIdforURL(id) {
      let encryptedText = this.$CryptoJS.AES.encrypt(
        JSON.stringify({
          id: id,
        }),
        process.env.VUE_APP_ENCRYPTION_KEY
      ).toString();
      encryptedText = encodeURIComponent(encryptedText.toString());

      /*console.log(encryptedText);

      //Decrypt
      let temp = decodeURIComponent(encryptedText);
      var bytes = this.$CryptoJS.AES.decrypt(temp, process.env.VUE_APP_ENCRYPTION_KEY);
      var decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
      console.log(decryptedData);*/
      return encryptedText;
    },
  }
};