<template>
  <div class="input-group input-group-outline vmd-input is-filled">
    <label :for="name" class="form-label">{{ label }}</label>
    <select
      :id="id"
      :name="name"
      class="form-select w-100 rounded border border-white text-white ps-2"
      :value="inputValue"
      @change="onChange"
      @blur="onChange"
      @click="onChange"
    >
      <option value="" selected disabled class="text-dark">{{ placeholder }}</option>
      <option v-for="(item, index) in items" :key="index" :value="item" class="text-dark">
        {{ item }}
      </option>
    </select>
    <p v-show="errorMessage" class="w-100 error-message text-sm text-danger">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    showSelect: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { value: inputValue, handleChange, errorMessage } = useField(
      props.name,
      undefined,
      {
        initialValue: props.value,
      }
    );

    const onChange = (event) => {
      const value = event.target.value;
      handleChange(value);
      // ctx.emit("update:modelValue", value);
    };

    return {
      onChange,
      errorMessage,
      inputValue,
    };
  },
};
</script>

<style scoped>
.invalid-feedback-custom-label {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 100%;
  color: #dc3545;
}
</style>
