<template>
  <div
    class="card multipart multipartminiature display_image"
    :class="{
      coverVisible: this.$parent.coverPage > 0,
    }"
  >
    <!--Cover Part -->
    <div v-if="this.$parent.coverPage > 0" class="coverContent">
      <div class="titleBox">{{ $t("multipart-minature.Cover-Part") }}</div>
      <div class="innerPreviewboxes">
        <!--dynamic div-->
        <div
          v-for="(index, c) in this.$parent.coverPageArray"
          :key="c"
          class="previewBox"
        >
          <div
            v-for="(newindex, s) in this.$parent.coverPageArray[c]"
            :key="s"
            class="singleSide"
            :class="{
              middleSide: s == 'coverMiddle',
              'd-none': s == 'coverMiddle',
            }"
          >
            <div
              :id="'variantcover_' + [this.$parent.coverPageArray[c][s]]"
              class="imagePreviewWrapper draggable card drag display_image"
              :class="{
                highlightDiv: highlightArray.includes(this.$parent.coverPageArray[c][s]),
              }"
              :draggable="EnableDrag"
              :data-variant="this.$parent.coverVariant"
              @dragstart="
                dragStartMultipart(this.$parent.coverPageArray[c][s], 'Cover', $event)
              "
              @dragleave="RemovehighlightDropArea"
              @dragover="highlightDropArea(this.$parent.coverPageArray[c][s], 'Cover')"
              @dragover.prevent
              @drop="
                dragFinishMultipart(
                  -1,
                  this.$parent.coverPageArray[c][s],
                  'Cover',
                  $event
                )
              "
            >
              <img
                v-if="
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                  undefined
                "
                :src="
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                    'dummyImage'
                  ]
                    ? this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                        'thumb'
                      ]
                    : this.$parent.externalImage +
                      this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                        'thumb'
                      ]
                "
              />

              <div
                v-if="
                  s != 'coverMiddle' &&
                  this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]] !=
                    undefined &&
                  !this.$parent.CoverImageWithThumb[this.$parent.coverPageArray[c][s]][
                    'dummyImage'
                  ]
                "
                class="drop_button dropdown dropstart"
              >
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
                ></a>
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                  data-bs-popper="none"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.ShowCanvas(
                          this.$parent.coverPageArray[c][s],
                          'miniature'
                        )
                      "
                      >PDFTool</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.confirmUnassignResource(
                          this.$parent.coverPageArray[c][s],
                          'Cover'
                        )
                      "
                      >{{ $t("release-print-data.unassign-link") }}</a
                    >
                  </li>
                </ul>
              </div>

              <p class="card-content">
                <strong v-if="s != 'coverMiddle'"
                  >U{{ this.$parent.coverPageArray[c][s] }}
                </strong>
                <strong v-else>{{ this.$parent.coverPageArray[c][s] }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--body part-->
    <div class="coverContent bookContent">
      <div class="titleBox">{{ $t("multipart-minature.Body-Part") }}</div>
      <div
        class="innerPreviewboxes"
        :class="{
          landscapeMode: this.$parent.bindingType == 'Top',
        }"
      >
        <div
          v-for="(index, l) in this.$parent.MainMinatureArray"
          :key="l"
          class="previewBox"
        >
          <div
            v-for="(newindex, k) in this.$parent.MainMinatureArray[l]"
            :key="k"
            class="singleSide"
            :class="{
              invisible:
                this.$parent.MainMinatureArray[l][k] == -1 ||
                this.$parent.MainMinatureArray[l][k] > this.$parent.bodyPage - 1,
            }"
          >
            <div
              :id="'variant_' + [this.$parent.MainMinatureArray[l][k] + 1]"
              class="imagePreviewWrapper draggable card drag display_image"
              :class="{
                highlightDiv: highlightArray.includes(
                  this.$parent.MainMinatureArray[l][k] + 1 + this.$parent.coverPage
                ),
              }"
              :draggable="EnableDrag"
              :data-variant="this.$parent.bodyVariant"
              @dragstart="
                dragStartMultipart(
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event
                )
              "
              @dragover="
                highlightDropArea(this.$parent.MainMinatureArray[l][k] + 1, 'Body')
              "
              @dragleave="RemovehighlightDropArea"
              @dragover.prevent
              @drop="
                dragFinishMultipart(
                  -1,
                  this.$parent.MainMinatureArray[l][k] + 1,
                  'Body',
                  $event
                )
              "
            >
              <img
                v-if="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                  undefined
                "
                :src="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1][
                    'dummyImage'
                  ]
                    ? this.$parent.thumbnailArray[
                        this.$parent.MainMinatureArray[l][k] + 1
                      ]['thumb']
                    : this.$parent.externalImage +
                      this.$parent.thumbnailArray[
                        this.$parent.MainMinatureArray[l][k] + 1
                      ]['thumb']
                "
              />
              <div
                v-if="
                  this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1] !=
                    undefined &&
                  !this.$parent.thumbnailArray[this.$parent.MainMinatureArray[l][k] + 1][
                    'dummyImage'
                  ]
                "
                class="drop_button dropdown dropstart"
              >
                <a
                  href="javascript:void(0)"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
                ></a>
                <ul
                  class="px-2 py-3 dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1"
                  data-bs-popper="none"
                >
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.ShowCanvas(
                          this.$parent.MainMinatureArray[l][k] +
                            1 +
                            this.$parent.coverPage,
                          'miniature'
                        )
                      "
                      >PDFTool</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="dropdown-item border-radius-md"
                      @click="
                        this.$parent.confirmUnassignResource(
                          this.$parent.MainMinatureArray[l][k] + 1,
                          'Body'
                        )
                      "
                      >{{ $t("release-print-data.unassign-link") }}</a
                    >
                  </li>
                </ul>
              </div>
              <p class="card-content">
                <strong>{{ this.$parent.MainMinatureArray[l][k] + 1 }} </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Undo Button -->
    <vmd-button
      v-show="this.$parent.undoArray.length > 0"
      id="undoBtn"
      class="shadow-none rounded-pill font-weight-normal submit-btn w-100"
      color="primary"
      @click="this.$parent.UndoProcess()"
      >{{ $t("multipart-minature.button-Undo") }}
    </vmd-button>
  </div>
</template>
<script>
import VmdButton from "@/components/VmdButton.vue";
import axiosInterceptor from "@/shared/axiosInterceptor";

export default {
  name: "MultipartMiniaturee",
  components: {
    VmdButton,
  },
  data: function () {
    return {
      highlightArray: [],
      payload: [],
    };
  },
  mounted() {
    var parentNode = document.querySelector(".col-lg-8.main-content");
    var superParentNode = document.querySelector(".print-page-container");
    setTimeout(() => {
      parentNode.classList.add("multiPartMainContent");
      superParentNode.classList.add("minimizeLeftSideBar");
      document.querySelectorAll(".multiPartMainContent").forEach(function (params) {
        params.addEventListener("click", function (e) {
          // var clickedElement = document.querySelector(".drop_button > a");
          var commonParent = document.querySelectorAll(".previewBox");
          var myElementToCheckIfClicksAreInsideOf = params.contains(e.target);
          if (params || myElementToCheckIfClicksAreInsideOf) {
            var parentSelector =
              e.target.parentElement.parentElement.parentElement.parentElement
                .parentElement;
            if (parentSelector) {
              commonParent.forEach((element) => {
                element.classList.remove("activePreviewBox");
              });

              parentSelector.classList.add("activePreviewBox");

              //console.log("parentPre", parentSelector.classList);
            } else {
              commonParent.forEach((element) => {
                element.classList.remove("activePreviewBox");
              });
            }
          } else {
            //console.log("dropClosed");
            commonParent.forEach((element) => {
              element.classList.remove("activePreviewBox");
            });
          }
        });
      });
    }, 0.2);
  },
  beforeUnmount() {
    var parentNode = document.querySelector(".col-lg-8.main-content");
    var superParentNode = document.querySelector(".print-page-container");
    if (parentNode) {
      setTimeout(() => {
        parentNode.classList.remove("multiPartMainContent");
        superParentNode.classList.remove("minimizeLeftSideBar");
      }, 0.2);
    }
  },
  methods: {
    async dragStartMultipart(dragimageindex, dragPartType, ev) {
      ev.dataTransfer.setData("Text", dragimageindex);
      let imageArrayIndex = this.$parent.images.findIndex((object) => {
        return object.sortSequence == dragimageindex && object.partType == dragPartType;
      });
      ev.dataTransfer.dropEffect = "move";
      this.$parent.dragging = imageArrayIndex;
      this.$parent.isDrag = imageArrayIndex;
    },
    highlightDropArea(seq, partType) {
      console.log(partType, `ssskk`);
      this.highlightArray = [];
      //this.highlightArray.push(seq);

      if (partType == "Cover") {
        this.highlightArray.push(seq);
        if (seq == this.$parent.coverPage) {
          seq = 0;
          partType = "Cover";
        }
      } else {
        this.highlightArray.push(seq + this.$parent.coverPage);
      }
      let tmpSeq = seq;
      if (partType == "Cover") {
        ///
      } else {
        tmpSeq = tmpSeq + this.$parent.coverPage;
      }

      //increase the index based on selected images
      const numImages = this.$parent.selectMultipleImage.length;
      if (numImages > 1) {
        const increaseSeqArray = Array.from(
          { length: numImages - 1 },
          (_, index) => tmpSeq + index + 1
        );
        this.highlightArray.push(...increaseSeqArray);
      }
    },
    RemovehighlightDropArea() {
      this.highlightArray = [];
    },
    async dragFinishMultipart(to, dropimageindex, dropPartType, ev) {
      var data = ev.dataTransfer.getData("Text");
      if (this.$parent.selectMultipleImage.length > 0) {
        // console.log("asdjkahsdagdhjadsghadg");
        this.dropMultipleImage(this.$parent.dragging, to, dropimageindex, dropPartType);
      } else {
        if (document.getElementById(data) != null || data != null) {
          await this.$parent.moveCoverItem(
            this.$parent.dragging,
            to,
            dropimageindex,
            dropPartType
          );
          this.RemovehighlightDropArea();
        }
      }
    },
    async dropMultipleImage(dragging, to, dropimageindex, dropPartType) {
      let loaderDisplayPosition = document.body;
      let loader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      await this.$parent.storedUndo();
      let dragImagelength = this.$parent.selectMultipleImage.length;
      const allSeq = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].sortSequence
      );
      console.log(
        `this.$parent.selectMultipleImage.length`,
        this.$parent.selectMultipleImage
      );
      const allDragPart = this.$parent.selectMultipleImage.map(
        (index) => this.$parent.images[index].partType
      );

      const dragArray = this.$parent.selectMultipleImage.map((index) => {
        const image = this.$parent.images[index];
        return {
          sortSequence: image.sortSequence,
          partType: image.partType,
        };
      });

      let temp = this.$parent.selectMultipleImage[0];
      console.log(`temp-------------s`, temp);
      console.log(`temp-kjkhjhj`, this.$parent.images[temp]);

      console.log(`allSeq`, allSeq, dropimageindex);
      let storeDropIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );
      console.log(`storeDropIndex`, storeDropIndex);
      let dropSeq;
      //image is not assigned
      if (storeDropIndex != -1) {
        dropSeq = this.$parent.images[storeDropIndex].sortSequence;
      } else {
        dropSeq = dropimageindex;
      }

      let dropPart = dropPartType;

      /*drop array list*/
      let dropArrayList = [];
      let dropArraySeq = dropSeq;
      let dropArrayPart = dropPart;
      let onlyOnceCover = 0;
      for (let t = 0; t < dragImagelength; t++) {
        dropArrayList.push({
          sortSequence: dropArraySeq,
          partType: dropArrayPart,
        });
        dropArraySeq = dropArraySeq + 1;
        // last condition set bcs if drop image has same seq as total cover page them next image has been first page of cover
        if (
          dropArraySeq > this.$parent.coverPage &&
          dropArrayPart == "Cover" &&
          dropArrayList[0].sortSequence != this.$parent.coverPage
        ) {
          dropArraySeq = 1;
          dropArrayPart = "Body";
        } else {
          if (
            onlyOnceCover == 0 &&
            dropArrayPart == "Cover" &&
            dropArraySeq > this.$parent.coverPage
          ) {
            console.log("wrrororororororooooooooooooooooooooooooooo");
            dropArraySeq = 1;
            dropArrayPart = "Cover";
            onlyOnceCover = 1;
          } else if (
            dropArrayList[0].sortSequence == this.$parent.coverPage &&
            dropArraySeq >= this.$parent.coverPage
          ) {
            console.log("else i f ***************************************");
            dropArraySeq = 1;
            dropArrayPart = "Body";
          }
        }
      }

      const commonByBoth = dragArray.filter((item1) =>
        dropArrayList.some(
          (item2) =>
            item1.sortSequence === item2.sortSequence && item1.partType === item2.partType
        )
      );
      console.log(`commonByBoth`, commonByBoth);
      console.log(`dragArray`, dragArray);
      console.log(`dropArrayList`, dropArrayList);
      console.log(`dropSeq`, dropSeq);
      console.log(`dropPartType`, dropPartType);
      /*Next logic Start*/
      let startLoop = dropimageindex;
      let endLoop;
      let startIndex = this.$parent.selectMultipleImage[0];
      console.log(`startIndex-------------s`, startIndex);
      let startDragPart = this.$parent.images[startIndex].partType;
      let startSeq = this.$parent.images[startIndex].sortSequence;

      console.log(`startDragPart`, startDragPart);
      console.log(`dropPartType`, dropPartType);
      // Only change Covers
      if (startDragPart == "Cover" && dropPartType == "Cover") {
        let maxSeq = Math.min(...allSeq);
        if (maxSeq >= dropimageindex) {
          endLoop = maxSeq;
        } else {
          endLoop = this.$parent.requiredPage;
        }
      } else if (startDragPart == "Cover" && dropPartType == "Body") {
        endLoop = this.$parent.bodyPage;
      } else if (startDragPart == "Body" && dropPartType == "Body") {
        console.log(`startSeq`, startSeq);
        console.log(`dropimageindex`, dropimageindex);
        if (startSeq > dropimageindex) {
          endLoop = startSeq;
        } else {
          endLoop = this.$parent.bodyPage;
        }
      } else if (startDragPart == "Body" && dropPartType == "Cover") {
        endLoop = this.$parent.requiredPage;
        // loop is not running if image drop into last page of cover
        if (dropArrayList[0].sortSequence == this.$parent.coverPage) {
          let temp = dragImagelength;
          if (dragImagelength < this.$parent.coverPage) {
            temp = this.$parent.coverPage - dragImagelength;
          }
          endLoop = endLoop + temp;
        }
      }

      // console.log(`startLoop`, startLoop);
      // console.log(`endLoop`, endLoop);
      let currSeq = null;
      let currSeqArray = [];
      let currSeqPart = [];
      let newSeq;
      let newSeqArray = [];
      let newSeqPart = [];
      let firstLoop = 0;
      let updatedK;
      let currPart = startDragPart;
      let updateDragLength = dragImagelength;
      updateDragLength = updateDragLength - commonByBoth.length;
      // SET NEW PART START SEQ IS GREATER THAN COVER PAGE
      let newPart = startDragPart;
      if (dropPartType == "Body") {
        newPart = "Body";
      }
      //console.log(`newPaet`, newPart);
      if (
        startLoop + updateDragLength > this.$parent.coverPage &&
        dropPartType == "Cover"
      ) {
        //updatedK = 1;
        // Set new part cover then it will go first condition of next seq
        newPart = "Cover";
      }
      // console.log(`newPaesssssst`, newPart);
      let oneTime = 0;
      let LastImageCover = 0;
      let dropArrayIndex = this.$parent.images.findIndex(
        this.$parent.getOldSortSequence(this.$parent.images, dropimageindex, dropPartType)
      );
      console.log(`dropArrayIndex`, dropArrayIndex);
      let seqMainArray = [];
      let seqPart = [];
      let findImagePartType = [];
      let findoldSeq = [];
      /* console.log(
        allDragPart,
        currSeq,
        currSeqArray,
        currSeqPart,
        newSeq,
        newSeqArray,
        newSeqPart,
        firstLoop,
        updatedK,
        currPart,
        oneTime,
        LastImageCover,
        findImagePartType,
        findoldSeq
      );*/
      if (dropArrayIndex != -1) {
        for (let k = startLoop; k < endLoop; k++) {
          //console.log(`k--------`, k);
          //console.log(`currSeq`, currSeq);
          if (currSeq >= this.$parent.coverPage && currPart === "Cover") {
            // console.log("iiiiiiiif 1");

            if (
              dropArrayList[0].sortSequence == this.$parent.coverPage &&
              currSeq == dropArrayList[0].sortSequence
            ) {
              currPart = "Cover";
            } else {
              currPart = "Body";
            }
            currSeq = 1;
          } else if (currSeq === null) {
            //console.log("iiiiiiiif 2");
            currSeq = k;
            currPart = dropPartType === "Cover" ? "Cover" : "Body";
          } else {
            //console.log("iiiiiiiif 3");
            currSeq += 1;
          }

          // For Curr seq
          let checkOldSeqAvailable = dropArrayList.filter(
            (check) => check.sortSequence == currSeq && check.partType == currPart
          );
          //console.log(`currSeq`, currSeq);
          //console.log(`checkOldSeqAvailable`, checkOldSeqAvailable);
          if (checkOldSeqAvailable.length >= 1 && currPart == "Cover") {
            if (
              checkOldSeqAvailable[0].sortSequence == this.$parent.coverPage &&
              currSeq == this.$parent.coverPage &&
              currSeqArray.includes(currSeq)
            ) {
              //console.log("demo1demoe");
              currSeq = 1;
              if (dragImagelength > this.$parent.coverPage) {
                // console.log("demo1demoefafsfsdfsdf");
                currSeq = dragImagelength - this.$parent.coverPage;
                currSeq = currSeq + 1;
              }
              //console.log(`dddddddddddddddddd`, currSeq);
              currPart = "Body";
            }
          }

          // if current seq and drag part is same as selcted seq then skip that
          if (allSeq.includes(currSeq)) {
            let getPart = allSeq.indexOf(currSeq);
            if (allDragPart[getPart] == currPart) {
              // It is required when drag image from B1,c2 and drop to C3
              //console.log(`continue`, currSeq, currPart);
              continue;
            }
          }
          //console.log(`k`, k);
          /* console.log(`------------------------currSeq`, currSeq);
          console.log(`-----------------------currPart`, currPart);
          console.log(`-----------------------newPart`, newPart);*/
          if (
            currSeq + updateDragLength > this.$parent.coverPage &&
            oneTime == 0 &&
            newPart == "Cover"
          ) {
            // console.log(`tttif 1`);
            // This case generated when you drag last 2 image of body and drop into last cover page
            if (
              dropArrayList[0].sortSequence == this.$parent.coverPage &&
              LastImageCover == 0
            ) {
              //console.log("yes in");
              LastImageCover = 1;
              // Start Seq from the last seq of drop array
              let legofDropArray = dropArrayList.length;
              newSeq = dropArrayList[legofDropArray - 1].sortSequence + 1;
              updatedK = newSeq;
              newPart = dropArrayList[legofDropArray - 1].partType;
            } else {
              // console.log("proper");
              newSeq = 1;
              updatedK = 1;
              newPart = "Body";
              oneTime = 1;
            }
          } else if (firstLoop == 0 && LastImageCover == 0 && oneTime == 0) {
            //console.log(`ddddif 2`);
            firstLoop = 1;
            newSeq = k + updateDragLength;

            updatedK = newSeq;
            if (dropPartType == "Cover") {
              newPart = "Cover";
            } else {
              newPart = "Body";
            }
          } else {
            ///console.log(`if 3`);
            newSeq = updatedK + 1;
            updatedK = newSeq;
          }

          let checkNewSeqAvailable = dropArrayList.filter(
            (check) => check.sortSequence == newSeq && check.partType == newPart
          );
          //console.log(`checkNewSeqAvailable`, checkNewSeqAvailable);
          if (checkNewSeqAvailable.length >= 1 && newPart == "Cover") {
            if (checkNewSeqAvailable[0].sortSequence == this.$parent.coverPage) {
              //console.log("osdjfsjfksdjfksdjfkdfjjkshjdhfj");
              updatedK = 1;
              newSeq = 1;
              newPart = "Body";
            }
          }

          //console.log(`newSeq`, newSeq);
          if (newSeq > this.$parent.bodyPage && newPart == "Body") {
            //console.log("break");
            break;
          }

          currSeqArray.push(currSeq);
          currSeqPart.push(currPart);
          newSeqArray.push(newSeq);
          newSeqPart.push(newPart);
        }
        /* console.log(`currSeq`, currSeqArray);
        console.log(`currPart`, currSeqPart);

        console.log(`newSeq`, newSeqArray);
        console.log(`newPart`, newSeqPart);*/

        let p = 0;
        // create the curr seq loop and if any image came blank in beetween then break the loop otherwise assign the image for next logic
        for (let k = 0; k < newSeqArray.length; k++) {
          let oldSeq = currSeqArray[k];
          let oldPart = currSeqPart[k];

          let newSeq = newSeqArray[k];
          let newPart = newSeqPart[k];

          let getOldSortSequence = this.$parent.getOldSortSequence;

          let currentIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, oldSeq, oldPart)
          );

          let nextIndexImage = this.$parent.images.findIndex(
            getOldSortSequence(this.$parent.images, newSeq, newPart)
          );

          //break the loop if any blank image came
          if (nextIndexImage == -1) {
            break;
          }

          if (currentIndexImage != -1) {
            seqMainArray.push(newSeq);
            seqPart.push(newPart);
            findoldSeq.push(oldSeq);
            findImagePartType.push(oldPart);
            const { thumbnail, mainImage } = this.$parent.images[currentIndexImage];
            const targetArray =
              newPart === "Cover"
                ? this.$parent.CoverImageWithThumb
                : this.$parent.thumbnailArray;
            /*console.log(`targetArray`, targetArray);
            console.log(`newSeq`, newSeq);*/
            targetArray[newSeq] = {
              thumb: thumbnail,
              main: mainImage,
              dummyImage: false,
            };

            this.$parent.previewImageArray[newSeq] = mainImage;
            // this condition required becuase it delete the newly assign value
            if (p < updateDragLength && !seqMainArray.includes(newSeq)) {
              console.log(`delete`, oldSeq);
              if (oldPart == "Cover") {
                delete this.$parent.CoverImageWithThumb[oldSeq];
              } else {
                delete this.$parent.thumbnailArray[oldSeq];
              }

              p++;
            }
          }
        }
        await this.updateMainImageArray(
          seqMainArray,
          seqPart,
          findImagePartType,
          findoldSeq
        );
      }
      /*Next logic End*/
      // console.log(`image`, this.$parent.images);
      /******* Drop image logic start****************************/
      console.log(
        `******* Drop image logic start****************************`,
        dropSeq,
        dropPart
      );
      let onlyOnce = 0;
      let pushDropArray = [];
      //let updateDropIndex = dropArrayIndex;
      for (let d = 0; d < dragImagelength; d++) {
        let getIndex = this.$parent.selectMultipleImage[d];
        //console.log(`getIndex`, getIndex, this.$parent.images[getIndex]);
        let dragSeq = this.$parent.images[getIndex].sortSequence;
        let dragPart = this.$parent.images[getIndex].partType;

        //let dropPart = this.$parent.images[updateDropIndex].partType;
        if (dropPart.trim() == null || dropPart.trim() == "") {
          dropPart = dropPartType;
        }

        if (dropSeq > this.$parent.coverPage && dropPart == "Cover") {
          // console.log("in 1 0000000000000");
          dropSeq = 1;
          dropPart = "Body";
          // image drop the last index then next value will be 1st page of cover
          if (dropArrayList[0].sortSequence == this.$parent.coverPage && onlyOnce == 0) {
            dropPart = "Cover";
            onlyOnce = 1;
          }
        }

        /** if drop ==4 & if already push then change to body*/
        if (
          dropSeq == this.$parent.coverPage &&
          dropArrayList[0].sortSequence == this.$parent.coverPage &&
          dropPart == "Cover"
        ) {
          let temp1 = dropSeq;
          let temp2 = dropPart;
          console.log("kkkk");
          let checkAlreadyPush = pushDropArray.filter(
            (check) => check.sortSequence === temp1 && check.partType === temp2
          );
          console.log(checkAlreadyPush);
          if (checkAlreadyPush.length > 0) {
            dropSeq = 1;
            dropPart = "Body";
          }
          ``;
        }

        //Drop Logic//
        /*console.log(`dragseq`, dragSeq);
        console.log(`dragPart`, dragPart);
        console.log(`dropSeq`, dropSeq);
        console.log(`dropPart`, dropPart);*/
        let checkSeq = dropSeq;
        let imgStyle = document
          .getElementById(this.$parent.selectMultipleImage[d])
          .querySelector("img");

        if (dropPart == "Cover" && checkSeq > this.$parent.coverPage) {
          await this.$parent.RemovedDataFromMultipleImage();
          break;
        } else {
          if (checkSeq > this.$parent.bodyPage) {
            await this.$parent.RemovedDataFromMultipleImage();
            break;
          }
        }
        /*if (dropSeq > this.$parent.requiredPage) {
          break;
        }*/

        if (dragSeq == dropSeq && dragPart == dropPart) {
          dropSeq = dropSeq + 1;
          continue;
        }
        console.log("in 1");
        // OLD SEQ REMOVE
        let currentIndexImage = this.$parent.images.findIndex(
          this.$parent.getOldSortSequence(this.$parent.images, dropSeq, dropPart)
        );
        if (currentIndexImage != -1) {
          this.$parent.images[currentIndexImage]["sortSequence"] = null;
          this.$parent.images[currentIndexImage]["partType"] = "";
          this.payload.push(this.$parent.images[currentIndexImage]);
          pushDropArray.push({
            sortSequence: null,
            partType: "",
          });
        }

        //NEW SEQ ASSIGN
        console.log(`NEW SEQ ASSIGN`, dropPart);
        this.$parent.images[getIndex]["sortSequence"] = dropSeq;
        this.$parent.images[getIndex]["partType"] = dropPart;
        this.$parent.images[getIndex]["variantId"] =
          dropPart == "Cover" ? this.$parent.coverVariant : this.$parent.bodyVariant;
        this.payload.push(this.$parent.images[getIndex]);
        pushDropArray.push({
          sortSequence: dropSeq,
          partType: dropPart,
        });
        // CHECK OLD IMAGE HAS ALREAY ASSIGNE tHEN DELETE FROM REQUIRED PLACES
        if (dragSeq != null) {
          const movingToCover = dropPart === "Cover";
          const movingFromCover = dragPart === "Cover";
          console.log(`movingToCover`, movingToCover);
          console.log(`movingFromCover`, movingFromCover);
          // Handle Cover-related operations
          if (movingToCover) {
            if (dragPart == "Cover") {
              console.log("ioioioioioioio-----");
              this.$parent.CoverImageWithThumb[
                dropSeq
              ] = this.$parent.CoverImageWithThumb[dragSeq];
            } else {
              console.log(
                "elelelelel-----",
                dragSeq,
                this.$parent.thumbnailArray[dragSeq]
              );
              this.$parent.CoverImageWithThumb[dropSeq] = this.$parent.thumbnailArray[
                dragSeq
              ];
              if (!seqMainArray.includes(dragSeq)) {
                delete this.$parent.thumbnailArray[dragSeq];
              }
            }

            const isPartTypeChanged =
              seqMainArray.includes(dragSeq) &&
              seqPart[seqMainArray.indexOf(dragSeq)] !== dragPart;

            if (!seqMainArray.includes(dragSeq) || isPartTypeChanged) {
              delete this.$parent.CoverImageWithThumb[dragSeq];
            }
          }

          // Handle Body-related operations
          if (!movingToCover) {
            console.log(`~movingToCover`, movingToCover);
            this.$parent.thumbnailArray[dropSeq] = movingFromCover
              ? this.$parent.CoverImageWithThumb[dragSeq]
              : this.$parent.thumbnailArray[dragSeq];
            if (movingFromCover) {
              delete this.$parent.CoverImageWithThumb[dragSeq];
            } else {
              console.log(`dragSeq`, dragSeq, `in else ---`);
              delete this.$parent.thumbnailArray[dragSeq];
            }

            const { main } = this.$parent.thumbnailArray[dropSeq]; // Destructuring
            this.$parent.previewImageArray[dropSeq] = main;
          }
        } else {
          const { mainImage, thumbnail } = this.$parent.images[getIndex];
          const targetArray =
            dropPart === "Cover"
              ? this.$parent.CoverImageWithThumb
              : this.$parent.thumbnailArray;
          targetArray[dropSeq] = {
            main: mainImage,
            thumb: thumbnail,
            dummyImage: false,
          };

          this.$parent.previewImageArray[dropSeq] = targetArray[dropSeq]["main"];
        }

        dropSeq = dropSeq + 1;

        //Removed the border
        /*let imgStyle = document
          .getElementById(this.$parent.selectMultipleImage[d])
          .querySelector("img");*/
        console.log("in 2");
        imgStyle.removeAttribute("style");
      }

      //console.log(`image`, this.$parent.images);
      /************************* Drop image logic end*******************/

      this.highlightArray = [];
      await this.$parent.SortImageorder();
      this.$parent.selectMultipleImage = [];
      this.payload = await this.removeDuplicates(this.payload);
      console.log(`final payload`, this.payload);
      let orderHeader = {
        orderId: this.$parent.OrderId,
      };

      const result1 = await axiosInterceptor.post(
        `/api/v1/resources/pageSortAndAssign?orderId=${this.$parent.OrderId}&plausibilityCheck=true&jobLock=true`,
        this.payload,
        orderHeader
      );
      await this.$parent.CheckValidation(result1.data.plausibilityCheck, 2);
      this.payload = [];
      loader.hide();
    },
    updateMainImageArray(seqMainArray, seqPart, oldPartList, oldSeqList) {
      /*console.log(`new Seq`, seqMainArray);
      console.log(`new part`, seqPart);
      console.log(`old seq`, oldSeqList);
      console.log(`old seq part`, oldPartList);*/
      console.log(`old seq`, oldSeqList);
      for (let l = 0; l < seqMainArray.length; l++) {
        let p = seqMainArray[l];
        //console.log(`p`, p);
        //Found the image name
        let imageName;
        let findImagePartType = oldPartList[l];
        let assignPartType = seqPart[l];

        if (this.$parent.thumbnailArray[p] && assignPartType == "Body") {
          imageName = this.$parent.thumbnailArray[p]["thumb"];
        }

        if (this.$parent.CoverImageWithThumb[p] && assignPartType == "Cover") {
          imageName = this.$parent.CoverImageWithThumb[p]["thumb"];
        }

        // console.log(`imageName`, imageName);
        if (imageName != null) {
          //console.log(`this.$parent.images`, this.$parent.images);
          let getImageIndex = this.$parent.images.findIndex(
            this.$parent.getindexbasedonimage(
              this.$parent.images,
              imageName,
              findImagePartType
            )
          );
          //console.log(`getImageIndex`, getImageIndex, this.$parent.images[getImageIndex]);
          if (getImageIndex != -1) {
            let previousSortSequenceIndex = this.$parent.images.findIndex(
              this.$parent.getOldSortSequence(this.$parent.images, p, assignPartType)
            );
            // console.log(`previousSortSequence`, previousSortSequenceIndex);
            if (previousSortSequenceIndex != -1) {
              this.$parent.images[previousSortSequenceIndex]["sortSequence"] = null;
              //this.$parent.images[previousSortSequenceIndex]["partType"] = "";
              this.payload.push(this.$parent.images[previousSortSequenceIndex]);
            }

            this.$parent.images[getImageIndex]["sortSequence"] = p;
            this.$parent.images[getImageIndex]["partType"] = assignPartType;
            this.$parent.images[getImageIndex]["variantId"] =
              assignPartType == "Cover"
                ? this.$parent.coverVariant
                : this.$parent.bodyVariant;
            //console.log(`after`, this.$parent.images[getImageIndex]["sortSequence"]);
            this.payload.push(this.$parent.images[getImageIndex]);
          }
        }
      }

      //console.log(`next pyload`, this.payload);
    },
    removeDuplicates(array) {
      const uniqueArray = array.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.singlePagesID === item.singlePagesID)
      );
      return uniqueArray;
    },

    async moveElementToPosition(array, elementIndex, newPosition) {
      const element = array[elementIndex];
      if (elementIndex > newPosition) {
        array.splice(elementIndex, 1);
        array.splice(newPosition, 0, element);
      } else if (elementIndex < newPosition) {
        array.splice(newPosition, 0, element);
        array[elementIndex] = undefined;
      }
      return array;
    },
  },
};
</script>
<style scoped>
.highlightDiv {
  border: 3px solid #6533dd;
}
</style>
