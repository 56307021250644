const state = () => ({
    list: []
});
const getters = {
    getListstate(state) {
        return state.list;
    }
};
const mutations = {
    ADD_NOTIFICATION(state, payload) {
        var index = state.list.map(function (t) { return parseInt(t.uniqueId); }).indexOf(parseInt(payload['uniqueId']));
        if(index==-1){
            state.list.push(payload);
        }
    },
    REMOVE_NOTIFICATION(state, payload) {
        if(payload.title=='order completed'){   
            state.list = state.list.filter(notification => notification.orderId !== payload.orderId && payload.resourceId ==0);
        }
        else if(payload.title=='error in save as image'){   
            state.list = state.list.filter(notification => notification.resourceId !== payload.resourceId && payload.resourceId > 0);
        }
        else{
            state.list = state.list.filter(notification => notification.uniqueId !== payload.uniqueId);
        }
        
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};