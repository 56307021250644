<template>
  <div class="bg-white">
    <main class="mt-0 main-content auth-pages">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div>
                <router-link
                  :to="{ name: 'Signin Illustration' }"
                  class="auth-custom-logo"
                >
                  <i class="fas fa-arrow-up" aria-hidden="true"></i>
                  <h4 class="text-h4">Upload Portal</h4>
                </router-link>
              </div>
              <div
                class="col-6 d-lg-flex d-none h-100 my-auto px-0 position-absolute top-0 start-0 text-center justify-content-center flex-column auth-image-section"
              >
                <div
                  class="position-relative h-100 px-7 d-flex flex-column justify-content-center bg-cover"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/illustrations/signup.png') + ')',
                  }"
                ></div>
              </div>
              <div
                class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column ms-auto custom-auth-form"
              >
                <div class="card card-plain">
                  <div class="card-header bg-transparent">
                    <img
                      :src="require('@/assets/img/loader.svg')"
                      style="max-width: 100px; margin: 0 auto 10px; display: block"
                      :class="{
                        'd-none': tokenValid == false,
                      }"
                    />

                    <img
                      :class="{
                        'd-none': tokenValid == true || tokenValid == null,
                      }"
                      :src="require('@/assets/img/cross-svgrepo-com.svg')"
                      style="
                        max-width: 90px;
                        margin: 0px auto 20px;
                        display: block;
                        padding: 20px;
                        border: 4px solid #fff;
                        border-radius: 50%;
                      "
                    />
                    <h4
                      class="font-weight-bolder text-center"
                      style="font-size: 25px !important"
                    >
                      {{ displayMessage }}
                    </h4>
                    <!-- <p class="mb-0">Enter your email and password to sign in</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Callback",
  data() {
    return {
      displayMessage: "Please wait while we proccess",
      tokenValid: null,
    };
  },
  async mounted() {
    // console.log("Yees mounted", this.$route.query);
    await this.$store.dispatch("auth/ZaikioCallback", this.$route.query);
    this.loginWithZaikio();
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },

  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    loginWithZaikio() {
      //console.log("demo");
      let loginStatus = this.$store.getters["auth/getLoginStatus"];
      //let already_login = this.$store.getters["auth/getAuthData"];
      console.log(loginStatus);
      if (loginStatus == "success") {
        this.tokenValid = true;
        this.$router.replace("/orders/list");
      } else {
        this.tokenValid = false;
        this.displayMessage = "Token is invalid";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Token is invalid",
        });
      }
    },
  },
};
</script>
