<template>
  <div
    class="card multipart temp"
    :class="{
      drag: this.$parent.isDragging,
      display_image: !this.$parent.canvasComponents,
      landScapeMultiPart: this.$parent.bindingType == 'Top',
    }"
  >
    <!--Cover Image-->
    <div
      v-for="(index, c) in this.$parent.coverPageArray"
      :key="c"
      class="imagePreviewWrapper draggable card coverCards"
      :class="{
        display_image:
          this.$parent.coverDisplay == true &&
          this.$parent.multiPartArray.includes(
            this.$parent.coverPageArray[c]['coverStart'] ||
              this.$parent.coverPageArray[c]['coverEnd']
          ),
      }"
    >
      <!-- {{ this.$parent.coverPageArray[c]['coverStart'] }} -->

      <div
        v-for="(newindex, k) in this.$parent.coverPageArray[c]"
        :id="'multipartcover' + newindex"
        :key="k"
        :class="{
          drag: this.$parent.isDragging,
          display_image:
            this.$parent.multiPartArray.includes(newindex) &&
            this.$parent.coverDisplay == true,
          //invisible: k == 0 || k > this.$parent.coverPage,
          //image_drop: i == this.$parent.isdisplay,
        }"
        class="imagePreviewWrapper draggable card"
        @dragover.prevent
        @drop="dragFinishMultipart(-1, newindex, 'Cover', $event)"
      >
        <img
          v-if="this.$parent.CoverImageWithThumb[newindex] != undefined"
          :id="`variantcover_` + k"
          :src="
            !this.$parent.CoverImageWithThumb[newindex]['dummyImage']
              ? this.$parent.externalImage +
                this.$parent.CoverImageWithThumb[newindex]['main']
              : this.$parent.CoverImageWithThumb[newindex]['main']
          "
          alt=""
          :class="{ noImage: this.$parent.CoverImageWithThumb[newindex] == undefined }"
          @load="handleLoad"
        />

        <div
          v-if="
            this.$parent.CoverImageWithThumb[newindex] != undefined &&
            !this.$parent.CoverImageWithThumb[newindex]['dummyImage']
          "
          class="drop_button dropdown dropstart"
        >
          <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false"
            ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
          ></a>
          <ul
            class="px-2 py-3 dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton1"
            data-bs-popper="none"
          >
            <li>
              <a
                href="javascript:void(0)"
                class="dropdown-item border-radius-md"
                @click="this.$parent.ShowCanvas(newindex, newindex)"
                >PDFTool</a
              >
            </li>
            <li>
              <a
                href="javascript:void(0)"
                class="dropdown-item border-radius-md"
                @click="this.$parent.confirmUnassignResource(newindex, 'Cover')"
                >{{ $t("release-print-data.unassign-link") }}</a
              >
            </li>
          </ul>
        </div>
        <p class="card-content">
          <strong
            :class="{
              'link-active': this.$parent.isdisplay == newindex,
            }"
            >U{{ newindex }}</strong
          >
        </p>
      </div>
    </div>

    <!--Body Image-->
    <div
      v-for="(index, i) in this.$parent.bodyPage + 2"
      :id="'multipart' + i"
      :key="i"
      :class="{
        drag: this.$parent.isDragging,
        display_image:
          this.$parent.multiPartArray.includes(i) && this.$parent.coverDisplay == false,
        invisible: i == 0 || i > this.$parent.bodyPage,
        //image_drop: i == this.$parent.isdisplay,
      }"
      class="imagePreviewWrapper draggable card"
      @dragover.prevent
      @drop="dragFinishMultipart(-1, i, 'Body', $event)"
    >
      <img
        :id="`variant_` + i"
        :data-variant="this.$parent.bodyVariant"
        :src="
          this.$parent.thumbnailArray[i] == undefined
            ? ''
            : !this.$parent.thumbnailArray[i]['dummyImage']
            ? this.$parent.externalImage + this.$parent.thumbnailArray[i]['main']
            : this.$parent.thumbnailArray[i]['main']
        "
        alt=""
        :class="{
          noImage: this.$parent.thumbnailArray[i] == undefined,
        }"
        @load="handleLoad"
      />
      <div
        v-if="
          this.$parent.thumbnailArray[i] != null &&
          !this.$parent.thumbnailArray[i]['dummyImage']
        "
        class="drop_button dropdown dropstart"
      >
        <a href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false"
          ><i class="fas fa-ellipsis-h" aria-hidden="true"></i
        ></a>
        <ul
          class="px-2 py-3 dropdown-menu dropdown-menu-end"
          aria-labelledby="dropdownMenuButton1"
          data-bs-popper="none"
        >
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="
                this.$parent.ShowCanvas(
                  i + this.$parent.coverPage,
                  i + this.$parent.coverPage
                )
              "
              >PDFTool</a
            >
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="dropdown-item border-radius-md"
              @click="this.$parent.confirmUnassignResource(i, 'Body')"
              >{{ $t("release-print-data.unassign-link") }}</a
            >
          </li>
        </ul>
      </div>
      <p class="card-content">
        <strong
          :class="{
            'link-active': this.$parent.isdisplay == i,
          }"
          >{{ i }}
        </strong>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Mutipartview",
  data() {
    return {
      imgLoaded: false,
    };
  },
  mounted() {
    var findMainCover = document.querySelectorAll(
      ".multipart.landScapeMultiPart:not(.multipartminiature) .imagePreviewWrapper:first-child, .multipart.landScapeMultiPart:not(.multipartminiature) .imagePreviewWrapper:nth-child(2)"
    );
    var i = 1;
    findMainCover.forEach((singleBox) => {
      var loaderDisplayPosition = document.body;
      this.mountLoader = this.$loading.show({
        container: loaderDisplayPosition,
      });
      var loaderCoverBox = document.querySelectorAll(".vld-container");
      var singleImage = singleBox.querySelector("img");
      if (singleImage) {
        console.log("singleImage", singleImage);
        var singleImageHeight;
        singleImage.onload = function () {
          singleImageHeight = singleImage.clientHeight;
          console.log("singleImageHeight", singleImageHeight, "== no=> " + i);
          singleBox.style.height = singleImageHeight + "px";
          singleBox.style.opacity = 1;
          singleBox.previousElementSibling.style.height = singleImageHeight + "px";
          loaderCoverBox.forEach((el) => el.remove());
        };

        setTimeout(() => {
          var imageNotLoaded = singleImage.classList.contains("noImage");
          if (imageNotLoaded == true) {
            singleBox.style.opacity = 1;
            loaderCoverBox.forEach((el) => el.remove());
          }
        }, 3500);
      }
      // if (singleImage.classList.contains("noImage") && this.imgLoaded == true) {
      //     singleBox.style.opacity = 1;
      //     singleImage.onerror = function () {
      //         loaderCoverBox.forEach(el => el.remove());
      //     }
      // }

      // singleImage.addEventListener('error', function handleError() {
      //     singleBox.style.opacity = 1;
      //     loaderCoverBox.forEach(el => el.remove());
      // });

      // singleImage.onerror = function () {
      //     singleBox.style.opacity = 1;
      // }
    });
  },
  methods: {
    async dragFinishMultipart(to, dropimageindex, dropPartType, ev) {
      var data = ev.dataTransfer.getData("Text");
      if (document.getElementById(data) != null) {
        this.$parent.isdisplay = dropimageindex;
        await this.$parent.moveCoverItem(
          this.$parent.dragging,
          to,
          dropimageindex,
          dropPartType
        );
        /* if (this.$parent.coverPage > 0) {
          await this.$parent.moveCoverItem(
            this.$parent.dragging,
            to,
            dropimageindex,
            dropPartType
          );
        } else {
         
          await this.$parent.moveCoverItem(
            this.$parent.dragging,
            to,
            dropimageindex,
            dropPartType
          );
        }*/
      }
    },
    handleLoad() {
      this.imgLoaded = true;
    },
  },
};
</script>
<style scoped>
.multipart.landScapeMultiPart:not(.multipartminiature) .imagePreviewWrapper:first-child,
.multipart.landScapeMultiPart:not(.multipartminiature) .imagePreviewWrapper:nth-child(2) {
  opacity: 0;
}
</style>
