<!--
=========================================================
* Vue Material Dashboard 2 PRO - v2.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-material-dashboard-2-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
-->
<template>
  <div id="rectappend"></div>
  <sidenav
    v-if="showSidenav"
    :custom_class="color"
    :class="[isRTL ? 'fixed-end' : 'fixed-start']"
    id="sidebarmain"
  />
  <main class="main-content position-relative h-100">
    <!-- nav -->
    <navbar
      v-if="showNavbar"
      :class="[isNavFixed ? navbarFixed : '', isAbsolute ? absolute : '']"
      :color="isAbsolute ? 'text-white opacity-8' : ''"
      :min-nav="navbarMinimize"
    />
    <router-view />
  </main>
</template>
<script>
import Navbar from "@/examples/Navbars/Navbar.vue";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { activateDarkMode } from "@/assets/js/dark-mode";
import i18n from "@/i18n";
import orderInfomration from "@/Mixins/orderInfomration";
export default {
  name: "App",
  components: {
    Navbar,
  },
  mixins: [orderInfomration],
  inject: ["eventBus"],
  data() {
    return {
      predefineUploadText:
        "<i class='fas fa-upload'></i>" +
        i18n.global.t("upload-image.predefine-upload-text") +
        " : ",
      predefineSplitPDFText:
        "<i class='fas fa-file-pdf'></i>" +
        i18n.global.t("upload-image.predefine-splitPDF-text") +
        " : ",
      predefineSaveImageText:
        "<i class='fas fa-image'></i>" +
        i18n.global.t("upload-image.predefine-saveAsImage-text") +
        " : ",
      otherRoutes: ["OrderListing", "OrderGrid", "NewOrder"],
      PreviewrouteNames: ["UploadImage", "Preview"],
      format: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
    };
  },
  computed: {
    ...mapState([
      "isRTL",
      "color",
      "isAbsolute",
      "isNavFixed",
      "navbarFixed",
      "absolute",
      "showSidenav",
      "showNavbar",
      "showFooter",
      "showConfig",
      "hideConfigButton",
    ]),
    ...mapGetters("notifications", {
      notifications: "getListstate",
    }),
    successMessageForPDF() {
      return i18n.global.t("order-common.socket-PDF-upload");
    },
    PDFCompleted() {
      return i18n.global.t("order-common.socket-PDF-completed");
    },
  },
  async beforeMount() {
    const sidenav = document.getElementsByClassName("g-sidenav-show")[0];

    if (window.innerWidth > 1200) {
      sidenav.classList.add("g-sidenav-pinned");
    }
    //For active Dark mode
    activateDarkMode();
  },
  async mounted() {
    //this.initListeners();
  },
  created() {
    this.minNav;
    const otherRoutes = this.otherRoutes;
    const PreviewrouteNames = this.PreviewrouteNames;
    this.userType = localStorage.getItem("user_type");

    window.addEventListener("storage", async (event) => {
      if (event.key === "refreshcalltrigger") {
        /*console.log(
          "value ssupdated===============",
          localStorage.getItem("access_token")
        );*/
        if (
          localStorage.getItem("loginType") == 2 &&
          localStorage.getItem("user_type") == "User"
        ) {
          //
          const obj = {
            access_token: localStorage.getItem("zaikio_access_token"),
            refresh_token: localStorage.getItem("zaikio_refresh_token"),
            id_token: localStorage.getItem("zaikio_idp_token"),
          };
          await this.$store.commit("auth/SaveZaikioTokenData", obj);
        } else {
          const obj = {
            access_token: localStorage.getItem("access_token"),
            refresh_token: localStorage.getItem("refresh_token"),
            userType: localStorage.getItem("user_type"),
          };
          await this.$store.commit("auth/saveTokenData", obj);
        }
      }
    });

    this.$socket.on("msgToClient", async (response) => {
      console.log("Navbar", response.message);
      if (this.socketClientId == null) {
        this.socketClientId = response.clientId;
      }

      if (Object.keys(response).indexOf("message") >= 0) {
        //Duploicate File error
        if (
          response.message.statusCode == 409 &&
          Object.keys(response.message).indexOf("workflow") == -1
        ) {
          if (this.$route.name == "UploadImage") {
            await this.eventBus.emit("activeAbortBtn", response);
          }
        }
        // When file uploaded successfully
        if (
          response.message.statusCode == 200 &&
          Object.keys(response.message).indexOf("workflow") == -1
        ) {
          //console.log(`response.message.fileName uploaded`, response.message.fileName);
          let deleteFileName = response.message.fileName;
          deleteFileName = deleteFileName.replace(/[^a-zA-Z]/g, "");

          let uploadResponse = document.getElementById("upload" + deleteFileName);
          /*if (uploadResponse != undefined) {
            uploadResponse.remove();
          }*/

          /**/

          if (this.$route.name == "UploadImage") {
            await this.eventBus.emit("activeAbortBtn", response);
          }

          //console.log(`uploadResponse`, uploadResponse);
          if (uploadResponse != undefined) {
            let mainDiv = uploadResponse;
            let mainParent = uploadResponse.closest(".main-upload");
            // console.log(`mainParent`, mainParent);

            if (mainParent) {
              uploadResponse.remove();

              mainDiv.id = "mainDivDrop" + response.message.resourceId;

              let successId = mainParent.querySelector(".dz-success-message");
              // console.log(`successId`, successId);
              successId.id = "customsuccessId" + response.message.resourceId;

              let addId = mainParent.querySelector(".dz-success-mark");
              addId.id = "imagedropzonediv" + response.message.resourceId;
              let errorAddId;
              //   if(type == 1){
              errorAddId = mainParent.querySelector(".dz-error-message");
              errorAddId.id = "customErrorDiv" + response.message.resourceId;
              // }

              var uploadPDF = document.createElement("span");
              uploadPDF.className = "customMessageDiv messageSuccess";
              uploadPDF.id = "uploadPDFdiv" + response.message.resourceId;
              uploadPDF.innerHTML =
                this.predefineUploadText +
                i18n.global.t("release-print-data.done-status");
              let uploadPDFPreview = mainParent.querySelector(".dz-success-message");
              let t = document.getElementById(
                "imagedropzonediv" + response.message.resourceId
              );
              t.innerHTML = "<i class = 'fas fa-check'></i>";
              t.classList.remove("d-none");
              uploadPDFPreview.insertBefore(uploadPDF, uploadPDFPreview.firstChild);
            }
          }
          /**/

          let newArray = {
            resourceId: response.message.resourceId,
            orderId: response.message.orderId,
            mergePDF: null,
            quickcheck: null,
            uniqueId: null,
            uploadedFilesName: null,
            saveasimage: null,
            fileName: response.message.fileName,
          };
          //It is function from vuex
          if (response.message.resourceId) {
            await this.AddallOrderInfo(newArray);
          }

          //If upload page is open
          /* if (PreviewrouteNames.includes(this.$route.name)) {
            // console.log("Current ", this.$route.name);
            let decodeOrderId = this.$route.params.OrderId;
            if (format.test(decodeOrderId)) {
              decodeOrderId = await this.decryptOrderId(this.$route.query);
            }
          }*/
        }

        if (Object.keys(response.message).indexOf("workflow") >= 0) {
          //Quickcheck
          if (response.message.workflow == "quickcheck") {
            //Stored the unique id for uploader page
            if (response.message.status == "start") {
              await this.eventBus.emit("storedUniqueId", response);
            }
            if (
              response.message.status == "completed" ||
              response.message.status == "error"
            ) {
              let newArray = {};
              newArray = {
                fileRadixResourceId: response.message.uplResourceId,
                mergePDF: "completed",
                quickcheck: response.message.status,
                orderId: response.message.orderId,
                uniqueId: response.message.uniqueId,
                resourceId: response.message.resourceId,
              };

              /*newArray["fileRadixResourceId"] = response.message.uplResourceId;
              newArray["mergePDF"] = "completed";
              newArray["quickcheck"] = response.message.status;
              newArray["orderId"] = response.message.orderId;
              newArray["uniqueId"] = response.message.uniqueId;
              newArray["resourceId"] = response.message.resourceId;*/

              if (response.message.status == "error") {
                if (response.message.message.message) {
                  newArray["errorMessage"] = response.message.message.message;
                } else {
                  newArray["errorMessage"] = response.message.message;
                }
                newArray["saveasimage"] = "error";
              }
              //It is function from vuex
              if (response.message.orderId > 0 && response.message.resourceId > 0) {
                await this.AddallOrderInfo(newArray);
              }

              //Show error in uploader and preview page
              if (
                response.message.status == "error" &&
                PreviewrouteNames.includes(this.$route.name)
              ) {
                this.DropzoneError(response, "quickcheck");
              }

              // Used in uploader page
              if (this.$route.name == "UploadImage") {
                this.eventBus.emit("increasequickcheck", response);
              }

              // Used in Preview page 'loadImage'
              if (this.$route.name == "Preview") {
                this.eventBus.emit("loadImages", response);
              }

              let orderProcessStatus = null;
              await this.$store
                .dispatch("auth/orderInProgress", response.message.orderId)
                .then((res) => {
                  orderProcessStatus = res.status;
                });
              if (orderProcessStatus == false) {
                let filenames;
                let quickcheckStatus;
                let tosterType = "success";
                let errorMessage;
                let defaultMessage = this.successMessageForPDF;
                await this.$store
                  .dispatch("auth/getUploadFileName", response.message.resourceId)
                  .then((res) => {
                    filenames = res.uploadedFileName;
                    quickcheckStatus = res.quickcheck;
                    errorMessage = res.errorMessage;
                  });

                if (quickcheckStatus == "error") {
                  tosterType = "danger";
                  defaultMessage = `Found error for order number ${response.message.orderId}: ${filenames} ${errorMessage}`;
                } else {
                  defaultMessage = defaultMessage + " " + response.message.orderId;
                }

                let decodeOrderId = this.$route.params.OrderId;
                if (this.format.test(decodeOrderId)) {
                  decodeOrderId = await this.decryptOrderId(this.$route.query);
                }

                if (
                  !PreviewrouteNames.includes(this.$route.name) &&
                  decodeOrderId != response.message.orderId
                ) {
                  //Generate the notification once order's quickcheck process has been done
                  await this.notificationAdd(
                    "save image!",
                    defaultMessage,
                    tosterType,
                    true,
                    response.message.orderId,
                    0,
                    response.message.resourceId
                  );
                }

                // once order's notification generated then load orderlisting
                if (otherRoutes.includes(this.$route.name)) {
                  this.eventBus.emit("loadlisting", response.message.orderId);
                }
              }
            }
          }

          // Save as image
          if (response.message.workflow == "saveasimgs") {
            let newArray = {};
            if (response.message.status == "processing") {
              newArray["fileRadixResourceId"] = response.message.uplResourceId;
              newArray["orderId"] = response.message.orderId;
              newArray["uniqueId"] = response.message.uniqueId;
              newArray["resourceId"] = response.message.resourceId;
            }
            if (response.message.status == "completed") {
              newArray["fileRadixResourceId"] = response.message.uplResourceId;
              newArray["orderId"] = response.message.orderId;
              newArray["uniqueId"] = response.message.uniqueId;
              newArray["resourceId"] = response.message.resourceId;

              //It is function from vuex
              if (response.message.orderId > 0 && response.message.resourceId > 0) {
                await this.AddallOrderInfo(newArray);
              }
              if (PreviewrouteNames.includes(this.$route.name)) {
                let decodeOrderId = this.$route.params.OrderId;
                if (this.format.test(decodeOrderId)) {
                  decodeOrderId = await this.decryptOrderId(this.$route.params.OrderId);
                }
                if (
                  decodeOrderId == response.message.orderId &&
                  response.message.statusCode != 200
                ) {
                  this.eventBus.emit("loadImages", response);
                }
              }

              if (otherRoutes.includes(this.$route.name)) {
                this.eventBus.emit("loadlisting", response.message.orderId);
              }
            }

            if (response.message.status === "error") {
              const { uplResourceId, orderId, uniqueId, resourceId } = response.message;

              let newArray = {
                fileRadixResourceId: uplResourceId,
                orderId,
                uniqueId,
                resourceId,
                saveasimage: "error",
              };

              // It is a function from Vuex
              if (orderId > 0 && resourceId > 0) {
                await this.AddallOrderInfo(newArray);
              }

              // Removed order if the file has an error
              // await this.DeleteResourceId(resourceId);

              //Show the dropzone error
              this.DropzoneError(response, "saveasimg");

              let storeAllFile = await this.$store.getters["auth/getallOrderInfo"];
              let errorFileName = "";

              //Find file index based on resourceId
              const filenameIndex = storeAllFile.findIndex(
                (object) => parseInt(object.resourceId) === parseInt(resourceId)
              );

              if (filenameIndex >= 0) {
                errorFileName = `for file ${storeAllFile[filenameIndex].fileName}`;
              }

              let errorMessage = `${orderId} order has an error ${errorFileName || ""}`;

              await this.notificationAdd(
                "error in save as image",
                errorMessage,
                "danger",
                true,
                orderId,
                resourceId,
                resourceId
              );

              if (this.$route.name === "UploadImage" || this.$route.name === "Preview") {
                //Eventbus for upload and preview page
                this.eventBus.emit(
                  this.$route.name === "UploadImage"
                    ? "increasesaveimage"
                    : "errorInSaveImage",
                  response
                );
              }
              await this.generateNotification(response);
            }

            //Status code 200 and if resourceId exist in socket message then single PDF process has been completed
            if (
              response.message.statusCode == 200 &&
              Object.keys(response.message).indexOf("resourceId") > 0
            ) {
              const { resourceId } = response.message;
              let newArray = {
                resourceId,
                saveasimage: "completed",
              };

              await this.AddallOrderInfo(newArray);
              await this.generateNotification(response);
            }

            // Removed the order from the process based on uniqueId
            if (response.message.statusCode == 200) {
              // Abort button Active if file process has been completed

              //Code comment as per ne logic
              /*if (this.$route.name == "UploadImage") {
                await this.eventBus.emit("activeAbortBtn", response);
              }*/

              // Hide the file from the dropzone in preview page if file uploadtion done
              if (this.$route.name == "Preview") {
                await this.eventBus.emit("hideFileDropzone", response);
              }

              if (Object.keys(response.message).indexOf("resourceId") == -1) {
                await this.RemoveOrderusingUniqueId(response.message.uniqueId);
                //show notification when order process has been completed
                await this.generateNotification(response);
              }
            }
          }

          //definePageOrder
          if (response.message.workflow == "definePageOrder") {
            // definePageOrder completed when stauscode has 200 value
            if (response.message.statusCode == 200) {
              // Preview page call
              let mergepdfresponse = document.getElementById("mergePDFspan");
              if (mergepdfresponse != undefined) {
                mergepdfresponse.className += "  messageSuccess";
                this.eventBus.emit("mergePDFaction", response);
              }
            }
          }
        }
      }
    });
  },
  methods: {
    ...mapMutations(["toggleConfigurator", "navbarMinimize"]),
    ...mapActions({
      AddallOrderInfo: "auth/AddallOrderInfo",
      RemoveOrderusingUniqueId: "auth/RemoveOrderusingUniqueId",
      DeleteResourceId: "auth/DeleteResourceId",
    }),
    notificationAdd(title, text, type, timeout, orderId, uplResourceId, resourceId) {
      this.$store.commit("notifications/ADD_NOTIFICATION", {
        title: title,
        text: text,
        type: type,
        timeout: timeout,
        orderId: orderId,
        uplResourceId: uplResourceId,
        delay: 10000,
        status: true,
        resourceId: resourceId,
      });
    },
    async DropzoneError(response, type) {
      let errorMessage = response.message.message;
      if (type == "saveasimg") {
        errorMessage = response.message.message.message;
        if (errorMessage == "") {
          errorMessage = "Some error occur";
        }
      }

      let decodeOrderId = this.$route.params.OrderId;
      if (this.format.test(decodeOrderId)) {
        decodeOrderId = await this.decryptOrderId(this.$route.params.OrderId);
      }
      // Checking if both has same order id
      if (decodeOrderId == response.message.orderId) {
        //Hide the upload div
        let uploadDiv = document.getElementById(
          "uploadPDFdiv" + response.message.resourceId
        );

        if (uploadDiv != undefined) {
          uploadDiv.className += " d-none";
        }

        // Display error message from the response
        let ErrorDisplay = document.getElementById(
          "customErrorDiv" + response.message.resourceId
        );
        if (ErrorDisplay != undefined) {
          ErrorDisplay.innerHTML = errorMessage;
        }

        // Show the cancel icon
        let mainDivDropDisplay = document.getElementById(
          "mainDivDrop" + response.message.resourceId
        );
        if (mainDivDropDisplay != undefined) {
          mainDivDropDisplay.className += " dz-error";
          mainDivDropDisplay.classList.remove("dz-success");
        }
      }
    },
    async generateNotification(response) {
      let orderId = response.message.orderId;
      let getAllFile = await this.$store.getters["auth/getallOrderInfo"];

      let checkAnyCompleted = getAllFile.some(
        (object) =>
          parseInt(object.orderId) === parseInt(orderId) &&
          object.saveasimage === "completed"
      );

      let checkOrderStatus = null;
      await this.$store.dispatch("auth/imageGenerate", orderId).then((res) => {
        //console.log(`res`, res);
        checkOrderStatus = res.status;
      });

      let decodeOrderId = this.$route.params.OrderId;
      if (this.format.test(decodeOrderId)) {
        decodeOrderId = await this.decryptOrderId(this.$route.params.OrderId);
      }

      if (
        this.otherRoutes.includes(this.$route.name) ||
        (this.PreviewrouteNames.includes(this.$route.name) && decodeOrderId != orderId)
      ) {
        if (checkAnyCompleted && !checkOrderStatus) {
          this.eventBus.emit("loadlisting", orderId);
          if (
            this.PreviewrouteNames.includes(this.$route.name) &&
            decodeOrderId != orderId
          ) {
            const extraNotification = {
              response,
              type: "notification order completed",
              status: "show",
              translate: false,
              message: `${this.PDFCompleted} ${orderId}`,
            };

            this.eventBus.emit("generateExtraNotification", extraNotification);
          } else {
            await this.notificationAdd(
              "order completed",
              `${this.PDFCompleted} ${orderId}`,
              "success",
              true,
              orderId,
              0,
              0
            );
          }
        }
      }
    },
  },
};
</script>
<style>
.dataTable-pagination-list .active a {
  background-image: linear-gradient(195deg, #66bb6a 0%, #43a047 100%) !important;
}
</style>
